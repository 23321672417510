/*eslint-disable*/
import React, { useState } from 'react'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { isNull } from 'lodash/lang'
import {
  ImageWrap,
  MessageWrap,
  PhoneNumber,
  RestorePassWrap,
  SuccessMessageHeading,
  SuccessMessageWrap
} from './styles'
import {
  AuthDescription,
  AuthHeadline,
  ScreenContainer
} from '../../../../global/styles'
import PhoneNumberInput from '../../../../components/UI/Inputs/PhoneNumberInput/PhoneNumberInput'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES,
  INPUT_SIZES
} from '../../../../const/UIvariants'
import Button from '../../../../components/UI/Buttons/Button/Button'
import ROUTES from '../../../../const/ROUTES'
import HoppaMessageSent from '../../../../assets/vector/HoppaMessageSent'
import AuthHeader from '../../../../components/AuthHeader'
import { usePostUserRestorePasswordMutation } from '../../../../api/api.generated'
import {
  setLastRestoreRequestTime,
  setRestorePhoneNumber
} from '../../authSlice'
import SuccessScreenOverlay from '../../../common/SuccessScreenOverlay'

const RestorePassword = () => {
  const [codeSent, setCodeSent] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [timeToRetry, setTimeToRetry] = useState(0)
  const lastRestoreRequestTime = useSelector(
    (state) => state.auth.lastRestoreRequestTime
  )
  const history = useHistory()
  const dispatch = useDispatch()
  const [requestPasswordRestore] = usePostUserRestorePasswordMutation()
  const handleSubmit = (values) => {
    const timePassedFromLastTry = Math.abs(
      dayjs(lastRestoreRequestTime).diff(new Date(), 'second')
    )
    setTimeToRetry(timePassedFromLastTry < 60 ? 60 - timePassedFromLastTry : 0)
    if (isNull(lastRestoreRequestTime) || timePassedFromLastTry > 60) {
      requestPasswordRestore({ body: { phoneNumber: values.phoneNumber } })
        .unwrap()
        .then(() => {})
        .catch(() => {})
        .finally(() => {
          dispatch(setLastRestoreRequestTime())
          dispatch(setRestorePhoneNumber(values.phoneNumber))
          setPhoneNumber(values.phoneNumber)
          setCodeSent(true)
        })
    }
  }

  return (
    <ScreenContainer>
      <RestorePassWrap>
        <AuthHeader />
        {codeSent ? (
          <SuccessMessageWrap>
            <ImageWrap>
              <HoppaMessageSent />
            </ImageWrap>
            <SuccessMessageHeading>
              Your request has been sent, check your messages to verify
            </SuccessMessageHeading>
            <AuthDescription align="center">
              We&apos;ve sent a verification code to
            </AuthDescription>
            <PhoneNumber>{`+${phoneNumber}`}</PhoneNumber>
            <Button
              variant={BUTTON_VARIANTS.PRIMARY}
              size={BUTTONS_SIZES.MED}
              onClick={() => {
                history.push(ROUTES.CODE_VERIFICATION)
              }}
            >
              Continue
            </Button>
          </SuccessMessageWrap>
        ) : (
          <Formik
            initialValues={{
              phoneNumber: ''
            }}
            validationSchema={Yup.object({
              phoneNumber: Yup.string()
                .matches(/^[0-9]+$/, 'Please enter valid phone number')
                .test(
                  'len',
                  'Must be exactly 11 digits',
                  (val) => val?.length === 11
                )
            })}
            onSubmit={handleSubmit}
          >
            {({ isValid }) => (
              <Form>
                <MessageWrap>
                  <AuthHeadline>Forgot your Password?</AuthHeadline>
                  <AuthDescription>
                    Enter your phone below, we’ll send you an email
                  </AuthDescription>
                </MessageWrap>
                <PhoneNumberInput
                  inputName="phoneNumber"
                  label="Phone Number"
                  type="tel"
                  placeholder="Enter your phone number"
                  size={INPUT_SIZES.LARGE}
                />
                <Button
                  variant={BUTTON_VARIANTS.PRIMARY}
                  size={BUTTONS_SIZES.MED}
                  disabled={!isValid}
                  type="submit"
                >
                  Continue
                </Button>
                {timeToRetry !== 0 && (
                  <AuthDescription>
                    Too many attempts, please try in {timeToRetry} seconds
                  </AuthDescription>
                )}
              </Form>
            )}
          </Formik>
        )}
      </RestorePassWrap>
    </ScreenContainer>
  )
}

export default RestorePassword

RestorePassword.propTypes = {}
