import React, { useState, useRef, useEffect } from 'react'
import dayjs from 'dayjs'
import ImageUploading from 'react-images-uploading'
import { isEmpty } from 'lodash/lang'
import { Attachment, Send } from '@styled-icons/material'
import { VStack } from '../../../global/styles'
import { useGetUserNotesQuery, usePostUserNoteMutation } from '../../../api/api.generated'
import usePersistOpenFriendsTab from '../../../hooks/friends/usePersistOpenFriendsTab'
import { getImageBase64 } from '../../../utils/helpers'
import NoteBubble from '../../../components/NoteBubble'
import LoadingSpinner from '../../../components/LoadingSpinner'
import IconButton from '../../../components/UI/Buttons/IconButton'
import {
  TopSectionWrap,
  ScrollWrapper,
  IsEmptyText,
  AttachButtonWrap,
  ChatInputWrap,
  Container,
  InputMessage,
  InputWrap,
  SentButton
} from './styles'

const Notes = () => {
  usePersistOpenFriendsTab('/notes')
  const { data: { notes } = {}, isLoading, error } = useGetUserNotesQuery()
  const [messageText, setMessageText] = useState('')
  const [postUserNote] = usePostUserNoteMutation()
  const notesEndRef  = useRef(null)

  const scrollToBottom = () => {
    notesEndRef.current?.scrollIntoView()
  }
  
  const handleSendMessage = () => {
    if (messageText) {
      setMessageText('')
      postUserNote({body: { message: messageText }})
    }
  }
  
  const handleSelectImage = (imageList) => {
    const selectedImage = getImageBase64({ source: imageList })
    if (selectedImage) {
      postUserNote({body: { message: null, image: selectedImage }})
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [notes])

  return (
    <VStack>
      <TopSectionWrap>
        {isLoading && <LoadingSpinner />}
        {error && <IsEmptyText variant="textM">Network Error!</IsEmptyText>}
        {!isLoading && !error && isEmpty(notes) && (
          <IsEmptyText variant="textM">No notes added yet.</IsEmptyText>
        )}
        {!isLoading && !error && !isEmpty(notes) && (
          <ScrollWrapper>
            {notes.map((note) => (
              <NoteBubble
                key={note.id}
                id={note.id}
                isOwnMessage={false}
                timeStamp={dayjs(note.timeStamp).fromNow()}
                messageImage={note.image}
                messageText={note.messageText}
                isViewed
                shopId={note.shopId}
                shopName={note.shopName}
              />
            ))}
          <div ref={notesEndRef} />
          </ScrollWrapper>
        )}
      </TopSectionWrap>
      <Container>
        <ChatInputWrap>
          <InputWrap>
            <InputMessage
              value={messageText}
              onChange={(e) => {
                setMessageText(e.target.value)
              }}
              placeholder="Enter Message"
            />
            <AttachButtonWrap>
              <ImageUploading
                value={[]}
                onChange={handleSelectImage}
                acceptType={['jpg', 'gif', 'png']}
                maxFileSize={4000000}
                dataURLKey="data_url"
              >
                {({ onImageUpload }) => (
                  <IconButton size={24} onClick={onImageUpload}>
                    <Attachment size={24} color="#A0A7B5" />
                  </IconButton>
                )}
              </ImageUploading>
            </AttachButtonWrap>
          </InputWrap>
          <SentButton onClick={handleSendMessage}>
            <Send size={24} color="#ffffff" />
          </SentButton>
        </ChatInputWrap>
      </Container>
    </VStack>
  )
}

export default Notes

Notes.propTypes = {}
