import React from 'react'
import { isEmpty } from 'lodash/lang'
import PropTypes from 'prop-types'
import { Store } from '@styled-icons/material'
import { withTheme } from 'styled-components'
import { EmptyShopLogo, ShopLogoImage } from './styles'
import { getImageFromServer, getImageBase64 } from '../../utils/helpers'

const ShopLogo = ({
  errors,
  source,
  logoUrl,
  size,
  theme,
  handleClick,
  isCurrentTarget
}) =>
  !isEmpty(source) || !isEmpty(logoUrl) ? (
    <ShopLogoImage
      isCurrentTarget={isCurrentTarget}
      onClick={handleClick}
      size={size}
      error={!isEmpty(errors)}
      src={
        getImageBase64({ source }) ?? logoUrl.includes('lorem')
          ? logoUrl
          : getImageFromServer(logoUrl)
      }
      alt="store logo"
    />
  ) : (
    <EmptyShopLogo
      size={size}
      onClick={handleClick}
      isCurrentTarget={isCurrentTarget}
    >
      <Store size={24} color={theme.colors.skyNeutral3} />
    </EmptyShopLogo>
  )

export default withTheme(ShopLogo)
ShopLogo.defaultProps = {
  errors: false,
  source: [],
  logoUrl: '',
  size: 48,
  isCurrentTarget: false,
  handleClick: () => {}
}
ShopLogo.propTypes = {
  errors: PropTypes.bool,
  theme: PropTypes.object.isRequired,
  source: PropTypes.array,
  logoUrl: PropTypes.string,
  size: PropTypes.number,
  handleClick: PropTypes.func,
  isCurrentTarget: PropTypes.bool
}
