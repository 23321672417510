import styled from 'styled-components'
import { Form } from 'formik'
import Typography from '../../UI/Typography'
import { HStack } from '../../../global/styles'

export const StyledForm = styled(Form)`
  padding: 16px;
  min-width: 50vw;
`
export const ModalHeadline = styled(Typography).attrs(({ theme }) => ({
  variant: 'textM',
  bold: true,
  color: theme.colors.skyNeutral
}))`
  text-align: center;
  margin-bottom: 30px;
`
export const ButtonWrapper = styled(HStack)`
  > :first-child {
    margin-right: 16px;
  }
`
export const ErrorWrap = styled(HStack)`
  padding: 0 32px 32px 32px;
  width: 100%;
  align-items: stretch;
`
