import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  TagIconBackground,
  TagIconShiftedBg,
  TagLabel,
  TagWrapper
} from './styles'
import { selectTagById } from '../../store/selectors'
import { TAG_ICONS } from '../../const/app'
import useSelectCategory from '../../hooks/homeScreen/useSelectCategory'

const HomeScreenTag = ({ tagId, allowClick }) => {
  const tagData = useSelector(selectTagById(tagId))
  const { handleSelectCategory } = useSelectCategory()
  return (
    tagData && (
      <TagWrapper
        onClick={() => {
          if (allowClick) {
            handleSelectCategory(tagId)
          }
        }}
      >
        <TagIconBackground bgColor={tagData.backgroundColor}>
          <TagIconShiftedBg bgColor={tagData.backgroundColor} />
          {React.cloneElement(TAG_ICONS[tagData.icon], {
            width: 24,
            height: 24,
            color: '#ffffff',
            style: {
              filter: 'drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.25))'
            }
          })}
        </TagIconBackground>
        <TagLabel>{tagData.tagName}</TagLabel>
      </TagWrapper>
    )
  )
}

export default HomeScreenTag

HomeScreenTag.propTypes = {
  tagId: PropTypes.string.isRequired,
  allowClick: PropTypes.bool.isRequired
}
