/*eslint-disable*/
import React from 'react'
import dayjs from 'dayjs'
import { isNull } from 'lodash/lang'
import { ArrowBack, Directions, Star } from '@styled-icons/material'
import { VStack } from '../../../global/styles'
import usePreviewShopRoute from '../../../hooks/route/usePreviewShopRoute'
import IconButton from '../../../components/UI/Buttons/IconButton'
import ShopLogo from '../../../components/ShopLogo/ShopLogo'
import DistanceToShop from '../../../components/DistanceToShop'
import GoNowButton from '../../../features/shopActionsButtons/GoNowButton'
import { BUTTONS_SIZES } from '../../../const/UIvariants'
import {
  IconButtonWrap,
  LastVisited,
  Rating,
  ShopAddress,
  ShopDataWrap,
  ShopMetaData,
  ShopName,
  ShopRating
} from '../../../components/ShopPreviewItem/styles'
import {
  Headline,
  PreviewRouteHeader,
  PreviewRouteWrap,
  PreviewShopContainer
} from './styles'

const PreviewRouteContainer = () => {
  const { startRouteToShop, closePreview, shopToGo } = usePreviewShopRoute()
  // todo: refactor shop logo+address and rating+ distance+lastVisited to components
  return (
    <PreviewRouteWrap>
      <PreviewRouteHeader>
        <IconButton onClick={closePreview}>
          <ArrowBack size={24} color="#737E92" />
        </IconButton>
        <Headline>Route Preview</Headline>
      </PreviewRouteHeader>
      <PreviewShopContainer>
        <ShopLogo logoUrl={shopToGo.logotype} />
        <ShopDataWrap>
          <VStack>
            <ShopName>{shopToGo.storeName}</ShopName>
            <ShopAddress>{shopToGo.location.displayAddress}</ShopAddress>
          </VStack>
          <ShopMetaData>
            <ShopRating>
              <Star size={16} color="hsla(47, 86%, 59%, 1)" />
              <Rating>{shopToGo.rating}</Rating>
            </ShopRating>
            <DistanceToShop
              shopId={shopToGo.id}
              initialDistance={shopToGo.location.displayDistance}
            />
            { !isNull(shopToGo.lastVisited) &&
            <LastVisited>{ dayjs(shopToGo.lastVisited).format('DD-MM-YY') }</LastVisited>
            }
          </ShopMetaData>
        </ShopDataWrap>
        <IconButtonWrap>
            <GoNowButton
              id={shopToGo.id}
              lng={shopToGo?.location?.lng}
              lat={shopToGo?.location?.lat}
              size={BUTTONS_SIZES.SMALL}
            />
          { false &&
          <IconButton onClick={startRouteToShop}>
            <Directions size={20} color="#2D6CE4" />
          </IconButton>
          }
        </IconButtonWrap>
      </PreviewShopContainer>
    </PreviewRouteWrap>
  )
}

export default PreviewRouteContainer

PreviewRouteContainer.propTypes = {}
