import { generatePath, useHistory, useLocation } from 'react-router-dom'
import ROUTES from '../../const/ROUTES'

const useShopDetails = () => {
  const history = useHistory()
  const location = useLocation()

  const handleOpenShop = (shopId) => {
    if (location.pathname.includes('chat/chat-room')) {
      history.push(
        ROUTES.HOME +
          generatePath(ROUTES.SHOP_OVERLAY, { shopId }).concat(
            ROUTES.SHOP_DETAILS
          )
      )
    } else {
      history.push(
        location.pathname +
          generatePath(ROUTES.SHOP_OVERLAY, { shopId }).concat(
            ROUTES.SHOP_DETAILS
          )
      )
    }
  }
  return { handleOpenShop }
}
export default useShopDetails
