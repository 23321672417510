import styled from 'styled-components'
import { HStack, VStack } from '../../global/styles'
import Typography from '../UI/Typography'

export const QuestionItemWrap = styled(HStack)`
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  position: relative;
  box-shadow: inset 0px -1px 0px rgba(208, 201, 214, 0.4);
  padding: 10px 0;
`
export const QuestionDetailsWrap = styled(VStack)`
  margin-left: 20px;
  justify-content: flex-end;
  align-self: stretch;
`

export const QuestionTitle = styled(Typography).attrs(({ theme }) => ({
  variant: 'textM',
  color: theme.colors.skyNeutral_1
}))``
