import React from 'react'
import { ScaleLoader } from 'react-spinners'
import PropTypes from 'prop-types'
import { withTheme } from 'styled-components'
import { VStack } from '../../global/styles'

const LoadingSpinner = ({ size, theme }) => (
  <VStack
    justify="center"
    alignItems="center"
    style={{ padding: `${size / 2}px 0` }}
  >
    <ScaleLoader height={size} color={theme.colors.skyBase} />
  </VStack>
)

export default withTheme(LoadingSpinner)

LoadingSpinner.defaultProps = {
  size: 32
}
LoadingSpinner.propTypes = {
  size: PropTypes.number,
  theme: PropTypes.object.isRequired
}
