import React from 'react'
import PropTypes from 'prop-types'
import { values } from 'lodash'
import {
  ACTION_GO_NOW,
} from '../../../const/app'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES,
  BUTTONS_SIZES as BUTTON_SIZES
} from '../../../const/UIvariants'
import Button from '../../../components/UI/Buttons/Button/Button'
import { usePostUserActionMutation } from '../../../api/api.generated'

const GoNowButton = ({ lat, lng, size, id }) => {
  const [postAction] = usePostUserActionMutation()

  const handleGoNowGoogle = () => {
    postAction({ body: {actionId: ACTION_GO_NOW, shopId: id } })

    window.open(
      `https://www.google.com/maps/dir/?api=1&dir_action=navigate&destination=${lat},${lng}`,
      '_blank'
    )
  }

  return (
    <Button
      variant={BUTTON_VARIANTS.PRIMARY}
      size={size}
      onClick={handleGoNowGoogle}
    >
      Go Now
    </Button>
  )
}

export default GoNowButton

GoNowButton.defaultProps = {
  size: BUTTON_SIZES.MED
}
GoNowButton.propTypes = {
  lat: PropTypes.string.isRequired,
  lng: PropTypes.string.isRequired,
  size: PropTypes.oneOf(values(BUTTONS_SIZES)),
  id: PropTypes.string.isRequired
}
