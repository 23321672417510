import { createSlice } from '@reduxjs/toolkit'
import { mapValues } from 'lodash/object'
import { logout } from '../auth/authSlice'

const settingsInitState = {
  notifications: {
    locationBased: true,
    aboutReview: true,
    termUpdate: true,
    reward: true
  },
  isTutorialOn: false
}

const settingsSlice = createSlice({
  name: 'settings',
  initialState: settingsInitState,
  reducers: {
    resetNotifications: (state) => {
      state.notifications = { ...settingsInitState.notifications }
    },
    turnOfNotifications: (state) => {
      state.notifications = mapValues(state.notifications, () => false)
    },
    changeNotificationsSettings: (state, { payload }) => {
      state.notifications[payload.type] = payload.state
    },
    toggleTutorial: (state, { payload }) => {
      state.isTutorialOn = payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => settingsInitState)
  }
})
export default settingsSlice.reducer

export const {
  resetNotifications,
  turnOfNotifications,
  changeNotificationsSettings
} = settingsSlice.actions
