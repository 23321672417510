import styled from 'styled-components'
import { get } from 'lodash/object'
import PropTypes from 'prop-types'
import { typographyVariants } from '../../../global/theme'

const Typography = styled.p.attrs(({ variant, component }) => ({
  as: component || get(typographyVariants, `${variant}.component`, 'p')
}))`
  text-align: ${({ align }) => align};
  color: ${({ color }) => color ?? 'inherit'};
  font-size: ${({ variant }) =>
    get(typographyVariants, `${variant}.fontSize`, 16)}px;
  line-height: ${({ variant }) =>
    get(typographyVariants, `${variant}.lineHeight`, 16)}px;
  font-weight: ${({ bold, weight }) => (bold ? 600 : weight)};
`

export default Typography

Typography.defaultProps = {
  align: 'left',
  component: 'p',
  color: null,
  bold: false,
  weight: 400
}
Typography.propTypes = {
  variant: PropTypes.oneOf([
    'headingXL',
    'headingL',
    'headingM',
    'headingS',
    'textL',
    'textM',
    'textS',
    'textXS',
    'textXXS',
    'textTiny',
    'labelL',
    'labelM',
    'labelS'
  ]).isRequired,
  align: PropTypes.oneOf(['left', 'center', 'right']),
  component: PropTypes.oneOf([
    'p',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'span',
    'label'
  ]),
  color: PropTypes.string,
  bold: PropTypes.bool,
  children: PropTypes.node.isRequired,
  weight: PropTypes.number
}
