import styled from 'styled-components'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { pxToVh } from '../../../utils/styles-utils'
import Typography from '../../../components/UI/Typography'
import { VStack } from '../../../global/styles'

// eslint-disable-next-line import/prefer-default-export
export const OrderContentWrapper = styled(OverlayScrollbarsComponent).attrs({
  options: {
    scrollbars: { visibility: 'hidden' }
  }
})`
  height: 100%;
  margin-top: ${pxToVh(100)};
  width: 100%;
  padding: 0 0 20px 0;
`
export const OrdersWrap = styled(VStack)`
  padding: 0;
`
export const IsEmptyText = styled(Typography)`
  color: ${({ theme }) => theme.colors.skyNeutral_1};
  text-align: center;
  align-self: center;
  margin-top: 30px;
`
