import { cloneDeep } from 'lodash'
import { get } from 'lodash/object'
import { isEmpty } from 'lodash/lang'
import { findIndex } from 'lodash/array'
import dayjs from 'dayjs'
import { STORAGE_URL } from '../const/api'

export const getHourAMPM = (dateTime) => dayjs(dateTime).format('h:mm a')

export const getImageFromServer = (address, storageUrl = STORAGE_URL) => {
  if (!address) {
    return null
  }
  if (address && address.includes('data:image')) {
    return address
  }

  return `${storageUrl}${address ?? ''}`
}

export const getImageBase64 = ({ source, imageIndex = 0 }) =>
  get(source, `[${imageIndex}].data_url`, null)

export const formatPostDeadline = (dateStart, dateEnd) => {
  const endYear = dayjs(dateEnd).format('YYYY')
  const startYear = dayjs(dateStart).format('YYYY')
  const postDeadline = `${dayjs(dateStart).format('DD MMM')} ${
    startYear !== endYear ? startYear : ''
  } - ${dayjs(dateEnd).format('DD MMM YYYY')}`
  return postDeadline
}

const minutesFromDayStart = (time) =>
  parseInt(dayjs(time).format('H'), 10) * 60 +
  parseInt(dayjs(time).format('m'), 10)

export const isShopOpen = (shopSchedule) => {
  const currentDay = dayjs().format('dddd').toLowerCase()
  const { open, close } = shopSchedule[currentDay]
  const minutesFromDayStartToOpenTime = minutesFromDayStart(open)
  const minutesFromDayStartToCloseTime = minutesFromDayStart(close)

  if (shopSchedule[currentDay].isOpen === false) {
    return false
  }
  return (
    minutesFromDayStartToOpenTime < minutesFromDayStart(dayjs()) &&
    minutesFromDayStartToCloseTime > minutesFromDayStart(dayjs())
  )
}
export const truncateLongShopName = (shopName, maxLength) =>
  shopName?.length && shopName.slice(0, maxLength)

export const truncateSentence = (sentence, maxLength = 20) =>
  sentence.split(' ').reduce((result, currWord) => {
    const added = `${result} ${currWord}`
    if (added.length < maxLength) {
      return added
    }
    if (result.endsWith('...')) {
      return result
    }
    return `${result}...`
  })

export const getBase64 = (file) =>
  new Promise((resolve) => {
    let baseURL = ''
    const reader = new FileReader()

    // Convert the file to base64 text
    reader.readAsDataURL(file)

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      baseURL = reader.result
      resolve(baseURL)
    }
  })

export const getUserInitials = (initUserName) => {
  if (isEmpty(initUserName)) return initUserName
  const nameToArray = initUserName.split(' ')
  const abbreviatedName = nameToArray
    .map((word) => word[0])
    .join('')
    .toUpperCase()
  if (abbreviatedName.length > 2) {
    return abbreviatedName.slice(0, 2)
  }
  return abbreviatedName
}

export const getMonthList = () => 
  [
    { value: '01', label: '01' },
    { value: '02', label: '02' },
    { value: '03', label: '03' },
    { value: '04', label: '04' },
    { value: '05', label: '05' },
    { value: '06', label: '06' },
    { value: '07', label: '07' },
    { value: '08', label: '08' },
    { value: '09', label: '09' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' }
  ]

export const getYearList = () => {
  const now = new Date().getUTCFullYear()
  const years = Array(10)
    .fill('')
    .map((v, idx) => now + idx)
  return years.map((value) => ({
    value: value.toString(),
    label: value.toString()
  }))
}

export const getErrorMessage = (err) => {
  if (err && err.data && err.data.message) {
    if (err.data.message.error) {
      return err.data.message.error
    }
    let message = ''
    Object.keys(err.data.message).forEach((key) => {
      message += `${err.data.message[key]} `
    })
    return message
  }
  return 'Network error'
}

export const mergeArrays = (original, changes) => {
  if (original.length > 0) {
    const arr = cloneDeep(original)
    changes.forEach(element => {
      const index = findIndex(arr, {'id' : element.id});
      if (index === -1) {
        arr.push(element)
      } else {
        arr[index] = element
      }
    });
    return arr
  }
  return changes
}

export const mergeArraysBottom = (original, changes) => {
  if (original.length > 0) {
    const arr = cloneDeep(original)
    const arrInsert = [];
    changes.forEach(element => {
      const index = findIndex(arr, {'id' : element.id});
      if (index === -1) {
        arrInsert.push(element)
      } else {
        arr[index] = element
      }
    });
    return arrInsert.concat(arr)
  }
  return changes
}