import React from 'react'
import { isEmpty } from 'lodash/lang'
import { AutoSizer, List } from 'react-virtualized'
import LoadingSpinner from '../../../components/LoadingSpinner'
import ShopPreviewItem from '../../../components/ShopPreviewItem'
import usePersistOpenHopTab from '../../../hooks/hop/usePersistOpenHopTab'
import useShopDetails from '../../../hooks/shop/useShopDetails'
import useGetCityShops from '../../../hooks/hop/useGetCityShops'
import 'react-virtualized/styles.css'
import { WindowedListWrapper } from './styles'

const HopShops = () => {
  usePersistOpenHopTab('/stores')
  const { filteredSortedShops, isLoading, closestShop } = useGetCityShops()
  const { handleOpenShop } = useShopDetails()
  const rowRenderer = ({ key, index, style }) => (
    <div style={{ ...style, padding: '0px 16px 0 16px' }} key={key}>
      <ShopPreviewItem
        id={filteredSortedShops[index].id}
        handleOpenShop={() => {
          handleOpenShop(filteredSortedShops[index].id)
        }}
        address={filteredSortedShops[index].location.displayAddress}
        shopName={filteredSortedShops[index].storeName}
        isFavorite={filteredSortedShops[index].isFavorite}
        lastVisited={filteredSortedShops[index].lastVisited}
        favoriteText={filteredSortedShops[index].favoriteText}
        distance={filteredSortedShops[index].location.displayDistance}
        lat={filteredSortedShops[index].location.lat}
        lng={filteredSortedShops[index].location.lng}
        logotype={filteredSortedShops[index].logotype}
        rating={filteredSortedShops[index].rating}
        isClosest={closestShop === index}
        sequence={filteredSortedShops[index].sequence}
      />
    </div>
  )
  return (
    <>
      {isLoading && <LoadingSpinner />}
      {!isEmpty(filteredSortedShops) && (
        <WindowedListWrapper>
          <AutoSizer>
            {({ width, height }) => (
              <List
                scrollToIndex={closestShop}
                height={height}
                rowCount={filteredSortedShops.length}
                rowHeight={270}
                rowRenderer={rowRenderer}
                width={width}
              />
            )}
          </AutoSizer>
        </WindowedListWrapper>
      )}
    </>
  )
}

export default HopShops

HopShops.propTypes = {}
