import { useState, useEffect } from 'react'

const useTimer = ({ initiateOnMount } = { initiateOnMount: false }) => {
  const [seconds, setSeconds] = useState(null)
  const startTimer = (delay) => {
    setSeconds(delay)
  }

  useEffect(() => {
    if (initiateOnMount) {
      startTimer(60)
    }
  }, [])
  useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      }
      if (seconds === 0) {
        clearInterval(myInterval)
      }
    }, 1000)
    return () => {
      clearInterval(myInterval)
    }
  }, [seconds])
  return { seconds, startTimer }
}

export default useTimer
