import styled from 'styled-components'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { ScreenContainer, VStack } from '../../../global/styles'

export const FindFriendsContainer = styled(ScreenContainer)`
  z-index: 100;
  background-color: white;
  position: fixed;
  top: 0;
  justify-content: flex-start;
`
export const SearchHeader = styled(VStack)`
  min-height: 132px;

  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.03);
  justify-content: flex-end;
`
export const StyledForm = styled.form`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  padding: 16px;
`

export const StyledScrollbars = styled(OverlayScrollbarsComponent).attrs({
  options: {
    scrollbars: { visibility: 'hidden' }
  }
})`
  width: 100%;
  height: 100%;
`
export const SearchResultsWrap = styled(VStack)`
  position: relative;
  height: 100%;
`
