import React from 'react'
import { InsertPhoto } from '@styled-icons/material'
import ImageUploading from 'react-images-uploading'
import {
  useGetUserShoppingCartQuery
} from '../../../api/api.generated'
import UserAvatar from '../../../components/UserAvatar'
import IconButton from '../../../components/UI/Buttons/IconButton'
import ROUTES from '../../../const/ROUTES'
import LoadingSpinner from '../../../components/LoadingSpinner'
import Button from '../../../components/UI/Buttons/Button'
import { BUTTON_VARIANTS, BUTTONS_SIZES } from '../../../const/UIvariants'
import ScreenHeader from '../../../components/ScreenHeader'
import {
  ButtonWrap,
  ButtonWrapper,
  Container,
  MenuItemsWrap,
  ScrollablePart,
  ScrollWrapper,
  UserInfo,
  Headline,
  DescriptionWrapper,
  Description,
  Value
} from './styles'
import MenuItemCell from '../../../components/MenuItemCell'
import useUserProfile from '../../../hooks/settings/useUserProfile'

const menuItems = [
  { label: 'Shopping Cart', route: ROUTES.SHOPPING_CART },
  { label: 'Orders', route: ROUTES.ORDERS },
  { label: 'Subscribed Stores', route: ROUTES.SUBSCRIBED_STORES },
  { label: 'Favourite Stores', route: ROUTES.FAVOURITE_STORES },
  { label: 'Recently Visited', route: ROUTES.RECENTLY_VISITED },
  { label: 'Profile', route: ROUTES.SETTINGS_ACCOUNT },
  { label: 'Suggestions', route: ROUTES.SETTINGS_SEND_FEEDBACK },
  { label: 'Change Password', route: ROUTES.SETTINGS_CHANGE_PASSWORD }
]
const SettingsScreen = () => {
  const {
    userData,
    isLoading,
    handleUpdateUserAvatar,
    handleLogout,
    isUploadingNewAvatar
  } = useUserProfile()
  const { data: { shopProducts } = {} } = useGetUserShoppingCartQuery()
  return (
    <Container>
      <ScreenHeader
        title="Account"
        backButton={false}
        actionTitle="Hop Tutorial"
      />
      <ScrollWrapper>
        <ScrollablePart>
          {isLoading && <LoadingSpinner />}
          {userData && (
            <UserInfo>
              <UserAvatar
                size={120}
                userName="UserName"
                image={userData.userAvatar}
                bgColor={userData?.avatarBackground}
              />
              {isUploadingNewAvatar && <LoadingSpinner />}
              <ButtonWrapper>
                <ImageUploading
                  value={[]}
                  onChange={handleUpdateUserAvatar}
                  acceptType={['jpg', 'gif', 'png']}
                  maxFileSize={4000000}
                  dataURLKey="data_url"
                >
                  {({ onImageUpload }) => (
                    <IconButton size={32} onClick={onImageUpload}>
                      <InsertPhoto size={12} color="#ffffff" />
                    </IconButton>
                  )}
                </ImageUploading>
              </ButtonWrapper>
              <Headline>{userData.username}</Headline>
              <Description>+{userData.phoneNumber}</Description>
              <DescriptionWrapper>
              <Description>Attended:&nbsp;</Description>
              <Value>{userData.attendedPostCount}</Value>
              <Description>&nbsp;Got Deal:&nbsp;</Description>
              <Value>{userData.gotDealPostCount}</Value>
              </DescriptionWrapper>
            </UserInfo>
          )}
          <MenuItemsWrap>
            {menuItems.map((item) => (
              <MenuItemCell
                key={item.label}
                label={item.label}
                destination={item.route}
                count={
                  item.label === 'Shopping Cart'
                    ? shopProducts?.length ?? 0
                    : null
                }                
              />
            ))}
          </MenuItemsWrap>
          <ButtonWrap>
            <Button
              size={BUTTONS_SIZES.LARGE}
              variant={BUTTON_VARIANTS.SECONDARY}
              onClick={handleLogout}
              isFullWidth
            >
              Logout
            </Button>
          </ButtonWrap>
        </ScrollablePart>
      </ScrollWrapper>
    </Container>
  )
}

export default SettingsScreen

SettingsScreen.propTypes = {}
