import { createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { keyBy } from 'lodash/collection'
import { api } from '../../api/api.generated'
import { logout } from '../auth/authSlice'
import { getDistanceBetweenTwoPoints } from '../../utils/map-utils'

const appInitState = {
  showAddToFavoriteModal: false,
  isRateShopModalOpen: false,
  addToFavoritesShopId: null,
  hereWeGoToken: null,
  hereWeGoTokenExpirationDate: 0,
  currentLat: -37.795199,
  currentLng: 144.96571,
  lastUpdatedAtLat: 0,
  lastUpdatedAtLng: 0,
  initialLat: 0,
  initialLng: 0,
  tagsById: {}
}
const appSlice = createSlice({
  name: 'app',
  initialState: appInitState,
  reducers: {
    setInitialLocation: (state, { payload }) => {
      state.initialLat = payload.lat
      state.initialLng = payload.lng
    },
    setCurrentLocation: (state, { payload }) => {
      state.currentLat = payload.lat
      state.currentLng = payload.lng
    },
    setLastUpdatedAtLocation: (state, { payload }) => {
      state.lastUpdatedAtLat = payload.lat
      state.lastUpdatedAtLng = payload.lng
    },

    setCurrentCity: (state, { payload }) => {
      state.currentCity = payload?.city
      state.currentCityId = payload?.currentCityId
    },

    openAddToFavoritesModal: (state, { payload }) => {
      state.showAddToFavoriteModal = true
      state.addToFavoritesShopId = payload.selectedShopId
    },
    closeAddToFavoritesModal: (state) => {
      state.showAddToFavoriteModal = false
      state.addToFavoritesShopId = null
    },
    toggleRateShopModal: (state) => {
      state.isRateShopModalOpen = !state.isRateShopModalOpen
    },
    clearHereToken: (state) => {
      state.hereWeGoToken = null
      state.hereWeGoTokenExpirationDate = 0
    },
    reset: () => appInitState
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout, () => appInitState)
      .addMatcher(
        api?.endpoints?.getHereWeGoToken?.matchFulfilled,
        (state, { payload }) => {
          state.hereWeGoToken = payload.token
          state.hereWeGoTokenExpirationDate = dayjs().valueOf()
        }
      )
      .addMatcher(
        api?.endpoints?.getTags?.matchFulfilled,
        (state, { payload }) => {
          state.tagsById = keyBy(payload.tags, 'id')
        }
      )
      .addMatcher(
        api?.endpoints?.getUserCities?.matchFulfilled,
        (state, { payload }) => {
          state.cities = payload.cities
          const distances = {}
          payload.cities.forEach((city) => {
            distances[city.id] = getDistanceBetweenTwoPoints(
              { lat: state.currentLat, lng: state.currentLng },
              { lat: city.location.lat, lng: city.location.lng }
            )
          })
          state.distanceToCities = { ...distances }
        }
      )
  }
})
export default appSlice.reducer

export const {
  openAddToFavoritesModal,
  closeAddToFavoritesModal,
  toggleRateShopModal,
  clearHereToken,
  setCurrentLocation,
  setLastUpdatedAtLocation,
  setInitialLocation,
  setCurrentCity
} = appSlice.actions
