import styled from 'styled-components'
import { HStack } from '../../global/styles'

// eslint-disable-next-line import/prefer-default-export
export const BadgeBody = styled(HStack)`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  background-color: ${({ theme }) => theme.colors.greenBase};
  border-radius: 12px;
  padding: 0 6px;
  width: auto;
  align-items: center;
  justify-content: space-between;
  > *:first-child {
    margin-right: 4px;
  }
`
