import React, { useEffect, useState, useRef, useCallback } from 'react'
import ReactPlayer from 'react-player/lazy'
import { isEmpty } from 'lodash/lang'
import PropTypes from 'prop-types'
import { mergeArrays } from '../../../utils/helpers'
import { useLazyGetUserShopByShopIdVideosQuery } from '../../../api/api.generated'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import { IsEmptyText } from '../ShopPosts/styles'
import { GalleryWrap, ImageWrap } from './styles'

const ShopVideos = ({ shopId }) => {
  const [hasMore, setHasMore] = useState(true)
  const [page, setPage] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [videos, setVideos] = useState([])
  const observer = useRef();
  const [getUserShopByShopIdVideos, { data: { shopVideos } = {}, isLoading: loadingVideos }] = useLazyGetUserShopByShopIdVideosQuery()
  const ITEMS_PER_PAGE = 20

  const lastElementRef = useCallback(  
    (node) => {
      if (isLoading) return;
      if (!hasMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setPage((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore]
  );

  const handleShowMore = () => {
    setIsLoading(true)
    getUserShopByShopIdVideos({ shopId, page, limit: ITEMS_PER_PAGE })
  }

  useEffect(() => {
    handleShowMore()
  }, [])

  useEffect(() => {
    handleShowMore()
  }, [page])

  useEffect(() => {
    if (shopVideos) {
      setIsLoading(false)
      setHasMore(shopVideos.length === ITEMS_PER_PAGE)
      setVideos(mergeArrays(videos, shopVideos))
    }
  }, [shopVideos])

  return (
    <>
      {loadingVideos && <LoadingSpinner />}
      {videos && (
        <GalleryWrap>
          {isEmpty(videos) && (
            <IsEmptyText>This store doesn&apos;t have any videos</IsEmptyText>
          )}
          {videos.map((video) => (
            <ImageWrap key={video.id}>
              <ReactPlayer controls width={320} height={180} url={video.url} />
            </ImageWrap>
          ))}
        </GalleryWrap>
      )}
      {isLoading &&
      <div>Loading...</div>
      }
      {!isLoading && hasMore &&
      <div ref={lastElementRef}>&nbsp;</div>
      }      
    </>
  )
}

export default ShopVideos

ShopVideos.propTypes = {
  shopId: PropTypes.string.isRequired
}
