import React from 'react'
import PropTypes from 'prop-types'
import { useField } from 'formik'
import { Star } from '@styled-icons/material'
import { withTheme } from 'styled-components'
import { StarsWrapper } from './styles'

const rates = Array.from(Array(5).keys())

const RatingInput = ({ name, theme }) => {
  const [field, meta, helpers] = useField({ name })
  const handleSetRate = (rate) => {
    if (meta.value !== rate) {
      helpers.setValue(rate)
    }
  }
  return (
    <StarsWrapper justify="space-between">
      {rates.map((ratePoint) => (
        <Star
          key={ratePoint}
          size={28}
          onClick={() => {
            handleSetRate(ratePoint)
          }}
          color={
            field.value >= ratePoint
              ? theme.colors.yellowBase
              : theme.colors.skyNeutral3
          }
        />
      ))}
    </StarsWrapper>
  )
}

export default withTheme(RatingInput)

RatingInput.propTypes = {
  name: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired
}
