import Modal from 'react-modal'

Modal.defaultStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'hsla(0, 0%, 0%, 0.2)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 300
  },
  content: {
    position: 'relative',
    margin: 'auto 16px',
    border: '1px solid #ccc',
    background: '#fff',
    maxHeight: '100vh',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '12px',
    outline: 'none',
    padding: '0'
  }
}
Modal.setAppElement('#root')
