import styled from 'styled-components/macro'
import { Form } from 'formik'
import { HStack, VStack } from '../../../global/styles'

export const TitleWrap = styled(HStack)`
  padding: 32px 32px 0 32px;
  width: 100%;
  align-items: stretch;
`
export const ButtonWrap = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  width: 47%;
  > :first-child {
    margin-right: 16px;
  }  
`
export const ActionButtonsSection = styled.div`
  display: flex;
  border-top: 1px solid hsla(220, 66%, 8%, 0.1);
  padding: 16px 32px;
`
export const FormInputsWrap = styled(VStack)`
  padding: 32px 32px 0 32px;
  width: 100%;
  align-items: stretch;
`
export const StyledForm = styled(Form)`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: column;
`
export const FormWrap = styled(VStack)`
  justify-content: flex-start;

  min-height: 500px;
`
export const InputsWrap = styled(VStack)`
  width: 100%;
  padding: 24px;
  &:first-child {
  }
`
export const ErrorWrap = styled(HStack)`
  padding: 0 32px 32px 32px;
  width: 100%;
  align-items: stretch;
`

export const CreditCardExpire = styled(HStack)`
  padding: 0;
  margin: 0;
`
