import React, { useContext } from 'react'
import { MyLocation } from '@styled-icons/material'
import { shallowEqual, useSelector } from 'react-redux'
import { StyledButton } from './styles'
import MapContext from '../../MapContext'
import { selectCurrentLocation } from '../../../../store/selectors'

const CenterOnCurrentLocation = () => {
  const mapInstance = useContext(MapContext)

  const pickCurrentLocation = useSelector(selectCurrentLocation, shallowEqual)

  const centerOnCurrentLocation = () => {
    const { currentLat, currentLng } = pickCurrentLocation
    mapInstance.setCenter({ lat: currentLat, lng: currentLng }, true)
  }

  return (
    <StyledButton onClick={centerOnCurrentLocation}>
      <MyLocation size={24} />
    </StyledButton>
  )
}

export default CenterOnCurrentLocation

CenterOnCurrentLocation.propTypes = {}
