import React from 'react'
import { Close, Search } from '@styled-icons/material'
import PropTypes from 'prop-types'
import { SearchInputBody, InputWrap, SearchIconWrap } from './styles'
import IconButton from '../UI/Buttons/IconButton'

const SearchInput = ({ handleClose, placeholder, handleChange, value }) => (
  <>
    <InputWrap>
      <SearchIconWrap>
        <Search size={24} color="#5B677F" />
      </SearchIconWrap>
      <SearchInputBody
        autoFocus
        type="search"
        onChange={handleChange}
        {...{ placeholder, value }}
      />
    </InputWrap>
    <IconButton onClick={handleClose}>
      <Close size={24} color="#5B677F" />
    </IconButton>
  </>
)

export default SearchInput

SearchInput.propTypes = {
  placeholder: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
}
