import { useHistory } from 'react-router-dom'
import { usePostUserSupportRequestMutation } from '../../api/api.generated'
import { adataptSupportFormData } from '../../utils/adapters'

const useSupportRequest = () => {
  const history = useHistory()
  const images = []
  const [sendSupportRequest] = usePostUserSupportRequestMutation()
  const sendRequest = async (values) => {
    const adaptedData = await adataptSupportFormData(values)
    sendSupportRequest(adaptedData)
      .unwrap()
      .then(() => {
        history.goBack()
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
  }

  return { sendRequest, images }
}

export default useSupportRequest
