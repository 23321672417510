import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ArrowBackIos } from '@styled-icons/material'
import PropTypes from 'prop-types'
import { useDeleteChatRoomMutation } from '../../api/api.generated'
import {  BUTTON_VARIANTS, BUTTONS_SIZES } from '../../const/UIvariants'
import { getErrorMessage } from '../../utils/helpers'
import Button from '../UI/Buttons/Button'
import IconButton from '../UI/Buttons/IconButton'
import DeleteConfirmationModal from '../DeleteConfirmationModal'
import ShowMessageModal from '../ShowMessageModal'
import UserAvatar from '../UserAvatar'
import {
  HeaderContainer,
  HeaderWrapper,
  HeaderButtonWrap,
  UserDataWrap,
  UserName,
  ContactNameWrap,
  Pronouns
} from './styles'

const ChatRoomHeader = ({ roomId, userName, userAvatar, bgColor, preferredPronouns }) => {
  const { goBack } = useHistory()
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [error, setError] = useState('')
  const [deleteChatRoom] = useDeleteChatRoomMutation()

  const handleDeleteSelected = () => {
    setShowConfirmDelete(false)
    deleteChatRoom(roomId)
      .unwrap()
      .then(() => {
        goBack()
      })
      .catch((err) => {
        setError(getErrorMessage(err))
      })
  }

  return (
    <HeaderContainer>
      <HeaderWrapper>
        <IconButton
          size={24}
          onClick={() => {
            goBack()
          }}
        >
          <ArrowBackIos size={24} color="#454E60" />
        </IconButton>
        <UserDataWrap>
          <UserAvatar
            size={40}
            image={userAvatar}
            userName={userName}
            bgColor={bgColor}
          />
          <ContactNameWrap>
            <UserName>{userName}</UserName>
            {preferredPronouns !== '' &&
            <Pronouns>({preferredPronouns})</Pronouns>
            }
          </ContactNameWrap>
        </UserDataWrap>
        <HeaderButtonWrap>
          <Button
            variant={BUTTON_VARIANTS.PRIMARY}
            size={BUTTONS_SIZES.MED}
            type="button"
            onClick={() => { setShowConfirmDelete(true) }}              
          >
            Delete
          </Button>
        </HeaderButtonWrap>
      </HeaderWrapper>
      {showConfirmDelete && (
        <DeleteConfirmationModal
          isOpen={showConfirmDelete}
          onClose={() => { setShowConfirmDelete(false) }}              
          headline="Delete room"
          description="Are you sure you want to delete this room?"
          handleDelete={handleDeleteSelected}
        />
      )}
      {error && 
        <ShowMessageModal
          title="Delete room"
          message={error}
          onClose={() => { setError("") }}              
        />
      }            
    </HeaderContainer>
  )
}

export default ChatRoomHeader
ChatRoomHeader.defaultProps = {
  preferredPronouns: '',
  userAvatar: null
}
ChatRoomHeader.propTypes = {
  roomId: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  preferredPronouns: PropTypes.string,
  userAvatar: PropTypes.string,
  bgColor: PropTypes.string.isRequired
}
