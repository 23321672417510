import styled from 'styled-components/macro'
import { INPUT_SIZES } from '../../../../const/UIvariants'
import Typography from '../../Typography'

export const StyledInput = styled.input`
  width: 100%;
  height: ${({ size }) => size}px;
  background-color: ${({ theme, error }) =>
    error ? theme.colors.pinkBase4 : theme.colors.skyNeutral4};
  color: ${({ theme, error }) =>
    error ? theme.colors.pinkBase : theme.colors.skyNeutral_1};
  outline: none;
  border: none;
  display: flex;
  border-radius: 8px;
  border: ${({ error }) => error && '2px solid #EC72A5'};
  padding-top: ${({ size }) => (size === INPUT_SIZES.LARGE ? 16 : 0)}px;
  transition: padding-top 0.2s ease-in-out;

  padding-left: ${({ leftPadding, size }) =>
    leftPadding ? 52 : size === INPUT_SIZES.LARGE ? 12 : 16}px;
  font-size: ${({ size }) => (size === INPUT_SIZES.LARGE ? 17 : 15)}px;
  line-height: ${({ size }) => (size === INPUT_SIZES.LARGE ? 20 : 17)}px;

  & :placeholder-shown {
    padding-top: 0;
  }
  &:focus {
    padding-top: ${({ hasLabel }) => (hasLabel ? '16px' : 0)};
  }
`
export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 8px;
  width: 100%;
  & :focus-within {
    input {
      box-shadow: 0 0 0 2px hsla(219, 12%, 51%, 1);
    }
    label {
      display: block;
      opacity: 1;
    }

    div > svg:first-child {
      color: ${({ theme }) => theme.colors.skyNeutral_1};
    }
  }
`
export const InputError = styled.p`
  font-size: 13px;
  margin-top: 8px;
  height: 16px;
  color: ${({ theme }) => theme.colors.greenCreator(7, 46)};
`

export const InputInnerLabel = styled.label`
  position: absolute;
  transition: opacity 0.2s ease-in-out;
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
  font-size: 13px;
  color: ${({ theme }) => theme.colors.skyNeutral2};
  left: ${({ leftPadding }) => (leftPadding ? 52 : 12)}px;
  top: 7px;
  line-height: 18px;
  z-index: 100;
`
export const InputOuterLabel = styled(Typography)`
  margin-bottom: 12px;
  color: ${({ theme }) => theme.colors.skyNeutral_1};
`
export const InputBodyWrapper = styled.div`
  position: relative;
  flex: 1;
  display: flex;
`
export const RightSideIcon = styled.div`
  display: flex;
  height: 14px;
  width: 14px;
  right: 21px;
  justify-content: center;
  align-items: center;
  position: absolute;
  align-self: center;
  &:hover {
    svg {
      color: ${({ theme }) => theme.colors.skyNeutral_2};
    }
  }
`
export const LeftSideIcon = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  align-self: center;

  color: ${({ theme }) => theme.colors.skyNeutral2};
  left: 18px;
`
