import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { setLastVisitedTab } from '../../features/friendsAndMessages/friendsSlice'
import { selectLastOpenFriendsTab } from '../../store/selectors'

const usePersistOpenFriendsTab = (slug) => {
  const dispatch = useDispatch()
  const lastOpenedTag = useSelector(selectLastOpenFriendsTab)
  useEffect(() => {
    if (lastOpenedTag !== slug) {
      dispatch(setLastVisitedTab(slug))
    }
  }, [])
}
export default usePersistOpenFriendsTab
