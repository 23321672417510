import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import ROUTES from '../../const/ROUTES'

const FallbackRoute = ({ fallbackPath }) => (
  <Route path="*">
    <Redirect to={{ pathname: fallbackPath }} />
  </Route>
)

export default FallbackRoute

FallbackRoute.defaultProps = {
  fallbackPath: ROUTES.LOGIN
}
FallbackRoute.propTypes = {
  fallbackPath: PropTypes.oneOf(Object.values(ROUTES))
}
