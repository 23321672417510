import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { generatePath, useHistory } from 'react-router-dom'
import useShopDetails from '../../hooks/shop/useShopDetails'
import { HStack, VStack } from '../../global/styles'
import { usePostShopPostAttendedMutation } from '../../api/api.generated'
import { formatPostDeadline, getImageFromServer, getErrorMessage } from '../../utils/helpers'
import Badge from '../UI/Badge'
import { BADGE_SIZES, BADGE_VARIANTS } from '../../const/UIvariants'
import ROUTES from '../../const/ROUTES'
import LikeButton from '../LikeButton'
import ShopLogo from '../ShopLogo'
import InterestedButton from '../InterestedButton'
import AttendedButton from '../AttendedButton'
import ShowMessageModal from '../Modals/ShowMessageModal'
import SendCodeModal from '../Modals/SendCodeModal'

import {
  ButtonWrap,
  PostDescription,
  PostDetailsWrap,
  PostImage,
  PostWrap,
  ButtonsWrapper,
  ShopLogoWrap,
  ShopNameLabel
} from './styles'

const ShopPostItem = ({
  postId,
  showShopLogo,
  logotype,
  shopId,
  shopName,
  showShopName,
  postImage,
  postDescription,
  postTitle,
  postDeadline,
  deadlineText,
  requireCode,
  dateStart,
  dateEnd,
  showLikeButton,
  isLiked,
  showInterestedButton,
  isInterested,
  showAttendedButton,
  isAttended,
}) => {
  const history = useHistory()
  const { handleOpenShop } = useShopDetails()
  const [postShopPostAttended] = usePostShopPostAttendedMutation()
  const [showMessageIsOpen, setShowMessageIsOpen] = useState(false)
  const [showMessageText, setShowMessageText] = useState("")
  const [sendCodeIsOpen, setSendCodeIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleOpenPostDetails = useCallback((_postId) => {
    history.push(
      history.location.pathname +
        generatePath(ROUTES.SHOP_POST_DETAILS, { postId: _postId }),
      {
        shopName,
        showShopName,
        postImage,
        postDescription,
        postDeadline,
        requireCode,
        postTitle,
        dateEnd,
        dateStart,
        showLikeButton,
        isLiked,
        showInterestedButton,
        isInterested,
        showAttendedButton,
        isAttended
      }
    )
  })

  const handleOpenShowMessage = () => {
    setShowMessageIsOpen(true)
  }

  const handleCloseShowMessage = () => {
    setShowMessageIsOpen(false)
  }

  const openShopClick = (e) => { 
    handleOpenShop(shopId) 
    e.stopPropagation()
  }

  const showCodeDialog = () => {
    setSendCodeIsOpen(true)
  }

  const handleClick = () => {
    setIsLoading(true)
    postShopPostAttended({
      postId,
      body: { isAttended: !isAttended, code: "" }
    })
    .unwrap()
    .then(() => {
      setIsLoading(false)
    })
    .catch((err) => {
      setIsLoading(false)
      setShowMessageText(getErrorMessage(err))
      handleOpenShowMessage()
    })
  }

  const handleCloseSendCode = () => {
    setSendCodeIsOpen(false)
  }

  return (
    <>
      <PostWrap
        onClick={() => {
          handleOpenPostDetails(postId)
        }}
      >
        { false &&
        <PostImage backgroundImage={getImageFromServer(postImage)} />
        }
        <PostDetailsWrap>
          <HStack>
            {showShopLogo && 
            <ShopLogoWrap>
              <ShopLogo size={60} logoUrl={logotype} handleClick={openShopClick}/>
            </ShopLogoWrap>
            }
            <VStack>
              {showShopName && 
              <ShopNameLabel onClick={openShopClick}>
                {shopName}
              </ShopNameLabel>
              }
              <PostDescription variant="textXS" align="left">
                {postTitle}
              </PostDescription>
            </VStack>
          </HStack>
          <ButtonWrap>
            {postDeadline ? (
              <Badge
                label={formatPostDeadline(dateStart, dateEnd)}
                variant={BADGE_VARIANTS.PINK}
              />
            ) : (
              <Badge
                label="Read More"
                variant={BADGE_VARIANTS.LIGHT}
                size={BADGE_SIZES.SMALL}
                onClick={() => {
                  handleOpenPostDetails(postId)
                }}
              />
            )}
          </ButtonWrap>
        </PostDetailsWrap>
      </PostWrap>
      <ButtonsWrapper>
        {showLikeButton && <LikeButton postId={postId} isLiked={isLiked} />}
        {showInterestedButton && (
          <InterestedButton postId={postId} isInterested={isInterested} />
        )}
        {showAttendedButton && (
          <AttendedButton isLoading={isLoading} isAttended={isAttended} title={deadlineText} requireCode={requireCode} onClick={requireCode && !isAttended ? showCodeDialog : handleClick}/>
        )}
      </ButtonsWrapper>
      { showMessageIsOpen &&
        <ShowMessageModal
          isOpen={showMessageIsOpen}
          onClose={handleCloseShowMessage}
          title={deadlineText}
          message={showMessageText}
        />
      }
      { sendCodeIsOpen &&
        <SendCodeModal
          isOpen={sendCodeIsOpen}
          onClose={handleCloseSendCode}
          title={deadlineText}
          postId={postId}
        />
      }      
    </>
  )
}

export default ShopPostItem

ShopPostItem.defaultProps = {
  showShopLogo: false,
  shopId: "",
  logotype: "",
  dateStart: null,
  dateEnd: null,
  postImage: null
}

ShopPostItem.propTypes = {
  postId: PropTypes.string.isRequired,
  showShopLogo: PropTypes.bool,
  shopId: PropTypes.string,
  logotype: PropTypes.string,
  shopName: PropTypes.string.isRequired,
  showShopName: PropTypes.bool.isRequired,
  postImage: PropTypes.string,
  postDescription: PropTypes.string.isRequired,
  postTitle: PropTypes.string.isRequired,
  postDeadline: PropTypes.bool.isRequired,
  deadlineText: PropTypes.string.isRequired,
  requireCode: PropTypes.bool.isRequired,
  dateStart: PropTypes.string,
  dateEnd: PropTypes.string,
  showLikeButton: PropTypes.bool.isRequired,
  isLiked: PropTypes.bool.isRequired,
  showInterestedButton: PropTypes.bool.isRequired,
  isInterested: PropTypes.bool.isRequired,
  showAttendedButton: PropTypes.bool.isRequired,
  isAttended: PropTypes.bool.isRequired
}
