import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { CitiesWrap, Headline } from './styles'
import CityItem from '../../../components/CityItem'
import usePersistOpenHopTab from '../../../hooks/hop/usePersistOpenHopTab'
import useHopCity from '../../../hooks/hopCities/useHopCity'
import {
  selectCities,
  selectDistancesToCities,
  selectSelectedCityId
} from '../../../store/selectors'
import { formatDistance } from '../../../utils/map-utils'
import { ContainerWrap } from '../HopDrawerContainer/styles'

const HopCities = () => {
  usePersistOpenHopTab('/cities')
  const selectedCityId = useSelector(selectSelectedCityId)
  const cities = useSelector(selectCities, shallowEqual)
  const distanceToCities = useSelector(selectDistancesToCities, shallowEqual)
  const { handleChangeCity } = useHopCity()
  return (
    <>
      {!isEmpty(cities) && (
        <ContainerWrap>
          <Headline>Select Cities</Headline>
          <CitiesWrap>
            {[...cities]
              .sort((first, second) => second.shopsInCity - first.shopsInCity)
              .map((city) => (
                <CityItem
                  key={city.id}
                  isActive={city.id === selectedCityId}
                  city={city.cityName}
                  country={city.country}
                  shopsInCity={city.shopsInCity}
                  distance={formatDistance(distanceToCities[city.id])}
                  handleChangeCity={() => {
                    handleChangeCity(city.id, city.cityName, {
                      lat: city.location.lat,
                      lng: city.location.lng
                    })
                  }}
                />
              ))}
          </CitiesWrap>
        </ContainerWrap>
      )}
    </>
  )
}

export default HopCities

HopCities.propTypes = {}
