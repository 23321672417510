import React from 'react'
import { NearMe } from '@styled-icons/material'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { ShopDistance } from '../ShopPreviewItem/styles'
import { DistanceWrap } from './styles'
import {
  selectDistanceToShopById,
  selectIsInRouteMode
} from '../../store/selectors'
import { formatDistance } from '../../utils/map-utils'
import useUpdateDistances from '../../hooks/hop/useUpdateDistances'

const DistanceToShop = ({ initialDistance, shopId }) => {
  useUpdateDistances()
  const updatedDistance = useSelector(selectDistanceToShopById(shopId))
  const isInRouteMode = useSelector(selectIsInRouteMode)

  return (
    <ShopDistance>
      <NearMe size={16} color="hsla(220, 12%, 67%, 1)" />
      {isInRouteMode ? (
        <DistanceWrap>{formatDistance(updatedDistance)} </DistanceWrap>
      ) : (
        <DistanceWrap>
          {updatedDistance
            ? formatDistance(updatedDistance)
            : `${initialDistance}KM`}{' '}
        </DistanceWrap>
      )}
    </ShopDistance>
  )
}

export default DistanceToShop

DistanceToShop.propTypes = {
  initialDistance: PropTypes.number.isRequired,
  shopId: PropTypes.string.isRequired
}
