import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Formik } from 'formik'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import { StyledForm, ButtonsWrapper } from './styles'
import { usePostUserRateShopByShopIdMutation } from '../../../api/api.generated'
import { toggleRateShopModal } from '../../../features/app/appSlice'
import { ModalHeadline } from '../AddToFavoritesModal/styles'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES as BUTTON_SIZES
} from '../../../const/UIvariants'
import Button from '../../UI/Buttons/Button/Button'
import RatingInput from '../../UI/Inputs/RatingInput'

const RateShopModal = ({ shopName, shopId }) => {
  const isOpen = useSelector((state) => state.app.isRateShopModalOpen)
  const dispatch = useDispatch()
  const [submitReview, { isLoading }] = usePostUserRateShopByShopIdMutation()
  const handleSubmitAndClose = (values) => {
    dispatch(toggleRateShopModal())
    submitReview({
      body: { userRating: values.rating >= 5 ? 5 : values.rating + 1 },
      shopId
    })
      .unwrap()
      .then(() => {})
  }
  const handleClose = () => {
    dispatch(toggleRateShopModal())
  }  
  return (
    <Modal {...{ isOpen }}>
      <Formik initialValues={{ rating: 5 }} onSubmit={handleSubmitAndClose}>
        <StyledForm>
          <ModalHeadline>How was your Experience with {shopName}</ModalHeadline>
          <RatingInput name="rating" />
          <ButtonsWrapper>
            <Button
              variant={BUTTON_VARIANTS.PRIMARY}
              size={BUTTON_SIZES.MED}
              isLoading={isLoading}
              type="submit"
            >
              Submit
            </Button>
            <Button
              variant={BUTTON_VARIANTS.SECONDARY}
              size={BUTTON_SIZES.MED}
              onClick={handleClose}
            >
              Cancel
            </Button>          
          </ButtonsWrapper>
        </StyledForm>
      </Formik>
    </Modal>
  )
}

export default RateShopModal

RateShopModal.propTypes = {
  shopName: PropTypes.string.isRequired,
  shopId: PropTypes.string.isRequired
}
