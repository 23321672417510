import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch
} from 'react-router-dom'
import { Close, Star, Share, ChatBubble } from '@styled-icons/material'
import { find } from 'lodash/collection'
import { useSelector } from 'react-redux'
import ROUTES from '../../../const/ROUTES'
import { HStack } from '../../../global/styles'
import {
  usePostUserNoteMutation,
  useGetUserShopByCityIdQuery,
  useGetUserShopByShopIdAboutQuery
} from '../../../api/api.generated'
import LoadingSpinner from '../../../components/LoadingSpinner'
import ShopLogo from '../../../components/ShopLogo'
import IconButton from '../../../components/UI/Buttons/IconButton'
import Badge from '../../../components/UI/Badge'
import Button from '../../../components/UI/Buttons/Button/Button'
import {
  BADGE_SIZES,
  BADGE_VARIANTS,
  BUTTON_VARIANTS,
  BUTTONS_SIZES as BUTTON_SIZES
} from '../../../const/UIvariants'
import Tab from '../../../components/UI/Tab'
import { isShopOpen, getErrorMessage } from '../../../utils/helpers'
import RateShopModal from '../../../components/Modals/RateShopModal'
import ShareShopModal from '../../../components/Modals/ShareShopModal'
import { selectCurrentCityId } from '../../../store/selectors'
import DistanceToShop from '../../../components/DistanceToShop'
import GoNowButton from '../../shopActionsButtons/GoNowButton'
import AddToRouteButton from '../../shopActionsButtons/AddToRouteButton'
import AddToFavButton from '../../shopActionsButtons/AddToFavButton'
import SubscribeButton from '../../shopActionsButtons/SubscribeButton'
import { SHOP_TABS } from '../../../const/tabs'
import SendMessageModal from '../../../components/Modals/SendMessageModal'
import ShowMessageModal from '../../../components/Modals/ShowMessageModal'
import ReportStoreModal from '../../../components/Modals/ReportStoreModal'
import {
  ButtonsWrapper,
  CloseButtonWrap,
  DetailsWrap,
  HeaderWrap,
  Rating,
  ScrollContainer,
  ShopAddress,
  ShopDataWrap,
  ShopHeader,
  ShopInfo,
  ShopMetaData,
  ShopName,
  ShopPreviewWrap,
  ShopRating,
  TabsWrap,
  StyledButton,
  ReportButtonWrap
} from './styles'

const ShopPreviewContainer = () => {
  const { url } = useRouteMatch()
  const history = useHistory()
  const currentCityId = useSelector(selectCurrentCityId)
  const location = useLocation()
  const { shopId } = useParams()
  const { data: { shopData } = {}, isLoading: loadingShopDetails } = useGetUserShopByShopIdAboutQuery({ shopId })
  const [reportModalIsOpen, setReportModalIsOpen] = useState(false)
  const [shareShopModalIsOpen, setShareShopModalIsOpen] = useState(false)
  const [sendMessageIsOpen, setSendMessageIsOpen] = useState(false)
  const [showMessageIsOpen, setShowMessageIsOpen] = useState(false)
  const [showMessageIsLoading, setShowMessageIsLoading] = useState(false)
  const [addToNotesMessage, setAddToNotesMessage] = useState("")
  const [postUserNote] = usePostUserNoteMutation()
  const { shopPreviewData, isLoading } = useGetUserShopByCityIdQuery(
    {
      cityId: currentCityId
    },
    {
      selectFromResult: ({ data }) => ({
        shopPreviewData: find(data?.shops, (shop) => shop.id === shopId)
      })
    }
  )

  const handleOpenReport = () => {
    setReportModalIsOpen(true)
  }

  const handleCloseReport = () => {
    setReportModalIsOpen(false)
  }

  const handleOpenShareShop = () => {
    setShareShopModalIsOpen(true)
  }

  const handleCloseShareShop = () => {
    setShareShopModalIsOpen(false)
  }

  const handleSendMessage = () => {
    setSendMessageIsOpen(true)
  }

  const handleCloseSendMessage = () => {
    setSendMessageIsOpen(false)
  }

  const handleOpenShowMessage = () => {
    setShowMessageIsOpen(true)
  }

  const handleCloseShowMessage = () => {
    setShowMessageIsOpen(false)
  }

  const handleAddToNotes = () => {
    // setError('')
    // setIsPosting(true)
    handleOpenShowMessage(true)
    setShowMessageIsLoading(true)
    setAddToNotesMessage("Adding to Notes...")
    postUserNote({
      body: {
        message: '',
        shopId
      }
    })
    .unwrap()
    .then(() => {
      setShowMessageIsLoading(false)
      setAddToNotesMessage("Store added to notes.")
    })
    .catch((err) => {
      setShowMessageIsLoading(false)
      setAddToNotesMessage(getErrorMessage(err))
    })
  }

  const tabs = SHOP_TABS.filter(tabItem => shopPreviewData && (tabItem.destination !== ROUTES.SHOP_PRODUCTS || shopPreviewData.showProducts))

  return ReactDOM.createPortal(
    shopPreviewData && (
      <>
        {shopPreviewData && shopPreviewData.isChatEnabled && (
          <StyledButton onClick={handleSendMessage}>
            <ChatBubble size={32} color="#ffffff" />
          </StyledButton>
        )}

        <ScrollContainer>
          <RateShopModal
            shopId={shopId}
            shopName={shopPreviewData?.storeName ?? ''}
          />
          <ShareShopModal
            isOpen={shareShopModalIsOpen}
            onClose={handleCloseShareShop}
            shopId={shopId}
          />

          { reportModalIsOpen &&
            <ReportStoreModal
              isOpen={reportModalIsOpen}
              onClose={handleCloseReport}
              shopId={shopPreviewData.id}
            />        
          }
          { sendMessageIsOpen &&
            <SendMessageModal
              isOpen={sendMessageIsOpen}
              onClose={handleCloseSendMessage}
              shopId={shopPreviewData.id}
            />
          }
          { showMessageIsOpen &&
            <ShowMessageModal
              isOpen={showMessageIsOpen}
              isLoading={showMessageIsLoading}
              onClose={handleCloseShowMessage}
              title="Share to Notes"
              message={addToNotesMessage}
            />
          }          
          <ShopPreviewWrap>
            <HeaderWrap>
              <CloseButtonWrap>
                <IconButton
                  onClick={() => {
                    history.goBack()
                  }}
                >
                  <Close size={14} />
                </IconButton>
              </CloseButtonWrap>
              {loadingShopDetails || (isLoading && <LoadingSpinner />)}
              {shopPreviewData && shopData && (
                <ShopHeader>
                  <ShopInfo>
                    <ShopLogo logoUrl={shopPreviewData.logotype} />
                    <ShopDataWrap>
                      <ShopName>{shopPreviewData.storeName}</ShopName>
                      <ShopAddress>
                        {shopPreviewData.location.displayAddress}
                      </ShopAddress>
                      <ShopMetaData>
                        <ShopRating>
                          <Star size={16} color="hsla(47, 86%, 59%, 1)" />
                          <Rating>{shopPreviewData.rating}</Rating>
                        </ShopRating>
                        <DistanceToShop
                          shopId={shopPreviewData.id}
                          initialDistance={shopPreviewData?.location?.displayDistance}
                        />
                      </ShopMetaData>
                    </ShopDataWrap>
                  </ShopInfo>
                  <ButtonsWrapper>
                    <HStack>
                      {(!shopData.isHoliday && isShopOpen(shopData.schedule)) ? (
                        <Badge
                          size={BADGE_SIZES.LARGE}
                          label="Open now"
                          variant={BADGE_VARIANTS.GREEN}
                        />
                      ) : (
                        <Badge
                          size={BADGE_SIZES.LARGE}
                          label="Closed"
                          variant={BADGE_VARIANTS.PINK}
                        />
                      )}
                      <ReportButtonWrap>
                        <Badge
                          size={BADGE_SIZES.LARGE}
                          label="Report"
                          variant={BADGE_VARIANTS.OUTLINED}
                          onClick={handleOpenReport}
                        />
                      </ReportButtonWrap>
                    </HStack>
                    <HStack>
                      <AddToRouteButton
                        sequence={shopPreviewData.sequence}
                        id={shopPreviewData.id}
                        lat={shopPreviewData.location.lat}
                        lng={shopPreviewData.location.lng}
                      />
                      <GoNowButton
                        sequence={shopPreviewData.sequence}
                        id={shopPreviewData.id}
                        lat={shopPreviewData.location.lat}
                        lng={shopPreviewData.location.lng}
                      />
                    </HStack>
                    <HStack>
                      <AddToFavButton id={shopPreviewData.id} />
                      <SubscribeButton
                        shopId={shopPreviewData.id}
                        isSubscribed={shopData.isSubscribed}
                      />
                    </HStack>
                    <HStack>  
                      <Button
                        variant={BUTTON_VARIANTS.OUTLINED}
                        leftIcon={<Share />}
                        size={BUTTON_SIZES.MED}
                        onClick={handleOpenShareShop}
                      >
                        Share
                      </Button>
                      <Button
                        variant={BUTTON_VARIANTS.OUTLINED}
                        size={BUTTON_SIZES.MED}
                        onClick={handleAddToNotes}
                      >
                        Share to Notes
                      </Button>
                    </HStack>
                  </ButtonsWrapper>
                </ShopHeader>
              )}
              <OverlayScrollbarsComponent
                style={{ height: '52px' }}
                options={{
                  scrollbars: { visibility: 'hidden' }
                }}
              >
                <TabsWrap>
                  {tabs.map((tabItem) => (
                    <Tab
                      autoWidth={false}
                      key={tabItem.destination}
                      destination={url.concat(tabItem.destination)}
                      isActive={location.pathname.includes(tabItem.destination)}
                      label={tabItem.label}
                    />
                   ))}
                </TabsWrap>
              </OverlayScrollbarsComponent>
            </HeaderWrap>
            <DetailsWrap>
              <Switch>
                {SHOP_TABS.map((routeItem) => (
                  <Route
                    key={routeItem.destination}
                    path={`*${routeItem.destination}`}
                    render={routeItem.Component(shopId)}
                  />
                ))}
              </Switch>
            </DetailsWrap>
          </ShopPreviewWrap>
        </ScrollContainer>
      </>
    ),
    document.getElementById('root')
  )
}

export default ShopPreviewContainer

ShopPreviewContainer.propTypes = {}
