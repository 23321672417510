import { createSlice } from '@reduxjs/toolkit'
import { logout } from '../auth/authSlice'

const friendsInitState = {
  lastVisitedTab: '/contacts',
  openChatUserId: null,
  openChatRoomId: null
}

const friendsSlice = createSlice({
  name: 'friends',
  initialState: friendsInitState,
  reducers: {
    setLastVisitedTab: (state, { payload }) => {
      state.lastVisitedTab = payload ?? '/contacts'
    },
    setOpenChatUserId: (state, { payload }) => {
      state.openChatUserId = payload
    },
    setOpenChatRoomId: (state, { payload }) => {
      state.openChatRoomId = payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => friendsInitState)
  }
})

export default friendsSlice.reducer

export const { setLastVisitedTab, setOpenChatUserId, setOpenChatRoomId } =
  friendsSlice.actions
