/*eslint-disable*/
import { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import {
  selectDistancesToShops,
  selectFilteredCityShops
} from '../../store/selectors'

const useGetCityShops = () => {
  const filteredShops = useSelector(selectFilteredCityShops, shallowEqual)
  const distancesToShop = useSelector(selectDistancesToShops, () => true)
  const [filteredSortedShops, setFilteredSortedShops] = useState()
  const [closestShop, setClosestShop] = useState(null)
  useEffect(() => {
    if (filteredShops.length === 0) {
      setFilteredSortedShops([])
    }
    if (filteredShops.length !== 0) {
      const sortedShopsByDistance = [...filteredShops].sort(
        (first, second) =>
          distancesToShop[first.id] - distancesToShop[second.id]
      )

      /*
      const closestShopSequence = sortedShopsByDistance[0].sequence
      const shopsWithLowerSequenceThenClosest = []
      const shopsWithHigherSequenceThenClosest = []

      filteredShops.forEach((shop) => {
        if (shop.sequence < closestShopSequence) {
          shopsWithLowerSequenceThenClosest.push(shop)
        }
        if (shop.sequence > closestShopSequence) {
          shopsWithHigherSequenceThenClosest.push(shop)
        }
      })
      // shops should be sorted like
      // closest shop > shops with higher sequence from small to large > shops with lower sequence from 0 to largest
      const shopsSortedBySequenceFromClosest = [
        ...shopsWithLowerSequenceThenClosest.sort(
          (a, b) => a.sequence < b.sequence
        ),
        sortedShopsByDistance[0],
        ...shopsWithHigherSequenceThenClosest.sort(
          (a, b) => a.sequence > b.sequence
        )
      ]
      setClosestShop(shopsWithLowerSequenceThenClosest.length)
      setFilteredSortedShops(shopsSortedBySequenceFromClosest)
      */

      setClosestShop(0)
      setFilteredSortedShops(sortedShopsByDistance)
    }
  }, [filteredShops])

  return { filteredSortedShops, closestShop }
}
export default useGetCityShops
