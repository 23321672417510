import { useCallback, useEffect, useState } from 'react'
import { isEmpty } from 'lodash/lang'
import { filter } from 'lodash/collection'
import {
  useGetUserContactRequestsQuery,
  useLazyGetUserAcceptRequestByUserIdQuery,
  useLazyGetUserDeclineRequestByUserIdQuery,
  useLazyGetUserSendRequestByUserIdQuery
} from '../../api/api.generated'

const useHandlePendingRequests = () => {
  const { data, isLoading } = useGetUserContactRequestsQuery(undefined, {
    refetchOnMountOrArgChange: true
  })
  const [listOfPendingRequests, setListOfPendingRequests] = useState([])
  const [handleSendFriendshipRequest] = useLazyGetUserSendRequestByUserIdQuery()
  const [handleAcceptFriendshipRequest] =
    useLazyGetUserAcceptRequestByUserIdQuery()
  const [handleDeclineFriendshipRequest] =
    useLazyGetUserDeclineRequestByUserIdQuery()

  const sendAddFriendRequest = useCallback((contactId) => {
    handleSendFriendshipRequest({ userId: contactId })
  }, [])
  const confirmAddFriendRequest = useCallback((contactId) => {
    setListOfPendingRequests((prev) =>
      filter(prev, (user) => user.id !== contactId)
    )
    handleAcceptFriendshipRequest({ userId: contactId })
  }, [])

  const declineAddFriendRequest = useCallback((contactId) => {
    handleDeclineFriendshipRequest({ userId: contactId })
    setListOfPendingRequests((prev) =>
      filter(prev, (user) => user.id !== contactId)
    )
  }, [])
  useEffect(() => {
    if (!isEmpty(data?.searchResults)) {
      setListOfPendingRequests(data.searchResults)
    }
  }, [data])
  return {
    requestsList: { listOfPendingRequests, isLoading },
    sendAddFriendRequest,
    confirmAddFriendRequest,
    declineAddFriendRequest
  }
}
export default useHandlePendingRequests
