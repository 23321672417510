import styled from 'styled-components'
import { VStack, HStack } from '../../global/styles'
import Typography from '../UI/Typography'

// eslint-disable-next-line import/prefer-default-export
export const TabWrap = styled(VStack)`
  height: 100%;
  padding: 12px 0 18px 0;
  justify-content: space-between;
  align-items: center;
`
export const CaptionWrap = styled(HStack)`
  justify-content: center
`
export const Count = styled(Typography).attrs(({ theme }) => ({
  color: theme.colors.white,
  variant: 'textXS',
  bold: true
}))`
  min-width: 20px;
  height: 20px;
  line-height: 19px;
  text-align: center;
  margin-left: 6px;
  padding: 0 6px;
  background-color: #cf713b;
  border-radius: 14px;
`