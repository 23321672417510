import styled from 'styled-components'
import { Form } from 'formik'
import { VStack } from '../../../global/styles'

export const StyledForm = styled(Form)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
`

export const HeadlineWrap = styled(VStack)`
  margin: 16px 0 24px 0;
`
export const InputsWrap = styled(VStack)`
  margin-bottom: auto;
`
