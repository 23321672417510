import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Lexend', 'Open Sans', 'Arial', sans-serif;
  }
  html,
  body {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
  button {
    outline: none;
    background: transparent;
    cursor: pointer;
  }
`
const colorCreators = {
  Sky(hue, lightness) {
    return `hsla(219,${hue}%,${lightness}%,1)`
  },
  Earth(hue, lightness) {
    return `hsla(22,${hue}%,${lightness}%,1)`
  },
  Yellow(hue, lightness) {
    return `hsla(47,${hue}%,${lightness}%,1)`
  },
  Pink(hue, lightness) {
    return `hsla(335,${hue}%,${lightness}%,1)`
  },
  Green(hue, lightness) {
    return `hsla(119,${hue}%,${lightness}%,1)`
  },
  Cyan(hue, lightness) {
    return `hsla(173,${hue}%,${lightness}%,1)`
  }
}
export const globalTheme = {
  colors: {
    skyCreator: (h, l) => colorCreators.Sky(h, l),
    earthCreator: (h, l) => colorCreators.Earth(h, l),
    greenCreator: (h, l) => colorCreators.Green(h, l),
    pinkCreator: (h, l) => colorCreators.Pink(h, l),
    yellowCreator: (h, l) => colorCreators.Yellow(h, l),

    /// adding frequently used colors as properties, for less used cases - used functions above
    /// + 1 shades are noted like skyNeutral1
    /// - 1 shades are noted as skyNeutral_1

    // Sky
    skyNeutral: colorCreators.Sky(17, 43),
    skyNeutral1: colorCreators.Sky(12, 51),
    skyNeutral3: colorCreators.Sky(13, 87),
    skyNeutral4: colorCreators.Sky(14, 96),
    skyNeutral5: colorCreators.Sky(0, 99),
    skyNeutral2: colorCreators.Sky(12, 67),
    skyNeutral_1: colorCreators.Sky(16, 32),
    skyNeutral_2: colorCreators.Sky(16, 22),

    skyBase: colorCreators.Sky(77, 54),
    skyBase1: colorCreators.Sky(77, 60),
    skyBase2: colorCreators.Sky(77, 73),
    skyBase3: colorCreators.Sky(78, 89),
    skyBase4: colorCreators.Sky(76, 97),
    skyBase_1: colorCreators.Sky(67, 41),
    skyBase_2: colorCreators.Sky(66, 29),

    // Earth
    earthNeutral: colorCreators.Earth(19, 39),
    earthBase: colorCreators.Earth(100, 38),

    // Yellow
    yellowNeutral: colorCreators.Yellow(26, 74),
    yellowBase: colorCreators.Yellow(86, 59),
    yellowBase3: colorCreators.Yellow(87, 91),
    yellowNeutral_1: colorCreators.Yellow(13, 58),
    yellowNeutral_2: colorCreators.Yellow(9, 37),

    // Pink
    pinkNeutral: colorCreators.Pink(13, 63),
    pinkBase: colorCreators.Pink(76, 69),
    pinkBase3: colorCreators.Pink(78, 93),
    pinkBase4: colorCreators.Pink(82, 98),

    // Green
    greenNeutral: colorCreators.Green(11, 60),
    greenNeutral3: colorCreators.Green(10, 90),
    greenNeutral_1: colorCreators.Green(7, 46),
    greenNeutral_2: colorCreators.Green(7, 46),
    greenBase: colorCreators.Green(49, 57),
    green_1: colorCreators.Green(37, 47),
    green_2: colorCreators.Green(37, 28),
    green3: colorCreators.Green(49, 90),
    green4: colorCreators.Green(50, 97),

    //  Cyan
    cyanNeutral: colorCreators.Cyan(16, 61),
    cyanNeutral_1: colorCreators.Cyan(10, 46),
    cyanBase: colorCreators.Cyan(63, 52),
    cyanBase3: colorCreators.Cyan(60, 88),
    cyanBase_1: colorCreators.Cyan(59, 44),

    appBackground: '#F3F4F6',
    white: '#ffffff',
    black: '#000000'
  },

  fonts: {
    main: 'Lexend'
  }
}
export const typographyVariants = {
  headingXL: {
    fontSize: 28,
    lineHeight: 32,
    letterSpacing: 1,
    component: 'h1'
  },
  headingL: { fontSize: 24, lineHeight: 30, letterSpacing: 1, component: 'h2' },
  // same as admin panel
  headingM: { fontSize: 20, lineHeight: 34, letterSpacing: 1, component: 'h3' },
  headingS: { fontSize: 16, lineHeight: 20, letterSpacing: 1, component: 'h4' },
  textL: { fontSize: 20, lineHeight: 32, letterSpacing: 0.75, component: 'h5' },
  textM: { fontSize: 17, lineHeight: 28, letterSpacing: 0.75, component: 'h6' },
  textS: { fontSize: 15, lineHeight: 24, letterSpacing: 0.75, component: 'p' },
  textXS: { fontSize: 13, lineHeight: 22, letterSpacing: 0.75, component: 'p' },
  textXXS: {
    fontSize: 11,
    lineHeight: 22,
    letterSpacing: 0.75,
    component: 'p'
  },
  textTiny: {
    fontSize: 10,
    lineHeight: 22,
    letterSpacing: 0.75,
    component: 'p'
  },
  labelL: { fontSize: 17, lineHeight: 28, letterSpacing: 0.75, component: 'p' },
  labelM: { fontSize: 15, lineHeight: 24, letterSpacing: 0.75, component: 'p' },
  labelS: { fontSize: 13, lineHeight: 22, letterSpacing: 0.75, component: 'p' }
}
