import { useEffect } from 'react'
import { useFormikContext } from 'formik'

const SubmitOnFilled = () => {
  const { values, submitForm } = useFormikContext()
  useEffect(() => {
    if (values.code.length === 5) {
      submitForm()
    }
  }, [values])

  return null
}

export default SubmitOnFilled

SubmitOnFilled.propTypes = {}
