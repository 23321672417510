import React from 'react'
import { Star } from '@styled-icons/material'
import PropTypes from 'prop-types'
import { withTheme } from 'styled-components'
import { RatingWrap } from './styles'

const Rating = ({ rating, theme }) => (
  <RatingWrap>
    {Array.from({ length: 5 }).map((_, index) => (
      <Star
        size={16}
        width={16}
        height={16}
        key={`${index.toString() + rating.toString()}`}
        color={
          rating >= index + 1
            ? theme.colors.yellowBase
            : theme.colors.yellowNeutral
        }
      />
    ))}
  </RatingWrap>
)

export default withTheme(Rating)

Rating.propTypes = {
  theme: PropTypes.object.isRequired,
  rating: PropTypes.number.isRequired
}
