import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { setLastOpenedTab } from '../../features/hop/hopSlice'

const usePersistOpenHopTab = (slug) => {
  const dispatch = useDispatch()
  const lastOpenedTag = useSelector((state) => state.hop.lastOpenedTab)
  useEffect(() => {
    if (lastOpenedTag !== slug) {
      dispatch(setLastOpenedTab(slug))
    }
  }, [])
}
export default usePersistOpenHopTab
