import React from 'react'
import ReactDOM from 'react-dom'
import { useHistory } from 'react-router-dom'
import { useSwipeable } from 'react-swipeable'
import {
  PostDetailsScreen,
  PostDetailsWrap,
  DragNotch,
  DragNotchWrap,
  ButtonWrap,
  PostDescription,
  PostImage,
  StyledScrollbars,
  Title
} from './styles'

import { formatPostDeadline, getImageFromServer } from '../../../utils/helpers'
import Badge from '../../../components/UI/Badge'
import { BADGE_VARIANTS } from '../../../const/UIvariants'

const PostDetailsOverlay = () => {
  const { location, goBack } = useHistory()
  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      if (eventData.dir === 'Down') {
        goBack()
      }
    },
    config: {
      trackTouch: true, // track touch input
      trackMouse: true
    }
  })
  return ReactDOM.createPortal(
    <PostDetailsScreen
      onClick={() => {
        goBack()
      }}
    >
      {location.state && (
        <PostDetailsWrap>
          <DragNotchWrap {...handlers}>
            <DragNotch />
          </DragNotchWrap>
          { false &&
          <PostImage
            backgroundImage={getImageFromServer(location.state?.postImage)}
          />
          }
          <Title variant="textS" align="left">
            {location.state?.postTitle}
          </Title>
          <ButtonWrap>
            {location.state?.postDeadline && (
              <Badge
                label={formatPostDeadline(
                  location.state?.dateStart,
                  location.state?.dateEnd
                )}
                variant={BADGE_VARIANTS.PINK}
              />
            )}
          </ButtonWrap>
          <StyledScrollbars>
            <PostDescription variant="textXS" align="left">
              {location.state?.postDescription}
            </PostDescription>
          </StyledScrollbars>
        </PostDetailsWrap>
      )}
    </PostDetailsScreen>,

    document.getElementById('root')
  )
}

export default PostDetailsOverlay

PostDetailsOverlay.propTypes = {}
