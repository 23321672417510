import styled from 'styled-components'
import { HStack } from '../../../global/styles'

// eslint-disable-next-line import/prefer-default-export
export const CheckboxWrap = styled(HStack).attrs({
  as: 'label'
})`
  color: ${({ theme }) => theme.colors.skyNeutral2};
  font-size: 13px;

  align-items: center;
  margin: 12px 0;
`
export const IconWrap = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 10px;
`
