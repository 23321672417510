import { useContext } from 'react'
import { shallowEqual, useDispatch, useSelector, useStore } from 'react-redux'
import MapContext from '../../features/hop/MapContext'
import { H, herePlatform } from '../../config/hereWego'
import {
  clearRouteDistances,
  setCurrentDestinationIndex,
  setGoNowShop,
  setMapRouteObjectIds,
  setRouteInstructions,
  setShopsToVisit,
  setSimulatedRoute,
  toggleRouteMode
} from '../../features/hop/hopSlice'
import { removeObjectById } from '../../utils/map-utils'

const useCreateMapRoute = () => {
  const mapInstance = useContext(MapContext)
  const store = useStore()
  const { getState } = store
  const routeItemsIds = useSelector(
    (state) => state.hop.mapRouteObjectIds,
    shallowEqual
  )
  const dispatch = useDispatch()
  const onResult = (result) => {
    dispatch(
      setRouteInstructions(
        result.routes[0].sections.map((section) => section.actions)
      )
    )
    const routeSimulationPoints = []
    if (result.routes.length) {
      const { sections } = result.routes[0]
      const lineStrings = []
      const ids = []
      sections.forEach((section) => {
        const lineString = H.geo.LineString.fromFlexiblePolyline(
          section.polyline
        )
        routeSimulationPoints.push(...lineString.getLatLngAltArray())
        const routeLine = new H.map.Group()
        // let customStyle = {
        //   strokeColor: 'black',
        //   fillColor: 'rgba(255, 255, 255, 0.5)',
        //   lineWidth: 10,
        //   lineCap: 'square',
        //   lineJoin: 'bevel'
        // };
        // var rect = new H.map.Rect(lineString.getBoundingBox(),
        //   { style: customStyle });
        //
        ids.push(routeLine.getId())
        lineStrings.push(lineString)

        const routeOutline = new H.map.Polyline(lineString, {
          style: {
            lineWidth: 5,
            strokeColor: 'rgba(0, 128, 255, 0.7)',
            lineTailCap: 'arrow-tail',
            lineHeadCap: 'arrow-head'
          }
        })
        const routeArrows = new H.map.Polyline(lineString, {
          style: {
            lineWidth: 10,
            fillColor: 'blue',
            strokeColor: 'rgba(255, 255, 255, 1)',
            lineDash: [0, 2],
            lineTailCap: 'arrow-tail',
            lineHeadCap: 'arrow-head'
          }
        })
        routeItemsIds.forEach((id) =>
          removeObjectById({ mapInstance, objectId: id })
        )
        routeLine.addObjects([routeOutline, routeArrows])
        mapInstance.addObject(routeLine)

        // mapInstance.addObject(rect)
      })
      dispatch(setMapRouteObjectIds([...ids]))
      dispatch(setSimulatedRoute(routeSimulationPoints))
      const bounds = new H.geo.MultiLineString(lineStrings).getBoundingBox()
      mapInstance.getViewModel().setLookAtData({ bounds })
      // console.log(routeSimulationPoints)
    } else {
      dispatch(setShopsToVisit([]))
    }
  }

  const buildRoute = (route) => {
    const state = getState()
    dispatch(setShopsToVisit([...route]))
    if (herePlatform && mapInstance && route.length) {
      const destination = route.length > 1 ? route[route.length - 1] : route[0]

      const viaPoints =
        route.length > 1
          ? new H.service.Url.MultiValueQueryParameter(
              route
                .filter((_, index, arr) => index < arr.length - 1)
                .map((shopInfo) => `${shopInfo.shopLat},${shopInfo.shopLng}`)
            )
          : undefined

      const routingParameters = {
        routingMode: 'fast',
        transportMode: 'car',
        origin: `${state.app.currentLat},${state.app.currentLng}`,
        destination: `${destination.shopLat},${destination.shopLng}`,
        return: [
          'polyline',
          'travelSummary',
          'turnByTurnActions',
          'instructions',
          'actions'
        ],
        ...(viaPoints && { via: viaPoints })
      }
      const router = herePlatform.getRoutingService(null, 8)
      router.calculateRoute(routingParameters, onResult, (error) => {
        // eslint-disable-next-line no-alert
        alert(error.message)
      })
    }
  }

  const stopRoute = () => {
    routeItemsIds.forEach((objectId) => {
      removeObjectById({ mapInstance, objectId })
    })
    dispatch(setMapRouteObjectIds([]))
    dispatch(setShopsToVisit([]))
    dispatch(setCurrentDestinationIndex(0))
    dispatch(toggleRouteMode(false))
    dispatch(setGoNowShop(null))
    dispatch(clearRouteDistances())
  }
  const buildAndStartRoute = (route) => {
    dispatch(toggleRouteMode(true))
    buildRoute(route)
  }

  return { buildRoute, buildAndStartRoute, stopRoute }
}

export default useCreateMapRoute
