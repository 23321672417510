import React from 'react'
import PropTypes from 'prop-types'
import { DoneAll } from '@styled-icons/material'
import Button from '../UI/Buttons/Button'
import { HStack } from '../../global/styles'
import EditDeleteMenu from '../EditDeleteMenu'
import { BUTTON_VARIANTS, BUTTONS_SIZES } from '../../const/UIvariants'
import useShopDetails from '../../hooks/shop/useShopDetails'

import {
  BubbleBody,
  BubbleText,
  BubbleShop,
  Image,
  TimeAndStatusWrap,
  TimeStamp
} from './styles'

const renderAll = (isOwnMessage, messageText, shopId, shopName) => {
  const { handleOpenShop } = useShopDetails()

  if (shopId === null) {
    return <BubbleText {...{ isOwnMessage }}>{messageText}</BubbleText>
  }
  return (
    <>
      <HStack>
        <BubbleText {...{ isOwnMessage }}>
          Tap button to view&nbsp;
        </BubbleText>
        <BubbleShop {...{ isOwnMessage }}>
          {shopName}
        </BubbleShop>
      </HStack>
      <Button
        variant={BUTTON_VARIANTS.PRIMARY}
        size={BUTTONS_SIZES.MED}
        onClick={() => {
          handleOpenShop(shopId)
        }}
      >
        View
      </Button>
    </>
  )
}

const Bubble = ({
  id,
  messageText,
  messageImage,
  shopId,
  shopName,
  isOwnMessage,
  timeStamp,
  isViewed,
  handleDelete
}) => {
  const onDelete = (messageId) => {
    handleDelete(messageId)
  }

  return (
    <BubbleBody {...{ isOwnMessage, messageImage }}>
      {messageImage ? (
        <Image image={messageImage} />
      ) : (
        renderAll(isOwnMessage, messageText, shopId, shopName)
      )}
      <TimeAndStatusWrap>
        <TimeStamp>{timeStamp}</TimeStamp>
        {isOwnMessage && isViewed && <DoneAll size={12} color="#2D6CE4" />}
        <EditDeleteMenu
          onDelete={() => {
            onDelete(id)
          }}
          isRight={isOwnMessage}
        />
      </TimeAndStatusWrap>
    </BubbleBody>
  )
}

export default React.memo(Bubble)

Bubble.defaultProps = {
  messageText: null,
  messageImage: null,
  shopId: null
}

Bubble.propTypes = {
  id: PropTypes.any.isRequired,
  messageText: PropTypes.string,
  messageImage: PropTypes.string,
  isOwnMessage: PropTypes.bool.isRequired,
  isViewed: PropTypes.bool.isRequired,
  timeStamp: PropTypes.string.isRequired,
  shopId: PropTypes.string,
  shopName: PropTypes.string.isRequired,
  handleDelete: PropTypes.func.isRequired
}
