import styled from 'styled-components'
import { HStack } from '../../../global/styles'
import Typography from '../../../components/UI/Typography'

export const PrevQueryWrap = styled(HStack)`
  width: 100%;
  height: 48px;
  box-shadow: inset 0px -1px 0px rgba(208, 201, 214, 0.4);
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  :active {
    background: ${({ theme }) => theme.colors.skyNeutral4};
  }
`
export const PrevQueryText = styled(Typography).attrs({
  variant: 'headingS'
})`
  color: ${({ theme }) => theme.colors.skyNeutral1};
`
