const ROUTES = {
  SPLASH: '/splash/splash',
  // public routes
  WELCOME: '/welcome',
  AUTH: '/auth/',
  LANDING: '/auth/landing',
  LOGIN: '/auth/login',
  SIGNUP: '/auth/signup',
  RESTORE_PASSWORD: '/auth/restore-password',
  CODE_VERIFICATION: '/auth/code-verification',
  CREATE_NEW_PASSWORD: '/auth/create-new-password',
  SUCCESS_SCREEN: '/main/success-screen',

  // private routes
  MAIN: '/main/',
  HOME: '/main/home',

  HOP: '/main/hop',

  HOP_CITIES: '/main/hop/cities',
  HOP_SHOPS: '/main/hop/stores',
  HOP_MYHOPS: '/main/hop/my-hops',
  HOP_FILTER: '/hop-filter',

  EVENTS: '/main/events',

  FRIENDS: '/main/friends',
  FRIENDS_CONTACTS: '/main/friends/contacts',
  FRIENDS_MESSAGES: '/main/friends/messages',
  FRIENDS_REQUESTS: '/main/friends/requests',
  FRIENDS_NOTES: '/main/friends/notes',
  FRIENDS_CHAT: '/chat/chat-room/:roomId',
  FRIENDS_MANAGE_REQUEST: '/main/friends/friend-request/:contactId',
  FIND_FRIENDS: '/find-friends',

  SETTINGS: '/main/settings',
  SETTINGS_ACCOUNT: '/settings/account',
  SETTINGS_SEND_FEEDBACK: '/settings/feedback',
  SETTINGS_CHANGE_PASSWORD: '/settings/change-password',
  SETTINGS_DEV_SCREEN: '/settings/devconfig',
  SETTINGS_CHECK_SMS: '/settings/checkSMS',

  // shopOverlay
  SHOP_OVERLAY: '/store/:shopId',
  SHOP_DETAILS: '/details',
  SHOP_PRODUCTS: '/products',
  SHOP_POSTS: '/posts',
  SHOP_DONATIONS: '/donations',
  SHOP_VOLUNTEERS: '/volunteers',
  SHOP_GALLERY: '/gallery',
  SHOP_VIDEOS: '/videos',
  SHOP_QUESTIONS: '/faq',
  SHOP_HOLIDAYS: '/holidays',
  SHOP_POST_DETAILS: '/post-detail/:postId',
  // search
  SEARCH: '/search',

  SHOPPING_CART: '/main/shopping-cart',
  ORDERS: '/main/orders',
  ORDER_ITEMS: '/main/order-detail/:orderId',
  SUBSCRIBED_STORES: '/main/subscribed-stores',
  FAVOURITE_STORES: '/main/favourite-stores',
  RECENTLY_VISITED: '/main/recently-visited'
}
export default ROUTES
