import React, { useState, useEffect } from 'react'
import { ScreenContainer } from '../../../global/styles'
import ChatRoomHeader from '../../../components/ChatRoomHeader'
import ChatRoomFooter from '../../../components/ChatRoomFooter'
import LoadingSpinner from '../../../components/LoadingSpinner'
import ChatMessageModal from '../../../components/Modals/ChatMessageModal'
import useGetRoomMetaData from '../../../hooks/friends/useGetRoomMetaData'
import usePusherChatWrapper from '../../../hooks/friends/usePusherChatWrapper'
import { TopSectionWrap } from './styles'

const ChatScreen = () => {
  const { currentRoomData, isLoading } = useGetRoomMetaData()
  const WrappedChatContainer = usePusherChatWrapper()
  const [showMessageModalIsOpen, setShowMessageModalIsOpen] = useState(false)
  const handleCloseShowMessageModal = () => {
    setShowMessageModalIsOpen(false)
  }

  useEffect(() => {
    if (currentRoomData) {
      setShowMessageModalIsOpen(currentRoomData.showChatMessage)
    }
  }, [currentRoomData])

  return (
    <ScreenContainer>
      <TopSectionWrap>
        {currentRoomData && (
          <ChatRoomHeader
            roomId={currentRoomData.id}
            userName={currentRoomData.userName}
            preferredPronouns={currentRoomData.preferredPronouns}
            userAvatar={currentRoomData.userAvatar}
            bgColor={currentRoomData.avatarBackground}
          />
        )}
        {isLoading && <LoadingSpinner />}
        {WrappedChatContainer && <WrappedChatContainer />}
        {currentRoomData && (
          <ChatMessageModal
            isOpen={showMessageModalIsOpen}
            onClose={handleCloseShowMessageModal}
            message={currentRoomData.chatMessage}
          />
        )}
      </TopSectionWrap>
      <ChatRoomFooter />
    </ScreenContainer>
  )
}

export default ChatScreen

ChatScreen.propTypes = {}
