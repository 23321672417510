import { createSlice } from '@reduxjs/toolkit'
import { remove, zipObject } from 'lodash/array'
import { filter, includes, keyBy, some } from 'lodash/collection'
import { isEmpty } from 'lodash/lang'
import { get, keys, mapValues } from 'lodash/object'
import dayjs from 'dayjs'
import { api } from '../../api/api.generated'
import { getDistanceBetweenTwoPoints } from '../../utils/map-utils'
import { logout } from '../auth/authSlice'

const initRateFilter = {
  0: false,
  1: false,
  2: false,
  3: false,
  4: false,
  5: false
}
const initShopsByRating = {
  0: [],
  1: [],
  2: [],
  3: [],
  4: [],
  5: []
}
const defaultRoute = [
  -37.79311, 144.97936, 0, -37.79341, 144.97931000000003, 0,
  -37.793350000000004, 144.97870000000003, 0, -37.793867000000006,
  144.97864600000003, 0, -37.793867, 144.978646, 0, -37.794019999999996,
  144.97863, 0, -37.794669999999996, 144.97852, 0, -37.7947, 144.9788, 0,
  -37.79471, 144.97908, 0, -37.79489, 144.97905, 0, -37.79534, 144.97897, 0,
  -37.79552, 144.97894, 0, -37.79576, 144.97889999999998, 0, -37.79594,
  144.97886999999997, 0, -37.79614, 144.97883999999996, 0, -37.796440000000004,
  144.97878999999998, 0, -37.79674000000001, 144.97874, 0, -37.79715000000001,
  144.97867, 0, -37.79754000000001, 144.9786, 0, -37.797770000000014, 144.97856,
  0, -37.79796000000002, 144.97852899999998, 0, -37.79796, 144.978529, 0,
  -37.798080000000006, 144.97851, 0, -37.79834, 144.97847, 0,
  -37.798370000000006, 144.97872999999998, 0, -37.798390000000005, 144.97893, 0,
  -37.798460000000006, 144.97958, 0, -37.79851000000001, 144.98011, 0,
  -37.79853000000001, 144.9803, 0, -37.798570000000005, 144.98071, 0,
  -37.79862000000001, 144.98118, 0, -37.798640000000006, 144.98137, 0,
  -37.79867000000001, 144.98166, 0, -37.79869000000001, 144.98187000000001, 0,
  -37.79872000000001, 144.98219, 0, -37.79878000000001, 144.98268000000002, 0,
  -37.798820000000006, 144.9831, 0, -37.798860000000005, 144.98347, 0,
  -37.79891000000001, 144.98389, 0, -37.798930000000006, 144.98408, 0,
  -37.798950000000005, 144.98428, 0, -37.79865, 144.98433, 0, -37.79826,
  144.9844, 0, -37.79814, 144.98442, 0, -37.797869999999996, 144.98446, 0,
  -37.79756, 144.98451, 0, -37.797349999999994, 144.98455, 0,
  -37.797079999999994, 144.9846, 0, -37.796839999999996, 144.98464, 0,
  -37.79656, 144.98468000000003, 0, -37.79618, 144.98475000000002, 0, -37.79598,
  144.98478000000003, 0, -37.79571, 144.98483000000002, 0, -37.79535, 144.9849,
  0, -37.79471, 144.985, 0, -37.794661000000005, 144.984507, 0
]
const initState = {
  route: [],
  visitedShops: {},
  filterRatings: initRateFilter,
  filterCategories: {},
  isRouteModeActive: false,
  mapRouteObjectIds: [],
  distancesFromLastUpdated: {},
  lastOpenedTab: '/cities',
  currentCityShops: {},
  filteredCityShops: [],
  distancesToFilteredShops: [],
  distanceUserShopsByShopId: {},
  closestFilteredShopId: null,
  closestShopInHop: null,
  shopsByRating: initShopsByRating,
  shopsIds: [],
  currentCityId: null,
  selectedCityId: null,
  currentCity: 'Unknown location...',
  currentDestinationIndex: 0,
  evaluatedRouteDistances: [],
  evaluatedRouteTravelTimes: [],
  goNowShop: null,
  previewRouteShopId: null,
  geoSimulationIsOn: false,
  simulatedRoute: defaultRoute,
  updateFrequencySpeed: 1000,
  routeRequiresUpdate: false,
  routeInstruction: []
}

const hopSlice = createSlice({
  name: 'hop',
  initialState: initState,
  reducers: {
    setDistancesFromLastUpdated: (state, { payload }) => {
      const { shopsIds, distnaces } = payload
      state.distancesFromLastUpdated = zipObject(shopsIds, distnaces)
    },
    addShopToRoute: (state, { payload }) => {
      if (state.isRouteModeActive) {
        state.routeRequiresUpdate = true
      }
      const sortedRoute = [...state.route, payload]
        .sort(
          (first, second) => state.distanceUserShopsByShopId[first.shopId] - state.distanceUserShopsByShopId[second.shopId]
        )
        // .sort(
        //   (a, b) => a.sequence - b.sequence
        // )
        state.route = sortedRoute
    },
    replaceRouteShops: (state, { payload }) => {
      state.route = payload
    },
    removeShopFromRouteByShopId: (state, { payload }) => {
      if (state.isRouteModeActive) {
        state.routeRequiresUpdate = true
      }
      remove(state.route, (shop) => shop.shopId === payload)
    },

    toggleRouteMode: (state, { payload }) => {
      state.isRouteModeActive = payload ?? !state.isRouteModeActive
    },
    setMapRouteObjectIds: (state, { payload }) => {
      state.mapRouteObjectIds = payload
    },
    setLastOpenedTab: (state, { payload }) => {
      state.lastOpenedTab = payload ?? '/cities'
    },
    clearRoute: (state) => {
      state.route = []
    },
    setVisitedStatus: (state, { payload }) => {
      state.visitedShops[payload.shopId] = payload.status
    },
    updateShopLastVisitDate: (state, { payload }) => {
      const newLastVisited = dayjs().format('YYYY-MM-DD')
      if (state.currentCityShops[payload]) {
        state.currentCityShops[payload].lastVisited = newLastVisited
      }
      const filteredIndex = state.filteredCityShops.findIndex(
        (shop) => shop.id === payload
      )
      if (state.filteredCityShops[filteredIndex]) {
        state.filteredCityShops[filteredIndex].lastVisited = newLastVisited
      }
    },
    updateShopData: (state, { payload: { shopId, data } }) => {
      if (state.currentCityShops[shopId]) {
        state.currentCityShops[shopId] = {
          ...state.currentCityShops[shopId],
          ...data
        }
      }
      const filteredIndex = state.filteredCityShops.findIndex(
        (shop) => shop.id === shopId
      )
      if (state.filteredCityShops[filteredIndex]) {
        state.filteredCityShops[filteredIndex] = {
          ...state.filteredCityShops[filteredIndex],
          ...data
        }
      }
    },
    setShopsToVisit: (state, { payload }) => {
      state.visitedShops = payload.reduce(
        (o, key) => Object.assign(o, { [key.shopId]: false }),
        {}
      )
    },
    setCurrentDestinationIndex: (state, { payload }) => {
      state.currentDestinationIndex = payload
    },
    toggleRateFilter: (state, { payload }) => {
      state.filterRatings[payload] = !state.filterRatings[payload]
    },
    toggleTagFilter: (state, { payload }) => {
      state.filterCategories[payload] = !state.filterCategories[payload]
    },
    selectTag: (state, { payload }) => {
      state.filterCategories = {
        ...mapValues(state.filterCategories, () => false),
        [payload]: true
      }
    },
    filterCityShops: (state) => {
      const selectedRates = keys(state.filterRatings).filter(
        (score) => state.filterRatings[score]
      )

      if (selectedRates.length === 0) {
        selectedRates.push(...keys(state.filterRatings))
      }

      const selectedTags = keys(state.filterCategories).filter(
        (tag) => state.filterCategories[tag]
      )
      const selectedRatings = []
      selectedRates.forEach((score) => {
        selectedRatings.push(...state.shopsByRating[score])
      })
      state.filteredCityShops = selectedRatings
      state.filteredCityShops = filter(selectedRatings, (shop) =>
        some(shop.tags, (tag) => includes(selectedTags, tag))
      )
    },
    setSelectedCityId: (state, { payload }) => {
      if (payload !== state.selectedCityId) {
        state.selectedCityId = payload
        state.currentCityShops = {}
        state.filteredCityShops = []
        state.route = []
      }
    },
    setCurrentCityId: (state, { payload }) => {
      state.currentCityId = payload
    },
    setSelectedCityName: (state, { payload }) => {
      state.currentCity = payload
    },
    setGoNowShop: (state, { payload }) => {
      state.goNowShop = payload
    },
    evaluateTravelDistances: (
      state,
      { payload: { destinations, initialPosition } }
    ) => {
      const evaluatedDistances = []
      if (destinations.length) {
        destinations?.forEach(({ shopLat, shopLng }) => {
          evaluatedDistances.push(
            getDistanceBetweenTwoPoints(
              { lat: parseFloat(shopLat), lng: parseFloat(shopLng) },
              {
                lat: parseFloat(initialPosition.lat),
                lng: parseFloat(initialPosition.lng)
              }
            )
          )
        })
        state.evaluatedRouteDistances = evaluatedDistances
      }
    },
    updateDistancesToFilteredShops: (
      state,
      { payload: { currentLocation } }
    ) => {
      const evaluatedDistances = []
      if (state.filteredCityShops.length) {
        state.filteredCityShops?.forEach((shop) => {
          evaluatedDistances.push(
            getDistanceBetweenTwoPoints(
              {
                lat: parseFloat(shop.location.lat),
                lng: parseFloat(shop.location.lng)
              },
              {
                lat: parseFloat(currentLocation.lat),
                lng: parseFloat(currentLocation.lng)
              }
            )
          )
        })
        state.distancesToFilteredShops = evaluatedDistances
      }
    },
    updateDistanceUserToShopsByShopId: (
      state,
      { payload: { currentLocation } }
    ) => {
      if (
        !isEmpty(state.currentCityShops) &&
        state.shopsIds.length &&
        state.currentCityId === state.selectedCityId
      ) {
        state.shopsIds?.forEach((shopId) => {
          const shopLocation = state.currentCityShops?.[shopId]?.location
          if (shopLocation) {
            const distanceToShop = getDistanceBetweenTwoPoints(
              {
                lat: parseFloat(shopLocation.lat),
                lng: parseFloat(shopLocation.lng)
              },
              {
                lat: parseFloat(currentLocation.lat),
                lng: parseFloat(currentLocation.lng)
              }
            )
            state.distanceUserShopsByShopId[shopId] = distanceToShop
          }
        })
      }
    },
    clearRouteDistances: (state) => {
      state.evaluatedRouteDistances = []
    },
    setPreviewRouteShopId: (state, { payload }) => {
      state.previewRouteShopId = payload
    },

    setClosestFilteredShopId: (state) => {
      const distancesById = [...state.filteredCityShops].map((shop) => ({
        id: shop.id,
        dist: state.distanceUserShopsByShopId[shop.id]
      }))
      const closestShopID = [...distancesById].sort(
        (first, second) => first.dist - second.dist
      )?.[0]?.id
      state.closestFilteredShopId = closestShopID
    },

    setClosestShopInHop: (state) => {
      const distancesById = state.route.map((shop) => ({
        id: shop.shopId,
        dist: state.distanceUserShopsByShopId[shop.shopId]
      }))
      state.closestShopInHop = [...distancesById].sort(
        (first, second) => first.dist - second.dist
      )?.[0]?.id
    },

    // geo simulation UI
    setIsSimulatingGeo: (state, { payload }) => {
      state.geoSimulationIsOn = payload
    },
    setSimulatedRoute: (state, { payload }) => {
      state.simulatedRoute = payload
    },
    setRefreshGeoFrequency: (state, { payload }) => {
      state.updateFrequencySpeed = payload
    },
    setRouteRequiredUpdate: (state, { payload }) => {
      state.routeRequiresUpdate = payload
    },
    setRouteInstructions: (state, { payload }) => {
      state.routeInstruction = payload
    },
    reset: () => initState
  },
  extraReducers: (builder) => {
    // builder
    // .addMatcher(
    //   api?.endpoints?.getHereWeGoToken?.matchFulfilled,
    //   (state, { payload }) => {
    //     state.hereWeGoToken = payload.token
    //     state.hereWeGoTokenExpirationDate =dayjs().valueOf()
    //   }
    // )
    builder

      .addCase(logout, () => initState)
      .addMatcher(
        api?.endpoints?.getUserShopByCityId?.matchFulfilled,
        (state, { payload, arg }) => {
          if (
            isEmpty(state.currentCityShops) ||
            state.currentCityId !== state.selectedCityId
          ) {
            state.currentCityShops = keyBy(payload.shops, 'id')
            state.filteredCityShops = payload.shops
            const newShopsIds = []
            const newShopsByRating = {
              0: [],
              1: [],
              2: [],
              3: [],
              4: [],
              5: []
            }
            payload.shops.forEach((shop) => {
              newShopsIds.push(shop.id)
              newShopsByRating[Math.floor(shop.rating)].push(shop)
            })
            state.shopsIds = [...newShopsIds]
            state.shopsByRating = {
              ...newShopsByRating
            }
            state.currentCityId = get(
              arg,
              'originalArgs.cityId',
              state.selectedCityId
            )
          }
        }
      )
      .addMatcher(
        api?.endpoints?.getTags?.matchFulfilled,
        (state, { payload }) => {
          state.filterCategories = payload.tags.reduce(
            (o, key) => Object.assign(o, { [key.id]: true }),
            {}
          )
        }
      )
  }
})
export default hopSlice.reducer

export const {
  addShopToRoute,
  replaceRouteShops,
  removeShopFromRouteByShopId,
  toggleRouteMode,
  setMapRouteObjectIds,
  setLastOpenedTab,
  clearRoute,
  toggleRateFilter,
  setVisitedStatus,
  setShopsToVisit,
  setCurrentDestinationIndex,
  toggleTagFilter,
  selectTag,
  updateShopLastVisitDate,
  filterCityShops,
  setSelectedCityId,
  setSelectedCityName,
  setCurrentCityId,
  setGoNowShop,
  updateShopData,
  evaluateTravelDistances,
  clearRouteDistances,
  updateDistanceUserToShopsByShopId,
  setPreviewRouteShopId,
  setClosestFilteredShopId,
  setClosestShopInHop,
  setIsSimulatingGeo,
  setSimulatedRoute,
  setRefreshGeoFrequency,
  setRouteRequiredUpdate,
  setRouteInstructions
} = hopSlice.actions
