import styled from 'styled-components'
import Typography from '../UI/Typography'
import { VStack } from '../../global/styles'

export const UserAvatarWrap = styled(VStack)`
  position: relative;
  height: ${({ size }) => size}px;
  min-height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  min-width: ${({ size }) => size}px;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background-image: url(${({ bgImage }) => bgImage});
  background-position: center;
  background-size: cover;
  background-color: ${({ bgColor }) => bgColor};
`

export const UserInitials = styled(Typography)``

export const Status = styled.div`
  width: ${({ size }) => Math.round(size / 4)}px;
  height: ${({ size }) => Math.round(size / 4)}px;
  background: ${({ theme }) => theme.colors.greenBase};
  border-radius: 100px;
  position: absolute;
  right: 0;
  bottom: 0;
  border: 2px solid ${({ theme }) => theme.colors.white};
`
