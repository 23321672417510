import styled from 'styled-components'
import { HStack } from '../../../global/styles'

export const GalleryWrap = styled(HStack)`
  flex-wrap: wrap;
  width: 100%;
  padding: 0 16px;
`
export const ImageWrap = styled.div`
  margin-right: 16px;
  margin-bottom: 16px;
`
