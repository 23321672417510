import React from 'react'
import { Formik } from 'formik'
import { isNull } from 'lodash'
import { ButtonWrapper } from './styles'
import { FormBody, HeadlineWrap, StyledForm } from '../ProfileDetails/styles'
import ScreenHeader from '../../../components/ScreenHeader'
import { Description, Headline } from '../SettingsScreen/styles'
import TextInput from '../../../components/UI/Inputs/TextInput/TextInput'
import Button from '../../../components/UI/Buttons/Button'
import { BUTTON_VARIANTS, BUTTONS_SIZES } from '../../../const/UIvariants'
import useChangePhoneNumber from '../../../hooks/settings/useChangePhoneNumber'
import useTimer from '../../../hooks/common/useTimer'

const CheckSmsCode = () => {
  const { handleCheckCode } = useChangePhoneNumber()
  const { seconds, startTimer } = useTimer({ initiateOnMount: true })

  return (
    <Formik
      initialValues={{
        verificationCode: ''
      }}
      onSubmit={(values, formik) => {
        handleCheckCode(values, formik)
        startTimer(60)
      }}
    >
      {({ submitForm }) => (
        <StyledForm>
          <ScreenHeader title="Check Code" backButton />
          <FormBody>
            <HeadlineWrap>
              <Headline>Enter code from SMS</Headline>
              <Description>Please enter code from SMS</Description>
            </HeadlineWrap>
            <TextInput
              label="Verification Code"
              inputName="verificationCode"
              placeholder="Enter code from SMS"
            />
            <ButtonWrapper>
              <Button
                type="submit"
                variant={BUTTON_VARIANTS.PRIMARY}
                size={BUTTONS_SIZES.LARGE}
              >
                Check Code
              </Button>
              {!isNull(seconds) && (
                <Button
                  type="button"
                  size={BUTTONS_SIZES.LARGE}
                  disabled={seconds !== 0}
                  variant={BUTTON_VARIANTS.SECONDARY}
                  onClick={() => {
                    submitForm()
                  }}
                >
                  Resend code
                  {seconds !== 0 &&
                    ` in ${seconds < 10 ? `0${seconds}` : seconds}`}
                </Button>
              )}
            </ButtonWrapper>
          </FormBody>
        </StyledForm>
      )}
    </Formik>
  )
}

export default CheckSmsCode

CheckSmsCode.propTypes = {}
