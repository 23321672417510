export const selectHopRoute = (state) => state.hop.route
export const selectIsInRouteMode = (state) => state.hop.isRouteModeActive
export const selectCurrentLocation = (state) => ({
  currentLat: state.app.currentLat,
  currentLng: state.app.currentLng
})
export const selectUpdatedAtLocation = (state) => ({
  lastUpdatedAtLat: state.app.lastUpdatedAtLat,
  lastUpdatedAtLng: state.app.lastUpdatedAtLng
})

export const selectCurrentCityShops = (state) => state.hop.currentCityShops
export const selectShopById = (shopId) => (state) => state.hop.currentCityShops[shopId]
export const selectFilteredCityShops = (state) => state.hop.filteredCityShops
export const selectCurrentCityName = (state) => state.hop.currentCity
export const selectCurrentCityId = (state) => state.hop.currentCityId
export const selectSelectedCityId = (state) => state.hop.selectedCityId
export const selectTagsById = (state) => state.app.tagsById
export const selectTagById = (tagId) => (state) => state.app.tagsById[tagId]
export const selectVisitedShops = (state) => state.hop.visitedShops
export const selectCurrentDestinationIndex = (state) => state.hop.currentDestinationIndex
export const selectEvaluatedDistances = (state) => state.hop.evaluatedRouteDistances
export const selectEvaluatedDistanceToShopInRoute = (shopId) => (state) => {
  const index = state.hop.route.findIndex((shop) => shop.shopId === shopId)
  return selectEvaluatedDistances(state)[index]
}
export const selectDistancesToShops = (state) =>
  state.hop.distanceUserShopsByShopId
export const selectDistanceToShopById = (shopId) => (state) =>
  state.hop.distanceUserShopsByShopId[shopId]
export const selectShopsNearby = (quantity) => (state) => {
  const shopByDistance = [...state.hop.shopsIds].sort(
    (shop1, shop2) =>
      state.hop.distanceUserShopsByShopId[shop1] -
      state.hop.distanceUserShopsByShopId[shop2]
  )
  return shopByDistance.slice(0, quantity)
}
export const selectEvaluatedTravelTime = (state) => state.hop.evaluatedRouteTravelTimes
export const selectFilterRatings = (state) => state.hop.filterRatings
export const selectFilterCategories = (state) => state.hop.filterCategories
export const selectMapObjectIds = (state) => state.hop.mapRouteObjectIds
export const selectPreviewRouteShopId = (state) => state.hop.previewRouteShopId
export const selectClosestInRoute = (state) => state.hop.closestShopInHop

// search
export const selectIsSearchOpen = (state) => state.search.isSearchOpen
export const selectPreviousSearchQueries = (state) => state.search.lastSearchQueriesQueue

export const selectSuggestedSearchQueries = (state) => state.search.suggestedQueries

export const selectToken = (state) => state.auth.token
export const selectDistancesToCities = (state) => state.app.distanceToCities
export const selectCities = (state) => state.app.cities

export const selectOpenChatUserId = (state) => state.friends.openChatUserId
export const selectUserId = (state) => state.auth.userId
export const selectLastOpenFriendsTab = (state) => state.friends.lastVisitedTab
export const selectDoesRouteRequiresUpdate = (state) => state.hop.routeRequiresUpdate
