import styled from 'styled-components'
import { HStack } from '../../../global/styles'

export const GalleryWrap = styled(HStack)`
  flex-wrap: wrap;
  width: 100%;
  padding: 0 16px;
  & :nth-child(2n) {
    margin-left: 16px;
  }
`
export const ImageWrap = styled.div`
  background: url(${({ background }) => background}) center;
  width: calc(50% - 8px);
  height: 141px;
  margin-bottom: 16px;
  border-radius: 6px;
  background-size: cover;
`
