import React from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Preview } from '@styled-icons/material'
import ROUTES from '../../const/ROUTES'
import IconButton from '../UI/Buttons/IconButton'
import { Wrapper, DetailsWrap, OrderCode, OrderDate, IconWrap } from './styles'

const OrderListItem = ({ id, code, date, total }) => {
  const history = useHistory()

  return (
    <Wrapper
      onClick={() => {
        history.push(generatePath(ROUTES.ORDER_ITEMS, { orderId: id }))
      }}
    >
      <DetailsWrap>
        <OrderCode>Order #{code}</OrderCode>
        <OrderDate>{date}</OrderDate>
        <OrderDate>${total}</OrderDate>
      </DetailsWrap>
      <IconButton size={34} onClick={() => {}}>
        <IconWrap>
          <Preview size={16} color="#ffffff" />
        </IconWrap>
      </IconButton>
    </Wrapper>
  )
}

export default OrderListItem

OrderListItem.propTypes = {
  id: PropTypes.number.isRequired,
  code: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired
}
