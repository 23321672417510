import React, { useEffect, useState } from 'react'
import { isEmpty } from 'lodash/lang'
import { shallowEqual, useSelector } from 'react-redux'
import { at } from 'lodash/object'
import { useHistory } from 'react-router-dom'
import { IconWrap, NoShopsWrap } from './styles'
import ShopPreviewItem from '../../../components/ShopPreviewItem'
import {
  selectCurrentCityShops,
  selectHopRoute
} from '../../../store/selectors'
import Button from '../../../components/UI/Buttons/Button'
import { BUTTON_VARIANTS, BUTTONS_SIZES } from '../../../const/UIvariants'
import useCreateMapRoute from '../../../hooks/route/useCreateMapRoute'
import usePersistOpenHopTab from '../../../hooks/hop/usePersistOpenHopTab'
import useShopDetails from '../../../hooks/shop/useShopDetails'
import { ContainerWrap } from '../HopDrawerContainer/styles'
import { NoDataMessage } from '../../../global/styles'
import ROUTES from '../../../const/ROUTES'
import EmptyHop from '../../../assets/vector/EmptyHop'

const MyHops = () => {
  usePersistOpenHopTab('/my-hops')
  const [routeShops, setRouteShops] = useState([])
  const route = useSelector(selectHopRoute, shallowEqual)
  const shops = useSelector(selectCurrentCityShops, shallowEqual)
  //  const closestInRoute = useSelector(selectClosestInRoute)
  const { handleOpenShop } = useShopDetails()
  const { buildAndStartRoute } = useCreateMapRoute()
  const history = useHistory()
  useEffect(() => {
    if (!isEmpty(route) && !isEmpty(shops)) {
      const ids = route.map((shop) => shop.shopId)
      setRouteShops(at(shops, ids))
    } else setRouteShops([])
  }, [route, shops])

  return (
    <ContainerWrap>
      {isEmpty(route) ? (
        <NoShopsWrap>
          <IconWrap>
            <EmptyHop />
          </IconWrap>
          <NoDataMessage align="center">
            You haven&apos;t added any stores yet
          </NoDataMessage>
          <Button
            size={BUTTONS_SIZES.MED}
            onClick={() => {
              history.push(ROUTES.HOP_SHOPS)
            }}
          >
            Browse Stores
          </Button>
        </NoShopsWrap>
      ) : (
        <>
          {routeShops.map(
            (shop, index) =>
              shop && (
                <ShopPreviewItem
                  key={shop.id}
                  id={shop.id}
                  handleOpenShop={() => {
                    handleOpenShop(shop.id)
                  }}
                  address={shop.location.displayAddress}
                  shopName={shop.storeName}
                  isFavorite={shop.isFavorite}
                  lastVisited={shop.lastVisited}
                  favoriteText={shop.favoriteText}
                  distance={shop.location.displayDistance}
                  lat={shop.location.lat}
                  lng={shop.location.lng}
                  logotype={shop.logotype}
                  rating={shop.rating}
                  isClosest={index === 0}
                  sequence={shop.sequence}
                />
              )
          )}
          { false &&
          <Button
            variant={BUTTON_VARIANTS.PRIMARY}
            size={BUTTONS_SIZES.LARGE}
            onClick={() => {
              buildAndStartRoute(route)
            }}
          >
            Confirm & Start Navigation
          </Button>
          }
          <div style={{ height: '300px' }} />
        </>
      )}
    </ContainerWrap>
  )
}

export default MyHops

MyHops.propTypes = {}
