/*eslint-disable*/
import React from 'react'
import PropTypes from 'prop-types'
import {
  QuestionItemWrap,
  QuestionDetailsWrap,
  QuestionTitle,
  QuestionData
} from './styles'

const QuestionItem = ({ question, answer }) => (
  <QuestionItemWrap>
    <QuestionDetailsWrap>
      <QuestionTitle>{question}</QuestionTitle>
      <QuestionData>{answer}</QuestionData>
    </QuestionDetailsWrap>
  </QuestionItemWrap>
)

export default QuestionItem

QuestionItem.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired
}
