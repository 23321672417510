import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper } from './styles'
import ContactListItemDetail from '../ContactListItemDetail'

const ContactListItem = ({
  id,
  contactName,
  preferredPronouns,
  contactAvatar,
  contactLocation,
  bgColor,
  onSendMessage
}) => (
  <Wrapper
    onClick={() => {
      onSendMessage(id);
    }}
  >
    <ContactListItemDetail
      contactName={contactName}
      preferredPronouns={preferredPronouns}
      contactAvatar={contactAvatar}
      contactLocation={contactLocation}
      bgColor={bgColor}
    />
  </Wrapper>
)

export default ContactListItem

ContactListItem.defaultProps = {
  preferredPronouns: '',
  contactAvatar: null,
  contactLocation: null
}

ContactListItem.propTypes = {
  id: PropTypes.string.isRequired,
  contactName: PropTypes.string.isRequired,
  preferredPronouns: PropTypes.string,
  bgColor: PropTypes.string.isRequired,
  contactLocation: PropTypes.string,
  contactAvatar: PropTypes.string,
  onSendMessage: PropTypes.func.isRequired,
}
