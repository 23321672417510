import {
  Album,
  Bathtub,
  Checkroom,
  Devices,
  HeadsetMic,
  House,
  LocalMall,
  RestaurantMenu
} from '@styled-icons/material'
import React from 'react'

export const USER_ROLE = 'user'
export const TAG_ICONS = {
  checkroom: <Checkroom />,
  bathtub: <Bathtub />,
  house: <House />,
  devices: <Devices />,
  restaurant_menu: <RestaurantMenu />,
  headset_mic: <HeadsetMic />,
  local_mall: <LocalMall />,
  album: <Album />
}
export const SHOPS_RATES = [0, 1, 2, 3, 4, 5]

export const ACTION_PROFILE = 1;
export const ACTION_SHARE = 2;
export const ACTION_GO_NOW = 3;
