import { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { H } from '../../../../config/hereWego'
import CurrentLocationMarker from '../../../../assets/MapMarkers/CurrentLocationMarker'
import { selectCurrentLocation } from '../../../../store/selectors'
import MapContext from '../../MapContext'
import useUpdateDistances from '../../../../hooks/hop/useUpdateDistances'

const USER_LOCATION_MARKER = 'USER_LOCATION_MARKER'

const CurrentLocationOnMap = () => {
  // todo:refactor this to background process component
  useUpdateDistances()
  const mapInstance = useContext(MapContext)

  const currentLocation = useSelector(selectCurrentLocation)
  const icon = new H.map.DomIcon(CurrentLocationMarker)
  const { currentLat, currentLng } = currentLocation
  useEffect(() => {
    if (mapInstance) {
      const marker = new H.map.DomMarker(
        { lat: currentLat, lng: currentLng },
        { icon }
      )
      // const markerMapGroup = new H.map.Group()
      // markerMapGroup.addObject(marker)
      marker.setRemoteId(USER_LOCATION_MARKER)
      marker.setZIndex(1000)
      // Object.values(mapInstance.getObjects()).forEach((object) => {
      //   if (object.getRemoteId() === USER_LOCATION_MARKER) {
      //     object.setGeometry({lat})
      //     // mapInstance.removeObject(object)
      //   }
      // })
      //
      mapInstance.addObject(marker)
    }
  }, [mapInstance])
  useEffect(() => {
    if (mapInstance) {
      Object.values(mapInstance.getObjects()).forEach((object) => {
        if (object.getRemoteId() === USER_LOCATION_MARKER) {
          object.setGeometry({ lat: currentLat, lng: currentLng })
          // mapInstance.removeObject(object)
        }
      })

      // mapInstance.addObject(markerMapGroup)
    }
  }, [currentLocation])

  return null
}

export default CurrentLocationOnMap

CurrentLocationOnMap.propTypes = {}
