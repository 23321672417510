import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Count, StyledTab, TabLabel } from './styles'

const Tab = ({ autoWidth, label, isActive, destination, count }) => {
  const history = useHistory()
  const handleClick = (dest) => {
    history.replace(dest)
  }
  return (
    <StyledTab
      {...{ isActive, autoWidth }}
      onClick={() => handleClick(destination)}
    >
      <TabLabel {...{ isActive }} variant="textXS" bold align="center">
        {label}
      </TabLabel>
      {count > 0 && !isActive && <Count>{count}</Count>}
    </StyledTab>
  )
}

export default Tab
Tab.defaultProps = {
  count: null
}

Tab.propTypes = {
  count: PropTypes.number,
  label: PropTypes.string.isRequired,
  destination: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  autoWidth: PropTypes.bool.isRequired
}
