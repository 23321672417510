import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import { useSelector } from 'react-redux'
import { Container } from './styles'
import { selectHopRoute } from '../../../store/selectors'
import Button from '../../UI/Buttons/Button/Button'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES as BUTTON_SIZES
} from '../../../const/UIvariants'
import { ButtonWrapper, ModalHeadline } from '../AddToFavoritesModal/styles'
import useCreateMapRoute from '../../../hooks/route/useCreateMapRoute'

const ConfirmStopRouteModal = ({ isOpen, handleClose }) => {
  const route = useSelector(selectHopRoute)
  const { stopRoute } = useCreateMapRoute()
  const handleStopRoute = () => {
    stopRoute()
    handleClose()
  }

  return (
    <Modal {...{ isOpen }}>
      <Container>
        <ModalHeadline>{`Are you sure want to stop directions to ${route.length} Stores?`}</ModalHeadline>
        <ButtonWrapper>
          <Button
            variant={BUTTON_VARIANTS.GREY}
            size={BUTTON_SIZES.MED}
            onClick={handleStopRoute}
          >
            Stop
          </Button>
          <Button
            variant={BUTTON_VARIANTS.PRIMARY}
            size={BUTTON_SIZES.MED}
            onClick={handleClose}
          >
            Continue
          </Button>
        </ButtonWrapper>
      </Container>
    </Modal>
  )
}

export default ConfirmStopRouteModal

ConfirmStopRouteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
}
