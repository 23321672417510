import React from 'react'
import { DirectionsCar } from '@styled-icons/material'
import PropTypes from 'prop-types'
import { values } from 'lodash/object'
import { useSelector } from 'react-redux'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES,
  BUTTONS_SIZES as BUTTON_SIZES
} from '../../../const/UIvariants'
import Button from '../../../components/UI/Buttons/Button/Button'
import useBuildHopRoute from '../../../hooks/route/useBuildHopRoute'
import { selectIsInRouteMode } from '../../../store/selectors'

const AddToRouteButton = ({ id, lat, lng, sequence, size, labelSize }) => {
  const { handleToggleInRoute, isInRoute } = useBuildHopRoute({
    shopId: id,
    shopLat: lat,
    shopLng: lng,
    sequence
  })

  const isInRouteMode = useSelector(selectIsInRouteMode)
  return (
    <Button
      variant={isInRoute ? BUTTON_VARIANTS.SECONDARY : BUTTON_VARIANTS.OUTLINED}
      size={size}
      onClick={handleToggleInRoute}
      leftIcon={isInRoute && <DirectionsCar />}
      labelSize={labelSize}
    >
      {isInRoute ? 'Added' : isInRouteMode ? 'Update My Hop' : 'Add to My Hop'}
    </Button>
  )
}

export default React.memo(AddToRouteButton)

AddToRouteButton.defaultProps = {
  size: BUTTON_SIZES.MED,
  sequence: 0,
  labelSize: 'textM'
}

AddToRouteButton.propTypes = {
  id: PropTypes.string.isRequired,
  sequence: PropTypes.number,
  lat: PropTypes.string.isRequired,
  lng: PropTypes.string.isRequired,
  labelSize: PropTypes.string,
  size: PropTypes.oneOf(values(BUTTONS_SIZES))
}
