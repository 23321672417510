import styled from 'styled-components'
import { HStack, VStack } from '../../global/styles'

export const Wrapper = styled.div`
  cursor: pointer;
`
export const MenuWrap = styled(VStack)`
  /* chat bubble */
  width: 100px;
  ${({ isRight }) => isRight && `
    right: 30px;
  `}
  background-color: white;
  position: absolute;
  /* right: 0; */
  z-index: 300;
  display: flex;
  flex-direction: column;
  border: 1px solid #d9dce2;
  box-shadow: inset 0px -1px 0px rgba(208, 201, 214, 0.4);
  border-radius: 12px;
  overflow: hidden;
  & > *:first-child {
    border-bottom: 1px solid #d9dce2;
  }
`
export const MenuItem = styled(HStack)`
  padding: 10px;
  width: 100%;
  &:hover {
    background-color: ${({ theme }) => theme.colors.skyBase4};
  }
  & > *:first-child {
    margin-right: 8px;
  }
`
