/*eslint-disable*/
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Favorite } from '@styled-icons/material'
import Button from '../UI/Buttons/Button/Button'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES as BUTTON_SIZES
} from '../../const/UIvariants'
import { usePostShopPostLikeMutation } from '../../api/api.generated'

const LikeButton = ({ postId, isLiked }) => {
  const [liked, setLiked] = useState(isLiked)
  const [postShopPostLike] = usePostShopPostLikeMutation()

  const handleClick = () => {
    postShopPostLike({
      postId,
      body: { isLiked: !liked }
    })
    setLiked(!liked)
  }

  return (
    <Button
      variant={liked ? BUTTON_VARIANTS.PINK : BUTTON_VARIANTS.GREY}
      leftIcon={<Favorite />}
      size={BUTTON_SIZES.MED}
      onClick={handleClick}
    >
      Like
    </Button>
  )
}

export default LikeButton

LikeButton.defaultProps = {
  isLiked: false
}
LikeButton.propTypes = {
  postId: PropTypes.string.isRequired,
  isLiked: PropTypes.bool
}
