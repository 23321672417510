import React from 'react'
import { isEmpty } from 'lodash/lang'
import { ScreenContainer } from '../../../global/styles'
import { IsEmptyText, PostsWrap, EventsContentWrapper } from './styles'
import EventsHeader from '../EventsHeader'
import ShopPostItem from '../../../components/ShopPostItem'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import { useGetEventsQuery } from '../../../api/api.generated'

const EventsScreenContainer = () => {
  const { data: { events } = {}, isLoading } = useGetEventsQuery()

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <ScreenContainer>
        <EventsHeader />
        <EventsContentWrapper>
          <PostsWrap>
            {isEmpty(events) ? (
              <IsEmptyText variant="textM">No events added yet.</IsEmptyText>
            ) : (
              <>
                {events.map((post) => (
                  <ShopPostItem
                    key={post.id}
                    showShopLogo
                    shopId={post.shopId}
                    logotype={post.logotype}
                    shopName={post.shopName}
                    showShopName
                    postDeadline={post.startTime !== '' && post.endTime !== ''}
                    deadlineText={post.deadlineText}
                    requireCode={post.requireCode}
                    postTitle={post.postTitle}
                    postDescription={post.postDescription}
                    postImage={post.postImage}
                    postId={post.id}
                    dateStart={post.startTime}
                    dateEnd={post.endTime}
                    showInterestedButton={post.isEvent}
                    isInterested={post.isInterested}
                    showLikeButton
                    isLiked={post.isLiked}
                    showAttendedButton={!post.isFinished}
                    isAttended={post.isAttended}
                  />
                ))}
              </>
            )}
          </PostsWrap>
        </EventsContentWrapper>
      </ScreenContainer>
    </>
  )
}

export default EventsScreenContainer

EventsScreenContainer.propTypes = {}
