import React from 'react'
import PropTypes from 'prop-types'
import { NorthEast } from '@styled-icons/material'
import { PrevQueryText, PrevQueryWrap } from './styles'

const PreviousSearchQueries = ({ queries, handleSearchAgain }) => (
  <>
    {queries.map((query) => (
      <PrevQueryWrap
        key={query}
        onClick={() => {
          handleSearchAgain(query)
        }}
      >
        <PrevQueryText>{query}</PrevQueryText>
        <NorthEast size={18} color="#5B677F" />
      </PrevQueryWrap>
    ))}
  </>
)

export default PreviousSearchQueries
PreviousSearchQueries.defaultProps = {
  queries: []
}

PreviousSearchQueries.propTypes = {
  queries: PropTypes.array,
  handleSearchAgain: PropTypes.func.isRequired
}
