import styled from 'styled-components'
import { HStack, VStack } from '../../global/styles'
import Typography from '../UI/Typography'

export const HeaderContainer = styled(VStack)`
  background: white;
  height: 136px;
  box-shadow: inset 0px -1px 0px rgba(95, 110, 130, 0.15);
  justify-content: flex-end;
  align-items: stretch;
  padding: 21px 16px;
  //position: fixed;
  //top: 0;
`
export const HeaderWrapper = styled(HStack)`
  justify-content: space-between;
  height: auto;
  align-items: center;
`
export const HeaderButtonWrap = styled(HStack)`
  width: 120px
`
export const UserDataWrap = styled(HStack)`
  align-items: center;
`
export const UserName = styled(Typography).attrs({
  variant: 'textM'
})`
  color: ${({ theme }) => theme.colors.skyNeutral};
  margin-left: 12px;
`
export const ContactNameWrap = styled(HStack)`
`
export const Pronouns = styled(Typography).attrs({
  variant: 'textXS',
  bold: true
})`
  margin-left: 5px;
  color: ${({ theme }) => theme.colors.skyNeutral};
`