/*eslint-disable*/
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import withPusher from 'react-pusher-hoc'
import ChatMessageContainer from '../../components/ChatMessageContainer'
import { useDispatch } from 'react-redux'
import { setOpenChatRoomId } from '../../features/friendsAndMessages/friendsSlice'

const usePusherChatWrapper = () => {
  const dispatch = useDispatch()
  const { roomId: selectedRoomId } = useParams()
  const [isMounted, setIsMounted] = useState()

  useEffect(() => {
    if (selectedRoomId) {
      setIsMounted(true)
      dispatch(setOpenChatRoomId(selectedRoomId))
    }
    return () => {
      setIsMounted(false)
      dispatch(setOpenChatRoomId(null))
    }
  }, [])

  return isMounted
    ? withPusher({
        mapPropsToValues: () => ({
          items: []
        }),
        events: {
          [`private-chat-${selectedRoomId}.App\\Events\\NewChatMessage`]: (
            item
          ) => ({
            items: [item]
          })
        }
      })(ChatMessageContainer)
    : withPusher({
        mapPropsToValues: () => ({
          items: []
        }),
        events: {}
      })(ChatMessageContainer)
}

export default usePusherChatWrapper
