/*eslint-disable*/
import React, { useState } from 'react'
import { MapWrap } from './styles'
import PropTypes from 'prop-types'
import { H, herePlatform } from '../../../config/hereWego'
import useAddMarkers from '../../../hooks/hop/useAddMarkers'

const Map = ({ setMapInstance }) => {
  const mapRef = React.useRef(null)
  const [localMapInstance, setLocalMapInstance] = useState(null)

  useAddMarkers(localMapInstance)

  React.useLayoutEffect(() => {
    // `mapRef.current` will be `undefined` when this hook first runs; edge case that
    if (!mapRef.current) return
    const defaultLayers = herePlatform.createDefaultLayers()
    const hMap = new H.Map(mapRef.current, defaultLayers.vector.normal.map, {
      center: { lat: -37.825746, lng: 144.948999 },
      zoom: 4,
      pixelRatio: window.devicePixelRatio || 1
    })
    setMapInstance(hMap)
    setLocalMapInstance(hMap)
    const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(hMap))

    // This will act as a cleanup to run once this hook runs again.
    // This includes when the component un-mounts
    return () => {
      hMap.dispose()
    }
  }, [mapRef]) // This will run this hook every time this ref is updated

  return (
    <MapWrap>
      <div
        className="map"
        ref={mapRef}
        style={{ height: '100%', width: '100%' }}
      />
    </MapWrap>
  )
}

export default Map

Map.propTypes = {
  setMapInstance: PropTypes.func.isRequired
}
