import styled from 'styled-components'
import { VStack } from '../../../../global/styles'
import { pxToVh } from '../../../../utils/styles-utils'

// eslint-disable-next-line import/prefer-default-export
export const CreateNewPasswordWrap = styled(VStack)`
  margin-top: ${pxToVh(84)};
  padding: 0 24px 24px 24px;
  height: 70vh;
  justify-content: space-between;
`
