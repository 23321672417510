import React, { useState } from 'react'
import Modal from 'react-modal'
import * as Yup from 'yup'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import { usePostReportMutation } from '../../../api/api.generated'
import { getErrorMessage } from '../../../utils/helpers'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES
} from '../../../const/UIvariants'
import Button from '../../UI/Buttons/Button'
import TextAreaInput from '../../UI/Inputs/TextAreaInput'
import FormSelectOneInput from '../../UI/Inputs/SelectOneInput/FormSelectOneInput'
import Typography from '../../UI/Typography'
import { ButtonWrapper, ModalHeadline, StyledForm, ErrorWrap } from './styles'

const ReportStoreModal = ({ isOpen, onClose, shopId }) => {
  
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [postReport] = usePostReportMutation()

  const getReasonList = [
    { value: '1', label: 'Inaccurate Information' },
    { value: '2', label: 'Store no longer exists' },
    { value: '3', label: 'Something else' }
  ]

  const handleSubmit = (values) => {
    setError('')
    setIsLoading(true)
    postReport({
      shopId,
      body: {
        reasonId: values.reason,
        description: values.description
      }
    })
      .unwrap()
      .then(() => {
        onClose()
      })
      .catch((err) => {
        setIsLoading(false)
        setError(getErrorMessage(err))
      })
  }

  return (
    <Modal {...{ isOpen }}>
      <Formik
        initialValues={{
          reason: '',
          description: ''
        }}
        validationSchema={Yup.object({
          reason: Yup.number().required('Reason is required'),
          description: Yup.string().required('Description is required').max(1000, 'Description is too long')
        })}
        onSubmit={handleSubmit}
      >
        <StyledForm>
          <ModalHeadline>Report</ModalHeadline>
          <FormSelectOneInput
            inputName="reason"
            label="Reason"
            options={getReasonList}
            size=""
            isGrey
          />
          <TextAreaInput
            rows={10}
            inputName="description"
            label="Please describe the issue below"
          />
          {error && (
          <ErrorWrap>
            <Typography variant="textS" color="red">
              {error}
            </Typography>
          </ErrorWrap>          
            )}
          <ButtonWrapper>
            <Button
              variant={BUTTON_VARIANTS.PRIMARY}
              size={BUTTONS_SIZES.MED}
              type="submit"
              isLoading={isLoading}
            >
              Submit
            </Button>
            <Button
              variant={BUTTON_VARIANTS.OUTLINED}
              size={BUTTONS_SIZES.MED}
              onClick={onClose}
              type="button"
            >
              Cancel
            </Button>
          </ButtonWrapper>
        </StyledForm>
      </Formik>
    </Modal>
  )
}

export default ReportStoreModal

ReportStoreModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  shopId: PropTypes.string.isRequired
}
