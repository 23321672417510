import styled from 'styled-components'
import { VStack } from '../../../global/styles'
import { pxToVh } from '../../../utils/styles-utils'

export const LoginWrap = styled(VStack)`
  margin-top: ${pxToVh(84)};
  padding: 0 24px;
  height: 70vh;
  justify-content: space-between;
`
export const InputsWrap = styled(VStack)`
  align-items: flex-end;
  margin-bottom: 24px;
`
export const MessageWrap = styled(VStack)``
