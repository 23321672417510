import styled from 'styled-components'
import { HStack, VStack } from '../../global/styles'
import Typography from '../UI/Typography'
import { pxToVh } from '../../utils/styles-utils'

export const HeaderBody = styled(VStack)`
  height: ${pxToVh(116)};
  box-shadow: inset 0px -1px 0px rgba(95, 110, 130, 0.15);
  justify-content: flex-end;
  align-items: stretch;
  padding: 16px 28px;
  background: ${({ theme }) => theme.colors.white};
`
export const HeadlineWrapper = styled(HStack)`
  align-items: center;
  justify-content: space-between;
`

export const HeaderTitle = styled(Typography).attrs({
  variant: 'headingM',
  bold: true
})`
  color: ${({ theme }) => theme.colors.skyNeutral_1};
  margin-right: auto;
`
export const ActionButton = styled.button`
  background: transparent;
  color: ${({ theme }) => theme.colors.skyBase};
  font-size: 16px;
  outline: none;
  border: none;
  &:disabled {
    color: ${({ theme }) => theme.colors.skyNeutral3};
  }
`
