import React from 'react'
import PropTypes from 'prop-types'
import Bubble from '../Bubble'
import { useDeleteUserNoteMutation } from '../../api/api.generated'

const NoteBubble = ({
  id,
  messageText,
  messageImage,
  shopId,
  shopName,
  isOwnMessage,
  timeStamp,
  isViewed
}) => {
  const [deleteUserNote] = useDeleteUserNoteMutation()

  const handleDelete = (messageId) => {
    deleteUserNote(messageId)
  }

  return (
    <Bubble { ... {id, messageText, messageImage, shopId, shopName, isOwnMessage, timeStamp, isViewed, handleDelete} }/>
  )
}

export default React.memo(NoteBubble)

NoteBubble.defaultProps = {
  messageText: null,
  messageImage: null,
  shopId: null
}

NoteBubble.propTypes = {
  id: PropTypes.any.isRequired,
  messageText: PropTypes.string,
  messageImage: PropTypes.string,
  isOwnMessage: PropTypes.bool.isRequired,
  isViewed: PropTypes.bool.isRequired,
  timeStamp: PropTypes.string.isRequired,
  shopId: PropTypes.string,
  shopName: PropTypes.string.isRequired
}
