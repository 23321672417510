import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import {
  useGetUserProfileDetailsQuery,
  usePatchUserProfileDetailsMutation,
  usePutUserProfileUserAvatarMutation
} from '../../api/api.generated'
import { getImageBase64 } from '../../utils/helpers'
import { logout } from '../../features/auth/authSlice'

const useUserProfile = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { data: userData, isLoading } = useGetUserProfileDetailsQuery()
  const [changeUserProfileImage, { isLoading: isUploadingNewAvatar }] =
    usePutUserProfileUserAvatarMutation()
  const [patchUserProfile, { isLoading: isPatchingProfile }] =
    usePatchUserProfileDetailsMutation()
  const handleLogout = useCallback(() => {
    dispatch(logout())
  }, [])
  const handleUpdateUserAvatar = useCallback((imageList) => {
    const selectedImage = getImageBase64({ source: imageList })
    if (selectedImage) {
      changeUserProfileImage({ body: { userAvatar: selectedImage } })
    }
  }, [])

  const handleUpdateUserData = (values, formik, callback) => {
    const patchObject = {
      username: values.username !== userData.username ? values.username : null,
      phoneNumber: values.phoneNumber !== userData.phoneNumber ? values.phoneNumber : null,
      email: values.email !== userData.email ? values.email : null,
      stateId: values.stateId !== userData.stateId ? values.stateId : null,
      age: values.age !== userData.age ? values.age : null,
      genderId: values.genderId !== userData.genderId ? values.genderId : null,
      customGender: values.genderId === 4 && values.customGender !== userData.customGender ? values.customGender : null,
      preferredPronouns: values.preferredPronouns !== userData.preferredPronouns ? values.preferredPronouns : null,
    }
    patchUserProfile({ body: { ...patchObject } })
      .unwrap()
      .then(() => {
        if (callback && values.phoneNumber !== userData.phoneNumber) {
          callback()
        } else {
          history.goBack()
        }
      })
      .catch((err) => {
        if (err?.data?.message) {
          formik.setErrors({ ...err.data.message })
        }
      })
  }

  return {
    userData,
    handleUpdateUserAvatar,
    handleUpdateUserData,
    isLoading,
    handleLogout,
    isUploadingNewAvatar,
    isPatchingProfile
  }
}

export default useUserProfile
