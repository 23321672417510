/*eslint-disable*/
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setIsSimulatingGeo, setRefreshGeoFrequency } from '../../hop/hopSlice'
import { useHistory } from 'react-router-dom'

const DevSettings = (props) => {
  const isGeoSimulationOn = useSelector((state) => state.hop.geoSimulationIsOn)
  const updateSpeed = useSelector((state) => state.hop.updateFrequencySpeed)
  const dispatch = useDispatch()
  const { goBack } = useHistory()
  return (
    <div>
      <h2>Version: 0.23</h2>
      <h2>Geolocation simulation is {isGeoSimulationOn ? 'ON' : 'OFF'}</h2>
      <p>
        When it's on it will get points of last builded route and cycle over
        them, even if route navigations is terminated{' '}
      </p>
      <br />
      <br />
      <br />
      <br />
      <button
        onClick={() => {
          dispatch(setIsSimulatingGeo(!isGeoSimulationOn))
        }}
      >
        TOGGLE GEOLOCATION SIMULATION
      </button>
      <br />
      <br />
      <br />
      <br />
      <br />
      <h2>Geolocation is updated every {updateSpeed} ms</h2>
      <input
        type="range"
        onChange={(e) => {
          dispatch(setRefreshGeoFrequency(e.target.value))
        }}
        min={100}
        max={5000}
        step={1}
        value={updateSpeed}
      />
      <button
        onClick={() => {
          goBack()
        }}
      >
        {' '}
        GO BACK
      </button>
    </div>
  )
}

export default DevSettings

DevSettings.propTypes = {}
