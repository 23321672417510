import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { SearchAndCenterWrap } from './styles'
import Map from '../Map/Map'
import { ScreenContainer } from '../../../global/styles'
import MapContext from '../MapContext'
import CenterOnCurrentLocation from '../Map/CenterOnCurrentLocation'
import HopDrawerContainer from '../HopDrawerContainer'
import InRouteDrawerContainer from '../InRouteDrawerContainer'
import HopStateAndTabPersistor from '../HopStateAndTabPersistor'
import {
  selectIsInRouteMode,
  selectPreviewRouteShopId
} from '../../../store/selectors'
// import TrackLocation from '../TrackLocation'
import CurrentLocationOnMap from '../Map/CurrentLocationOnMap'
import PreviewRouteContainer from '../PreviewRouteContainer'
import OpenSearchFakeInput from '../../search/OpenSearchFakeInput'

const HopContainer = () => {
  const [mapInstance, setMapInstance] = useState(null)
  const isInRouteMode = useSelector(selectIsInRouteMode)
  const previewRouteShopId = useSelector(selectPreviewRouteShopId)

  return (
    <ScreenContainer>
      <MapContext.Provider value={mapInstance}>
        <HopStateAndTabPersistor>
          <SearchAndCenterWrap>
            <OpenSearchFakeInput /> <CenterOnCurrentLocation />
          </SearchAndCenterWrap>
          {previewRouteShopId ? (
            <PreviewRouteContainer />
          ) : isInRouteMode ? (
            <InRouteDrawerContainer />
          ) : (
            <HopDrawerContainer />
          )}
        </HopStateAndTabPersistor>
        <CurrentLocationOnMap />
      </MapContext.Provider>

      <Map setMapInstance={setMapInstance} />
      
      {/* TODO: this is causing browser to freeze 
      <TrackLocation /> 
      */}
    </ScreenContainer>
  )
}

export default HopContainer

HopContainer.propTypes = {}
