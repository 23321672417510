import React, { useState } from 'react'
import SwipeableBottomSheet from 'react-swipeable-bottom-sheet'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { Close, KeyboardArrowDown, Star } from '@styled-icons/material'
import { useSelector } from 'react-redux'
import {
  Collapsed,
  DrawerFooter,
  DrawerWrapper,
  Expanded,
  ExpandedShopListWrap,
  Headline,
  InstructionItem,
  InstructionLabel,
  LogoListWrap,
  RouteSection,
  ShopInfo,
  SwipeableStyles
} from './styles'
import { DragNotch, DragNotchWrap } from '../HopDrawerContainer/styles'
import ShopLogo from '../../../components/ShopLogo'
import { VStack } from '../../../global/styles'
import Button from '../../../components/UI/Buttons/Button'
import { BUTTON_VARIANTS, BUTTONS_SIZES } from '../../../const/UIvariants'
import {
  LastVisited,
  Rating,
  ShopAddress,
  ShopDataWrap,
  ShopMetaData,
  ShopName,
  ShopRating
} from '../../../components/ShopPreviewItem/styles'
import DistanceToShop from '../../../components/DistanceToShop'
import EvaluatedTimeAndDistance from './EvaluatedTimeAndDistance'
import ConfirmStopRouteModal from '../../../components/Modals/ConfirmStopRouteModal'
import ClosestBadge from '../../../components/ClosestBadge'
import IconButton from '../../../components/UI/Buttons/IconButton'
import useOnRoute from '../../../hooks/route/useOnRoute'

const InRouteDrawerContainer = () => {
  const [showConfirmStopModal, setShowConfirmStopModal] = useState(false)
  const {
    closestInRoute,
    isOpen,
    shopsInRoutesData,
    handleClickOnShopLogo,
    handleRemoveShopFromRoute,
    currentTargetIndex,
    setIsOpen
  } = useOnRoute()
  const routeInstructions = useSelector((state) => state.hop.routeInstruction)
  return (
    <>
      <ConfirmStopRouteModal
        isOpen={showConfirmStopModal}
        handleClose={() => {
          setShowConfirmStopModal(false)
        }}
      />

      <SwipeableBottomSheet
        overflowHeight={198}
        marginTop={190}
        open={isOpen}
        onChange={(open) => {
          setIsOpen(open)
        }}
        topShadow={false}
        style={SwipeableStyles.style}
        bodyStyle={SwipeableStyles.bodyStyle}
        overlayStyle={SwipeableStyles.overlayStyle}
      >
        <DrawerWrapper isOpen={isOpen}>
          <DragNotchWrap>
            <DragNotch />
          </DragNotchWrap>
          {shopsInRoutesData?.length && (
            <>
              <Expanded>
                <Collapsed>
                  <Headline> Your Hop Route</Headline>
                  {!isOpen && (
                    <OverlayScrollbarsComponent
                      style={{ maxWidth: '70%', justifyContent: 'flex-end' }}
                      options={{
                        scrollbars: { visibility: 'hidden' }
                      }}
                    >
                      <LogoListWrap>
                        {shopsInRoutesData.map((shop, index) => (
                          <ShopLogo
                            isCurrentTarget={index === currentTargetIndex}
                            handleClick={() => {
                              handleClickOnShopLogo({
                                lat: shop.location.lat,
                                lng: shop.location.lng
                              })
                            }}
                            key={shop.id}
                            logoUrl={shop.logotype}
                          />
                        ))}
                      </LogoListWrap>
                    </OverlayScrollbarsComponent>
                  )}
                </Collapsed>
                <OverlayScrollbarsComponent
                  style={{
                    width: '100%',
                    height: `60vh`
                  }}
                  options={{
                    scrollbars: { visibility: 'hidden' }
                  }}
                >
                  <ExpandedShopListWrap>
                    {shopsInRoutesData.map((shop, index) => (
                      <RouteSection
                        isCurrentTarget={index === currentTargetIndex}
                      >
                        {routeInstructions?.[index] &&
                          routeInstructions[index].map((action) => (
                            <InstructionItem>
                              <InstructionLabel key={action.offset}>
                                {action.instruction.split('.')[0]}
                                <br />
                                {action.instruction
                                  .split('.')
                                  .slice(1)
                                  .join('.')}
                              </InstructionLabel>
                              <KeyboardArrowDown size={16} color="#2D6CE4" />
                            </InstructionItem>
                          ))}
                        <ShopInfo key={shop.id}>
                          <ShopLogo
                            size={40}
                            logoUrl={shop.logotyape}
                            handleClick={() => {
                              handleClickOnShopLogo({
                                lat: shop.location.lat,
                                lng: shop.location.lng
                              })
                            }}
                          />
                          <ShopDataWrap>
                            <VStack onClick={() => {}}>
                              <ShopName>{shop.storeName}</ShopName>
                              <ShopAddress>{shop.location.displayAddress}</ShopAddress>
                            </VStack>
                            <ShopMetaData>
                              <ShopRating>
                                <Star size={16} color="hsla(47, 86%, 59%, 1)" />
                                <Rating>{shop.rating}</Rating>
                              </ShopRating>
                              <DistanceToShop
                                shopId={shop.id}
                                initialDistance={shop.location.displayDistance}
                              />
                              <LastVisited>{shop.lastVisited}</LastVisited>
                            </ShopMetaData>
                            <ClosestBadge
                              isClosest={closestInRoute === shop.id}
                            />
                          </ShopDataWrap>
                          <IconButton
                            onClick={() => {
                              handleRemoveShopFromRoute(shop.id)
                            }}
                          >
                            <Close size={24} color="#737E92" />
                          </IconButton>
                        </ShopInfo>
                      </RouteSection>
                    ))}
                  </ExpandedShopListWrap>
                </OverlayScrollbarsComponent>
              </Expanded>
            </>
          )}
        </DrawerWrapper>
      </SwipeableBottomSheet>
      <DrawerFooter>
        <EvaluatedTimeAndDistance />
        <Button
          onClick={() => {
            setShowConfirmStopModal(true)
          }}
          variant={BUTTON_VARIANTS.PINK}
          size={BUTTONS_SIZES.LARGE}
        >
          Stop
        </Button>
      </DrawerFooter>
    </>
  )
}

export default InRouteDrawerContainer

InRouteDrawerContainer.propTypes = {}
