import React from 'react'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash/lang'
import { useGetOrderProductsQuery } from '../../../api/api.generated'
import OrderProductItem from '../../../components/OrderProductItem'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import Badge from '../../../components/UI/Badge'
import { BADGE_VARIANTS } from '../../../const/UIvariants'
import { ScreenContainer } from '../../../global/styles'
import OrdersHeader from '../OrdersHeader'
import { IsEmptyText, OrdersWrap, OrderContentWrapper, ShopsWrap, ShopWrap, TotalWrap } from './styles'

const OrderScreen = () => {
  const { orderId } = useParams()
  const {
    data: { shops } = {},
    isLoading,
    error
  } = useGetOrderProductsQuery({ orderId })

  return (
    <>
      <ScreenContainer>
        <OrdersHeader title="Order Details" showBack />
        <OrderContentWrapper>
          <OrdersWrap>
            {isLoading && <LoadingSpinner />}
            {error && <IsEmptyText variant="textM">Network Error!</IsEmptyText>}
            {!isLoading && !error && !isEmpty(shops) && (
              shops.map((shop) => (
                <ShopsWrap key={shop.id}>
                  <ShopWrap>{shop.name}</ShopWrap>
                  <TotalWrap>Total: ${shop.total}</TotalWrap>
                  { shop.isDelivery && <Badge label="Delivery" variant={BADGE_VARIANTS.PINK} /> }
                  { !shop.isDelivery && <Badge label="Click and Collect" variant={BADGE_VARIANTS.PINK} /> }
                  { shop.products.map((product)  => (
                    <OrderProductItem
                      key={product.id}
                      productImage={product.productImage}
                      productName={product.productName}
                      productPrice={product.productPrice}
                      shippingCost={product.shippingCost}
                    />
                  ))}
                </ShopsWrap>
              ))
            )}
          </OrdersWrap>
        </OrderContentWrapper>
      </ScreenContainer>
    </>
  )
}

export default OrderScreen

OrderScreen.propTypes = {}
