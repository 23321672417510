import styled from 'styled-components'
import Typography from '../UI/Typography'
import { HStack, VStack } from '../../global/styles'
import { getImageFromServer } from '../../utils/helpers'

export const BubbleBody = styled(VStack)`
  background: ${({ theme, isOwnMessage }) =>
    isOwnMessage ? theme.colors.skyBase4 : theme.colors.skyNeutral4};
  max-width: 80%;
  width: ${({ messageImage }) => (messageImage ? ' 80%' : 'auto')};
  align-self: ${({ isOwnMessage }) =>
    isOwnMessage ? 'flex-end' : 'flex-start'};
  margin-bottom: 20px;
  padding: 16px;
  border-radius: ${({ isOwnMessage }) =>
    isOwnMessage ? '8px 0 8px 8px' : '0 8px 8px 8px'};
`
export const Image = styled.div`
  background: url(${({ image }) => getImageFromServer(image)}) center;
  background-size: cover;
  border-radius: 8px;
  width: 100%;
  height: 250px;
`
export const BubbleText = styled(Typography).attrs({
  variant: 'textS'
})`
  word-wrap: anywhere;
  color: ${({ theme, isOwnMessage }) =>
    isOwnMessage ? theme.colors.skyBase : theme.colors.skyNeutral};
`
export const BubbleShop = styled(Typography).attrs({
  variant: 'textS',
  bold: true
})`
  word-wrap: anywhere;
  color: ${({ theme, isOwnMessage }) =>
    isOwnMessage ? theme.colors.skyBase : theme.colors.skyNeutral};
`
export const TimeAndStatusWrap = styled(HStack)`
  align-items: center;
  margin-top: 8px;
  align-self: flex-end;
  width: auto;
`
export const TimeStamp = styled(Typography).attrs({
  variant: 'textXS'
})`
  color: ${({ theme }) => theme.colors.skyNeutral};
  margin-right: 8px;
`
