import styled from 'styled-components'
import { HStack } from '../../global/styles'
import Typography from '../UI/Typography'

export const ShopDistance = styled(HStack)`
  align-items: center;
  width: auto;
`

export const DistanceWrap = styled(Typography).attrs(({ theme }) => ({
  variant: 'textXS',
  color: theme.colors.skyNeutral1
}))``
