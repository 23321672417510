import React from 'react'
import PropTypes from 'prop-types'
import { Directions, Star } from '@styled-icons/material'
import { useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import { isNull } from 'lodash/lang'
import {
  ButtonsWrapper,
  IconButtonWrap,
  LastVisited,
  Rating,
  ShopAddress,
  ShopDataWrap,
  ShopInfo,
  ShopItemWrap,
  ShopMetaData,
  ShopName,
  ShopRating
} from './styles'
import ShopLogo from '../ShopLogo/ShopLogo'
import { HStack, VStack } from '../../global/styles'
import IconButton from '../UI/Buttons/IconButton'
import DistanceToShop from '../DistanceToShop'
import useCreateMapRoute from '../../hooks/route/useCreateMapRoute'
import { setPreviewRouteShopId } from '../../features/hop/hopSlice'
import ClosestBadge from '../ClosestBadge'
import AddToRouteButton from '../../features/shopActionsButtons/AddToRouteButton'
import GoNowButton from '../../features/shopActionsButtons/GoNowButton'
import AddToFavButton from '../../features/shopActionsButtons/AddToFavButton'
import { truncateSentence } from '../../utils/helpers'

const ShopPreviewItem = ({
  id,
  shopName,
  address,
  distance,
  rating,
  lastVisited,
  handleOpenShop,
  logotype,
  lat,
  lng,
  isClosest,
  sequence,
  showPreviewIcon
}) => {
  const dispatch = useDispatch()
  const { buildRoute } = useCreateMapRoute()
  const handlePreviewRoute = () => {
    buildRoute([
      {
        shopId: id,
        shopLat: lat,
        shopLng: lng
      }
    ])
    dispatch(setPreviewRouteShopId(id))
  }
  return (
    <ShopItemWrap>
      <ShopInfo>
        {logotype &&
        <ShopLogo logoUrl={logotype} />
        }
        <ShopDataWrap>
          <VStack onClick={handleOpenShop}>
            <ShopName>{truncateSentence(shopName, 25)}</ShopName>
            <ShopAddress>{truncateSentence(address, 50)}</ShopAddress>
          </VStack>
          <ShopMetaData>
            <ShopRating>
              <Star size={16} color="hsla(47, 86%, 59%, 1)" />
              <Rating>{rating}</Rating>
            </ShopRating>
            <DistanceToShop shopId={id} initialDistance={distance} />
            { !isNull(lastVisited) &&
            <LastVisited>{ dayjs(lastVisited).format('DD-MM-YY') }</LastVisited>
            }
            <ClosestBadge shopId={id} isClosest={isClosest} />
          </ShopMetaData>
        </ShopDataWrap>
        {showPreviewIcon && (
          <IconButtonWrap>
            <IconButton onClick={handlePreviewRoute}>
              <Directions size={20} color="#D9DCE2" />
            </IconButton>
          </IconButtonWrap>
        )}
      </ShopInfo>
      <ButtonsWrapper>
        <HStack>
          <AddToRouteButton {...{ sequence, id, lat, lng }} />
          <GoNowButton {...{ sequence, id, lat, lng }} />
        </HStack>
        <AddToFavButton {...{ id }} />
      </ButtonsWrapper>
    </ShopItemWrap>
  )
}

export default React.memo(ShopPreviewItem)
ShopPreviewItem.defaultProps = {
  lastVisited: '',
  isClosest: false,
  showPreviewIcon: true,
  logotype: ''
}
ShopPreviewItem.propTypes = {
  id: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  distance: PropTypes.number.isRequired,
  isClosest: PropTypes.bool,
  lastVisited: PropTypes.string,
  logotype: PropTypes.string,
  rating: PropTypes.number.isRequired,
  sequence: PropTypes.number.isRequired,
  lat: PropTypes.string.isRequired,
  lng: PropTypes.string.isRequired,
  shopName: PropTypes.string.isRequired,
  handleOpenShop: PropTypes.func.isRequired,
  showPreviewIcon: PropTypes.bool
}
