/*eslint-disable*/
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '../UI/Buttons/Button/Button'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES as BUTTON_SIZES,
  BADGE_VARIANTS
} from '../../const/UIvariants'
import {
  ProductDetailsWrap,
  ProductImage,
  ProductItemWrap,
  ProductName,
  ProductPrice,
  ShippingCost,
  TagsWrap,
  BuyProductButtonWrap
} from './styles'
import { HStack } from '../../global/styles'
import {
  usePostShoppingCartAddMutation,
  usePostShoppingCartRemoveMutation
} from '../../api/api.generated'
import { getImageFromServer } from '../../utils/helpers'
import Badge from '../UI/Badge'
import { BADGE_SIZES } from '../../const/UIvariants'

const ProductItem = ({
  productId,
  productImage,
  productPrice,
  shippingCost,
  tags,
  productName,
  showBuyButton,
  isAdded,
  hideOnRemove,
  isShippingEnabled,
  isCollectEnabled
}) => {
  const [added, setAdded] = useState(isAdded)
  const [postShopProductAdd] = usePostShoppingCartAddMutation()
  const [postShopProductRemove] = usePostShoppingCartRemoveMutation()
  const handleClick = () => {
    if (isAdded) {
      postShopProductRemove({ productId })
    } else {
      postShopProductAdd({ productId })
    }
    setAdded(!added)
  }

  if (!hideOnRemove || added) {
    return (
      <>
        <ProductItemWrap>
          <ProductImage background={getImageFromServer(productImage)} />
          <ProductDetailsWrap>
            <ProductName>{productName}</ProductName>
            <HStack>
              <ProductPrice>Price: ${productPrice}</ProductPrice>
              <ShippingCost>Shipping: ${shippingCost}</ShippingCost>
            </HStack>
            <TagsWrap>
              {isShippingEnabled &&
              <Badge key="isShippingEnabled" size={BADGE_SIZES.SMALL} variant={BADGE_VARIANTS.GREEN} label="Shipping" />
              }
              {isCollectEnabled &&
              <Badge key="isCollectEnabled" size={BADGE_SIZES.SMALL} variant={BADGE_VARIANTS.GREEN} label="Click & Collect" />
              }
              {tags.map((tag) => (
                <Badge key={tag} size={BADGE_SIZES.SMALL} label={tag} />
              ))}
            </TagsWrap>
          </ProductDetailsWrap>
        </ProductItemWrap>
        {showBuyButton && (
          <BuyProductButtonWrap>
            <Button
              variant={added ? BUTTON_VARIANTS.PINK : BUTTON_VARIANTS.GREY}
              size={BUTTON_SIZES.MED}
              onClick={handleClick}
            >
              {added ? 'Remove' : 'Add'}
            </Button>
          </BuyProductButtonWrap>
        )}
      </>
    )
  } else {
    return null
  }
}

export default ProductItem

ProductItem.defaultProps = {
  tags: [],
  productImage: null,
  hideOnRemove: false,
  isShippingEnabled: false,
  isCollectEnabled: false
}

ProductItem.propTypes = {
  productId: PropTypes.string.isRequired,
  productImage: PropTypes.string,
  productPrice: PropTypes.number.isRequired,
  shippingCost: PropTypes.number.isRequired,
  tags: PropTypes.array,
  productName: PropTypes.string.isRequired,
  showBuyButton: PropTypes.bool.isRequired,
  isAdded: PropTypes.bool.isRequired,
  hideOnRemove: PropTypes.bool,
  isShippingEnabled: PropTypes.bool,
  isCollectEnabled: PropTypes.bool

}
