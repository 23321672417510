import styled from 'styled-components'
import { VStack } from '../../../global/styles'
import { pxToVh } from '../../../utils/styles-utils'

// eslint-disable-next-line import/prefer-default-export
export const LandingWrap = styled(VStack)`
  margin-top: ${pxToVh(84)};
  padding: 24px;
  height: 65vh;
  justify-content: space-between;
`
