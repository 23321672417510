import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from 'styled-components'
import { useHistory } from 'react-router-dom'
import OphopLogo from '../../../assets/vector/OphopLogo'
import Typography from '../../../components/UI/Typography'
import Button from '../../../components/UI/Buttons/Button'
import { BUTTON_VARIANTS, BUTTONS_SIZES } from '../../../const/UIvariants'
import {
  AuthButtonsWrap,
  AuthHeadline,
  ScreenContainer
} from '../../../global/styles'
import ROUTES from '../../../const/ROUTES'
import { LandingWrap } from './styles'

const Landing = ({ theme }) => {
  const history = useHistory()
  return (
    <ScreenContainer>
      <LandingWrap>
        <OphopLogo height={29} width={112} />
        <AuthHeadline>Welcome to Our Community</AuthHeadline>
        <AuthButtonsWrap>
          <Button
            variant={BUTTON_VARIANTS.PRIMARY}
            size={BUTTONS_SIZES.MED}
            onClick={() => {
              history.push(ROUTES.LOGIN)
            }}
          >
            Login
          </Button>
          {false &&
          <Button
            variant={BUTTON_VARIANTS.SECONDARY}
            size={BUTTONS_SIZES.MED}
            onClick={() => {
              history.push(ROUTES.SIGNUP)
            }}
          >
            Create Account
          </Button>
          }
        </AuthButtonsWrap>

        <Typography
          variant="headingS"
          align="center"
          color={theme.colors.skyNeutral2}
        >
          By creating a new account, you agree to our Terms of Services &
          Privacy Policy
        </Typography>
      </LandingWrap>
    </ScreenContainer>
  )
}

export default withTheme(Landing)

Landing.propTypes = {
  theme: PropTypes.object.isRequired
}
