import React from 'react'
import { useSelector } from 'react-redux'
import { Search } from '@styled-icons/material'
import PropTypes from 'prop-types'
import { PlaceholderText, SearchInputWrapper } from './styles'
import useToggleSearch from '../../../hooks/search/useToggleSearch'
import { selectCurrentCityName } from '../../../store/selectors'

const OpenSearchFakeInput = ({ variant }) => {
  const { openSearch } = useToggleSearch()
  const currentCity = useSelector(selectCurrentCityName)
  return (
    <SearchInputWrapper {...{ variant }} onClick={openSearch}>
      <Search size={24} color="#737E92" />
      <PlaceholderText> Search in {currentCity}</PlaceholderText>
    </SearchInputWrapper>
  )
}

export default OpenSearchFakeInput
OpenSearchFakeInput.defaultProps = {
  variant: null
}
OpenSearchFakeInput.propTypes = {
  variant: PropTypes.oneOf(['dark'])
}
