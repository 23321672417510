import styled from 'styled-components'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { pxToVh } from '../../../utils/styles-utils'

// eslint-disable-next-line import/prefer-default-export
export const FriendsContentWrapper = styled(OverlayScrollbarsComponent).attrs({
  options: {
    scrollbars: { visibility: 'hidden' }
  }
})`
  height: 100%;
  margin-top: ${pxToVh(164)};
  width: 100%;
  padding: 18px 0 20px 0;
`
