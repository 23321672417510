import React, { useState } from 'react'
import * as Yup from 'yup'
import { RemoveRedEye, VisibilityOff } from '@styled-icons/material'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { CreateNewPasswordWrap } from './styles'
import {
  AuthDescription,
  AuthHeadline,
  ScreenContainer
} from '../../../../global/styles'

import AuthHeader from '../../../../components/AuthHeader'
import TextInput from '../../../../components/UI/Inputs/TextInput'
import { BUTTONS_SIZES, INPUT_SIZES } from '../../../../const/UIvariants'
import Button from '../../../../components/UI/Buttons/Button'
import { MessageWrap } from '../../SignUp/styles'
import { usePostUserCreateNewPasswordMutation } from '../../../../api/api.generated'
import { setRestorePhoneNumber } from '../../authSlice'
import SuccessScreenOverlay from '../../../common/SuccessScreenOverlay'
import ROUTES from '../../../../const/ROUTES'

const CreateNewPassword = () => {
  const [passwordInputType, setPasswordInputType] = useState('password')
  const [showSuccess, setShowSuccess] = useState(false)

  const dispatch = useDispatch()

  const restoreRequestPhoneNumber = useSelector(
    (state) => state.auth.restoreRequestPhoneNumber
  )
  const [createNewPassword] = usePostUserCreateNewPasswordMutation()

  const handleShowPassword = () => {
    if (passwordInputType === 'password') {
      setPasswordInputType('text')
      return
    }
    setPasswordInputType('password')
  }

  const submitNewPassword = (values, { setFieldError }) => {
    createNewPassword({
      body: {
        newPassword: values.password,
        phoneNumber: restoreRequestPhoneNumber
      }
    })
      .then(() => {
        setShowSuccess(true)
        dispatch(setRestorePhoneNumber(null))
      })
      .catch(() => {
        setFieldError('password', 'Password is not correct')
      })
  }

  return (
    <ScreenContainer>
      {showSuccess && (
        <SuccessScreenOverlay
          destination={ROUTES.LOGIN}
          title="Your Password has been changed, Start hopping"
          description="Yay! your password has been successfuly changed."
        />
      )}
      <CreateNewPasswordWrap>
        <AuthHeader />
        <MessageWrap>
          <AuthHeadline>Create New Password</AuthHeadline>
          <AuthDescription>
            Create your new password, making it unique for security
          </AuthDescription>
        </MessageWrap>
        <Formik
          initialValues={{
            password: '',
            confirmPassword: ''
          }}
          validationSchema={Yup.object({
            password: Yup.string()
              .min(6, 'Password must contain at least 8 characters')
              .required('Required'),
            confirmPassword: Yup.string()
              .required('Please confirm your password')
              .when('password', {
                is: (password) => password && password.length > 0,
                then: Yup.string().oneOf(
                  [Yup.ref('password')],
                  "Password doesn't match"
                )
              })
          })}
          onSubmit={submitNewPassword}
        >
          <Form>
            <TextInput
              type={passwordInputType}
              placeholder="Enter your password"
              rightIcon={
                passwordInputType === 'password' ? (
                  <RemoveRedEye />
                ) : (
                  <VisibilityOff />
                )
              }
              rightIconClick={handleShowPassword}
              size={INPUT_SIZES.LARGE}
              inputName="password"
              label="Password"
            />
            <TextInput
              type="password"
              placeholder="Confirm your password"
              size={INPUT_SIZES.LARGE}
              inputName="confirmPassword"
              label="Confirm Password"
            />
            <Button isLoading={false} size={BUTTONS_SIZES.MED} type="submit">
              Change Password
            </Button>
          </Form>
        </Formik>
      </CreateNewPasswordWrap>
    </ScreenContainer>
  )
}

export default CreateNewPassword

CreateNewPassword.propTypes = {}
