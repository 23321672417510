import React, { useEffect, useState, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash/lang'
import { mergeArrays } from '../../../utils/helpers'
import LoadingSpinner from '../../../components/LoadingSpinner'
import HolidayItem from '../../../components/HolidayItem'
import { useLazyGetUserShopByShopIdHolidaysQuery } from '../../../api/api.generated'
import { IsEmptyText } from '../../../global/styles'
import { QuestionsWrap } from './styles'

const ShopHolidays = ({ shopId }) => {
  const [hasMore, setHasMore] = useState(true)
  const [page, setPage] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [holidays, setHolidays] = useState([])
  const observer = useRef();
  const [getUserShopByShopIdHolidays, { data: { shopHolidays } = {}, isLoading: loadingHolidays, error }] = useLazyGetUserShopByShopIdHolidaysQuery()
  const ITEMS_PER_PAGE = 20

  const lastElementRef = useCallback(  
    (node) => {
      if (isLoading) return;
      if (!hasMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setPage((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore]
  );

  const handleShowMore = () => {
    setIsLoading(true)
    getUserShopByShopIdHolidays({ shopId, page, limit: ITEMS_PER_PAGE })
  }

  useEffect(() => {
    handleShowMore()
  }, [])

  useEffect(() => {
    handleShowMore()
  }, [page])

  useEffect(() => {
    if (shopHolidays) {
      setIsLoading(false)
      setHasMore(shopHolidays.length === ITEMS_PER_PAGE)
      setHolidays(mergeArrays(holidays, shopHolidays))
    }
  }, [shopHolidays])

  return (
    <>
      {loadingHolidays && <LoadingSpinner />}
      {error && <IsEmptyText variant="textM">Network Error!</IsEmptyText>}
      {isEmpty(holidays) && (
        <IsEmptyText>This store doesn&apos;t have any holidays.</IsEmptyText>
      )}
      {holidays && (
        <QuestionsWrap>
          {holidays.map((item) => (
            <HolidayItem
              key={item.id}
              name={item.name}
              dateFrom={item.dateFrom}
              dateTo={item.dateTo}
            />
          ))}
        </QuestionsWrap>
      )}
      {isLoading &&
      <div>Loading...</div>
      }
      {!isLoading && hasMore &&
      <div ref={lastElementRef}>&nbsp;</div>
      }      
    </>
  )
}

export default ShopHolidays

ShopHolidays.propTypes = {
  shopId: PropTypes.string.isRequired
}
