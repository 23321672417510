import React from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { isNull } from 'lodash/lang'
import { Star } from '@styled-icons/material'
import { selectShopById } from '../../store/selectors'
import {
  ButtonsWrapper,
  LastVisited,
  Rating,
  ShopAddress,
  ShopDataWrap,
  ShopInfo,
  ShopItemWrap,
  ShopMetaData,
  ShopName,
  ShopRating
} from './styles'
import ShopLogo from '../ShopLogo/ShopLogo'
import { HStack, VStack } from '../../global/styles'
import DistanceToShop from '../DistanceToShop'
import AddToRouteButton from '../../features/shopActionsButtons/AddToRouteButton'
import GoNowButton from '../../features/shopActionsButtons/GoNowButton'
import AddToFavButton from '../../features/shopActionsButtons/AddToFavButton'
import useShopDetails from '../../hooks/shop/useShopDetails'

const StoreOfTheWeek = ({ shopId }) => {
  const shopData = useSelector(selectShopById(shopId), shallowEqual)
  const { handleOpenShop } = useShopDetails()
  return (
    <>
      {shopData ? (
        <ShopItemWrap>
          <ShopInfo>
            <ShopLogo logoUrl={shopData.logotype} />
            <ShopDataWrap>
              <VStack
                onClick={() => {
                  handleOpenShop(shopId)
                }}
              >
                <ShopName>{shopData.storeName}</ShopName>
                <ShopAddress>{shopData.location.displayAddress}</ShopAddress>
              </VStack>
              <ShopMetaData>
                <ShopRating>
                  <Star size={16} color="hsla(47, 86%, 59%, 1)" />
                  <Rating>{shopData.rating}</Rating>
                </ShopRating>
                <DistanceToShop shopId={shopId} initialDistance={shopData.location.displayDistance} />
                { !isNull(shopData.lastVisited) &&
                <LastVisited>{ dayjs(shopData.lastVisited).format('DD-MM-YY') }</LastVisited>
                }
              </ShopMetaData>
            </ShopDataWrap>
          </ShopInfo>
          <ButtonsWrapper>
            <HStack>
              <AddToRouteButton
                sequence={shopData.sequence}
                id={shopId}
                lat={shopData.location.lat}
                lng={shopData.location.lng}
              />
              <GoNowButton
                sequence={shopData.sequence}
                id={shopId}
                lat={shopData.location.lat}
                lng={shopData.location.lng}
              />
            </HStack>
            <AddToFavButton id={shopId} />
          </ButtonsWrapper>
        </ShopItemWrap>
      ) : (
        <p>store not found</p>
      )}
    </>
  )
}

export default StoreOfTheWeek
StoreOfTheWeek.defaultProps = {
  shopId: ''
}

StoreOfTheWeek.propTypes = {
  shopId: PropTypes.string
}
