import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-responsive-carousel'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  ButtonsWrapper,
  CarouselIndicator,
  CarouselItem,
  IndicatorsWrapper,
  MessageWrap,
  StyledScreenContainer,
  WelcomeDescription,
  WelcomeTitle
} from './styles'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import FirstWelcomeScreenCollage from '../../../assets/vector/FirstWelcomeScreenCollage'
import Button from '../../../components/UI/Buttons/Button'
import { BUTTON_VARIANTS, BUTTONS_SIZES } from '../../../const/UIvariants'
import ROUTES from '../../../const/ROUTES'
import SecondWelcomeScreenCollage from '../../../assets/vector/SecondWelcomeScreenCollage'
import ThirdWelcomeScreenCollage from '../../../assets/vector/ThirdWelcomeScreenCollage'
import { selectToken } from '../../../store/selectors'

const WelcomeScreens = () => {
  const [currentScreen, setCurrentScreen] = useState(0)
  const token = useSelector(selectToken)
  const history = useHistory()
  const goToLanding = () => {
    history.push(ROUTES.LANDING)
  }
  useEffect(() => {
    if (token) {
      history.replace(ROUTES.HOME)
    }
  }, [token])

  return (
    <StyledScreenContainer>
      <Carousel
        selectedItem={currentScreen}
        autoPlay={false}
        showArrows={false}
        showStatus={false}
        onChange={(index) => {
          setCurrentScreen(index)
        }}
        showIndicators={false}
        // dynamicHeight
      >
        <CarouselItem>
          <FirstWelcomeScreenCollage />
          <MessageWrap>
            <WelcomeTitle>Welcome to OpHop</WelcomeTitle>
            <WelcomeDescription>
              Australia&apos;s first full featured op shopping app.
            </WelcomeDescription>
          </MessageWrap>
        </CarouselItem>
        <CarouselItem>
          <SecondWelcomeScreenCollage />
          <MessageWrap>
            <WelcomeTitle>
              A circular economy, making secondhand first in Australia!
            </WelcomeTitle>
            <WelcomeDescription>
              Op Stores have the most amazing range of clothing, furniture and
              household items which, although pre-loved, are high quality, and
              have stood the test of time.
            </WelcomeDescription>
          </MessageWrap>
        </CarouselItem>
        <CarouselItem>
          <ThirdWelcomeScreenCollage />
          <MessageWrap>
            <WelcomeTitle>OpHop and Away! </WelcomeTitle>
            <WelcomeDescription>
              Connect with friends, stores, and use our optimised Routing system
            </WelcomeDescription>
          </MessageWrap>
        </CarouselItem>
      </Carousel>
      <IndicatorsWrapper>
        {Array.from(Array(3).keys()).map((dot, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <CarouselIndicator key={index} isActive={index === currentScreen} />
        ))}
      </IndicatorsWrapper>
      {currentScreen !== 2 ? (
        <ButtonsWrapper>
          <Button
            size={BUTTONS_SIZES.LARGE}
            variant={BUTTON_VARIANTS.PRIMARY}
            onClick={() => {
              setCurrentScreen((p) => p + 1)
            }}
          >
            Next
          </Button>
          <Button
            size={BUTTONS_SIZES.LARGE}
            variant={BUTTON_VARIANTS.SECONDARY}
            onClick={goToLanding}
          >
            Skip
          </Button>
        </ButtonsWrapper>
      ) : (
        <ButtonsWrapper>
          <Button
            size={BUTTONS_SIZES.LARGE}
            variant={BUTTON_VARIANTS.PRIMARY}
            onClick={goToLanding}
          >
            Let’s Get Hopping
          </Button>
        </ButtonsWrapper>
      )}
    </StyledScreenContainer>
  )
}

export default WelcomeScreens

WelcomeScreens.propTypes = {}
