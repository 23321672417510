import React, { useRef, useState } from 'react'
import { Create, DeleteForever, MoreVert } from '@styled-icons/material'
import { withTheme } from 'styled-components'
import PropTypes from 'prop-types'
import { MenuItem, MenuWrap, Wrapper } from './styles'
import useOutsideAlerter from '../../hooks/useClickOutside'
import Typography from '../UI/Typography'

const EditDeleteMenu = ({ theme, onEdit, onDelete, isRight }) => {
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef()

  const handleShowModal = () => {
    setIsOpen((p) => !p)
  }
  const handleMenuItemClick = (callback, hideMenu = handleShowModal) => {
    if (callback) {
      callback()
    }
    hideMenu()
  }
  useOutsideAlerter(ref, handleShowModal)
  return (
    <Wrapper>
      <MoreVert
        size={16}
        color={theme.colors.skyNeutral2}
        onClick={handleShowModal}
      />
      {isOpen && (
        <MenuWrap ref={ref} isRight={isRight}>
          {onEdit && (
            <MenuItem
              onClick={() => {
                handleMenuItemClick(onEdit)
              }}
            >
              <Create size={20} color={theme.colors.skyNeutral2} />
              <Typography variant="textXS" color={theme.colors.skyNeutral}>
                Edit
              </Typography>
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              handleMenuItemClick(onDelete)
            }}
          >
            <DeleteForever size={20} color={theme.colors.pinkBase} />
            <Typography variant="textXS" color={theme.colors.skyNeutral}>
              Delete
            </Typography>
          </MenuItem>
        </MenuWrap>
      )}
    </Wrapper>
  )
}

export default withTheme(EditDeleteMenu)

EditDeleteMenu.defaultProps = {
  onEdit: null
}
EditDeleteMenu.propTypes = {
  theme: PropTypes.object.isRequired,
  isRight: PropTypes.bool.isRequired,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func.isRequired
}
