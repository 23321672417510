import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import dayjs from 'dayjs'
import { usePatchUserCheckProfileChangePhoneMutation } from '../../api/api.generated'

const useChangePhoneNumber = () => {
  const { location, goBack } = useHistory()
  const [lastSendAt, setLastSendAt] = useState(null)
  const [checkCodeForNewPhone] = usePatchUserCheckProfileChangePhoneMutation()
  const handleCheckCode = (values, formik) => {
    setLastSendAt(dayjs())
    if (values.verificationCode && location.state?.newPhoneNumber) {
      checkCodeForNewPhone({
        verificationCode: values.verificationCode,
        phoneNumber: location.state.newPhoneNumber
      })
        .unwrap()
        .then(() => {
          goBack()
        })
        .catch((err) => {
          formik?.setErrors(err?.data?.message)
        })
    }
  }
  return { handleCheckCode, lastSendAt, setLastSendAt }
}
export default useChangePhoneNumber
