/*eslint-disable*/
import { useContext, useEffect } from 'react'
import useCreateMapRoute from './useCreateMapRoute'
import { shallowEqual, useSelector } from 'react-redux'
import { selectIsInRouteMode, selectIsSearchOpen } from '../../store/selectors'
import MapContext from '../../features/hop/MapContext'
import useToggleSearch from '../search/useToggleSearch'

const useStartGoNow = () => {
  const mapInstance = useContext(MapContext)
  const isSearchOpen = useSelector(selectIsSearchOpen)
  const { closeSearch } = useToggleSearch()
  const { buildAndStartRoute } = useCreateMapRoute()
  const goNowShop = useSelector((state) => state.hop.goNowShop, shallowEqual)
  const isInRouteMode = useSelector(selectIsInRouteMode)

  useEffect(() => {
    if (mapInstance && goNowShop && !isInRouteMode) {
      buildAndStartRoute([goNowShop])
    }
    if (isSearchOpen) {
      closeSearch()
    }
  }, [mapInstance, goNowShop])
}

export default useStartGoNow
