import styled from 'styled-components'
import { VStack } from '../../../../global/styles'
import { pxToVh } from '../../../../utils/styles-utils'
import Typography from '../../../../components/UI/Typography'

export const RestorePassWrap = styled(VStack)`
  margin-top: ${pxToVh(84)};
  padding: 0 24px;
  height: 60vh;
  width: 100%;
  justify-content: space-between;
`
export const MessageWrap = styled(VStack)`
  margin: ${pxToVh(43)} auto ${pxToVh(24)} auto;
`
export const SuccessMessageWrap = styled(VStack)`
  justify-content: space-between;
  height: 60vh;
  align-items: center;
  margin-top: ${pxToVh(98)};
`

export const SuccessMessageHeading = styled(Typography).attrs({
  variant: 'headingL'
})`
  text-align: center;
  color: ${({ theme }) => theme.colors.skyNeutral_2};
  margin: ${pxToVh(68)} 0 ${pxToVh(24)} 0;
`

export const PhoneNumber = styled(Typography).attrs({
  variant: 'headingM'
})`
  color: ${({ theme }) => theme.colors.skyBase_2};
  margin-bottom: ${pxToVh(24)};
`
export const ImageWrap = styled(VStack)`
  width: 100%;
  height: ${pxToVh(220)};
`
