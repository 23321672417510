import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Star } from '@styled-icons/material'
import {
  ClosestBadgeWrap,
  ShopItemWrap,
  Rating,
  ShopAddress,
  ShopMetaData,
  ShopName,
  ShopRating,
  NameAddressWrap,
  ButtonsWrapper
} from './styles'
import useShopDetails from '../../hooks/shop/useShopDetails'
import { selectShopById } from '../../store/selectors'
import ShopLogo from '../ShopLogo'
import ClosestBadge from '../ClosestBadge'
import { VStack } from '../../global/styles'

import DistanceToShop from '../DistanceToShop'
import AddToFavButton from '../../features/shopActionsButtons/AddToFavButton'
import AddToRouteButton from '../../features/shopActionsButtons/AddToRouteButton'
import GoNowButton from '../../features/shopActionsButtons/GoNowButton'
import { BUTTONS_SIZES } from '../../const/UIvariants'
import { truncateLongShopName } from '../../utils/helpers'

const NearbyShopItem = ({ shopId, isClosest }) => {
  const { handleOpenShop } = useShopDetails()
  const shopData = useSelector(selectShopById(shopId))
  return (
    shopData && (
      <ShopItemWrap>
        <ShopLogo size={60} logoUrl={shopData?.logotype} />
        {isClosest && (
          <ClosestBadgeWrap>
            <ClosestBadge {...{ isClosest }} />
          </ClosestBadgeWrap>
        )}
        <NameAddressWrap
          onClick={() => {
            handleOpenShop(shopId)
          }}
        >
          <ShopName>{truncateLongShopName(shopData?.storeName, 20)}</ShopName>
          <ShopAddress>{shopData?.location?.suburbName}</ShopAddress>
        </NameAddressWrap>
        <ShopMetaData>
          <ShopRating>
            <Star size={16} color="hsla(47, 86%, 59%, 1)" />
            <Rating>{shopData?.rating}</Rating>
          </ShopRating>
          <DistanceToShop
            shopId={shopId}
            initialDistance={shopData?.location?.displayDistance}
          />
        </ShopMetaData>
        <VStack>
          <AddToFavButton id={shopId} size={BUTTONS_SIZES.SMALL} />
          <ButtonsWrapper>
            <AddToRouteButton
              id={shopId}
              sequence={shopData?.sequence}
              lng={shopData?.location?.lng}
              lat={shopData?.location?.lat}
              size={BUTTONS_SIZES.SMALL}
              labelSize="textTiny"
            />
            <GoNowButton
              id={shopId}
              sequence={shopData?.sequence}
              lng={shopData?.location?.lng}
              lat={shopData?.location?.lat}
              size={BUTTONS_SIZES.SMALL}
            />
          </ButtonsWrapper>
        </VStack>
      </ShopItemWrap>
    )
  )
}

export default NearbyShopItem

NearbyShopItem.defaultProps = {
  isClosest: false
}
NearbyShopItem.propTypes = {
  shopId: PropTypes.string.isRequired,
  isClosest: PropTypes.bool
}
