import styled from 'styled-components/macro'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { VStack, HStack } from '../../../global/styles'
import Typography from '../../../components/UI/Typography'
import { slideIn } from '../../../global/animations'

export const ShopPreviewWrap = styled(VStack)`
  margin-top: 20px;
  min-height: 100vh;
  border-radius: 24px 24px 0 0;
  background: white;
  z-index: 1000;
  animation: ${slideIn} 0.3s ease-in-out forwards;
  width: 100%;
  align-items: stretch;
`
export const ShopHeader = styled(VStack)`
  width: 100%;
  padding: 0 16px;
`
export const ShopInfo = styled(HStack)`
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
`
export const ShopMetaData = styled(HStack)`
  align-items: center;
`
export const ShopName = styled(Typography).attrs(({ theme }) => ({
  color: theme.colors.skyNeutral_2,
  variant: 'headingL'
}))`
  line-height: 28px;
`
export const CloseButtonWrap = styled(HStack)`
  justify-content: flex-end;
  margin: 21px 16px 0 0;
  padding-right: 16px;
`
export const ShopAddress = styled(Typography).attrs(({ theme }) => ({
  color: theme.colors.skyNeutral2,
  variant: 'textS'
}))`
  margin: 2px 0 4px 0;
`

export const Rating = styled(Typography).attrs(({ theme }) => ({
  color: theme.colors.skyNeutral_1,
  variant: 'textXS'
}))`
  margin-left: 2px;
`

export const ShopRating = styled(HStack)`
  width: auto;
  margin-right: 8px;
  align-items: center;
`
export const ButtonsWrapper = styled(VStack)`
  width: 100%;
  > * {
    margin-bottom: 12px;
    justify-content: space-between;
    > *:first-child {
      margin-right: 12px;
    }
    > *:not(:last-child) {
      margin-right: 12px;
    }
  }
`

export const ShopDataWrap = styled(VStack)`
  margin-left: 20px;
`
export const TabsWrap = styled(HStack).attrs({ as: 'ul' })`
  height: 52px; ;
`

export const ScrollContainer = styled(OverlayScrollbarsComponent).attrs({
  options: {
    scrollbars: { visibility: 'hidden' }
  }
})`
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  z-index: 200;
`
export const DetailsWrap = styled.div``
export const HeaderWrap = styled.div`
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.07);
  margin-bottom: 24px;
`
export const StyledButton = styled.button`
  display: block;
  position: fixed !important;
  right: 16px;
  bottom: 15%;
  z-index: 220;
  background: ${({ theme }) => theme.colors.skyNeutral_1};
  border: none;
  padding: 12px;
  border-radius: 32px;
  box-shadow: 0 7px 20px rgba(0, 0, 0, 0.07);
`
export const ReportButtonWrap = styled(HStack)`
  justify-content: space-between;
  max-width: 80px;
`