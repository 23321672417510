import styled from 'styled-components'
import { HStack, VStack } from '../../global/styles'
import Typography from '../UI/Typography'

export const Wrapper = styled(HStack)`
  justify-content: space-between;
  position: relative;
  align-items: center;
  padding: 16px;
  box-shadow: inset 0px -1px 0px rgba(208, 201, 214, 0.4);
  &:last-of-type {
    margin-bottom: 200px;
  }
`

export const ImageNameWrap = styled(HStack)`
  align-items: center;
  justify-content: flex-start;
`

export const ContactName = styled(Typography).attrs({
  variant: 'textM'
})`
  color: ${({ theme }) => theme.colors.skyNeutral};
`
export const LastMessage = styled(Typography).attrs({
  variant: 'textS'
})`
  color: ${({ theme }) => theme.colors.skyNeutral2};
`

export const IconWrap = styled(HStack)`
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border-radius: 24px;
  background: ${({ theme }) => theme.colors.skyNeutral_1};
`

export const NameLocationWrap = styled(VStack)`
  margin-left: 16px;
`
export const ContactNameWrap = styled(HStack)`
`
export const Pronouns = styled(Typography).attrs({
  variant: 'textXS',
  bold: true
})`
  margin-left: 5px;
  color: ${({ theme }) => theme.colors.skyNeutral};
`
export const UnreadMessagesCount = styled(Typography).attrs({
  variant: 'textXS'
})`
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.pinkBase};
  padding: 3px;
  max-height: 20px;
  width: 20px;
  vertical-align: center;
  text-align: center;
  line-height: 14px;
  border-radius: 200px;
`
export const TimeStamp = styled(Typography).attrs({
  variant: 'textXS'
})`
  color: ${({ theme }) => theme.colors.skyNeutral2};
  margin-top: 5px;
  text-align: right;
`
export const StatusTimestampWrap = styled(VStack)`
  width: auto;
  justify-content: space-between;
  height: 100%;
  align-items: flex-end;
  min-width: 30%;
`
