import * as React from 'react'

function InstagramIcon(props) {
  return (
    <svg
      viewBox="0 0 36 36"
      width="100%"
      height="100%"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={18} cy={18} r={18} fill="url(#prefix__paint0_linear)" />
      <path
        d="M18 14.148a3.854 3.854 0 100 7.707 3.854 3.854 0 000-7.707zm0 6.355a2.503 2.503 0 110-5.006 2.503 2.503 0 010 5.006zM22.005 14.904a.898.898 0 100-1.796.898.898 0 000 1.796z"
        fill="#fff"
      />
      <path
        d="M25.11 13.092a3.838 3.838 0 00-2.194-2.193 5.505 5.505 0 00-1.821-.35c-.803-.035-1.057-.045-3.092-.045s-2.296 0-3.091.045a5.46 5.46 0 00-1.82.35 3.832 3.832 0 00-2.195 2.194 5.488 5.488 0 00-.349 1.821c-.036.802-.046 1.056-.046 3.092 0 2.035 0 2.294.046 3.091.013.624.13 1.239.35 1.823a3.841 3.841 0 002.194 2.193 5.485 5.485 0 001.821.375c.803.035 1.057.046 3.092.046s2.296 0 3.091-.046a5.512 5.512 0 001.822-.349 3.843 3.843 0 002.194-2.194c.22-.583.337-1.198.35-1.822.035-.801.046-1.056.046-3.091 0-2.036 0-2.294-.046-3.092a5.483 5.483 0 00-.351-1.848zm-1.014 7.944c-.006.48-.093.956-.26 1.406a2.49 2.49 0 01-1.426 1.426 4.153 4.153 0 01-1.392.26c-.791.036-1.015.045-3.045.045-2.031 0-2.239 0-3.046-.045a4.132 4.132 0 01-1.39-.26 2.485 2.485 0 01-1.433-1.425 4.234 4.234 0 01-.26-1.391c-.035-.792-.043-1.015-.043-3.045 0-2.031 0-2.239.044-3.046.005-.48.093-.956.259-1.406a2.488 2.488 0 011.433-1.427 4.176 4.176 0 011.39-.259c.793-.036 1.015-.046 3.046-.046 2.031 0 2.24 0 3.045.046.476.006.946.094 1.392.26a2.494 2.494 0 011.427 1.426c.163.446.25.916.259 1.39.035.793.045 1.016.045 3.047 0 2.03 0 2.248-.036 3.045h-.01v-.001z"
        fill="#fff"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={22.5}
          y1={0}
          x2={6}
          y2={33.5}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7D35B0" />
          <stop offset={0.232} stopColor="#DB2669" />
          <stop offset={0.542} stopColor="red" />
          <stop offset={0.766} stopColor="#FF5C00" />
          <stop offset={1} stopColor="#FFF500" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default InstagramIcon
