import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import ROUTES from '../../../const/ROUTES'
import useCreateMapRoute from '../../../hooks/route/useCreateMapRoute'
import { selectIsInRouteMode, selectHopRoute } from '../../../store/selectors'
import { VStack } from '../../../global/styles'
import MapContext from '../MapContext'

const HopStateAndTabPersistor = ({ children }) => {
  const route = useSelector(selectHopRoute, shallowEqual)
  const mapInstance = useContext(MapContext)
  const isInRouteMode = useSelector(selectIsInRouteMode)
  const lastVisitedTab = useSelector((state) => state.hop.lastOpenedTab)

  const { buildAndStartRoute } = useCreateMapRoute()
  useEffect(() => {
    if (route.length && mapInstance && isInRouteMode) {
      buildAndStartRoute(route)
    }
  }, [mapInstance])
  return (
    <VStack
      justify="space-between"
      style={{ height: '100%', paddingTop: '122px' }}
    >
      <Redirect to={ROUTES.HOP + lastVisitedTab} />
      {children}
    </VStack>
  )
}

export default HopStateAndTabPersistor

HopStateAndTabPersistor.propTypes = {
  children: PropTypes.node.isRequired
}
