import React, { useCallback } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import useShopDetails from '../../hooks/shop/useShopDetails'
import { getImageFromServer } from '../../utils/helpers'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES
} from '../../const/UIvariants'
import Button from '../UI/Buttons/Button/Button'
import ROUTES from '../../const/ROUTES'
import ShopLogo from '../ShopLogo'
import {
  AnnounceWrap,
  AnnounceImage,
  AnnounceLogoWrap,
  AnnounceTitleWrap,
  AnnounceTitle,
  AnnounceDescription,
  Posted,
  ShopNameLabel,
  ButtonWrap
} from './styles'

const SubscribedShopPost = ({
  postId,
  postTitle,
  postDescription,
  startTime,
  dateStart,
  dateEnd,
  createdAt,
  postImage,
  shopId,
  shopName,
  logotype
}) => {
  const history = useHistory()

  const { handleOpenShop } = useShopDetails()

  const handleOpenPostDetails = useCallback((_postId) => {
    history.push(
      history.location.pathname +
        generatePath(ROUTES.SHOP_POST_DETAILS, { postId: _postId }),
      {
        postImage,
        postTitle,
        postDescription,
        postDeadline: dateStart !== '' && dateEnd !== '',
        dateStart,
        dateEnd
      }
    )
  })

  return (
    <AnnounceWrap>
      { false &&
      <AnnounceImage
        image={getImageFromServer(postImage)}
      />
      }
      <AnnounceTitleWrap onClick={() => { handleOpenShop(shopId) }}>
        <AnnounceLogoWrap>
          <ShopLogo size={60} logoUrl={logotype} handleClick={() => { handleOpenShop(shopId) }}/>
        </AnnounceLogoWrap>
        <ShopNameLabel>{shopName}</ShopNameLabel>
      </AnnounceTitleWrap>
      <Posted>Posted: { dayjs(createdAt).format('DD MMM YYYY h:mm a') }</Posted>
      <AnnounceTitle>{postTitle}</AnnounceTitle>
      <AnnounceDescription>{postDescription}</AnnounceDescription>
      { false &&
      <Posted>{dayjs(startTime).fromNow()}</Posted>
      }
      <ButtonWrap>
        <Button
          variant={BUTTON_VARIANTS.PRIMARY}
          size={BUTTONS_SIZES.SMALL}
          onClick={() => { handleOpenPostDetails(postId) }}
        >
          Read More
        </Button>
      </ButtonWrap>   
    </AnnounceWrap>
  )
}

export default SubscribedShopPost

SubscribedShopPost.defaultProps = {
  postImage: "",
  logotype: "",
  dateStart: "",
  dateEnd: ""
}

SubscribedShopPost.propTypes = {
  postId: PropTypes.string.isRequired,
  postTitle: PropTypes.string.isRequired,
  postDescription: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  dateStart: PropTypes.string,
  dateEnd: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  postImage: PropTypes.string,
  shopId: PropTypes.string.isRequired,
  shopName: PropTypes.string.isRequired,
  logotype: PropTypes.string
}
