import React from 'react'
import PropTypes from 'prop-types'
import { ChatBubble } from '@styled-icons/material'
import IconButton from '../UI/Buttons/IconButton'
import {
  ContactLocation,
  ContactName,
  IconWrap,
  ImageNameWrap,
  NameLocationWrap,
  ContactNameWrap, 
  Pronouns
} from './styles'
import UserAvatar from '../UserAvatar'

const ContactListItemDetail = ({
  contactName,
  preferredPronouns,
  contactAvatar,
  contactLocation,
  bgColor
}) => (
  <>
    <ImageNameWrap>
      <UserAvatar
        image={contactAvatar}
        size={40}
        userName={contactName}
        {...{ bgColor }}
      />
      <NameLocationWrap>
        <ContactNameWrap>
          <ContactName>{contactName}</ContactName>
          {preferredPronouns !== '' &&
          <Pronouns>({preferredPronouns})</Pronouns>
          }
        </ContactNameWrap>
        {contactLocation && (
          <ContactLocation>{contactLocation}</ContactLocation>
        )}
      </NameLocationWrap>
    </ImageNameWrap>
    <IconButton size={34} onClick={() => {}}>
      <IconWrap>
        <ChatBubble size={16} color="#ffffff" />
      </IconWrap>
    </IconButton>
  </>
)

export default ContactListItemDetail

ContactListItemDetail.defaultProps = {
  preferredPronouns: '',
  contactAvatar: null,
  contactLocation: null
}

ContactListItemDetail.propTypes = {
  contactName: PropTypes.string.isRequired,
  preferredPronouns: PropTypes.string,
  bgColor: PropTypes.string.isRequired,
  contactLocation: PropTypes.string,
  contactAvatar: PropTypes.string
}
