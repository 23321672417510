import styled from 'styled-components'
import { pxToVh } from '../../../utils/styles-utils'
import { ScreenContainer, VStack } from '../../../global/styles'

// eslint-disable-next-line import/prefer-default-export

export const SuccessScreenWrap = styled(VStack)`
  height: 70vh;
  margin-top: ${pxToVh(84)};
  padding: 0 24px;
  position: relative;
  justify-content: space-between;
`
export const ContentWrap = styled(VStack)`
  align-items: center;
  > * {
    margin: 1vh 0;
  }
`
export const OverlayScreen = styled(ScreenContainer)`
  height: 100vh;
  width: 100%;
  z-index: 1000;
  position: absolute;
  background-color: #ffffff;
`
