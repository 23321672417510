/*eslint-disable*/
import React from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Done, RemoveRedEye, VisibilityOff } from '@styled-icons/material'

import { useHistory } from 'react-router-dom'
import { LoginLinkWrap, MessageWrap, SignUpWrap, StyledForm } from './styles'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES,
  INPUT_SIZES
} from '../../../const/UIvariants'
import TextInput from '../../../components/UI/Inputs/TextInput'
import Button from '../../../components/UI/Buttons/Button/Button'
import {
  AuthDescription,
  AuthHeadline,
  ScreenContainer,
  VStack
} from '../../../global/styles'
import ROUTES from '../../../const/ROUTES'
import LinkButton from '../../../components/UI/Buttons/LinkButton'
import OphopLogo from '../../../assets/vector/OphopLogo'
import Checkbox from '../../../components/UI/Checkbox'
import PhoneNumberInput from '../../../components/UI/Inputs/PhoneNumberInput'
import SuccessScreenOverlay from '../../common/SuccessScreenOverlay'
import useSignUp, { SIGN_UP_STEPS } from '../../../hooks/auth/useSignUp'
import useTimer from '../../../hooks/common/useTimer'
import { isNull } from 'lodash'
import { ButtonWrapper } from '../../settings/CheckSmsCode/styles'

const SignUp = () => {
  const { seconds, startTimer } = useTimer()
  const history = useHistory()
  const {
    step,
    showSuccess,
    passwordInputType,
    checkingCredentials,
    creatingAccount,
    handleCreateAccount,
    handleCheckContacts,
    handleShowPassword,
    handleVerifySmsCode,
    verifyingSmsCode
  } = useSignUp()

  return (
    <ScreenContainer>
      <Formik
        initialValues={{
          username: '',
          phoneNumber: '',
          email: '',
          verificationCode: '',
          password: '',
          confirmPassword: '',
          agreedWithTerms: false
        }}
        validationSchema={Yup.object({
          username: Yup.string()
            .max(40, 'Name is too long')
            .required('Name is required'),

          phoneNumber: Yup.string().matches(
            /^[0-9]+$/,
            'Please enter valid phone number'
          ),
          // .test(
          //   'len',
          //   'Must be exactly 11 digits',
          //   (val) => val?.length === 11
          // ),
          email: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
          verificationCode: Yup.number('Code must be a number'),
          password: Yup.string()
            .min(6, 'Password must contain at least 6 characters')
            .required('Required'),
          confirmPassword: Yup.string()
            .required('Please confirm your password')
            .when('password', {
              is: (password) => password && password.length > 0,
              then: Yup.string().oneOf(
                [Yup.ref('password')],
                "Password doesn't match"
              )
            }),
          agreedWithTerms: Yup.bool().oneOf([true], 'Field must be checked')
        })}
        onSubmit={(values, formik) => {
          startTimer(60)
          handleCreateAccount(values, formik)
        }}
      >
        {({ values, submitForm, errors, touched, isValid, setFieldError }) => (
          <StyledForm>
            {showSuccess && <SuccessScreenOverlay />}
            <SignUpWrap>
              <OphopLogo />
              <MessageWrap>
                <AuthHeadline>Register New Account</AuthHeadline>
                <AuthDescription>
                  G’day there! Sign up below to get hopping.
                </AuthDescription>
              </MessageWrap>
              {step === SIGN_UP_STEPS.CONTACTS && (
                <VStack>
                  <TextInput
                    inputName="username"
                    label="Username"
                    placeholder="User Name"
                    size={INPUT_SIZES.LARGE}
                  />
                  <PhoneNumberInput
                    inputName="phoneNumber"
                    label="Phone Number"
                    type="tel"
                    placeholder="Phone Number"
                    size={INPUT_SIZES.LARGE}
                  />
                  <TextInput
                    inputName="email"
                    label="Email Address"
                    placeholder="Email Address"
                    size={INPUT_SIZES.LARGE}
                  />

                  <Button
                    variant={BUTTON_VARIANTS.PRIMARY}
                    size={BUTTONS_SIZES.MED}
                    onClick={() => {
                      handleCheckContacts(values, setFieldError)

                      startTimer(60)
                    }}
                    isLoading={checkingCredentials}
                    type="button"
                    disabled={
                      !!(
                        errors.username ||
                        errors.phoneNumber ||
                        errors.email ||
                        !touched.username ||
                        !touched.phoneNumber ||
                        !touched.email
                      )
                    }
                  >
                    Next
                  </Button>
                </VStack>
              )}

              {step === SIGN_UP_STEPS.PASSWORD && (
                <VStack>
                  <TextInput
                    placeholder="Enter code from SMS"
                    inputName="verificationCode"
                    label="Code from SMS"
                    type="number"
                  />
                  <TextInput
                    type={passwordInputType}
                    placeholder="Enter your password"
                    rightIcon={
                      passwordInputType === 'password' ? (
                        <RemoveRedEye />
                      ) : (
                        <VisibilityOff />
                      )
                    }
                    rightIconClick={handleShowPassword}
                    size={INPUT_SIZES.LARGE}
                    inputName="password"
                    label="Password"
                  />
                  <TextInput
                    type="password"
                    inputName="confirmPassword"
                    label="Confirm Password"
                    rightIcon={
                      !errors.confirmPassword && <Done color="#4EA64C" />
                    }
                    placeholder="Repeat your password"
                    size={INPUT_SIZES.LARGE}
                  />
                  <Checkbox
                    name="agreedWithTerms"
                    label="By creating a new account, you agree to our Terms of Services & Privacy Policy"
                  />
                  <ButtonWrapper>
                    {false &&
                    <Button
                      variant={BUTTON_VARIANTS.PRIMARY}
                      size={BUTTONS_SIZES.MED}
                      disabled={!isValid}
                      isLoading={creatingAccount}
                      type="submit"
                    >
                      Create Account
                    </Button>
                    }
                    <Button
                      type="button"
                      size={BUTTONS_SIZES.MED}
                      disabled={seconds !== 0}
                      variant={BUTTON_VARIANTS.SECONDARY}
                      onClick={() => {
                        submitForm()
                      }}
                    >
                      Resend code
                      {seconds &&
                        ` in ${seconds < 10 ? `0${seconds}` : seconds}`}
                    </Button>
                  </ButtonWrapper>
                </VStack>
              )}
              <LoginLinkWrap>
                <AuthDescription>Already have an account?</AuthDescription>
                <LinkButton
                  type="button"
                  onClick={() => {
                    history.push(ROUTES.LOGIN)
                  }}
                >
                  Login
                </LinkButton>
              </LoginLinkWrap>
            </SignUpWrap>
          </StyledForm>
        )}
      </Formik>
    </ScreenContainer>
  )
}

export default SignUp

SignUp.propTypes = {}
