import React from 'react'
import ReactDOM from 'react-dom'
import { useSelector } from 'react-redux'
import {
  SearchHeader,
  SearchResultsWrap,
  SearchScreenContainer,
  StyledForm,
  StyledScrollbars
} from './styles'
import useToggleSearch from '../../../hooks/search/useToggleSearch'
import useSearchShops from '../../../hooks/search/useSearchShops'
import PreviousSearchQueries from '../PreviousSearchQueries'
import SearchResults from '../SearchResults'
import { selectCurrentCityName } from '../../../store/selectors'
import SearchInput from '../../../components/SearchInput'
import SelectOneInput from '../../../components/UI/Inputs/SelectOneInput/SelectOneInput'

const SearchScreen = () => {
  const currentCityName = useSelector(selectCurrentCityName)

  const {
    searchQuery,
    setSearchQuery,
    searchOption, 
    setSearchOption,
    handleSubmit,
    suggestedShops,
    handleSearchSuggested,
    suggestedQueries
  } = useSearchShops()

  const searchOptionsList = [
    {value: 1, label: 'All'}, 
    {value: 2, label: 'Donations'},
  ];

  const { closeSearch } = useToggleSearch()
  return ReactDOM.createPortal(
    <SearchScreenContainer>
      <SearchHeader>
        <StyledForm onSubmit={handleSubmit}>
          <SelectOneInput
            options={searchOptionsList}
            size="100px"
            selected={searchOption}
            setSelected={setSearchOption}
          />
          <SearchInput
            placeholder={`Search in ${currentCityName}`}
            handleChange={(e) => {
              setSearchQuery(e.target.value)
            }}
            value={searchQuery}
            handleClose={closeSearch}
          />
        </StyledForm>
      </SearchHeader>
      <SearchResultsWrap>
        <StyledScrollbars>
          {searchQuery.length === 0 && (
            <PreviousSearchQueries
              queries={suggestedQueries}
              handleSearchAgain={handleSearchSuggested}
            />
          )}
          {searchQuery.length !== 0 && <SearchResults shops={suggestedShops} searchQuery={searchQuery} searchOption={searchOption} />}
        </StyledScrollbars>
      </SearchResultsWrap>
    </SearchScreenContainer>,
    document.getElementById('root')
  )
}

export default SearchScreen

SearchScreen.propTypes = {}
