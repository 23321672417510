import React from 'react'
import { useField } from 'formik'
import PropTypes from 'prop-types'
import { CheckBox, CheckBoxOutlineBlank } from '@styled-icons/material'
import { withTheme } from 'styled-components'
import { CheckboxWrap, IconWrap } from './styles'

const Checkbox = ({ name, label, theme }) => {
  const [field] = useField({ name, type: 'checkbox' })

  return (
    <CheckboxWrap htmlFor={name}>
      <IconWrap>
        {field.checked ? (
          <CheckBox size={24} color={theme.colors.skyBase} />
        ) : (
          <CheckBoxOutlineBlank size={24} color={theme.colors.skyNeutral2} />
        )}
      </IconWrap>
      {label ?? ''}
      <input {...field} type="checkbox" id={name} hidden />
    </CheckboxWrap>
  )
}

export default withTheme(Checkbox)

Checkbox.defaultProps = {
  label: null
}
Checkbox.propTypes = {
  theme: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string
}
