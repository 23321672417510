import styled from 'styled-components'
import { HStack } from '../../../global/styles'
import Typography from '../../../components/UI/Typography'

export const SearchInputWrapper = styled(HStack)`
  filter: ${({ variant }) =>
    variant === 'dark'
      ? ''
      : ' drop-shadow(0px 7px 20px rgba(0, 0, 0, 0.07)) '};
  align-items: center;
  padding: 16px 12px;
  border-radius: 12px;
  justify-content: flex-start;
  background-color: ${({ theme, variant }) =>
    variant === 'dark' ? theme.colors.skyNeutral4 : theme.colors.white};
`

export const PlaceholderText = styled(Typography).attrs({
  variant: 'textM'
})`
  color: ${({ theme }) => theme.colors.skyNeutral2};
`
