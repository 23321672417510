import styled from 'styled-components'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { HStack, ScreenContainer, VStack } from '../../../global/styles'
import { fadeId, slideIn } from '../../../global/animations'
import Typography from '../../../components/UI/Typography'

// eslint-disable-next-line import/prefer-default-export
export const PostDetailsScreen = styled(ScreenContainer)`
  z-index: 400;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  animation: ${fadeId} 0.3s ease-in-out forwards;
`

export const PostDetailsWrap = styled(VStack)`
  background: white;
  border-radius: 12px 12px 0 0;
  margin-top: auto;
  padding: 12px;
  animation-delay: 0.3s;
  animation: ${slideIn} 0.3s ease-in-out forwards;
  max-height: 80vh;
  min-height: 50vh;
`
export const DragNotchWrap = styled(HStack)`
  width: 100%;
  min-height: 56px;
  align-items: center;
  justify-content: center;
`
export const DragNotch = styled.div`
  width: 52px;
  height: 4px;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.skyNeutral2};
`

export const StyledScrollbars = styled(OverlayScrollbarsComponent).attrs({
  options: {
    scrollbars: { visibility: 'hidden' }
  }
})`
  width: 100%;
  height: 100%;
`
export const Title = styled(Typography)`
  margin-top: 0;
  margin-bottom: 4px;
  min-height: 44px;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.skyNeutral};
`
export const PostDescription = styled(Typography)`
  margin-top: 0;
  margin-bottom: 4px;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.skyNeutral};
`
export const PostImage = styled.div`
  background: url(${({ backgroundImage }) => backgroundImage});
  background-size: cover;
  background-position: center;
  height: 96px;
  min-height: 96px;
  width: 100%;
  border-radius: 3px;
  min-width: 153px;
`
export const ButtonWrap = styled.div`
  margin-bottom: 20px;
`
