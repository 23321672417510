import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import useFilter from '../hop/useFilter'
import ROUTES from '../../const/ROUTES'
import { setLastOpenedTab } from '../../features/hop/hopSlice'

const useSelectCategory = () => {
  const { handleSelectOneTag } = useFilter()
  const dispatch = useDispatch()
  const history = useHistory()

  const handleSelectCategory = (tagId) => {
    handleSelectOneTag(tagId)
    dispatch(setLastOpenedTab('/stores'))
    history.push(ROUTES.HOP_SHOPS)
  }
  return { handleSelectCategory }
}

export default useSelectCategory
