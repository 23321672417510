import {
  combineReducers,
  configureStore,
  getDefaultMiddleware
} from '@reduxjs/toolkit'

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist'

import storage from 'redux-persist/lib/storage'
import auth from '../features/auth/authSlice'
import app from '../features/app/appSlice'
import hop from '../features/hop/hopSlice'
import search from '../features/search/searchSlice'
import friends from '../features/friendsAndMessages/friendsSlice'
import settings from '../features/settings/settingsSlice'
import { api } from '../api/api.generated'

const persistConfig = {
  key: 'ophop-client v0.1',
  version: 1,
  blacklist: ['api'],
  storage
}
export const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  auth,
  app,
  hop,
  search,
  friends,
  settings
})
const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }).concat(api.middleware)
  ]
})
export const persistor = persistStore(store)
export default store
