import styled from 'styled-components'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { pxToVh } from '../../../utils/styles-utils'
import Typography from '../../../components/UI/Typography'
import { VStack } from '../../../global/styles'

export const ButtonWrap = styled(VStack)`
  margin-top: ${pxToVh(100)};
  padding: 16px;
  /* TODO:
  height: 76px;
  */
  > * {
    > *:first-child {
      margin-right: 12px;
    }
  }
`
export const OrderContentWrapper = styled(OverlayScrollbarsComponent).attrs({
  options: {
    scrollbars: { visibility: 'hidden' }
  }
})`
  height: 100%;
  width: 100%;
  padding: 0;
  /* TODO:
  height: calc(100% - 148px - 12.35vh);
  */
`
export const ProductsWrap = styled(VStack)`
  padding: 0 16px;
  margin-bottom: 50px;
  /* TODO:
  margin-bottom: 0; 
  */
`
export const ShopsWrap = styled(VStack)`
  /* mobile */
  padding-bottom: 70px;
  /* TODO:
  padding-bottom: 30px; 
  */
`
export const ShopWrap = styled(Typography).attrs({
  variant: 'textL',
  bold: true
})`
`
export const WarningWrap = styled(Typography).attrs(({ theme }) => ({
  variant: 'textM',
  color: theme.colors.pinkBase
}))`
`
export const TotalWrap = styled(Typography).attrs(({ theme }) => ({
  variant: 'textM',
  color: theme.colors.skyNeutral_1
}))`
  margin-right: 20px;
`
export const StoreButtonsWrap = styled(VStack)`
  padding: 10px 0 10px 0;
  > * {
    > *:first-child {
      margin-right: 12px;
    }
  }
`