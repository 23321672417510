import { createSlice } from '@reduxjs/toolkit'
import { api } from '../../api/api.generated'

const initState = {
  suggestedQueries: [],
  lastSearchesLimit: 5,
  resultShopsIds: [],
  isSearchOpen: false
}

const searchSlice = createSlice({
  name: 'search',
  initialState: initState,
  reducers: {
    addToLastSearchQueue: (state, { payload }) => {
      state.lastSearchQueriesQueue.push(payload)
    },
    setSearchIsOpen: (state, { payload }) => {
      state.isSearchOpen = payload
    },
    reset: () => initState
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      api?.endpoints?.getTags?.matchFulfilled,
      (state, { payload }) => {
        state.suggestedQueries = payload.tags.map((tag) => tag.tagName)
      }
    )
  }
})

export default searchSlice.reducer

export const { setSearchIsOpen, addToLastSearchQueue, reset } =
  searchSlice.actions
