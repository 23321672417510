import styled from 'styled-components'
import { VStack } from '../../global/styles'
import Typography from '../UI/Typography'

export const AnnounceWrap = styled(VStack)`
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.07);
  padding: 8px 12px;
  height: 260px;
  width: 193px;
  flex-shrink: 0;
  margin-right: 12px;
`
export const AnnounceImage = styled.div`
  width: 100%;
  height: 126px;
  background: url(${({ image }) => image}) center;
  background-size: cover;
`
export const AnnounceLogoWrap = styled.div`
  cursor: pointer;
  align-items: center;
  margin-left: 54px;
`
export const AnnounceTitleWrap = styled.div`
  width: 100%;
  cursor: pointer;
`
export const AnnounceTitle = styled(Typography).attrs({
  variant: 'textM'
})`
  margin-bottom: 5px;
  color: ${({ theme }) => theme.colors.skyNeutral};
  text-align: center;
  line-height: normal;
`
export const AnnounceDescription = styled(Typography).attrs({
  variant: 'textS'
})`
  color: ${({ theme }) => theme.colors.skyNeutral2};
  max-height: 45px;
  margin-bottom: 5px;
  font-size: 12px;
  line-height: normal;
  text-overflow: ellipsis;
  overflow: hidden;
`
export const Posted = styled(Typography).attrs({
  variant: 'textXXS'
})`
  margin-top: 5px;
  text-align: left;
  color: ${({ theme }) => theme.colors.skyNeutral};
`
export const ShopNameLabel = styled(Typography).attrs(({ theme }) => ({
  color: theme.colors.skyNeutral_2,
  variant: 'textS',
  bold: true
}))`
  text-align: center;
`
export const ButtonWrap = styled.div`
  width: 169px;
  position: absolute;
  bottom: 20px
`