import styled from 'styled-components'
import { HStack, VStack } from '../../global/styles'
import Typography from '../UI/Typography'

export const ImageNameWrap = styled(HStack)`
  align-items: center;
  justify-content: flex-start;
`

export const ContactName = styled(Typography).attrs({
  variant: 'textM'
})`
  color: ${({ theme }) => theme.colors.skyNeutral};
`
export const ContactLocation = styled(Typography).attrs({
  variant: 'textS'
})`
  color: ${({ theme }) => theme.colors.skyNeutral2};
`

export const IconWrap = styled(HStack)`
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border-radius: 24px;
  background: ${({ theme }) => theme.colors.skyNeutral_1};
`

export const NameLocationWrap = styled(VStack)`
  margin-left: 16px;
`
export const ContactNameWrap = styled(HStack)`
`
export const Pronouns = styled(Typography).attrs({
  variant: 'textXS',
  bold: true
})`
  margin-left: 5px;
  color: ${({ theme }) => theme.colors.skyNeutral};
`