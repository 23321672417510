import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useContext, useEffect } from 'react'
import { isEmpty, isNull } from 'lodash/lang'
import { keys } from 'lodash/object'
import {
  setCurrentCityId,
  setSelectedCityId,
  setSelectedCityName
} from '../../features/hop/hopSlice'
import { centerMapOnCoords } from '../../utils/map-utils'
import MapContext from '../../features/hop/MapContext'
import {
  selectCities,
  selectCurrentCityId,
  selectDistancesToCities,
  selectSelectedCityId
} from '../../store/selectors'

const useHopCity = () => {
  const dispatch = useDispatch()
  const mapInstance = useContext(MapContext)
  const currentCityId = useSelector(selectCurrentCityId)
  const selectedCityId = useSelector(selectSelectedCityId)
  const distancesToCities = useSelector(selectDistancesToCities, shallowEqual)
  const storedCities = useSelector(selectCities, shallowEqual)

  useEffect(() => {
    if (
      !isEmpty(distancesToCities) &&
      !isEmpty(storedCities) &&
      (isNull(currentCityId) || isNull(selectedCityId))
    ) {
      const closestCity = Number(
        keys(distancesToCities).reduce((acc, curr) =>
          distancesToCities[acc] > distancesToCities[curr] ? curr : acc
        )
      )
      dispatch(setCurrentCityId(closestCity))
      dispatch(setSelectedCityId(closestCity))
      dispatch(
        setSelectedCityName(
          storedCities?.find((city) => city.id === closestCity)?.cityName
        )
      )
    }
  }, [storedCities, distancesToCities])

  const handleChangeCity = (cityId, cityName, location) => {
    dispatch(setSelectedCityId(cityId))
    dispatch(setSelectedCityName(cityName))
    if (mapInstance && location?.lat && location?.lng) {
      centerMapOnCoords(mapInstance, location.lat, location.lng)
      mapInstance.setZoom(12)
    }
  }
  return { handleChangeCity }
}

export default useHopCity
