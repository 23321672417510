import React, { useEffect, useState } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { Formik } from 'formik'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { withTheme } from 'styled-components'
import ROUTES from '../../../const/ROUTES'
import { usePostShoppingCartCheckoutMutation } from '../../../api/api.generated'
import { getMonthList, getYearList, getErrorMessage } from '../../../utils/helpers'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES,
  INPUT_SIZES
} from '../../../const/UIvariants'
import Typography from '../../UI/Typography'
import Button from '../../UI/Buttons/Button'
import TextInput from '../../UI/Inputs/TextInput'
import FormSelectOneInput from '../../UI/Inputs/SelectOneInput/FormSelectOneInput'
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner'
import {
  TitleWrap,
  ActionButtonsSection,
  ButtonWrap,
  FormInputsWrap,
  FormWrap,
  LeftHalf,
  RightHalf,
  StyledForm,
  ErrorWrap,
  CreditCardExpire
} from './styles'

const emptyData = {
  firstName: '',
  lastName: '',
  phone: '',
  address: '',
  cardName: '',
  cardNumber: '',
  cardMonth: '',
  cardYear: '',
  cardCode: ''
}
const CheckoutModal = ({ theme, isOpen, onClose, total, delivery }) => {
  const history = useHistory()
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [initialData, setInitialData] = useState(emptyData)
  const [postShoppingCartCheckout] = usePostShoppingCartCheckoutMutation()

  useEffect(() => {
    setInitialData(emptyData)
  }, [])

  const handleSubmitForm = (values) => {
    setError('')
    setIsLoading(true)
    postShoppingCartCheckout({
      body: {
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phone,
        address: values.address,
        cardName: values.cardName,
        cardNumber: values.cardNumber,
        cardMonth: values.cardMonth,
        cardYear: values.cardYear,
        cardCode: values.cardCode,
        amount: total,
        delivery
      }
    })
      .unwrap()
      .then((res) => {
        history.push(generatePath(ROUTES.ORDER_ITEMS, { orderId: res.orderId }))
        onClose()
      })
      .catch((err) => {
        setIsLoading(false)
        setError(getErrorMessage(err))
      })
  }

  const validationSchema = Yup.object({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    phone: Yup.string().required('Phone is required'),
    address: Yup.string().required('Address is required'),
    cardName: Yup.string().required('Name on card is required'),
    cardNumber: Yup.string().required('Card number is required'),
    cardMonth: Yup.string().required('Card month is required'),
    cardYear: Yup.string().required('Card year is required'),
    cardCode: Yup.string().required('Card code is required')
  })

  const monthList = getMonthList();
  const yearList = getYearList();  

  return (
    <Modal {...{ isOpen }}>
      <FormWrap>
        <Formik
          initialValues={initialData}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleSubmitForm(values)
          }}
        >
          <StyledForm>
            {isLoading && <LoadingSpinner />}
            <TitleWrap>
              <Typography variant="headingM" color={theme.colors.skyNeutral_2}>Payment</Typography>
            </TitleWrap>
            <FormInputsWrap>
              <LeftHalf>
                <TextInput
                  size={INPUT_SIZES.SMALL}
                  inputName="lastName"
                  label="First Name"
                  placeholder="Enter first name"
                />
                <TextInput
                  size={INPUT_SIZES.SMALL}
                  inputName="firstName"
                  label="Last Name"
                  placeholder="Enter last name"
                />
                <TextInput
                  size={INPUT_SIZES.SMALL}
                  inputName="phone"
                  label="Phone"
                  placeholder="Enter phone"
                />
                <TextInput
                  size={INPUT_SIZES.SMALL}
                  inputName="address"
                  label="Address"
                  placeholder="Enter address"
                />
              </LeftHalf>
              <RightHalf>
                <TextInput
                  size={INPUT_SIZES.SMALL}
                  inputName="cardName"
                  label="Name on Card "
                  placeholder="Enter name on card"
                />
                <TextInput
                  size={INPUT_SIZES.SMALL}
                  inputName="cardNumber"
                  label="Card Number "
                  placeholder="Enter card number"
                />
                <CreditCardExpire>
                  <FormSelectOneInput
                    inputName="cardMonth"
                    label="Expiry Month"
                    options={monthList}
                    size=""
                    isGrey
                  />
                  <FormSelectOneInput
                    inputName="cardYear"
                    label="Expiry Year"
                    options={yearList}
                    size=""
                    isGrey
                  />
                </CreditCardExpire>
                <TextInput
                  size={INPUT_SIZES.SMALL}
                  inputName="cardCode"
                  label="Card Code (CVC) "
                  placeholder="Enter CVC"
                />
              </RightHalf>
            </FormInputsWrap>
            <ErrorWrap>
              {error && (
                <Typography variant="textS" color="red">
                  {error}
                </Typography>
              )}
            </ErrorWrap>
            <ActionButtonsSection>
              <ButtonWrap>
                <Button
                  type="submit"
                  variant={BUTTON_VARIANTS.PRIMARY}
                  size={BUTTONS_SIZES.MED}
                >
                  Pay
                </Button>
                <Button
                  type="button"
                  variant={BUTTON_VARIANTS.SECONDARY}
                  size={BUTTONS_SIZES.MED}
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </ButtonWrap>
            </ActionButtonsSection>
          </StyledForm>
        </Formik>
      </FormWrap>
    </Modal>
  )
}

export default withTheme(CheckoutModal)

CheckoutModal.propTypes = {
  theme: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  delivery: PropTypes.array.isRequired
}
