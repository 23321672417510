import styled from 'styled-components'

// eslint-disable-next-line import/prefer-default-export
export const MapWrap = styled.div`
  width: 100%;
  height: 100%;
  align-self: stretch;
  margin-bottom: 0;
  position: absolute;
  z-index: 0;
  top: 0;
`
