import React from 'react'
import { values } from 'lodash/object'
import PropTypes from 'prop-types'
import { withTheme } from 'styled-components'
import { ScaleLoader } from 'react-spinners'
import { LeftIconWrap, RightIconWrap, StyledButton } from './styles'
import Typography from '../../Typography'
import {
  BUTTONS_SIZES,
  BUTTON_VARIANTS,
  BUTTON_LABEL_SIZE
} from '../../../../const/UIvariants'
import { iconButtonSize } from '../../../../utils/styles-utils'

const Button = ({
  variant,
  size,
  disabled,
  leftIcon,
  rightIcon,
  fillColor,
  children,
  type,
  onClick,
  isLoading,
  theme,
  isFullWidth,
  labelSize
}) => (
  <StyledButton
    disabled={isLoading || disabled}
    {...{ variant, size, fillColor, type, onClick, isLoading, isFullWidth }}
  >
    {leftIcon && (
      <LeftIconWrap {...{ size, variant }}>
        {React.cloneElement(leftIcon, {
          ...iconButtonSize(size)
        })}
      </LeftIconWrap>
    )}
    {isLoading ? (
      <ScaleLoader height={size / 3} color={theme.colors.skyBase} />
    ) : (
      <Typography variant={labelSize || BUTTON_LABEL_SIZE[size]} component="p">
        {children}
      </Typography>
    )}
    {rightIcon && (
      <RightIconWrap {...{ size }}>
        {React.cloneElement(rightIcon, {
          ...iconButtonSize(size)
        })}
      </RightIconWrap>
    )}
  </StyledButton>
)

export default withTheme(Button)

Button.defaultProps = {
  variant: BUTTON_VARIANTS.PRIMARY,
  disabled: false,
  leftIcon: null,
  rightIcon: null,
  fillColor: null,
  onClick: null,
  isLoading: false,
  size: BUTTONS_SIZES.SMALL,
  labelSize: null,
  isFullWidth: true,
  type: 'button',
}
Button.propTypes = {
  variant: PropTypes.oneOf(values(BUTTON_VARIANTS)),
  size: PropTypes.oneOf(values(BUTTONS_SIZES)),
  labelSize: PropTypes.string,
  disabled: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  fillColor: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  theme: PropTypes.object.isRequired
}
