import styled from 'styled-components'
import { Form } from 'formik'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { VStack } from '../../../global/styles'

export const StyledForm = styled(Form)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

export const HeadlineWrap = styled(VStack)`
  margin: 16px 0 24px 0;
`

export const FormBody = styled(VStack)`
  justify-content: space-between;
`
export const StyledScrollbars = styled(OverlayScrollbarsComponent).attrs({
  options: {
    scrollbars: { visibility: 'hidden' }
  }
})`
  width: 100%;
  height: 100%;
`
