import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { selectCurrentLocation } from '../../store/selectors'
import { updateDistanceUserToShopsByShopId } from '../../features/hop/hopSlice'

const useUpdateDistances = () => {
  const dispatch = useDispatch()
  const currentLocation = useSelector(selectCurrentLocation, shallowEqual)
  useEffect(() => {
    dispatch(
      updateDistanceUserToShopsByShopId({
        currentLocation: {
          lat: currentLocation.currentLat,
          lng: currentLocation.currentLng
        }
      })
    )
  }, [currentLocation])
}

export default useUpdateDistances
