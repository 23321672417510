import styled from 'styled-components'
import { VStack } from '../../../global/styles'
import Typography from '../../../components/UI/Typography'

export const VolunteersWrap = styled(VStack)``

export const TitleWrap = styled(VStack)`
  margin-bottom: 16px;
`
export const TitleText = styled(Typography)`
  color: ${({ theme }) => theme.colors.skyNeutral_1};
  text-align: center;
  align-self: center;
  font-weight: bold;
`
export const MessageWrap = styled(VStack)`
  margin-bottom: 16px;
`
export const ButtonWrap = styled(VStack)`
  margin-bottom: 16px;
  padding: 0 16px;
`
