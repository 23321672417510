import React from 'react'
import { HeaderWrap, Headline, HeadlineWrap } from './styles'

const EventsHeader = () => (
  <HeaderWrap>
    <HeadlineWrap>
      <Headline>Events List</Headline>
    </HeadlineWrap>
  </HeaderWrap>
)

export default EventsHeader

EventsHeader.propTypes = {}
