import styled from 'styled-components'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { HStack, VStack } from '../../../global/styles'
import Typography from '../../../components/UI/Typography'
import { getImageFromServer } from '../../../utils/helpers'

export const InfoBlock = styled(VStack)`
  margin-bottom: 16px;
  padding: 0 ${({ withPadding }) => (withPadding ? '16px' : 0)};
`
export const Heading = styled(Typography).attrs(({ theme }) => ({
  color: theme.colors.skyNeutral_2,
  variant: 'headingS',
  bold: true
}))`
  text-align: left;
  margin-bottom: 16px;
  margin-left: ${({ withPadding }) => (withPadding ? '16px' : 0)};
`
export const SocialIconsWrap = styled(HStack)`
  margin-bottom: 16px;
`
export const InlineText = styled(Typography).attrs(({ theme }) => ({
  color: theme.colors.skyNeutral_1,
  variant: 'textS'
}))``
export const PhoneNumber = styled(Typography).attrs(({ theme }) => ({
  color: theme.colors.skyBase,
  variant: 'textM',
  as: 'a'
}))`
  text-decoration: none;
`
export const ScheduleWrap = styled(HStack)`
  flex-wrap: wrap;
  align-items: stretch;
  & > div:nth-child(3n + 2) {
    //border: 1px solid red;
    border-left: 1px solid ${({ theme }) => theme.colors.skyNeutral4};
    border-right: 1px solid ${({ theme }) => theme.colors.skyNeutral4};
  }
`

export const DayItem = styled(VStack)`
  min-width: 30%;
  width: 33%;
  padding: 0 14px;
  margin-bottom: 20px;
`

export const DayLabel = styled(Typography).attrs(({ theme }) => ({
  color: theme.colors.skyNeutral_1,
  variant: 'textS'
}))`
  margin-bottom: 8px;
`

export const DonationsWrap = styled(HStack)`
  & > *:first-child {
    margin-left: 16px;
  }
  & > * {
    margin-right: 12px;
  }
`
export const LatestPostsWrap = styled(HStack)`
  > div {
    margin-left: 16px;
  }
`
export const GalleryWrapper = styled(HStack)`
  height: 168px;
  align-items: stretch;
`
export const SmallHeading = styled(Typography).attrs(({ theme }) => ({
  color: theme.colors.skyNeutral2,
  variant: 'textXS',
  bold: true
}))`
  margin-bottom: 16px;
  margin-left: ${({ withPadding }) => (withPadding ? '16px' : 0)};
`

export const ImageContainer = styled.div`
  margin-right: 2%;
  margin-bottom: 2%;
  display: flex;
  border-radius: 4px;
  background: url(${({ bg }) => (bg ? getImageFromServer(bg) : '')});
  overflow: hidden;
  background-size: cover;
  flex-grow: 1;
  flex-wrap: wrap;
  min-width: 40%;
  position: relative;
`
export const ImageCount = styled(Typography).attrs(({ theme }) => ({
  color: theme.colors.white,
  variant: 'textM',
  bold: 'true'
}))`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${({ theme }) => theme.colors.white};
  z-index: 20;
`
export const CustomerReviewWrap = styled(HStack)`
  padding: 10px 8px 10px 4px;

  width: 100%;
`
export const ReviewDetails = styled(VStack)`
  width: 100%;
  margin-left: 15px;
`
export const CustomerName = styled(Typography).attrs(({ theme }) => ({
  color: theme.colorsSkyNeutral_2
}))``

export const TimeStamp = styled(Typography).attrs(({ theme }) => ({
  color: theme.colors.syNeutral2,
  variant: 'textXS'
}))`
  text-align: right;
`
export const ReviewWrap = styled(HStack)`
  justify-content: space-between;
  width: 100%;
  height: 16px;
`
export const BadgeTabsWrap = styled(HStack)``
export const SocialIconWrap = styled.button`
  width: 32px;
  height: 32px;
  outline: none;
  border: none;
  margin-right: 12px;
`
export const ImageOverlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ca6226;
  z-index: 10;
  opacity: 0.5;
`
export const HorScroll = styled(OverlayScrollbarsComponent).attrs({
  options: {
    scrollbars: { visibility: 'hidden' }
  }
})`
  width: 100%;
  display: flex;
`
export const TagWrap = styled(HStack)`
`