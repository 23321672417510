import React, { useMemo } from 'react'
import { random } from 'lodash/number'
import { withTheme } from 'styled-components'
import PropTypes from 'prop-types'
import { Status, UserAvatarWrap, UserInitials } from './styles'
import { USER_AVATAR_SIZES } from '../../const/UIvariants'
import { getImageFromServer, getUserInitials } from '../../utils/helpers'

const UserAvatar = ({ theme, size, userName, isOnline, image, bgColor }) => {
  const getBgColor = useMemo(() => random(0, 5), [])
  // const getUserInitials = (initUserName) =>
  //   initUserName
  //     ?.split(' ')
  //     .map((word) => word[0])
  //     .join('')
  //     .toUpperCase()

  return (
    <UserAvatarWrap
      {...{ size, bgColor }}
      bgVariant={getBgColor}
      bgImage={image && getImageFromServer(image)}
    >
      {!image && (
        <UserInitials variant="textXS" color={theme.colors.white}>
          {getUserInitials(userName)}
        </UserInitials>
      )}
      {isOnline && <Status {...{ size }} />}
    </UserAvatarWrap>
  )
}

export default withTheme(UserAvatar)

UserAvatar.defaultProps = {
  size: USER_AVATAR_SIZES.SMALL,
  isOnline: false,
  bgColor: 'hsla(173,63%,52%,1)',
  image: null
}

UserAvatar.propTypes = {
  theme: PropTypes.object.isRequired,
  size: PropTypes.number,
  userName: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
  image: PropTypes.string,
  isOnline: PropTypes.bool
}
