import styled from 'styled-components'
import { VStack, HStack } from '../../../global/styles'
import Typography from '../../../components/UI/Typography'
import { fadeId } from '../../../global/animations'

export const DrawerWrapper = styled(VStack)``
export const DrawerFooter = styled(HStack)`
  width: 100%;
  position: fixed;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 24px 16px;
  height: 96px;
  z-index: 20;
  bottom: 70px;
`

export const Expanded = styled(VStack)`
  padding: 16px;
`

export const Collapsed = styled(HStack)`
  width: 100%;
  position: relative;
  justify-content: space-between;
  align-items: center;
`
export const Headline = styled(Typography).attrs({
  variant: 'headingM',
  bold: true
})`
  color: ${({ theme }) => theme.colors.skyNeutral1};
  width: 100%;
`

export const LogoListWrap = styled(HStack)`
  padding-right: 16px;
  animation: ${fadeId} 0.3s ease-in-out forwards;
  > *:last-child {
  }
`
export const ExpandedShopListWrap = styled(VStack)`
  margin-top: 24px;
  padding-bottom: 100px;
  z-index: 100;
  //height: auto;
`
export const SwipeableStyles = {
  style: { borderRadius: '16px 16px 0 0', zIndex: '20', marginBottom: '64px' },
  bodyStyle: {
    borderRadius: '16px 16px 0 0',
    overflow: 'hidden'
  },
  overlayStyle: { borderRadius: '16px 16px 0 0', background: 'transparent' }
}

export const RouteSection = styled(VStack)`
  padding: 6px;
  border-radius: 24px;
  background: ${({ theme }) => theme.colors.skyNeutral5};
  margin-bottom: 24px;
  border: ${({ isCurrentTarget, theme }) =>
    isCurrentTarget ? `1px solid ${theme.colors.skyBase3}` : 'none'};
`

export const InstructionLabel = styled(Typography).attrs({
  variant: 'textS'
})`
  margin: 14px 0;
  color: ${({ theme }) => theme.colors.skyNeutral};
  text-align: center;
  width: 100%;
`
export const InstructionItem = styled(VStack)`
  align-items: center;
`
export const ShopInfo = styled(HStack)`
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 20px;
`
