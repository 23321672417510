import styled from 'styled-components'
import Typography from '../components/UI/Typography'

export const Card = styled.div`
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 6px 16px -4px rgba(15, 18, 20, 0.1);
`

export const HStack = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({ justify }) => justify ?? 'flex-start'};
  align-items: ${({ alignItems }) => alignItems ?? 'flex-start'};
  width: ${({ width }) => (width ? `${width}px` : '100%')};
`
export const VStack = styled.div`
  display: flex;
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  flex-direction: column;
  justify-content: ${({ justify }) => justify ?? 'flex-start'};
  align-items: ${({ alignItems }) => alignItems ?? 'flex-start'};
`
// screen
export const ScreenContainer = styled(VStack)`
  height: 100%;
  background: white;
  width: 100%;
`

// auth text styles
export const AuthHeadline = styled(Typography).attrs({
  variant: 'headingXL'
})`
  color: ${({ theme }) => theme.colors.skyNeutral_1};
  margin-bottom: 4px;
`

export const AuthDescription = styled(Typography).attrs({
  variant: 'headingS'
})`
  color: ${({ theme }) => theme.colors.skyNeutral2};
`
export const AuthButtonsWrap = styled(VStack)`
  width: 100%;
  align-items: stretch;
  > *:first-child {
    margin-bottom: 20px;
  }
`
export const NoDataMessage = styled(Typography).attrs({
  variant: 'headingS'
})`
  color: ${({ theme }) => theme.colors.skyNeutral2};
  width: 100%;
  text-align: center;
  padding: 16px;
`
export const ShopNameLabel = styled(Typography).attrs(({ theme }) => ({
  color: theme.colors.skyNeutral_2,
  variant: 'textS',
  bold: true
}))`
  text-align: center;
`
export const IsEmptyText = styled(Typography)`
  margin-top: 30px;
  color: ${({ theme }) => theme.colors.skyNeutral_1};
  text-align: center;
  align-self: center;
`