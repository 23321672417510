import React, { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { keys } from 'lodash/object'
import { LocationOn } from '@styled-icons/material'
import { isEmpty } from 'lodash/lang'
import {
  selectCurrentCityId,
  selectCurrentCityName,
  selectShopsNearby,
  selectTagsById
} from '../../../store/selectors'
import { useLazyGetUserHomeScreenQuery } from '../../../api/api.generated'
import OphopLogo from '../../../assets/vector/OphopLogo'
import OpenSearchFakeInput from '../../search/OpenSearchFakeInput'
import HomeScreenTag from '../../../components/HomeScreenTag'
import NearbyShopItem from '../../../components/NearbyShopItem'
import SubscribedShopPost from '../../../components/SubscribedShopPost'
import {
  Headline,
  HomeLocationLabel,
  HomeLocationWrap,
  HomeScreenContainer,
  HomeScreenContent,
  HomeScreenHeader,
  HorScroll,
  LogoLocationWrap,
  Section,
  SectionCaption,
  ShopsWrap,
  PostsWrap,
  TagWrap,
  IsEmptyText
} from './styles'

const Home = () => {
  const currentCityId = useSelector(selectCurrentCityId)
  const shopsNearby = useSelector(selectShopsNearby(10), shallowEqual)
  const currentCity = useSelector(selectCurrentCityName)
  const tagById = useSelector(selectTagsById)
  const [getHomeData, { data: homeData }] = useLazyGetUserHomeScreenQuery()

  useEffect(() => {
    if (currentCityId) {
      getHomeData({
        cityId: currentCityId
      })
    }
  }, [currentCityId])

  return (
    <>
      <HomeScreenHeader>
        <LogoLocationWrap>
          <OphopLogo fill="#2D6CE4" width={99} height={25} />
          <HomeLocationWrap>
            <LocationOn size={24} color="#2D6CE4" />
            <HomeLocationLabel>{currentCity}</HomeLocationLabel>
          </HomeLocationWrap>
        </LogoLocationWrap>
        <OpenSearchFakeInput variant="dark" />
      </HomeScreenHeader>
      <HomeScreenContainer>
        <HomeScreenContent>
          <Headline>Explore</Headline>
          <Section withScroll>
            <SectionCaption withPadding>Select Category</SectionCaption>
            <HorScroll>
              {!isEmpty(tagById) && (
                <TagWrap>
                  {keys(tagById).map((tagId) => (
                    <HomeScreenTag key={tagId} {...{ tagId }} allowClick/>
                  ))}
                </TagWrap>
              )}
            </HorScroll>
          </Section>
          <Section withScroll>
            <SectionCaption withPadding>Near You</SectionCaption>
            <HorScroll>
              <ShopsWrap>
                {shopsNearby.map((shopId, index) => (
                  <NearbyShopItem
                    key={shopId}
                    shopId={shopId}
                    isClosest={index === 0}
                  />
                ))}
              </ShopsWrap>
            </HorScroll>
          </Section>
          {homeData && (
            <Section withScroll>
              <SectionCaption withPadding>Subscribed Store Posts</SectionCaption>
              {isEmpty(homeData.post) && 
              <IsEmptyText variant="textXS">No subscribed store posts.</IsEmptyText>
              }
              <HorScroll>
                <PostsWrap>
                  {homeData.post.map((post) => (
                    <SubscribedShopPost
                      key={post.id}
                      postId={post.id}
                      postTitle={post.postTitle}
                      postDescription={post.postDescription}
                      startTime={post.startTime}
                      dateStart={post.startTime}
                      dateEnd={post.endTime}
                      createdAt={post.createdAt}
                      postImage={post.postImage}
                      shopId={post.shopId}
                      shopName={post.shopName}
                      logotype={post.logotype}
                     />
                  ))}
                </PostsWrap>
              </HorScroll>
            </Section>
          )}
        </HomeScreenContent>
      </HomeScreenContainer>

    </>
  )
}

export default Home

Home.propTypes = {}
