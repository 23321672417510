import styled from 'styled-components'

// eslint-disable-next-line import/prefer-default-export
export const StyledButton = styled.button`
  background: ${({ theme }) => theme.colors.white};
  min-width: 48px;
  border: none;
  height: 48px;
  right: 16px;
  margin-left: 20px;
  border-radius: 50px;
  color: ${({ theme }) => theme.colors.skyBase};
  outline: none;
  :active {
    background: ${({ theme }) => theme.colors.skyNeutral_2};
    outline: none;
  }
`
