import React from 'react'
import { useHistory } from 'react-router-dom'
import ReactDOM from 'react-dom'
import {
  FindFriendsContainer,
  SearchHeader,
  SearchResultsWrap,
  StyledForm,
  StyledScrollbars
} from './styles'
import SearchInput from '../../../components/SearchInput'
import useFindFriends from '../../../hooks/friends/useFindFriends'
import { NoDataMessage, VStack } from '../../../global/styles'
import ContactSearchResultItem from '../../../components/ContactSearchResultItem'
import LoadingSpinner from '../../../components/LoadingSpinner'

const FindFriends = () => {
  const { goBack } = useHistory()
  const {
    handleSubmit,
    searchQuery,
    setSearchQuery,
    searchResults,
    isLoading,
    status
  } = useFindFriends()

  return ReactDOM.createPortal(
    <FindFriendsContainer>
      <SearchHeader>
        <StyledForm onSubmit={handleSubmit}>
          <SearchInput
            placeholder="Find Friends"
            handleChange={(e) => {
              setSearchQuery(e.target.value)
            }}
            value={searchQuery}
            handleClose={() => {
              goBack()
            }}
          />
        </StyledForm>
      </SearchHeader>
      <SearchResultsWrap>
        <StyledScrollbars>
          {isLoading && <LoadingSpinner />}
          {status === 'fulfilled' &&
            searchResults.length === 0 &&
            !isLoading && <NoDataMessage> Nothing found</NoDataMessage>}
          <VStack>
            {!!searchResults.length &&
              searchResults.map((contact) => (
                <ContactSearchResultItem
                  key={contact.id}
                  id={contact.id}
                  roomId={contact.chatRoomId}
                  contactAvatar={contact.contactAvatar}
                  contactName={contact.contactName}
                  preferredPronouns={contact.preferredPronouns}
                  requestStatus={contact.requestStatus}
                  bgColor={contact.avatarBackground}
                />
              ))}
          </VStack>
        </StyledScrollbars>
      </SearchResultsWrap>
    </FindFriendsContainer>,
    document.getElementById('root')
  )
}

export default FindFriends

FindFriends.propTypes = {}
