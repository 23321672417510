import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { ArrowBackIos } from '@styled-icons/material'
import { HeaderContainer, HeaderWrap, HeadlineWrap, Headline } from './styles'
import IconButton from '../../../components/UI/Buttons/IconButton'

const OrdersHeader = ({ title, showBack }) => {
  const { goBack } = useHistory()

  return (
    <HeaderContainer>
      <HeaderWrap>
        {showBack && (
          <IconButton
            size={24}
            onClick={() => {
              goBack()
            }}
          >
            <ArrowBackIos size={24} color="#454E60" />
          </IconButton>
        )}
        <HeadlineWrap>
          <Headline>{title}</Headline>
        </HeadlineWrap>
      </HeaderWrap>
    </HeaderContainer>
  )
}

export default OrdersHeader

OrdersHeader.propTypes = {}

OrdersHeader.propTypes = {
  title: PropTypes.string.isRequired,
  showBack: PropTypes.bool.isRequired
}
