import React from 'react'
import * as PropTypes from 'prop-types'
import { StyledIconButton } from './styles'

const IconButton = ({ children, onClick, size }) => (
  <StyledIconButton onClick={onClick} type="button" minSize={size}>
    {children}
  </StyledIconButton>
)

export default IconButton

IconButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  size: PropTypes.number
}

IconButton.defaultProps = {
  onClick: () => {},
  size: 24
}
