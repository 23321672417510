import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { evaluateTravelDistances } from '../../features/hop/hopSlice'
import {
  selectCurrentDestinationIndex,
  selectCurrentLocation,
  selectHopRoute
} from '../../store/selectors'

const useEvaluateRouteDistances = () => {
  const dispatch = useDispatch()
  const currentLocation = useSelector(selectCurrentLocation, shallowEqual)
  const currentDestinationIndex = useSelector(selectCurrentDestinationIndex)
  const route = useSelector(selectHopRoute, shallowEqual)

  useEffect(() => {
    if (route.length >= currentDestinationIndex + 1) {
      dispatch(
        evaluateTravelDistances({
          destinations: route,
          initialPosition: {
            lat: currentLocation.currentLat,
            lng: currentLocation.currentLng
          }
        })
      )
    }
  }, [currentLocation])
}
export default useEvaluateRouteDistances
