import styled from 'styled-components'
import { HStack } from '../../../../global/styles'

// eslint-disable-next-line import/prefer-default-export
export const StarsWrapper = styled(HStack)`
  width: auto;
  justify-content: center;
  margin-bottom: 24px;
  > * {
    margin: 0 4px;
  }
`
