/*eslint-disable*/
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import ROUTES from '../../../const/ROUTES'
import PrivateRoute from '../../../components/PrivateRoute'
import ShopPreviewContainer from '../../shopDetails/ShopPreviewContainer'
import { ScreenContainer } from '../../../global/styles'
import MainSectionTabBar from '../../../components/MainSectionTabBar'
import Home from '../../homeScreen/Home'
import HopContainer from '../../hop/HopContainer'
import FallbackRoute from '../../../components/FallbackRoute'
// import { useDispatch } from 'react-redux'
// import useGetHereWeGoToken from '../../../hooks/_archived/useGetHereWeGoToken'
import HopFilter from '../../hop/HopFilter'
import SearchScreen from '../../search/SearchScreen'
import SettingsScreen from '../../settings/SettingsScreen'
import EventsScreenContainer from '../../events/EventsScreenContainer'
import ContactsScreenContainer from '../../friendsAndMessages/ContactsScreenContainer'
import FindFriends from '../../friendsAndMessages/FindFriends'
import PostDetailsOverlay from '../PostDetailsOverlay'
import ShoppingCart from '../../shoppingCart/ShoppingCart'
import OrdersScreenContainer from '../../orders/OrdersScreenContainer'
import OrderScreen from '../../orders/OrderScreen'
import SubscribedStores from '../../settings/SubscribedStores'
import FavouriteStores from '../../settings/FavouriteStores'
import RecentlyVisited from '../../settings/RecentlyVisited'

const PRIVATE_ROUTES = [
  { route: ROUTES.HOME, Component: <Home /> },
  { route: ROUTES.HOP, Component: <HopContainer /> },
  { route: ROUTES.SETTINGS, Component: <SettingsScreen /> },
  { route: ROUTES.FRIENDS, Component: <ContactsScreenContainer /> },
  { route: ROUTES.EVENTS, Component: <EventsScreenContainer /> },
  { route: ROUTES.SHOPPING_CART, Component: <ShoppingCart /> },
  { route: ROUTES.ORDERS, Component: <OrdersScreenContainer /> },
  { route: ROUTES.ORDER_ITEMS, Component: <OrderScreen /> },
  { route: ROUTES.SUBSCRIBED_STORES, Component: <SubscribedStores /> },
  { route: ROUTES.FAVOURITE_STORES, Component: <FavouriteStores /> },
  { route: ROUTES.RECENTLY_VISITED, Component: <RecentlyVisited /> }
]
const SEARCH_ROUTE = {
  route: ROUTES.SEARCH,
  Component: <SearchScreen />
}
const FIND_FRIENDS_ROUTE = {
  route: ROUTES.FIND_FRIENDS,
  Component: <FindFriends />
}
const MainContainer = () => {
  // const hereToken = useGetHereWeGoToken()
  // const dispatch = useDispatch()
  return (
    <ScreenContainer>
      <Switch>
        {PRIVATE_ROUTES.map((routeItem) => (
          <Route key={routeItem.route} path={routeItem.route}>
            {routeItem.Component}
          </Route>
        ))}
        <FallbackRoute fallbackPath={ROUTES.HOME} />
      </Switch>

      <PrivateRoute path={`*${FIND_FRIENDS_ROUTE.route}`}>
        {FIND_FRIENDS_ROUTE.Component}
      </PrivateRoute>

      <PrivateRoute path={`*${SEARCH_ROUTE.route}`}>
        {SEARCH_ROUTE.Component}
      </PrivateRoute>

      <PrivateRoute path={`*${ROUTES.SHOP_OVERLAY}`}>
        <ShopPreviewContainer />
      </PrivateRoute>

      <PrivateRoute path={`*${ROUTES.HOP_FILTER}`}>
        <HopFilter />
      </PrivateRoute>

      <PrivateRoute path={`*${ROUTES.SHOP_POST_DETAILS}`}>
        <PostDetailsOverlay />
      </PrivateRoute>
      <MainSectionTabBar />
    </ScreenContainer>
  )
}

export default MainContainer

MainContainer.propTypes = {}
