import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash/lang'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { mergeArraysBottom } from '../../utils/helpers'
import { BUTTON_VARIANTS, BUTTONS_SIZES } from '../../const/UIvariants'
import { selectUserId } from '../../store/selectors'
import { useLazyGetChatRoomByRoomIdMessagesQuery } from '../../api/api.generated'
import Button from '../UI/Buttons/Button'
import { VStack } from '../../global/styles'
import ChatBubble from '../ChatBubble'
import LoadingSpinner from '../LoadingSpinner'
import { 
  ScrollWrapper, 
  ShowMoreWrap
} from './styles'

const ChatMessageContainer = ({ items }) => {
  const { roomId } = useParams()
  const userId = useSelector(selectUserId)
  const [messagesStream, setMessagesStream] = useState([])
  const [firstMessage, setFirstMessage] = useState('')
  const [lastMessage, setLastMessage] = useState('')
  const [showMoreButton, setShowMoreButton] = useState(false)
  const [getRoomMessages, { data: roomMessages, isLoading }] = useLazyGetChatRoomByRoomIdMessagesQuery()
  const messagesStartRef = useRef(null)
  const messagesEndRef = useRef(null)

  const scrollToTop = () => {
    messagesStartRef.current?.scrollIntoView()
  }

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView()
  }

  const handleShowMore = () => {
    getRoomMessages({roomId, messageId: firstMessage})
  }
  
  const onMessageDelete = (messageId) => {
    const arr = messagesStream.filter(obj => obj.id !== messageId)
    setMessagesStream(arr)
  }

  useEffect(() => {
    if (roomId) {
      getRoomMessages({roomId, messageId: firstMessage})
    }
  }, [roomId])

  useEffect(() => {
    if (roomMessages) {
      setShowMoreButton(roomMessages.length === 20)
      setMessagesStream(mergeArraysBottom(messagesStream, roomMessages))
    }
  }, [roomMessages])

  useEffect(() => {
    if (messagesStream.length) {
      if (lastMessage !== messagesStream[messagesStream.length - 1].id) {
        scrollToBottom()
      } else if (firstMessage !== messagesStream[0].id) {
        scrollToTop()
      }
      setFirstMessage(messagesStream[0].id)
      setLastMessage(messagesStream[messagesStream.length - 1].id)
    }
  }, [messagesStream])

  useEffect(() => {
    if (!isEmpty(items)) {
      setMessagesStream((prev) => [...prev, items?.[0]?.chatMessage])
    }
  }, [items])

  return (
    <ScrollWrapper>
      <VStack>
        {isLoading && <LoadingSpinner />}
        <div ref={messagesStartRef} />
        {!isLoading && showMoreButton &&
        <ShowMoreWrap>
          <Button
            variant={BUTTON_VARIANTS.PRIMARY}
            size={BUTTONS_SIZES.SMALL}
            type="button"
            onClick={() => { handleShowMore() }}              
          >
            Show More
          </Button>
        </ShowMoreWrap>
        }        
        {messagesStream.length !== 0 &&
          messagesStream.map((message) => (
            <ChatBubble
              key={message.id}
              id={message.id}
              isOwnMessage={userId === message.userId}
              timeStamp={dayjs(message.timeStamp).fromNow()}
              messageImage={message.image}
              messageText={message.messageText}
              isViewed={message.viewed}
              shopId={message.shopLinkId}
              shopName={message.shopLinkName}
              onDelete={onMessageDelete}
            />
          ))}
        <div ref={messagesEndRef} />
      </VStack>
    </ScrollWrapper>
  )
}

export default ChatMessageContainer
ChatMessageContainer.defaultProps = {
  items: []
}
ChatMessageContainer.propTypes = {
  items: PropTypes.array
}
