import React, { useEffect, useState, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash/lang'
import { mergeArrays } from '../../../utils/helpers'
import { useLazyGetUserShopByShopIdPostsQuery } from '../../../api/api.generated'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import ShopPostItem from '../../../components/ShopPostItem'
import { 
  IsEmptyText, 
  PostsWrap 
} from './styles'

const ShopPosts = ({ shopId }) => {
  const [hasMore, setHasMore] = useState(true)
  const [page, setPage] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [posts, setPosts] = useState([])
  const observer = useRef();
  const [getUserShopByShopIdPosts, { data: { shopPosts } = {}, isLoading: loadingPosts }] = useLazyGetUserShopByShopIdPostsQuery()
  const ITEMS_PER_PAGE = 20

  const lastElementRef = useCallback(  
    (node) => {
      if (isLoading) return;
      if (!hasMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setPage((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore]
  );

  const handleShowMore = () => {
    setIsLoading(true)
    getUserShopByShopIdPosts({ shopId, page, limit: ITEMS_PER_PAGE })
  }

  useEffect(() => {
    handleShowMore()
  }, [])

  useEffect(() => {
    handleShowMore()
  }, [page])

  useEffect(() => {
    if (shopPosts) {
      setIsLoading(false)
      setHasMore(shopPosts.length === ITEMS_PER_PAGE)
      setPosts(mergeArrays(posts, shopPosts))
    }
  }, [shopPosts])

  return (
    <>
      {loadingPosts && <LoadingSpinner />}
      <PostsWrap>
        {isEmpty(posts) ? (
          <IsEmptyText variant="textM">No posts added yet</IsEmptyText>
        ) : (
          <>
            {posts.map((post) => (
              <ShopPostItem
                key={post.id}
                shopName={post.shopName}
                showShopName={false}
                postDeadline={post.startTime !== '' && post.endTime !== ''}
                deadlineText={post.deadlineText}
                requireCode={post.requireCode}
                postTitle={post.postTitle}
                postDescription={post.postDescription}
                postImage={post.postImage}
                postId={post.id}
                dateStart={post.startTime}
                dateEnd={post.endTime}
                showInterestedButton={post.isEvent}
                isInterested={post.isInterested}
                showLikeButton
                isLiked={post.isLiked}
                showAttendedButton={!post.isFinished}
                isAttended={post.isAttended}
              />
            ))}
            {isLoading &&
            <div>Loading...</div>
            }
            {!isLoading && hasMore &&
            <div ref={lastElementRef} /> 
            }            
          </>
        )}
      </PostsWrap>
    </>
  )
}

export default ShopPosts

ShopPosts.propTypes = {
  shopId: PropTypes.string.isRequired
}
