import styled from 'styled-components'
import { HStack, VStack } from '../../../../global/styles'
import Typography from '../../../../components/UI/Typography'

export const EvaluationWrap = styled(VStack)`
  flex-grow: 2;
  min-width: 70%;
`
export const DistanceWrap = styled(HStack)``
export const Distance = styled(Typography).attrs(({ theme, isShopName }) => ({
  color: isShopName ? theme.colors.skyBase : theme.colors.skyNeutral_2,
  variant: 'textM',
  bold: true
}))`
  :first-child {
    margin-right: 8px;
  }
`
export const Duration = styled(Typography).attrs(({ theme }) => ({
  color: theme.colors.skyNeutral2,
  variant: 'textS'
}))``
