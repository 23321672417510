import React, { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import {
  selectCurrentCityId,
  selectCurrentCityShops
} from '../../../store/selectors'
import { useLazyGetUserHomeScreenQuery } from '../../../api/api.generated'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import StoreOfTheWeek from '../../../components/StoreOfTheWeek'
import OrdersHeader from '../../orders/OrdersHeader'
import { ScreenContainer } from '../../../global/styles'
import {
  OrderContentWrapper, 
  OrdersWrap, 
  Section,
  SectionCaption,
 } from './styles'

const SubscribedStores = () => {
  const currentCityId = useSelector(selectCurrentCityId)
  const currentCityShops = useSelector(selectCurrentCityShops, shallowEqual)
  const [getHomeData, { data: homeData }] = useLazyGetUserHomeScreenQuery()
  useEffect(() => {
    if (currentCityId) {
      getHomeData({
        cityId: currentCityId
      })
    }
  }, [currentCityId])

  return (
    <ScreenContainer>
      <OrdersHeader title="Subscribed Stores" showBack />
      <OrderContentWrapper>
        <OrdersWrap>
          {!homeData && <LoadingSpinner />}
          {homeData && currentCityShops && false && (
            <Section>
              <SectionCaption>Store of the Week</SectionCaption>
              <StoreOfTheWeek shopId={homeData?.storeOfWeek?.[0]?.id} />
            </Section>
          )}
          {homeData && (
            <Section>
              {homeData.subscribed.map((subscribed) => (
                <StoreOfTheWeek
                  key={subscribed.shopId}
                  shopId={subscribed.shopId}
                />
              ))}
            </Section>
          )}
        </OrdersWrap>
      </OrderContentWrapper>
    </ScreenContainer>
  )
}

export default SubscribedStores
