import styled from 'styled-components'
import { HStack } from '../../global/styles'

export const Wrapper = styled(HStack)`
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  box-shadow: inset 0px -1px 0px rgba(208, 201, 214, 0.4);
  &:last-of-type {
    margin-bottom: 200px;
  }
`

export default Wrapper
