/*eslint-disable*/
import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useField } from 'formik'
import { CodeNumber, VerificationWrap } from './styles'

const pinBlueprint = [1, 2, 3, 4, 5]
const VerificationCodeInput = ({ name }) => {
  const [field] = useField({ name })
  const inputRef = useRef()
  const handleChange = (e) => {
    field.onChange(e)
  }
  useEffect(() => {
    inputRef.current.focus()
  })
  return (
    <>
      <VerificationWrap>
        {pinBlueprint.map((item, key) => (
          <CodeNumber key={item} isFilled={field.value.toString()[key]}>
            {field.value.toString()[key] ?? '0'}
          </CodeNumber>
        ))}
      </VerificationWrap>
      <input
        {...field}
        ref={inputRef}
        maxLength="5"
        autoComplete="off"
        onChange={handleChange}
        style={{ opacity: 0 }}
        type="text"
      />
    </>
  )
}

export default VerificationCodeInput

VerificationCodeInput.propTypes = {
  name: PropTypes.string.isRequired
}
