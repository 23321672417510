import React from 'react'
import { isEmpty } from 'lodash/lang'
import OrderListItem from '../../../components/OrderListItem'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import { useGetOrdersQuery } from '../../../api/api.generated'
import { ScreenContainer } from '../../../global/styles'
import OrdersHeader from '../OrdersHeader'
import { IsEmptyText, OrdersWrap, OrderContentWrapper } from './styles'

const OrdersScreenContainer = () => {
  const { data: { orders } = {}, isLoading, error } = useGetOrdersQuery()

  return (
    <ScreenContainer>
      <OrdersHeader title="Order History" showBack />
      <OrderContentWrapper>
        <OrdersWrap>
          {isLoading && <LoadingSpinner />}
          {error && <IsEmptyText variant="textM">Network Error!</IsEmptyText>}
          {!isLoading && !error && isEmpty(orders) && (
            <IsEmptyText variant="textM">No orders added yet.</IsEmptyText>
          )}
          {!isLoading && !error && !isEmpty(orders) && (
            <>
              {orders.map((order) => (
                <OrderListItem
                  key={order.id}
                  id={order.id}
                  code={order.code}
                  date={order.createdAt}
                  total={order.total}
                />
              ))}
            </>
          )}
        </OrdersWrap>
      </OrderContentWrapper>
    </ScreenContainer>
  )
}

export default OrdersScreenContainer

OrdersScreenContainer.propTypes = {}
