import React from 'react'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { ButtonWrapper, ModalHeadline, StyledForm } from './styles'
import Button from '../../UI/Buttons/Button'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES as BUTTON_SIZES
} from '../../../const/UIvariants'

const ChatMessageModal = ({ isOpen, onClose, message }) => (
  <Modal {...{ isOpen }}>
    <Formik>
      <StyledForm>
        <ModalHeadline>{message}</ModalHeadline>
        <ButtonWrapper>
          <Button
            variant={BUTTON_VARIANTS.OUTLINED}
            size={BUTTON_SIZES.MED}
            onClick={onClose}
            type="button"
          >
            Close
          </Button>
        </ButtonWrapper>
      </StyledForm>
    </Formik>
  </Modal>
)

export default ChatMessageModal

ChatMessageModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired
}
