import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  selectCurrentCityId
} from '../../../store/selectors'
import { useLazyGetUserHomeScreenQuery } from '../../../api/api.generated'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import StoreOfTheWeek from '../../../components/StoreOfTheWeek'
import OrdersHeader from '../../orders/OrdersHeader'
import { ScreenContainer } from '../../../global/styles'
import {
  OrderContentWrapper, 
  OrdersWrap, 
  Section,
 } from './styles'

const RecentlyVisited = () => {
  const currentCityId = useSelector(selectCurrentCityId)
  const [getHomeData, { data: homeData }] = useLazyGetUserHomeScreenQuery()
  useEffect(() => {
    if (currentCityId) {
      getHomeData({
        cityId: currentCityId
      })
    }
  }, [currentCityId])

  return (
    <ScreenContainer>
      <OrdersHeader title="Recently Visited" showBack />
      <OrderContentWrapper>
        <OrdersWrap>
          {!homeData && <LoadingSpinner />}
          {homeData && (
            <Section>
              {homeData.visited.map((subscribed) => (
                <StoreOfTheWeek
                  key={subscribed.shopId}
                  shopId={subscribed.shopId}
                />
              ))}
            </Section>
          )}
        </OrdersWrap>
      </OrderContentWrapper>
    </ScreenContainer>
  )
}

export default RecentlyVisited
