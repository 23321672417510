import styled from 'styled-components'
import { HStack, VStack } from '../../global/styles'
import Typography from '../UI/Typography'

export const PostWrap = styled(VStack)`
  min-width: 80%;
  width: 100%;
  position: relative;
  button:only-of-type {
    max-width: none;
  }
`
export const ButtonWrap = styled.div`
  margin-top: auto;
`

export const PostImage = styled.div`
  background: url(${({ backgroundImage }) => backgroundImage});
  background-size: cover;
  background-position: center;
  height: 96px;
  width: 100%;
  border-radius: 3px;
  min-width: 153px;
`

export const PostDetailsWrap = styled(VStack)`
  min-width: 40%;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
`
export const PostTitleWrap = styled(HStack)`
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`
export const PostDescription = styled(Typography)`
  margin-top: 12px;
  margin-bottom: 4px;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.skyNeutral};
`
export const PostTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.skyNeutral};
`

export const ButtonsWrapper = styled(HStack)`
  margin-top: 5px;
  margin-bottom: 24px;
  > *:not(:last-child) {
    margin-right: 8px;
  }
`
export const ShopLogoWrap = styled(HStack)`
  cursor: pointer;
  width: 70px;
`
export const ShopNameLabel = styled(Typography).attrs(({ theme }) => ({
  color: theme.colors.skyNeutral_2,
  variant: 'textS',
  bold: true
}))`
  text-align: center;
  cursor: pointer;
`