import styled from 'styled-components'
import { VStack } from '../../../global/styles'
import Typography from '../../../components/UI/Typography'

export const ProductsWrap = styled(VStack)`
  padding: 24px 16px;
`
export const IsEmptyText = styled(Typography)`
  color: ${({ theme }) => theme.colors.skyNeutral_1};
  text-align: center;
  align-self: center;
`
export const OnlineShop = styled(Typography)`
  color: ${({ theme }) => theme.colors.skyNeutral_1};
  margin-bottom: 12px;
`
