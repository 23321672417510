import React, { useEffect, useState, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { mergeArrays } from '../../../utils/helpers'
import { useLazyGetUserShopByShopIdQuestionsQuery } from '../../../api/api.generated'
import LoadingSpinner from '../../../components/LoadingSpinner'
import QuestionItem from '../../../components/QuestionItem'
import { QuestionsWrap } from './styles'

const ShopQuestions = ({ shopId }) => {
  const [hasMore, setHasMore] = useState(true)
  const [page, setPage] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [questions, setQuestions] = useState([])
  const observer = useRef();
  const [getUserShopByShopIdQuestions, { data: { shopQuestions } = {}, isLoading: loadingQuestions }] = useLazyGetUserShopByShopIdQuestionsQuery()
  const ITEMS_PER_PAGE = 20

  const lastElementRef = useCallback(  
    (node) => {
      if (isLoading) return;
      if (!hasMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setPage((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore]
  );

  const handleShowMore = () => {
    setIsLoading(true)
    getUserShopByShopIdQuestions({ shopId, page, limit: ITEMS_PER_PAGE })
  }

  useEffect(() => {
    handleShowMore()
  }, [])

  useEffect(() => {
    handleShowMore()
  }, [page])

  useEffect(() => {
    if (shopQuestions) {
      setIsLoading(false)
      setHasMore(shopQuestions.length === ITEMS_PER_PAGE)
      setQuestions(mergeArrays(questions, shopQuestions))
    }
  }, [shopQuestions])

  return (
    <>
      {loadingQuestions && <LoadingSpinner />}
      {questions && (
        <QuestionsWrap>
          {questions.map((item) => (
            <QuestionItem
              key={item.id}
              question={item.question}
              answer={item.answer}
            />
          ))}
        </QuestionsWrap>
      )}
      {isLoading &&
      <div>Loading...</div>
      }
      {!isLoading && hasMore &&
      <div ref={lastElementRef}>&nbsp;</div>
      }          
    </>
  )
}

export default ShopQuestions

ShopQuestions.propTypes = {
  shopId: PropTypes.string.isRequired
}
