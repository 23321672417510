import styled from 'styled-components'
import { Form } from 'formik'
import { VStack } from '../../../../global/styles'
import { pxToVh } from '../../../../utils/styles-utils'

export const CodeVerificationWrap = styled(VStack)`
  margin-top: ${pxToVh(84)};
  padding: 0 24px 24px 24px;
  height: calc(100% - ${pxToVh(84)});
  justify-content: flex-start;
`
export const MessageWrap = styled(VStack)`
  margin: ${pxToVh(24)} auto ${pxToVh(32)} auto;
  > * {
    margin-bottom: 12px;
  }
`

export const StyledForm = styled(Form)`
  width: 100%;
  margin-top: 24vh;
`
