import * as React from 'react'

function FacebookIcon(props) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={18} cy={18} r={18} fill="#2568EB" />
      <path
        d="M18.001 9.668a8.333 8.333 0 00-1.302 16.565V20.41h-2.116V18h2.116v-1.835c0-2.09 1.244-3.242 3.147-3.242.912 0 1.867.162 1.867.162v2.05h-1.054c-1.033 0-1.356.643-1.356 1.302v1.562h2.309l-.37 2.41h-1.94v5.823a8.333 8.333 0 00-1.301-16.564z"
        fill="#fff"
      />
    </svg>
  )
}

export default FacebookIcon
