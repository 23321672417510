import React, { useState } from 'react'
import { keyBy } from 'lodash'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash/lang'
import {DonationsWrap, ButtonWrap } from './styles'
import {
  useGetTagsQuery,
  useGetUserShopByShopIdAboutQuery
} from '../../../api/api.generated'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { TAG_ICONS } from '../../../const/app'
import { IsEmptyText } from '../ShopPosts/styles'
import DonationItem from '../../../components/DonationItem'
import Button from '../../../components/UI/Buttons/Button'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES as BUTTON_SIZES
} from '../../../const/UIvariants'
import DonateModal from '../../../components/Modals/DonateModal'

const ShopDonations = ({ shopId }) => {
  const [donateModalIsOpen, setDonateModalIsOpen] = useState(false)
  const { shopDonations, notTakenDonations, isLoading } = useGetUserShopByShopIdAboutQuery(
    { shopId },
    {
      selectFromResult: ({ data }) => ({
        shopDonations: data?.shopData?.donations,
        notTakenDonations: data?.shopData?.notTakenDonations
      })
    }
  )
  const { tagsById } = useGetTagsQuery(undefined, {
    selectFromResult: ({ data }) => ({ tagsById: keyBy(data?.tags, 'id') })
  })

  const handleOpenDonate = () => {
    setDonateModalIsOpen(true)
  }

  const handleCloseDonate = () => {
    setDonateModalIsOpen(false)
  }

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <DonateModal
        isOpen={donateModalIsOpen}
        onClose={handleCloseDonate}
        shopId={shopId}
      />
      <ButtonWrap>
        <Button
          variant={BUTTON_VARIANTS.OUTLINED}
          size={BUTTON_SIZES.MED}
          onClick={handleOpenDonate}
        >
          Donate
        </Button>
      </ButtonWrap>      
      {isEmpty(shopDonations) && (
        <IsEmptyText>This store doesn&apos;t have any donations</IsEmptyText>
      )}
      {shopDonations && tagsById && (
        <DonationsWrap>
          {shopDonations.map((donation) => (
            <DonationItem
              id={donation.id}
              key={donation.tag_id}
              backgroundColor={tagsById[donation.tag_id]?.backgroundColor}
              icon={TAG_ICONS[tagsById[donation.tag_id]?.icon]}
              tagName={donation.tag_name}
              height={donation.height}
              width={donation.width}
              length={donation.length}
              priority={donation.priority_name}
              isTaken
            />
          ))}
        </DonationsWrap>
      )}
      {notTakenDonations && tagsById && (
        <DonationsWrap>
          {notTakenDonations.map((donation) => (
            <DonationItem
              id={donation.id}
              key={donation.id}
              backgroundColor={tagsById[donation.id]?.backgroundColor}
              icon={TAG_ICONS[tagsById[donation.id]?.icon]}
              tagName={donation.tagName}
              isTaken={false}
            />
          ))}
        </DonationsWrap>
      )}
    </>
  )
}

export default ShopDonations

ShopDonations.propTypes = {
  shopId: PropTypes.string.isRequired
}
