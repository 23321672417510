import React from 'react'
import { useHistory } from 'react-router-dom'
import { LogoWrap } from './styles'
import OphopLogo from '../../assets/vector/OphopLogo'
import LinkButton from '../UI/Buttons/LinkButton'
import ROUTES from '../../const/ROUTES'

const AuthHeader = () => {
  const history = useHistory()
  return (
    <LogoWrap>
      <OphopLogo />
      <LinkButton
        onClick={() => {
          history.push(ROUTES.LOGIN)
        }}
      >
        back to Login
      </LinkButton>
    </LogoWrap>
  )
}

export default AuthHeader

AuthHeader.propTypes = {}
