import React from 'react'
import { withTheme } from 'styled-components'
import PropTypes from 'prop-types'
import { TabWrap, CaptionWrap, Count } from './styles'
import Typography from '../UI/Typography'

const MainTab = ({ isActive, icon, label, theme, count, handleClick }) => (
  <TabWrap onClick={handleClick}>
    {React.cloneElement(icon, {
      color: isActive ? theme.colors.skyBase : theme.colors.skyNeutral2
    })}
    <CaptionWrap>
    <Typography
      variant="textXS"
      color={isActive ? theme.colors.skyBase : theme.colors.skyNeutral2}
    >
      {label}
    </Typography>
    {count > 0 &&
    <Count>{count}</Count>
    }
    </CaptionWrap>

  </TabWrap>
)

export default withTheme(MainTab)

MainTab.defaultProps = {
  count: null
}

MainTab.propTypes = {
  theme: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired,
  icon: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  count: PropTypes.number
}
