import React from 'react'
import ImageUploading from 'react-images-uploading'
import { Attachment, Send } from '@styled-icons/material'
import {
  AttachButtonWrap,
  ChatInputWrap,
  Container,
  InputMessage,
  InputWrap,
  SentButton
} from './styles'
import IconButton from '../UI/Buttons/IconButton'
import useChatInput from '../../hooks/friends/useChatInput'

const ChatRoomFooter = () => {
  const { messageText, setMessageText, handleSendMessage, handleSelectImage } =
    useChatInput()
  return (
    <Container>
      <ChatInputWrap>
        <InputWrap>
          <InputMessage
            value={messageText}
            onChange={(e) => {
              setMessageText(e.target.value)
            }}
            placeholder="Enter Message"
          />
          <AttachButtonWrap>
            <ImageUploading
              value={[]}
              onChange={handleSelectImage}
              acceptType={['jpg', 'gif', 'png']}
              maxFileSize={4000000}
              dataURLKey="data_url"
            >
              {({ onImageUpload }) => (
                <IconButton size={24} onClick={onImageUpload}>
                  <Attachment size={24} color="#A0A7B5" />
                </IconButton>
              )}
            </ImageUploading>
          </AttachButtonWrap>
        </InputWrap>
        <SentButton onClick={handleSendMessage}>
          <Send size={24} color="#ffffff" />
        </SentButton>
      </ChatInputWrap>
    </Container>
  )
}

export default ChatRoomFooter

ChatRoomFooter.propTypes = {}
