import styled from 'styled-components'
import Typography from '../../../components/UI/Typography'
import { HStack, ScreenContainer, VStack } from '../../../global/styles'

export const WelcomeTitle = styled(Typography).attrs({
  variant: 'headingXL'
})`
  color: ${({ theme }) => theme.colors.skyNeutral_1};
  text-align: center;
  margin-bottom: 24px;
`

export const WelcomeDescription = styled(Typography).attrs({
  variant: 'headlineS'
})`
  color: ${({ theme }) => theme.colors.skyNeutral2};
  text-align: center;
`

export const MessageWrap = styled(VStack)`
  align-items: center;
  justify-content: flex-start;
  padding: 0 24px;
`

export const ButtonsWrapper = styled(VStack)`
  padding: 0 24px;
  > :first-child {
    margin-bottom: 12px;
  }
`

export const StyledScreenContainer = styled(ScreenContainer)`
  height: 100%;
  justify-content: space-between;
  padding: 58px 0;
`
export const CarouselItem = styled(VStack)`
  justify-content: space-between;
  align-items: center;
  height: 60vh;
  max-width: 100vw;
`
export const IndicatorsWrapper = styled(HStack)`
  align-items: center;
  justify-content: center;
`
export const CarouselIndicator = styled.div`
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: ${({ theme, isActive }) =>
    isActive ? theme.colors.skyBase : theme.colors.skyNeutral3};
  border-radius: 20px;
`
