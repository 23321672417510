import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { usePostChatRoomByRoomIdMessageMutation } from '../../api/api.generated'
import { getImageBase64 } from '../../utils/helpers'

const useChatInput = () => {
  const [messageText, setMessageText] = useState('')
  const { roomId } = useParams()
  const [postNewMessage] = usePostChatRoomByRoomIdMessageMutation()
  const handleSendMessage = () => {
    if (messageText) {
      setMessageText('')
      postNewMessage({ roomId, body: { message: messageText } })
    }
  }

  const handleSelectImage = (imageList) => {
    const selectedImage = getImageBase64({ source: imageList })
    if (selectedImage) {
      postNewMessage({ roomId, body: { message: null, image: selectedImage } })
    }
  }
  return { messageText, setMessageText, handleSendMessage, handleSelectImage }
}
export default useChatInput
