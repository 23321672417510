import styled from 'styled-components'
import { HStack } from '../../../global/styles'
import { pxToVh } from '../../../utils/styles-utils'

// eslint-disable-next-line import/prefer-default-export
export const SearchAndCenterWrap = styled(HStack)`
  flex-wrap: nowrap;
  justify-content: space-between;
  z-index: 30;
  position: fixed;
  align-items: center;
  top: ${pxToVh(55)};
  padding: 0 16px;
`
