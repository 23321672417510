import styled from 'styled-components'
import { pxToVh } from '../../../utils/styles-utils'
import Typography from '../../../components/UI/Typography'
import { VStack } from '../../../global/styles'

export const LoaderWrap = styled(VStack)`
  margin-top: ${pxToVh(120)};
  padding: 20px;
  > * {
    > *:first-child {
      margin-right: 12px;
    }
  }
`
export const IsEmptyText = styled(Typography)`
  color: ${({ theme }) => theme.colors.skyNeutral_1};
  text-align: center;
  align-self: center;
`
