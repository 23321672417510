import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Formik } from 'formik'
import dayjs from 'dayjs'
import { isNull } from 'lodash/lang'
import { useDispatch, useSelector } from 'react-redux'
import { CodeVerificationWrap, MessageWrap, StyledForm } from './styles'
import ROUTES from '../../../../const/ROUTES'
import {
  AuthDescription,
  AuthHeadline,
  ScreenContainer
} from '../../../../global/styles'
import VerificationCodeInput from '../../../../components/UI/Inputs/VerificationCodeInput'
import { BUTTON_VARIANTS, BUTTONS_SIZES } from '../../../../const/UIvariants'
import Button from '../../../../components/UI/Buttons/Button/Button'
import AuthHeader from '../../../../components/AuthHeader'
import SubmitOnFilled from './SubmitOnFilled'
import { setLastRestoreRequestTime } from '../../authSlice'
import {
  usePostUserRestorePasswordMutation,
  usePostUserVerificationCodeMutation
} from '../../../../api/api.generated'

const CodeVerification = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [timeToRetry, setTimeToRetry] = useState(0)
  const [requestPasswordRestore, { isLoading: sendingCode }] =
    usePostUserRestorePasswordMutation()
  const [sendCode, { isLoading: checkingCode }] =
    usePostUserVerificationCodeMutation()
  const lastRestoreRequestTime = useSelector(
    (state) => state.auth.lastRestoreRequestTime
  )

  const restoreRequestPhoneNumber = useSelector(
    (state) => state.auth.restoreRequestPhoneNumber
  )

  const handleSubmit = (values, { setFieldError, resetForm }) => {
    sendCode({
      body: { code: values.code, phoneNumber: restoreRequestPhoneNumber }
    })
      .unwrap()
      .then(() => {
        history.push(ROUTES.CREATE_NEW_PASSWORD)
      })
      .catch(() => {
        resetForm()
        setFieldError('code', 'Code is not correct')
      })
  }

  const handleResendCode = () => {
    const timePassedFromLastTry = Math.abs(
      dayjs(lastRestoreRequestTime).diff(new Date(), 'second')
    )
    setTimeToRetry(timePassedFromLastTry < 60 ? 60 - timePassedFromLastTry : 0)

    if (isNull(lastRestoreRequestTime) || timePassedFromLastTry > 60) {
      dispatch(setLastRestoreRequestTime())
      requestPasswordRestore({
        body: { phoneNumber: restoreRequestPhoneNumber }
      })
        .unwrap()
        .then(() => {
          dispatch(setLastRestoreRequestTime())
        })
    }
  }

  return (
    <ScreenContainer>
      <CodeVerificationWrap>
        <AuthHeader />
        <Formik
          initialValues={{
            code: ''
          }}
          onSubmit={handleSubmit}
        >
          {({ errors }) => (
            <StyledForm>
              <SubmitOnFilled />
              <MessageWrap>
                <AuthHeadline>Enter Verification code</AuthHeadline>
                <AuthDescription>Enter your verification code </AuthDescription>
              </MessageWrap>
              <VerificationCodeInput name="code" />
              <Button
                variant={BUTTON_VARIANTS.PRIMARY}
                size={BUTTONS_SIZES.MED}
                type="button"
                isLoading={sendingCode || checkingCode}
                onClick={handleResendCode}
              >
                Resend Code
              </Button>
              {errors.code && <p> {errors.code}</p>}
            </StyledForm>
          )}
        </Formik>

        {timeToRetry !== 0 && (
          <AuthDescription align="center" style={{ marginTop: '24px' }}>
            Too many attempts, please try in {timeToRetry} seconds
          </AuthDescription>
        )}
      </CodeVerificationWrap>
    </ScreenContainer>
  )
}

export default CodeVerification

CodeVerification.propTypes = {}
