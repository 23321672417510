import React, { useState } from 'react'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as Yup from 'yup'
import {
  INPUT_SIZES,
  BUTTON_VARIANTS,
  BUTTONS_SIZES
} from '../../../const/UIvariants'
import TextInput from '../../UI/Inputs/TextInput'
import Button from '../../UI/Buttons/Button'
import Typography from '../../UI/Typography'
import { getErrorMessage } from '../../../utils/helpers'
import { usePostShopPostAttendedMutation } from '../../../api/api.generated'
import { ButtonWrapper, ModalHeadline, StyledForm, ErrorWrap } from './styles'

const SendCodeModal = ({ isOpen, onClose, title, postId }) => {
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [postShopPostAttended] = usePostShopPostAttendedMutation()

  const handleSubmit = (values) => {
    setError('')
    setIsLoading(true)

    postShopPostAttended({
      postId,
      body: { isAttended: true, code: values.code }
    })
    .unwrap()
    .then(() => {
      onClose()
    })
    .catch((err) => {
      setIsLoading(false)
      setError(getErrorMessage(err))
    })
  }  

  return (
    <Modal {...{ isOpen }}>
      <Formik
        initialValues={{
          code: ''
        }}
        validationSchema={Yup.object({
          code: Yup.string().max(20, 'Code is too long').required('Code is required')
        })}        
        onSubmit={handleSubmit}
      >
        <StyledForm>
          <ModalHeadline>{title}</ModalHeadline>
          <TextInput size={INPUT_SIZES.SMALL} placeholder="Enter Code" inputName="code" />
          {error && (
            <ErrorWrap>
              <Typography variant="textS" color="red">
                {error}
              </Typography>
            </ErrorWrap>          
           )}
          <ButtonWrapper>
            <Button
              variant={BUTTON_VARIANTS.PRIMARY}
              size={BUTTONS_SIZES.MED}
              type="submit"
              isLoading={isLoading}
            >
              Send
            </Button>
            <Button
              variant={BUTTON_VARIANTS.OUTLINED}
              size={BUTTONS_SIZES.MED}
              onClick={onClose}
              type="button"
            >
              Cancel
            </Button>
          </ButtonWrapper>
        </StyledForm>
      </Formik>
    </Modal>
  )
}

export default SendCodeModal

SendCodeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  postId: PropTypes.string.isRequired
}
