/*eslint-disable*/
import { H } from '../../config/hereWego'
import { ORGANIZATION_ICONS } from '../../assets/OrganizationIcon/OrganizationIcon'

const defaultImage = 'https://lorempixel.com/500/500/sports/'

const defaultShop = (image) => {
  if (image) {
    return `<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  preserveAspectRatio="xMidYMid">
<path d="M1 15C1 7.26801 7.26801 1 15 1C22.732 1 29 7.26801 29 15C29 22.732 22.732 29 15 29C7.26801 29 1 22.732 1 15Z" fill="white"/>
<circle  id="myClip" cx="15" cy="15" r="14" fill="url(#pattern0)"/>
<path d="M15 28C7.8203 28 2 22.1797 2 15H0C0 23.2843 6.71573 30 15 30V28ZM28 15C28 22.1797 22.1797 28 15 28V30C23.2843 30 30 23.2843 30 15H28ZM15 2C22.1797 2 28 7.8203 28 15H30C30 6.71573 23.2843 0 15 0V2ZM15 0C6.71573 0 0 6.71573 0 15H2C2 7.8203 7.8203 2 15 2V0Z" fill="#D9DCE2"/>
 <image id="image0"  height='30' viewBox='0 0 30 30'  clip-path="url(#shape)" xlink:href=${image} />
<defs>
    <clipPath id="shape">
      <circle  cx="15" cy="15" r="13" />
    </clipPath>
  </defs>
</svg>
`
  }
  return `<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  preserveAspectRatio="xMidYMid">
<path d="M1 15C1 7.26801 7.26801 1 15 1C22.732 1 29 7.26801 29 15C29 22.732 22.732 29 15 29C7.26801 29 1 22.732 1 15Z" fill="white"/>
<circle  id="myClip" cx="15" cy="15" r="14" fill="url(#pattern0)"/>
<path d="M15 28C7.8203 28 2 22.1797 2 15H0C0 23.2843 6.71573 30 15 30V28ZM28 15C28 22.1797 22.1797 28 15 28V30C23.2843 30 30 23.2843 30 15H28ZM15 2C22.1797 2 28 7.8203 28 15H30C30 6.71573 23.2843 0 15 0V2ZM15 0C6.71573 0 0 6.71573 0 15H2C2 7.8203 7.8203 2 15 2V0Z" fill="#D9DCE2"/>
$<g width="16" height="16" viewBox="0 0 30 30"  transform='translate(6,6) scale(.8)' fill="none">
  <path  d="M22.6667 0.333496H1.33333V3.00016H22.6667V0.333496ZM24 13.6668V11.0002L22.6667 4.3335H1.33333L0 11.0002V13.6668H1.33333V21.6668H14.6667V13.6668H20V21.6668H22.6667V13.6668H24ZM12 19.0002H4V13.6668H12V19.0002Z" fill="#D9DCE2"/>
</g>
</svg>`
}

const inRouteShop = (image) => {
  if (image) {
    return `<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  preserveAspectRatio="xMidYMid">
<path d="M1 15C1 7.26801 7.26801 1 15 1C22.732 1 29 7.26801 29 15C29 22.732 22.732 29 15 29C7.26801 29 1 22.732 1 15Z" fill="white"/>
<circle  id="myClip" cx="15" cy="15" r="14" fill="url(#pattern0)"/>
<path d="M15 28C7.8203 28 2 22.1797 2 15H0C0 23.2843 6.71573 30 15 30V28ZM28 15C28 22.1797 22.1797 28 15 28V30C23.2843 30 30 23.2843 30 15H28ZM15 2C22.1797 2 28 7.8203 28 15H30C30 6.71573 23.2843 0 15 0V2ZM15 0C6.71573 0 0 6.71573 0 15H2C2 7.8203 7.8203 2 15 2V0Z" fill="#2D6CE4"/>
 <image id="image0"  height='30' viewBox='0 0 30 30'  clip-path="url(#shape)" xlink:href=${image} />
<defs>
    <clipPath id="shape">
      <circle  cx="15" cy="15" r="13" />
    </clipPath>
  </defs>
</svg>
`
  }
  return `<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  preserveAspectRatio="xMidYMid">
<path d="M1 15C1 7.26801 7.26801 1 15 1C22.732 1 29 7.26801 29 15C29 22.732 22.732 29 15 29C7.26801 29 1 22.732 1 15Z" fill="white"/>
<circle  id="myClip" cx="15" cy="15" r="14" fill="url(#pattern0)"/>
<path d="M15 28C7.8203 28 2 22.1797 2 15H0C0 23.2843 6.71573 30 15 30V28ZM28 15C28 22.1797 22.1797 28 15 28V30C23.2843 30 30 23.2843 30 15H28ZM15 2C22.1797 2 28 7.8203 28 15H30C30 6.71573 23.2843 0 15 0V2ZM15 0C6.71573 0 0 6.71573 0 15H2C2 7.8203 7.8203 2 15 2V0Z" fill="#D9DCE2"/>
<g width="16" height="16" viewBox="0 0 30 30"  transform='translate(6,6) scale(.8)' fill="none">
  <path  d="M22.6667 0.333496H1.33333V3.00016H22.6667V0.333496ZM24 13.6668V11.0002L22.6667 4.3335H1.33333L0 11.0002V13.6668H1.33333V21.6668H14.6667V13.6668H20V21.6668H22.6667V13.6668H24ZM12 19.0002H4V13.6668H12V19.0002Z" fill="#D9DCE2"/>
</g>
</svg>`
}

const nonRouteShop = (image) => {
  if (image) {
    return `<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  preserveAspectRatio="xMidYMid">
<g  transform='scale(.7)' opacity="0.7">
<path d="M1 15C1 7.26801 7.26801 1 15 1C22.732 1 29 7.26801 29 15C29 22.732 22.732 29 15 29C7.26801 29 1 22.732 1 15Z" fill="white"/>
<circle  id="myClip" cx="15" cy="15" r="14" fill="url(#pattern0)"/>
<path d="M15 28C7.8203 28 2 22.1797 2 15H0C0 23.2843 6.71573 30 15 30V28ZM28 15C28 22.1797 22.1797 28 15 28V30C23.2843 30 30 23.2843 30 15H28ZM15 2C22.1797 2 28 7.8203 28 15H30C30 6.71573 23.2843 0 15 0V2ZM15 0C6.71573 0 0 6.71573 0 15H2C2 7.8203 7.8203 2 15 2V0Z" fill="D9DCE2"/>
 <image id="image0"  height='30' viewBox='0 0 30 30'  clip-path="url(#shape)" xlink:href=${image} />
<defs>
    <clipPath id="shape">
      <circle  cx="15" cy="15" r="13" />
    </clipPath>
  </defs></g>
</svg>
`
  }
  return `<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  preserveAspectRatio="xMidYMid">
<path d="M1 15C1 7.26801 7.26801 1 15 1C22.732 1 29 7.26801 29 15C29 22.732 22.732 29 15 29C7.26801 29 1 22.732 1 15Z" fill="white"/>
<circle  id="myClip" cx="15" cy="15" r="14" fill="url(#pattern0)"/>
<path d="M15 28C7.8203 28 2 22.1797 2 15H0C0 23.2843 6.71573 30 15 30V28ZM28 15C28 22.1797 22.1797 28 15 28V30C23.2843 30 30 23.2843 30 15H28ZM15 2C22.1797 2 28 7.8203 28 15H30C30 6.71573 23.2843 0 15 0V2ZM15 0C6.71573 0 0 6.71573 0 15H2C2 7.8203 7.8203 2 15 2V0Z" fill="D9DCE2"/>
<g width="16" height="16" viewBox="0 0 30 30"  transform='translate(6,6) scale(.8)' fill="none">
  <path  d="M22.6667 0.333496H1.33333V3.00016H22.6667V0.333496ZM24 13.6668V11.0002L22.6667 4.3335H1.33333L0 11.0002V13.6668H1.33333V21.6668H14.6667V13.6668H20V21.6668H22.6667V13.6668H24ZM12 19.0002H4V13.6668H12V19.0002Z" fill="#D9DCE2"/>
</g>
</svg>`
}
const defineIcon = (isInCurrentRoute, routingIsActive, img) => {
  if (isInCurrentRoute) {
    return inRouteShop(img)
  }
  if (routingIsActive && !isInCurrentRoute) {
    return nonRouteShop(img)
  }
  return defaultShop(img)
}
// Create an icon object, an object with geographic coordinates and a marker:
const ShopMarker = ({ lat, lng, img, isInCurrentRoute, routingIsActive, organizationIcon, shopId, handleClick }) => {
  const icon = new H.map.DomIcon(
    ORGANIZATION_ICONS[organizationIcon]
  )

  /*
  const icon = new H.map.DomIcon(
    defineIcon(isInCurrentRoute, routingIsActive, img)
  )
  */

  const tapEvent = (evt) => {
    handleClick(shopId)
  }

  const marker = new H.map.DomMarker({ lat, lng }, { icon })
  marker.addEventListener('tap', tapEvent)
  return marker
}

export default ShopMarker
