import styled from 'styled-components'
import { HStack, VStack } from '../../global/styles'
import Typography from '../UI/Typography'

export const Wrapper = styled(HStack)`
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  box-shadow: inset 0px -1px 0px rgba(208, 201, 214, 0.4);
  &:last-of-type {
    margin-bottom: 200px;
  }
`
export const DetailsWrap = styled(VStack)`
  justify-content: flex-start;
`
export const OrderCode = styled(Typography).attrs({
  variant: 'textM'
})`
  color: ${({ theme }) => theme.colors.skyNeutral};
`
export const OrderDate = styled(Typography).attrs({
  variant: 'textS'
})`
  color: ${({ theme }) => theme.colors.skyNeutral2};
`
export const IconWrap = styled(HStack)`
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border-radius: 24px;
  background: ${({ theme }) => theme.colors.skyNeutral_1};
`
