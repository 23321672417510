/*eslint-disable*/
import React, { useEffect } from 'react'
import { Distance, DistanceWrap, Duration, EvaluationWrap } from './styles'
import {
  selectCurrentCityShops,
  selectCurrentDestinationIndex,
  selectCurrentLocation,
  selectEvaluatedDistances,
  selectEvaluatedTravelTime,
  selectHopRoute
} from '../../../../store/selectors'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import useCreateMapRoute from '../../../../hooks/route/useCreateMapRoute'
import { usePostCalculateDistanceMatrixMutation } from '../../../../api/api.generated'
import { formatDistance, formatTime } from '../../../../utils/map-utils'
import { truncateLongShopName } from '../../../../utils/helpers'
import dayjs from 'dayjs'
import useRouteShopReached from '../../../../hooks/route/useRouteShopReached'
import { evaluateTravelDistances } from '../../hopSlice'
import useEvaluateRouteDistances from '../../../../hooks/route/useEvaluateRouteDistances'

const EvaluatedTimeAndDistance = () => {
  useRouteShopReached()
  useEvaluateRouteDistances()
  const route = useSelector(selectHopRoute, shallowEqual)
  const shops = useSelector(selectCurrentCityShops, shallowEqual)
  const currentDestinationIndex = useSelector(selectCurrentDestinationIndex)
  const evaluatedDistances = useSelector(selectEvaluatedDistances, shallowEqual)
  const evaluatedTravelTime = useSelector(
    selectEvaluatedTravelTime,
    shallowEqual
  )

  return (
    <EvaluationWrap>
      <DistanceWrap>
        <Distance>
          {formatDistance(evaluatedDistances[currentDestinationIndex])} to
        </Distance>
        <Distance isShopName>
          {truncateLongShopName(
            shops?.[route?.[currentDestinationIndex]?.shopId]?.storeName,
            15
          )}
        </Distance>
      </DistanceWrap>
      <Duration>
        {formatTime(evaluatedTravelTime[currentDestinationIndex])}
      </Duration>
    </EvaluationWrap>
  )
}

export default EvaluatedTimeAndDistance

EvaluatedTimeAndDistance.propTypes = {}
