import styled from 'styled-components'
import { HStack } from '../../global/styles'
import Typography from '../UI/Typography'

export const MenuItemLabel = styled(Typography).attrs({
  variant: 'headingS'
})`
  color: ${({ theme }) => theme.colors.skyNeutral};
`
export const MenuItemCellWrap = styled(HStack)`
  padding: 16px 17px 16px 20px;
  justify-content: space-between;
  box-shadow: inset 0px -1px 0px rgba(208, 201, 214, 0.4);
  &:active {
    background: ${({ theme }) => theme.colors.skyNeutral4};
  }
`
export const Count = styled(Typography).attrs(({ theme }) => ({
  color: theme.colors.white,
  variant: 'textXS',
  bold: true
}))`
  min-width: 20px;
  height: 20px;
  line-height: 19px;
  text-align: center;
  margin-left: 6px;
  padding: 0 6px;
  background-color: #cf713b;
  border-radius: 14px;
`
