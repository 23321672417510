import styled from 'styled-components'
import Typography from '../../../components/UI/Typography'

export const Headline = styled(Typography).attrs({
  variant: 'textM',
  bold: true
})`
  color: ${({ theme }) => theme.colors.skyNeutral1};
  margin-bottom: 18px;
`
export const CitiesWrap = styled.div`
  padding-bottom: 50px;
`
