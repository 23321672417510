import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  closeAddToFavoritesModal,
  openAddToFavoritesModal
} from '../../features/app/appSlice'
import { usePostUserToggleFavoriteByShopIdMutation } from '../../api/api.generated'
import { selectShopById } from '../../store/selectors'

const useAddToFavorite = ({ shopId }) => {
  const dispatch = useDispatch()
  const shopData = useSelector(selectShopById(shopId), shallowEqual)
  // const showAddToFavoriteModal = useSelector(
  //   (state) => state.app.showAddToFavoriteModal
  // )
  const [removeFromFavorites] = usePostUserToggleFavoriteByShopIdMutation()

  // useEffect( () => {  },[shopData.isFavorite, shopData.favoriteText])
  const handleRemoveFromFav = () => {
    removeFromFavorites({
      shopId,
      body: { reasonOfAdding: '', isFavorite: false }
    })
      .unwrap()
      .then(() => {
        dispatch(closeAddToFavoritesModal())
      })
  }

  const handleToggleAddToFavModal = () => {
    dispatch(openAddToFavoritesModal({ selectedShopId: shopId }))
  }

  const toggleAddToFavorite = () => {
    if (shopData.isFavorite) {
      handleRemoveFromFav()
    } else {
      handleToggleAddToFavModal()
    }
  }
  return {
    toggleAddToFavorite,
    isFavorite: shopData.isFavorite,
    favoriteText: shopData.favoriteText
  }
}
export default useAddToFavorite
