import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash/lang'
import {
  selectCurrentCityShops,
  selectPreviewRouteShopId
} from '../../store/selectors'
import useCreateMapRoute from './useCreateMapRoute'
import {
  replaceRouteShops,
  setPreviewRouteShopId
} from '../../features/hop/hopSlice'

const usePreviewShopRoute = () => {
  const dispatch = useDispatch()
  const previewRouteId = useSelector(selectPreviewRouteShopId)
  const cityShops = useSelector(selectCurrentCityShops, shallowEqual)
  const { stopRoute, buildAndStartRoute } = useCreateMapRoute()
  const [shopToGo, setShopToGo] = useState(null)

  useEffect(() => {
    if (previewRouteId && !isEmpty(cityShops)) {
      setShopToGo({
        shopId: previewRouteId,
        shopLat: cityShops[previewRouteId].location.lat,
        shopLng: cityShops[previewRouteId].location.lng
      })
    }
  }, [previewRouteId])

  const previewRouteToShop = (shopId) => {
    dispatch(setPreviewRouteShopId(shopId))
  }

  const startRouteToShop = () => {
    if (shopToGo) {
      buildAndStartRoute([shopToGo])
      dispatch(replaceRouteShops([shopToGo]))
      dispatch(setPreviewRouteShopId(null))
    }
  }
  const closePreview = () => {
    dispatch(setPreviewRouteShopId(null))
    stopRoute()
  }
  return {
    previewRouteToShop,
    startRouteToShop,
    closePreview,
    shopToGo: cityShops[previewRouteId]
  }
}

export default usePreviewShopRoute
