import React from 'react'
import { isEmpty } from 'lodash/lang'
import {
  useGetTagsQuery,
  useGetUserShoppingCartQuery
} from '../../../api/api.generated'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import OrdersHeader from '../../orders/OrdersHeader'
import ShoppingCartItems from '../ShoppingCartItems'
import { ScreenContainer } from '../../../global/styles'
import { IsEmptyText, LoaderWrap } from './styles'

const ShoppingCart = () => {
  const { data: { tags } = [], isLoading: loadingTags } = useGetTagsQuery(undefined, {} )
  const { data: { shops } = {}, isLoading } = useGetUserShoppingCartQuery()

  return (
    <ScreenContainer>
      <OrdersHeader title="Shopping Cart" showBack />
      {(loadingTags || isLoading) ? (
      <LoaderWrap>
        <LoadingSpinner />
      </LoaderWrap>
      ) : (
        isEmpty(shops) ? (
          <LoaderWrap>
            <IsEmptyText variant="textM">No products added yet.</IsEmptyText>
          </LoaderWrap>
        ) : (
          <ShoppingCartItems 
            tags={tags}
            shops={shops}
          />
        )
      )}
    </ScreenContainer>
  )
}

export default ShoppingCart
