import styled from 'styled-components'
import { HStack } from '../../global/styles'

export const Container = styled.div`
  height: 92px;
  width: 100%;
  box-shadow: inset 0 1px 0 rgba(208, 201, 214, 0.4);
  background: #ffffff;
  position: fixed;
  bottom: 0;
`

export const ChatInputWrap = styled(HStack)`
  position: relative;
  padding: 12px 16px;
`
export const InputWrap = styled(HStack)`
  position: relative;
  align-items: center;
`
export const InputMessage = styled.input`
  height: 56px;
  border-radius: 4px;
  font-size: 16px;
  background: ${({ theme }) => theme.colors.skyNeutral4};
  outline: none;
  border: none;
  width: 100%;
  margin-right: 12px;
  padding: 12px 50px 12px 12px;
  &:focus {
    box-shadow: 0 0 0 2px hsla(219, 12%, 51%, 1);
  }
`

export const AttachButtonWrap = styled.div`
  position: absolute;
  right: 30px;
  top: 16px;
  margin: auto 0;
`
export const SentButton = styled.button`
  min-width: 56px;
  height: 56px;
  background: ${({ theme }) => theme.colors.skyBase};
  border: none;
  outline: none;
  border-radius: 8px;
`
