/*eslint-disable*/
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { EventAvailable } from '@styled-icons/material'
import Button from '../UI/Buttons/Button/Button'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES as BUTTON_SIZES
} from '../../const/UIvariants'
import { usePostShopPostInterestedMutation } from '../../api/api.generated'

const InterestedButton = ({ postId, isInterested }) => {
  const [interested, setInterested] = useState(isInterested)
  const [postShopPostInterested] = usePostShopPostInterestedMutation()

  const handleClick = () => {
    postShopPostInterested({
      postId,
      body: { isInterested: !interested }
    })
    setInterested(!interested)
  }

  return (
    <Button
      variant={interested ? BUTTON_VARIANTS.PINK : BUTTON_VARIANTS.GREY}
      leftIcon={<EventAvailable />}
      size={BUTTON_SIZES.MED}
      onClick={handleClick}
    >
      Interested
    </Button>
  )
}

export default InterestedButton

InterestedButton.defaultProps = {
  isInterested: false
}

InterestedButton.propTypes = {
  postId: PropTypes.string.isRequired,
  isInterested: PropTypes.bool
}
