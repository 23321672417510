import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useCallback } from 'react'
import { selectIsSearchOpen } from '../../store/selectors'
import { setSearchIsOpen } from '../../features/search/searchSlice'
import ROUTES from '../../const/ROUTES'

const useToggleSearch = () => {
  const isSearchOpen = useSelector(selectIsSearchOpen)
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const openSearch = useCallback(() => {
    dispatch(setSearchIsOpen(true))
    history.push(location.pathname + ROUTES.SEARCH)
  }, [location.pathname])

  const closeSearch = useCallback(() => {
    dispatch(setSearchIsOpen(false))
    history.goBack()
  }, [])

  const toggleSearch = useCallback(() => {
    if (isSearchOpen) {
      closeSearch()
      return
    }
    openSearch()
  }, [])

  return { openSearch, closeSearch, toggleSearch }
}
export default useToggleSearch
