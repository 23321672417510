import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import {
  LastMessage,
  ContactName,
  ImageNameWrap,
  NameLocationWrap,
  Wrapper,
  TimeStamp,
  UnreadMessagesCount,
  StatusTimestampWrap,
  ContactNameWrap,
  Pronouns
} from './styles'
import UserAvatar from '../UserAvatar'
import useChatRoom from '../../hooks/friends/useChatRoom'

const MessagesListItem = ({
  id,
  contactName,
  preferredPronouns,
  contactAvatar,
  lastMessage,
  unreadMessages,
  lastMessageTimeStamp,
  bgColor,
  image
}) => {
  const { handleOpenChatRoom } = useChatRoom(null, id)
  return (
    <Wrapper onClick={handleOpenChatRoom}>
      <ImageNameWrap>
        <UserAvatar
          image={contactAvatar}
          size={40}
          userName={contactName}
          {...{ bgColor }}
        />
        <NameLocationWrap>
          <ContactNameWrap>
            <ContactName>{contactName}</ContactName>
            {preferredPronouns !== '' &&
            <Pronouns>({preferredPronouns})</Pronouns>
            }
          </ContactNameWrap>
          <LastMessage>{ (image) ? '🏙 image' : lastMessage }</LastMessage>
        </NameLocationWrap>
      </ImageNameWrap>
      <StatusTimestampWrap>
        {unreadMessages !== 0 && (
          <UnreadMessagesCount>{unreadMessages}</UnreadMessagesCount>
        )}
        <TimeStamp>
          {lastMessageTimeStamp && dayjs(lastMessageTimeStamp).fromNow()}
        </TimeStamp>
      </StatusTimestampWrap>
    </Wrapper>
  )
}

export default MessagesListItem

MessagesListItem.defaultProps = {
  contactAvatar: null,
  lastMessage: null,
  image: null,
  preferredPronouns: ''
}

MessagesListItem.propTypes = {
  id: PropTypes.string.isRequired,
  contactName: PropTypes.string.isRequired,
  preferredPronouns: PropTypes.string,
  bgColor: PropTypes.string.isRequired,
  lastMessage: PropTypes.string,
  contactAvatar: PropTypes.string,
  unreadMessages: PropTypes.number.isRequired,
  lastMessageTimeStamp: PropTypes.string.isRequired,
  image: PropTypes.string
}
