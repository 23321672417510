import styled from 'styled-components'

// eslint-disable-next-line import/prefer-default-export
export const StyledIconButton = styled.button`
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  cursor: pointer;
  min-width: ${({ minSize }) => minSize}px;
  min-height: ${({ minSize }) => minSize}px;
  margin: 0;
  flex-grow: 0;
  width: 24px;

  background: transparent;
  border-radius: 2px;
  border: none;
  transition: all 0.3s ease-in-out;
`
