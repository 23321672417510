import React from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import ROUTES from '../../const/ROUTES'
import Bubble from '../Bubble'
import { useDeleteChatMessageMutation } from '../../api/api.generated'

const ChatBubble = ({
  id,
  messageText,
  messageImage,
  shopId,
  shopName,
  isOwnMessage,
  timeStamp,
  isViewed,
  onDelete
}) => {
  const history = useHistory()
  const [deleteChatMessage] = useDeleteChatMessageMutation()

  const handleDelete = (messageId) => {
    deleteChatMessage(messageId)
    .unwrap()
      .then((res) => {
        if (res.roomDeleted) {
          history.push(ROUTES.FRIENDS)
        } else {
          onDelete(messageId)         
        }
      })
      .catch(() => {
        // error
      })      
  }

  return (
    <Bubble { ... {id, messageText, messageImage, shopId, shopName, isOwnMessage, timeStamp, isViewed, handleDelete} }/>
  )
}

export default React.memo(ChatBubble)
ChatBubble.defaultProps = {
  messageText: null,
  messageImage: null,
  shopId: null,
  onDelete: () => {}
}
ChatBubble.propTypes = {
  id: PropTypes.any.isRequired,
  messageText: PropTypes.string,
  messageImage: PropTypes.string,
  isOwnMessage: PropTypes.bool.isRequired,
  isViewed: PropTypes.bool.isRequired,
  timeStamp: PropTypes.string.isRequired,
  shopId: PropTypes.string,
  shopName: PropTypes.string.isRequired,
  onDelete: PropTypes.func
}
