/*eslint-disable*/
import React from 'react'
import ReactDOM from 'react-dom'
import {
  BadgesWrap,
  FilterContent,
  FilterHeader,
  FilterHeadline,
  FilterHeadlineWrap,
  FilterScreenContainer,
  FilterSection,
  FilterTitle
} from './styles'
import { ScreenContainer } from '../../../global/styles'
import { Close, Star } from '@styled-icons/material'
import { SHOPS_RATES } from '../../../const/app'
import Badge from '../../../components/UI/Badge'
import {
  BADGE_VARIANTS,
  BUTTON_VARIANTS,
  BUTTONS_SIZES
} from '../../../const/UIvariants'
import { useDispatch, useSelector } from 'react-redux'
import { selectTagsById } from '../../../store/selectors'
import IconButton from '../../../components/UI/Buttons/IconButton'
import { useHistory } from 'react-router-dom'
import Button from '../../../components/UI/Buttons/Button'
import useFilter from '../../../hooks/hop/useFilter'

const HopFilter = () => {
  const history = useHistory()
  const tags = useSelector(selectTagsById)
  const { selectedTags, selectedRates, handleToggleTag, handleToggleRate } =
    useFilter()

  return ReactDOM.createPortal(
    <FilterScreenContainer>
      <FilterHeader>
        <FilterHeadlineWrap>
          <FilterHeadline> Filters</FilterHeadline>
          <IconButton
            onClick={() => {
              history.goBack()
            }}
          >
            <Close color="#828282" size={18} />
          </IconButton>
        </FilterHeadlineWrap>
      </FilterHeader>
      <FilterContent>
        <FilterSection>
          <FilterTitle>SelectRating</FilterTitle>
          <BadgesWrap>
            {SHOPS_RATES.map((rate) => (
              <Badge
                key={rate}
                variant={BADGE_VARIANTS.LIGHT}
                onClick={() => {
                  handleToggleRate(rate)
                }}
                icon={<Star size={16} color="#F0C93F" />}
                isActive={selectedRates[rate]}
                label={`${rate}.0`}
              />
            ))}
          </BadgesWrap>
        </FilterSection>
        <FilterSection>
          <FilterTitle>Select Category</FilterTitle>
          <BadgesWrap>
            {Object.values(tags).map((tag) => (
              <Badge
                key={tag.id}
                variant={
                  selectedTags[tag.id]
                    ? BADGE_VARIANTS.DARK
                    : BADGE_VARIANTS.LIGHT
                }
                onClick={() => {
                  handleToggleTag(tag.id)
                }}
                label={tag.tagName}
              />
            ))}
          </BadgesWrap>
        </FilterSection>
        <Button
          onClick={() => {
            history.goBack()
          }}
          variant={BUTTON_VARIANTS.SECONDARY}
          size={BUTTONS_SIZES.LARGE}
        >
          Apply Filters
        </Button>
      </FilterContent>
    </FilterScreenContainer>,
    document.getElementById('root')
  )
}

export default HopFilter

HopFilter.propTypes = {}
