import React from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { ArrowForwardIos } from '@styled-icons/material'
import { HStack } from '../../global/styles'
import { MenuItemCellWrap, MenuItemLabel, Count } from './styles'

const MenuItemCell = ({ label, destination, count }) => {
  const { push } = useHistory()

  return (
    <MenuItemCellWrap
      onClick={() => {
        push(destination)
      }}
    >
      <HStack>
        <MenuItemLabel>{label}</MenuItemLabel>
        {count > 0 &&
        <Count>{count}</Count>
        }        
      </HStack>
      <ArrowForwardIos size={24} color="#A0A7B5" />
    </MenuItemCellWrap>
  )
}

export default MenuItemCell

MenuItemCell.defaultProps = {
  count: null
}

MenuItemCell.propTypes = {
  label: PropTypes.string.isRequired,
  destination: PropTypes.string.isRequired,
  count: PropTypes.number
}
