import styled from 'styled-components'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { pxToVh } from '../../../utils/styles-utils'
import { VStack } from '../../../global/styles'

// eslint-disable-next-line import/prefer-default-export
export const OrderContentWrapper = styled(OverlayScrollbarsComponent).attrs({
  options: {
    scrollbars: { visibility: 'hidden' }
  }
})`
  height: 100%;
  margin-top: ${pxToVh(120)};
  width: 100%;
  padding: 0 0 20px 0;
`
export const OrdersWrap = styled(VStack)`
  padding: 0;
`
export const Section = styled(VStack)`
  padding: ${({ withScroll }) => (withScroll ? '0' : '0 16px')};
  margin-bottom: 70px;
`
