/*eslint-disable*/
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Favorite } from '@styled-icons/material'
import Button from '../../../components/UI/Buttons/Button/Button'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES as BUTTON_SIZES
} from '../../../const/UIvariants'
import { usePostShopSubscribeMutation } from '../../../api/api.generated'

const SubscribeButton = ({ shopId, isSubscribed }) => {
  const [subscribed, setSubscribed] = useState(isSubscribed)
  const [postShopSubscribe] = usePostShopSubscribeMutation()

  const handleClick = () => {
    const newState = !subscribed
    postShopSubscribe({
      shopId,
      body: { isSubscribed: newState }
    })
    setSubscribed(newState)
  }

  return (
    <Button
      variant={subscribed ? BUTTON_VARIANTS.PINK : BUTTON_VARIANTS.GREY}
      size={BUTTON_SIZES.MED}
      onClick={handleClick}
    >
      {subscribed ? 'Unsubscribe' : 'Subscribe'}
    </Button>
  )
}

export default SubscribeButton

SubscribeButton.defaultProps = {
  isSubscribed: false
}

SubscribeButton.propTypes = {
  shopId: PropTypes.string.isRequired,
  isSubscribed: PropTypes.bool
}
