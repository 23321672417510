import React from 'react'
import MessagesListItem from '../../../components/MessagesListItem'
import { useGetRoomsQuery } from '../../../api/api.generated'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { truncateLongShopName } from '../../../utils/helpers'
import { Wrapper } from './styles'
import usePersistOpenFriendsTab from '../../../hooks/friends/usePersistOpenFriendsTab'

const Messages = () => {
  usePersistOpenFriendsTab('/messages')
  const { data: roomsList, isLoading } = useGetRoomsQuery(undefined, {
    refetchOnMountOrArgChange: true
  })

  return (
    <Wrapper>
      {isLoading && <LoadingSpinner />}
      {roomsList &&
        roomsList.map((room) => (
          <MessagesListItem
            key={room.id}
            contactAvatar={room.userAvatar}
            contactName={room.userName}
            preferredPronouns={room.preferredPronouns}
            id={room.id}
            lastMessageTimeStamp={room.lastMessageTimeStamp}
            lastMessage={truncateLongShopName(room.lastMessage, 25)}
            unreadMessages={room.unreadMessagesCount}
            bgColor={room.avatarBackground}
            image={room.image}
          />
        ))}
    </Wrapper>
  )
}

export default Messages

Messages.propTypes = {}
