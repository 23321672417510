/*eslint-disable*/
import React from 'react'
import PropTypes from 'prop-types'
import { Star } from '@styled-icons/material'
import { shallowEqual, useSelector } from 'react-redux'
import { filter } from 'lodash/collection'
import { HStack } from '../../../global/styles'
import {
  BADGE_SIZES,
  BADGE_VARIANTS
} from '../../../const/UIvariants'
import { selectTagsById } from '../../../store/selectors'
import Badge from '../../../components/UI/Badge'
import ShopLogo from '../../../components/ShopLogo'
import DistanceToShop from '../../../components/DistanceToShop'
import ClosestBadge from '../../../components/ClosestBadge'
import AddToRouteButton from '../../shopActionsButtons/AddToRouteButton'
import GoNowButton from '../../shopActionsButtons/GoNowButton'
import AddToFavButton from '../../shopActionsButtons/AddToFavButton'
import {
  ButtonsWrapper,
  NameSuburbWrap,
  Rating,
  ShopAddress,
  ShopDataWrap,
  ShopInfo,
  ShopItemWrap,
  ShopMetaData,
  ShopName,
  ShopRating,
  TagsWrap
} from './styles'

const SearchResultItem = ({
  id,
  shopName,
  address,
  distance,
  rating,
  tags,
  donations,
  handleOpenShop,
  logotype,
  lat,
  lng,
  isClosest,
  sequence,
  searchQuery,
  searchOption
}) => {
  const tagsById = useSelector(selectTagsById, shallowEqual)
  const filteredTags = filter(tags, (tag) => tagsById?.[tag]?.tagName.toLowerCase().includes(searchQuery.trim().toLowerCase()))
  const filteredDonations = filter(donations, (tag) => tagsById?.[tag]?.tagName.toLowerCase().includes(searchQuery.trim().toLowerCase()))
  return (
    <ShopItemWrap>
      <ShopInfo>
        <ShopLogo logoUrl={logotype} />
        <ShopDataWrap>
          <NameSuburbWrap onClick={handleOpenShop}>
            <ShopName>{shopName}</ShopName>
          </NameSuburbWrap>
          <ShopMetaData>
            <ShopRating>
              <Star size={16} color="hsla(47, 86%, 59%, 1)" />
              <Rating>{rating}</Rating>
            </ShopRating>
            <DistanceToShop shopId={id} initialDistance={distance} />

            <ShopAddress>{address}</ShopAddress>
          </ShopMetaData>
          <ClosestBadge shopId={id} isClosest={isClosest} />
        </ShopDataWrap>
      </ShopInfo>
      { searchOption === 1 && filteredTags.length > 0 &&
      <TagsWrap>
        {filteredTags.map((tagId) => (
          <Badge
            key={tagId}
            size={BADGE_SIZES.TINY}
            label={tagsById[tagId].tagName}
            variant={BADGE_VARIANTS.GREY_OUTLINED}
          />
        ))}
      </TagsWrap>
      }
      { searchOption === 1 && filteredTags.length === 0 &&
      <TagsWrap>
        {tags.map((tagId) => (
          <Badge
            key={tagId}
            size={BADGE_SIZES.TINY}
            label={tagsById[tagId].tagName}
            variant={BADGE_VARIANTS.GREY_OUTLINED}
          />
        ))}
      </TagsWrap>
      }
      { searchOption === 2 && filteredDonations.length > 0 &&
      <TagsWrap>
        {filteredDonations.map((tagId) => (
          <Badge
            key={tagId}
            size={BADGE_SIZES.TINY}
            label={tagsById[tagId].tagName}
            variant={BADGE_VARIANTS.GREY_OUTLINED}
          />
        ))}
      </TagsWrap>
      }
      { searchOption === 2 && filteredDonations.length === 0 &&
        <TagsWrap>
          {tags.map((tagId) => (
            <Badge
              key={tagId}
              size={BADGE_SIZES.TINY}
              label={tagsById[tagId].tagName}
              variant={BADGE_VARIANTS.GREY_OUTLINED}
            />
          ))}
        </TagsWrap>
        }      
      <ButtonsWrapper>
        <HStack>
          <AddToRouteButton {...{ sequence, id, lat, lng }} />
          <GoNowButton {...{ sequence, id, lat, lng }} />
          <AddToFavButton {...{ id }} isSmall />
        </HStack>
      </ButtonsWrapper>
    </ShopItemWrap>
  )
}

export default SearchResultItem
SearchResultItem.defaultProps = {
  favoriteText: null,
  isClosest: false,
  tags: [],
  logotype: '',
}
SearchResultItem.propTypes = {
  id: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  distance: PropTypes.number.isRequired,
  isClosest: PropTypes.bool,
  logotype: PropTypes.string,
  rating: PropTypes.number.isRequired,
  sequence: PropTypes.number.isRequired,
  lat: PropTypes.string.isRequired,
  lng: PropTypes.string.isRequired,
  tags: PropTypes.array,
  donations: PropTypes.array,
  shopName: PropTypes.string.isRequired,
  handleOpenShop: PropTypes.func.isRequired,
  searchQuery: PropTypes.string.isRequired,
  searchOption: PropTypes.number.isRequired
}
