import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useState } from 'react'
import {
  addShopToRoute,
  removeShopFromRouteByShopId,
  replaceRouteShops
} from '../../features/hop/hopSlice'
import { selectHopRoute } from '../../store/selectors'

const useBuildHopRoute = ({ shopId, shopLat, shopLng, sequence }) => {
  const dispatch = useDispatch()
  const [isInRoute, setIsInRoute] = useState(false)
  const route = useSelector(selectHopRoute, () => true)

  const handleAddShopToRoute = useCallback(
    ({ goNow } = false) => {
      if (goNow) {
        dispatch(replaceRouteShops([{ shopId, shopLat, shopLng, sequence }]))
        return
      }
      dispatch(addShopToRoute({ shopId, shopLat, shopLng, sequence }))
    },
    [isInRoute, route]
  )

  const removeShopFromRoute = useCallback(() => {
    dispatch(removeShopFromRouteByShopId(shopId))
  }, [])

  const isIncludedRouteInitially = (initialRouteItems, shopIdToCheck) =>
    initialRouteItems.some(
      (shopInRoute) => shopIdToCheck === shopInRoute.shopId
    )

  const handleToggleInRoute = useCallback(() => {
    setIsInRoute((p) => !p)
    if (isInRoute) {
      removeShopFromRoute()
      return
    }
    handleAddShopToRoute()
  }, [isInRoute, route])

  useEffect(() => {
    setIsInRoute(isIncludedRouteInitially(route, shopId))
  }, [route])

  return {
    handleToggleInRoute,
    handleAddShopToRoute,
    removeShopFromRoute,
    route,
    isInRoute
  }
}

export default useBuildHopRoute
