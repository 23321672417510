/*eslint-disable*/
import React from 'react'
import PropTypes from 'prop-types'
import { Beenhere } from '@styled-icons/material'
import Button from '../UI/Buttons/Button/Button'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES as BUTTON_SIZES
} from '../../const/UIvariants'

const AttendedButton = ({ isAttended, title, isLoading, onClick }) => {
  return (
    <Button
      variant={isAttended ? BUTTON_VARIANTS.PINK : BUTTON_VARIANTS.GREY}
      leftIcon={isLoading ? null : <Beenhere />}
      size={BUTTON_SIZES.MED}
      onClick={onClick}
      isLoading={isLoading}
    >
      { title }
    </Button>
  )
}

export default AttendedButton

AttendedButton.defaultProps = {
  isAttended: false
}

AttendedButton.propTypes = {
  title: PropTypes.string.isRequired,
  isAttended: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
}
