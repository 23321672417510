/*eslint-disable*/
import { shallowEqual, useSelector } from 'react-redux'
import {
  selectCurrentCityShops,
  selectSuggestedSearchQueries,
  selectTagsById,
  selectDistancesToShops
} from '../../store/selectors'
import { useEffect, useRef, useState } from 'react'
import { filter, some } from 'lodash/collection'
import { debounce } from 'lodash/function'

const useSearchShops = () => {
  const currentCityShops = useSelector(selectCurrentCityShops, shallowEqual)
  const tagsById = useSelector(selectTagsById)
  const suggestedQueries = useSelector(selectSuggestedSearchQueries, shallowEqual)
  const distancesToShop = useSelector(selectDistancesToShops, () => true)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [searchOption, setSearchOption] = useState(1)
  const [suggestedShops, setSuggestedShops] = useState([])

  const handleSearch = useRef(
    debounce((e, _searchQuery, _searchOption, _setIsSubmitted, _setSuggestedShops) => {
      if (e) e.preventDefault()
      if (_searchQuery.length !== 0) {
        _setIsSubmitted(true)
        const filteredShops = filter(currentCityShops, (shop) => {
          if (_searchOption == 1) {
            if (
              shop.storeName.toLowerCase().includes(_searchQuery.trim().toLowerCase()) 
              ||
              shop.location.displayAddress.toLowerCase().includes(_searchQuery.trim().toLowerCase())
            ) {
              return true
            }
            if (some(shop.tags, (tag) => tagsById?.[tag]?.tagName.toLowerCase().includes(_searchQuery.trim().toLowerCase()))) {
              return true
            }
            if (
              _searchQuery.toLowerCase().includes('donations') 
              &&
              some(shop.donations, (tag) => tagsById[tag].tagName.toLowerCase().includes(_searchQuery.split(' ')[1].trim().toLowerCase()))
            ) {
              return true
            }
          } else {
            if (some(shop.takenDonations, (donation) => donation.toLowerCase().includes(_searchQuery.trim().toLowerCase()))) {
              return true
            }
          }
        })
        const sorted = [...filteredShops].sort((first, second) => distancesToShop[first.id] - distancesToShop[second.id])
        _setSuggestedShops(sorted)
      }
    }, 300)
  )
  
  const handleSubmit = (e) => handleSearch.current(e, searchQuery, searchOption, setIsSubmitted, setSuggestedShops)
  const handleSearchSuggested = (query) => { setSearchQuery(query) }
  
  useEffect(() => {
    handleSearch.current(null, searchQuery, searchOption, setIsSubmitted, setSuggestedShops)
  }, [searchQuery, searchOption])

  return {
    searchQuery,
    setSearchQuery,
    searchOption, 
    setSearchOption,
    isSubmitted,
    handleSubmit,
    suggestedShops,
    handleSearchSuggested,
    suggestedQueries
  }
}
export default useSearchShops
