/*eslint-disable*/
import React, { useState } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { PusherProvider } from 'react-pusher-hoc'
import SwipeableBottomSheet from 'react-swipeable-bottom-sheet'
import ROUTES from '../../../const/ROUTES'
import PrivateRoute from '../../../components/PrivateRoute'
import MainContainer from '../MainContainer'
import Landing from '../../auth/Landing'
import SignUp from '../../auth/SignUp'
import Login from '../../auth/Login'
import RestorePassword from '../../auth/forgotPassword/RestorePassword'
import CodeVerification from '../../auth/forgotPassword/CodeVerification'
import CreateNewPassword from '../../auth/forgotPassword/CreateNewPassword'
import FallbackRoute from '../../../components/FallbackRoute'
import AddToFavoritesModal from '../../../components/Modals/AddToFavoritesModal'
import OnAppLaunch from '../OnAppLaunch'
import ChatScreen from '../../friendsAndMessages/ChatScreen'
import usePusher from '../../../hooks/friends/usePusher'
import PortalWrapper from '../PortalWrapper'
import ProfileDetails from '../../settings/ProfileDetails'
import ChangePassword from '../../settings/ChangePassword'
import DevSettings from '../../settings/DevSettings'
import SupportRequest from '../../settings/SupportRequest'
import CheckSmsCode from '../../settings/CheckSmsCode'
import WelcomeScreens from '../WelcomeScreens'
import { AppWrap } from './styles'

const PUBLIC_ROUTES = [
  { route: ROUTES.LANDING, Component: <Landing /> },
  { route: ROUTES.LOGIN, Component: <Login /> },
  { route: ROUTES.SIGNUP, Component: <SignUp /> },
  { route: ROUTES.RESTORE_PASSWORD, Component: <RestorePassword /> },
  { route: ROUTES.CODE_VERIFICATION, Component: <CodeVerification /> },
  { route: ROUTES.CREATE_NEW_PASSWORD, Component: <CreateNewPassword /> }
]

function App() {
  const token = useSelector((state) => state.auth.token)
  const { pusherClient } = usePusher()
  const [open, setIsOpen] = useState(false)
  return (
    <PusherProvider value={pusherClient}>
      <AppWrap>
        <OnAppLaunch />
        <AddToFavoritesModal />
        <Switch>
          <Route path={ROUTES.AUTH}>
            {token && <Redirect to={{ pathname: ROUTES.HOME }} />}
            <Switch>
              {PUBLIC_ROUTES.map((routeItem) => (
                <Route key={routeItem.route} path={routeItem.route}>
                  {routeItem.Component}
                </Route>
              ))}
            </Switch>
          </Route>
          <Route path={ROUTES.WELCOME}>
            <WelcomeScreens />
          </Route>
          <PrivateRoute path={ROUTES.MAIN}>
            <MainContainer />
          </PrivateRoute>
          <PrivateRoute path={ROUTES.FRIENDS_CHAT}>
            <ChatScreen />
          </PrivateRoute>
          <PrivateRoute path={ROUTES.SETTINGS_ACCOUNT}>
            <PortalWrapper>
              <ProfileDetails />
            </PortalWrapper>
          </PrivateRoute>
          <PrivateRoute path={ROUTES.SETTINGS_CHANGE_PASSWORD}>
            <PortalWrapper>
              <ChangePassword />
            </PortalWrapper>
          </PrivateRoute>
          <PrivateRoute path={ROUTES.SETTINGS_SEND_FEEDBACK}>
            <PortalWrapper>
              <SupportRequest />
            </PortalWrapper>
          </PrivateRoute>
          <PrivateRoute path={ROUTES.SETTINGS_CHECK_SMS}>
            <PortalWrapper>
              <CheckSmsCode />
            </PortalWrapper>
          </PrivateRoute>
          <PrivateRoute path={ROUTES.SETTINGS_DEV_SCREEN}>
            <PortalWrapper>
              <DevSettings />
            </PortalWrapper>
          </PrivateRoute>
          <PrivateRoute path="/test">
            <p>test</p>
            <SwipeableBottomSheet
              overflowHeight={164}
              open={open}
              onChange={(open) => {
                console.log('test')
                setIsOpen(open)
              }}
            >
              <div style={{ height: '1240px' }}>
                Here goes the content of your bottom sheet
              </div>
            </SwipeableBottomSheet>
          </PrivateRoute>
          <FallbackRoute fallbackPath={ROUTES.WELCOME} />
        </Switch>
      </AppWrap>
    </PusherProvider>
  )
}

export default App
