/* eslint-disable */
import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { Formik } from 'formik'
import * as Yup from 'yup'
import ROUTES from '../../../const/ROUTES'
import { useGetStatesQuery } from '../../../api/api.generated'
import useUserProfile from '../../../hooks/settings/useUserProfile'
import ScreenHeader from '../../../components/ScreenHeader'
import LoadingSpinner from '../../../components/LoadingSpinner'
import TextInput from '../../../components/UI/Inputs/TextInput'
import FormSelectOneInput from '../../../components/UI/Inputs/SelectOneInput/FormSelectOneInput'
import PhoneNumberInput from '../../../components/UI/Inputs/PhoneNumberInput'
import { Headline, Description } from '../SettingsScreen/styles'
import { ContentWrapper, FormBody, HeadlineWrap, StyledForm, LineWrap } from './styles'

const ProfileDetails = () => {
  const history = useHistory()
  const { userData, isLoading, handleUpdateUserData, error: userError } = useUserProfile()
  const { data: statesData, isLoading: isStatesLoading, error: statesError } = useGetStatesQuery()

  const stateList = useMemo(
    () =>
    statesData
        ? statesData?.states?.map((organization) => ({value: organization.id, label: organization.name })) 
        : [],
    [statesData]
  )

  const genderList = [
    {value: 1, label: 'Rather not tell'}, 
    {value: 2, label: 'Male'}, 
    {value: 3, label: 'Female'}, 
    {value: 4, label: 'Custom'}
  ];

  return (
    <>
      {(isLoading || isStatesLoading) && <LoadingSpinner />}
      {!isLoading && !isStatesLoading && (userError || statesError) &&
        <Formik>
          <StyledForm>
            <ScreenHeader
              title="Account"
              actionHandler={() => {}}
              actionTitle="Save"
              actionIsDisabled
              backButton
            />
            <FormBody>
              <HeadlineWrap>
                <Description>Network Error!</Description>
              </HeadlineWrap>
            </FormBody>
          </StyledForm>
        </Formik>
      }
      {userData && statesData && (
        <Formik
          initialValues={{
            username: userData?.username,
            phoneNumber: userData?.phoneNumber,
            email: userData?.email,
            stateId: userData?.stateId,
            preferredPronouns: userData?.preferredPronouns,
            age: userData?.age,
            genderId: userData?.genderId,
            customGender: userData?.customGender
          }}
          validationSchema={Yup.object({
            username: Yup.string().max(40, 'Name is too long').required('Name is required'),
            phoneNumber: Yup.string().matches(/^[0-9]+$/, 'Please enter valid phone number'),
            // .test(
            //   'len',
            //   'Must be exactly 11 digits',
            //   (val) => val?.length === 11
            // ),
            email: Yup.string().email('Invalid email address').required('Email is required'),
            stateId: Yup.string().required('State is required'),
            preferredPronouns: Yup.string().max(20, 'Preferred Pro-Nouns are too long'),
            age: Yup.number().integer('Age must be a number').positive("Must be more than 0"),
            genderId: Yup.string().required('Gender is required')
          })}
          onSubmit={(values, formik) => {
            handleUpdateUserData(values, formik, () => {
              history.replace(ROUTES.SETTINGS_CHECK_SMS, {
                newPhoneNumber: values.phoneNumber
              })
            })
          }}
        >
          {({ isValid, dirty }) => (
            <StyledForm>
              <ScreenHeader
                title="Account"
                actionHandler={() => {}}
                actionTitle="Save"
                actionIsDisabled={!isValid || !dirty}
                backButton
              />
              <ContentWrapper>

              <FormBody>
                <HeadlineWrap>
                  <Headline>Your Profile</Headline>
                  <Description>Your account has been verified</Description>
                </HeadlineWrap>
                <LineWrap>
                  <TextInput
                    label="Username"
                    inputName="username"
                    placeholder="Enter your name"
                  />
                </LineWrap>
                <LineWrap>
                  <TextInput
                    label="E-mail"
                    inputName="email"
                    placeholder="Enter your email"
                  />
                  <PhoneNumberInput
                    label="Phone Number"
                    placeholder="Enter your phone number"
                    inputName="phoneNumber"
                  />
                </LineWrap>
                <LineWrap>
                  <FormSelectOneInput
                    label="State"
                    inputName="stateId"
                    options={stateList}
                    size="100%"
                    value={userData?.stateId}
                    isGrey
                  />
                </LineWrap>
                <LineWrap>
                  <TextInput
                    label="Preferred Pro-Nouns"
                    inputName="preferredPronouns"
                    placeholder="Enter preferred pro-nouns"
                  />
                  <TextInput
                    label="Age"
                    inputName="age"
                    placeholder="Enter your age"
                  />                  
                </LineWrap>
                <LineWrap>
                  <FormSelectOneInput
                    label="Gender"
                    inputName="genderId"
                    options={genderList}
                    size="100%"
                    value={userData?.genderId}
                    isGrey
                  />
                </LineWrap>
                <LineWrap>
                  <TextInput
                    label="Custom Gender"
                    inputName="customGender"
                    placeholder="Enter your gender"
                  />
                </LineWrap>
              </FormBody>
              </ContentWrapper>              
            </StyledForm>
          )}
        </Formik>
      )}
    </>
  )
}

export default ProfileDetails

ProfileDetails.propTypes = {}
