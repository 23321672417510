import { generatePath, useHistory } from 'react-router-dom'
import { isNil } from 'lodash/lang'
import ROUTES from '../../const/ROUTES'
// import { useLazyGetChatRoomByUserIdQuery } from '../../api/api.generated'

const useChatRoom = (userId, chatRoomId) => {
  const history = useHistory()
  // const [createRoom, { data }] = useLazyGetChatRoomByUserIdQuery()

  const handleNavigateToChatRoom = (_chatRoomId) => {
    if (_chatRoomId) {
      history.push(generatePath(ROUTES.FRIENDS_CHAT, { roomId: _chatRoomId }))
    }
  }

  const handleOpenChatRoom = () => {
    if (isNil(chatRoomId)) {
      // createRoom(userId)
      history.push(ROUTES.FRIENDS_CONTACTS)
      return
    }
    handleNavigateToChatRoom(chatRoomId)
  }

  /*
  useEffect(() => {
    if (isNil(chatRoomId) && data?.roomId) {
      const newRoomId = data.roomId
      handleNavigateToChatRoom(newRoomId)
    }
  }, [data])
  */
  
  return { handleOpenChatRoom }
}
export default useChatRoom
