import { keyframes } from 'styled-components/macro'

export const slideIn = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform:  translateY(0);
  }
`
export const fadeId = keyframes`
  from {
   opacity: 0;
  }
  to {
    opacity: 1;
  }
`
