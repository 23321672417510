import styled from 'styled-components'
import { HStack } from '../../global/styles'

// eslint-disable-next-line import/prefer-default-export
export const TabBarWrap = styled(HStack)`
  background-color: ${({ theme }) => theme.colors.white};
  border-top: 1px solid ${({ theme }) => theme.colors.skyNeutral3};
  height: 72px;
  //position: absolute;
  bottom: 0;
  position: fixed;
  margin-top: auto;
  align-self: flex-end;
  width: 100%;
  z-index: 50;
  justify-content: space-between;
  padding: 0 32px;
`
