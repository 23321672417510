import styled from 'styled-components'
import Typography from '../Typography'

export const StyledTab = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  // opacity: ${({ isActive }) => (isActive ? 1 : 0.75)};
  border-color: ${({ theme, isActive }) =>
    isActive ? theme.colors.skyBase : 'transparent'};
  border-bottom-style: solid;
  border-width: 0 0 2px 0;
  width: ${({ autoWidth }) => (autoWidth ? '100%' : '100px')};
  max-width: ${({ autoWidth }) => (autoWidth ? 'auto' : '100px')};
  min-width: ${({ autoWidth }) => (autoWidth ? 'auto' : '100px')};
  padding: 16px 0 12px 0;
  position: relative;
  transition: border-bottom-color 0.1s ease-in-out;
  :after {
    content: '';
    opacity: ${({ isActive }) => (isActive ? 1 : 0.75)};
    display: block;
    border-bottom: 2px solid ${({ theme }) => theme.colors.skyBase};
    width: 0;
    position: absolute;
    right: 0;
    bottom: -2px;
    -webkit-transition: 0.2s ease;
    transition: 0.2s ease;
  }
  // :active {
  //   &:after {
  //     width: 100%;
  //   }
  //   opacity: 1;
  //   p {
  //     color: ${({ theme }) => theme.colors.skyBase};
  //   }
  // }
`

export const TabLabel = styled(Typography)`
  transition: color 0.2s ease-in-out;

  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.skyBase : theme.colors.skyNeutral3};
`

export const Count = styled(Typography).attrs(({ theme }) => ({
  color: theme.colors.white,
  variant: 'textXS',
  bold: true
}))`
  min-width: 20px;
  height: 20px;
  line-height: 19px;
  text-align: center;
  margin-left: 12px;
  padding: 0 6px;
  background-color: #cf713b;
  border-radius: 14px;
`
