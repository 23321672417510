import * as Yup from 'yup'
import React from 'react'
import { Formik } from 'formik'
import { HeadlineWrap, InputsWrap, StyledForm } from './styles'
import ScreenHeader from '../../../components/ScreenHeader'
import TextInput from '../../../components/UI/Inputs/TextInput/TextInput'
import { Description, Headline } from '../SettingsScreen/styles'
import Button from '../../../components/UI/Buttons/Button'
import { BUTTON_VARIANTS, BUTTONS_SIZES } from '../../../const/UIvariants'
import useChangePassword from '../../../hooks/settings/useChangePassword'
import SuccessScreenOverlay from '../../common/SuccessScreenOverlay'
import ROUTES from '../../../const/ROUTES'

const ChangePassword = () => {
  const { handleUpdatePassword, isSubmitted, isLoading } = useChangePassword()
  return isSubmitted ? (
    <SuccessScreenOverlay
      title="New password is set!"
      destination={ROUTES.SETTINGS}
      description=""
    />
  ) : (
    <Formik
      initialValues={{
        currentPassword: '',
        newPassword: '',
        repeatNewPassword: ''
      }}
      validationSchema={Yup.object().shape(
        {
          currentPassword: Yup.string().when('newPassword', {
            is: (newPassword) => newPassword && newPassword.length > 0,
            then: Yup.string().required('Old password is required')
          }),
          newPassword: Yup.string()
            .min(6, 'Password must contain at least 6 characters')
            .when('currentPassword', {
              is: (currentPassword) =>
                currentPassword && currentPassword.length > 0,
              then: Yup.string().notOneOf(
                [Yup.ref('currentPassword')],
                'New password should be different from old one'
              )
            })
            .required('New password is required'),
          repeatNewPassword: Yup.string().when('newPassword', {
            is: (newPassword) => newPassword && newPassword.length > 0,
            then: Yup.string()
              .oneOf([Yup.ref('newPassword')], "Password doesn't match")
              .required('Please confirm your password')
          })
        },
        ['currentPassword', 'newPassword', 'repeatNewPassword']
      )}
      onSubmit={handleUpdatePassword}
    >
      {({ isValid, dirty }) => (
        <>
          <ScreenHeader title="Account" backButton />
          <StyledForm>
            <HeadlineWrap>
              <Headline>Change Password</Headline>
              <Description>
                Create you password with min 6 characters
              </Description>
            </HeadlineWrap>
            <InputsWrap>
              <TextInput
                label="Old Password"
                type="password"
                inputName="currentPassword"
                placeholder="Enter your current password"
              />

              <TextInput
                label="New Password"
                type="password"
                inputName="newPassword"
                placeholder="Enter new password"
              />
              <TextInput
                label="Repeat New Password"
                type="password"
                inputName="repeatNewPassword"
                placeholder="Enter new password"
              />
            </InputsWrap>
            <Button
              variant={BUTTON_VARIANTS.PRIMARY}
              size={BUTTONS_SIZES.LARGE}
              type="submit"
              disabled={!isValid || !dirty}
              isLoading={isLoading}
            >
              Change Password
            </Button>
          </StyledForm>
        </>
      )}
    </Formik>
  )
}

export default ChangePassword

ChangePassword.propTypes = {}
