const CurrentLocationMarker = `<svg style='left:-1.5%; top:-1.5% ' width="37" height='37'   viewBox='0 0 37 37'  fill="none" xmlns="http://www.w3.org/2000/svg" >
<g clip-path="#clip0"  >
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.2278 36.4557C28.2948 36.4557 36.4557 28.2948 36.4557 18.2279C36.4557 8.16089 28.2948 0 18.2278 0C8.16088 0 0 8.16089 0 18.2279C0 28.2948 8.16088 36.4557 18.2278 36.4557Z" fill="#2D6CE4" fill-opacity="0.19"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.2279 29.6203C24.5197 29.6203 29.6203 24.5198 29.6203 18.2279C29.6203 11.9361 24.5197 6.83551 18.2279 6.83551C11.936 6.83551 6.83545 11.9361 6.83545 18.2279C6.83545 24.5198 11.936 29.6203 18.2279 29.6203Z" fill="black" fill-opacity="0.1"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.3831 22.9787C20.9213 22.9787 22.9788 20.9211 22.9788 18.383C22.9788 15.8448 20.9213 13.7872 18.3831 13.7872C15.8449 13.7872 13.7874 15.8448 13.7874 18.383C13.7874 20.9211 15.8449 22.9787 18.3831 22.9787Z" fill="#2D6CE4" stroke="white"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="36.4557" height="36.4557" fill="white"/>
</clipPath>
</defs>
</svg>
`

export default CurrentLocationMarker
