/*eslint-disable*/
import ROUTES from './ROUTES'
import React from 'react'
import FriendshipRequests from '../features/friendsAndMessages/FriendshipRequests'
import HopCities from '../features/hop/HopCities'
import HopShops from '../features/hop/HopShops'
import MyHops from '../features/hop/MyHops'
import Contacts from '../features/friendsAndMessages/Contacts'
import Messages from '../features/friendsAndMessages/Messages'
import Notes from '../features/friendsAndMessages/Notes'
import ShopDetails from '../features/shopDetails/ShopDetails'
import ShopProducts from '../features/shopDetails/ShopProducts'
import ShopPosts from '../features/shopDetails/ShopPosts'
import ShopDonations from '../features/shopDetails/ShopDonations'
import ShopVolunteers from '../features/shopDetails/ShopVolunteers'
import ShopGallery from '../features/shopDetails/ShopGallery'
import ShopVideos from '../features/shopDetails/ShopVideos'
import ShopQuestions from '../features/shopDetails/ShopQuestions'
import ShopHolidays from '../features/shopDetails/ShopHolidays'

export const FRIENDS_TABS = [
  {
    label: 'Contacts',
    destination: ROUTES.FRIENDS_CONTACTS,
    Component: (props) => <Contacts {...props} />
  },
  {
    label: 'Messages',
    destination: ROUTES.FRIENDS_MESSAGES,
    Component: (props) => <Messages {...props} />
  },
  {
    label: 'Requests',
    destination: ROUTES.FRIENDS_REQUESTS,
    Component: (props) => <FriendshipRequests {...props} />
  },
  {
    label: 'Notes',
    destination: ROUTES.FRIENDS_NOTES,
    Component: (props) => <Notes {...props} />
  }
]

export const HOP_TABS = [
  {
    label: 'Cities',
    destination: ROUTES.HOP_CITIES,
    Component: (props) => <HopCities {...props} />
  },
  {
    label: 'Stores',
    destination: ROUTES.HOP_SHOPS,
    Component: (props) => <HopShops {...props} />
  },
  {
    label: 'My Hop',
    destination: ROUTES.HOP_MYHOPS,
    Component: (props) => <MyHops {...props} />
  }
]
export const SHOP_TABS = [
  {
    label: 'About',
    destination: ROUTES.SHOP_DETAILS,
    Component: (shopId) => (props) => <ShopDetails {...props} shopId={shopId} />
  },
  {
    label: 'Products',
    destination: ROUTES.SHOP_PRODUCTS,
    Component: (shopId) => (props) =>
      <ShopProducts {...props} shopId={shopId} />
  },
  {
    label: 'Posts',
    destination: ROUTES.SHOP_POSTS,
    Component: (shopId) => (props) => <ShopPosts {...props} shopId={shopId} />
  },
  {
    label: 'Donations',
    destination: ROUTES.SHOP_DONATIONS,
    Component: (shopId) => (props) =>
      <ShopDonations {...props} shopId={shopId} />
  },
  {
    label: 'Volunteers',
    destination: ROUTES.SHOP_VOLUNTEERS,
    Component: (shopId) => (props) =>
      <ShopVolunteers {...props} shopId={shopId} />
  },
  {
    label: 'Gallery',
    destination: ROUTES.SHOP_GALLERY,
    Component: (shopId) => (props) => <ShopGallery {...props} shopId={shopId} />
  },
  {
    label: 'Videos',
    destination: ROUTES.SHOP_VIDEOS,
    Component: (shopId) => (props) => <ShopVideos {...props} shopId={shopId} />
  },
  {
    label: 'FAQ',
    destination: ROUTES.SHOP_QUESTIONS,
    Component: (shopId) => (props) =>
      <ShopQuestions {...props} shopId={shopId} />
  },
  {
    label: 'Holidays',
    destination: ROUTES.SHOP_HOLIDAYS,
    Component: (shopId) => (props) =>
      <ShopHolidays {...props} shopId={shopId} />
  }
]
