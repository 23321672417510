import React, { useRef } from 'react'
import { useField } from 'formik'
import * as PropTypes from 'prop-types'
import { Close, InsertPhoto } from '@styled-icons/material'
import {
  ImageWrapper,
  ImagePreview,
  SelectImage,
  Label,
  SelectImageWrap,
  ImagePreviewWrap,
  CloseIconWrap
} from './styles'
import { truncateSentence } from '../../utils/helpers'

const acceptedFormats = ['image/png', 'image/jpeg', 'image/jpg']
const ImagePicker = ({ name }) => {
  const [, meta, helpers] = useField({ name })
  const inputRef = useRef()

  const handleChange = (e) => {
    if (meta.value.some((image) => image.name === e.target.files[0].name)) {
      // console.log('File with this name was already added')
      return
    }
    if (
      acceptedFormats.some((format) => format === e.target?.files?.[0]?.type)
    ) {
      helpers.setValue([
        ...meta.value,
        {
          name: e.target.files[0].name,
          src: URL.createObjectURL(e.target.files[0]),
          file: e.target.files[0]
        }
      ])
    }
  }
  const handleDeleteImage = (imageToDeleteName) => {
    helpers.setValue(
      meta.value.filter((image) => image.name !== imageToDeleteName)
    )
  }
  return (
    <ImageWrapper>
      {!!meta.value.length &&
        meta.value.map((image) => (
          <ImagePreviewWrap
            onClick={() => {
              handleDeleteImage(image.name)
            }}
            key={image.name}
          >
            <CloseIconWrap>
              <Close color="#735F17" size={24} />
            </CloseIconWrap>
            <ImagePreview src={image.src} />
            <Label>{truncateSentence(image.name, 12)}</Label>
          </ImagePreviewWrap>
        ))}

      <SelectImageWrap htmlFor="imagePicker">
        <SelectImage>
          <InsertPhoto size={42} color="#A0A7B5" />
        </SelectImage>
        <Label>Select image</Label>
        <input
          id="imagePicker"
          type="file"
          onChange={handleChange}
          ref={inputRef}
          hidden
        />
      </SelectImageWrap>
    </ImageWrapper>
  )
}

export default ImagePicker

ImagePicker.propTypes = {
  name: PropTypes.string.isRequired
}
