/*eslint-disable*/
import React from 'react'
import PropTypes from 'prop-types'
import {
  DonationItemWrap,
  DonationDataWrap,
  TagIconWrap,
  TagLabel,
  DonationData,
  DonationNameWrap
} from './styles'
import Badge from '../UI/Badge'
import { BADGE_SIZES, BADGE_VARIANTS } from '../../const/UIvariants'

const DonationItem = ({
  id,
  backgroundColor,
  icon,
  tagName,
  height,
  width,
  length,
  priority,
  isTaken
}) => {
  const maxSize = []
  var maxSizeStr = ''
  if (height !== '') {
    maxSize.push('Height: ' + height)
  }
  if (width !== '') {
    maxSize.push('Width: ' + width)
  }
  if (length !== '') {
    maxSize.push('Length: ' + length)
  }
  if (maxSize.length > 0) {
    maxSizeStr = 'Maximum size - ' + maxSize.join(', ')
  }

  return (
    <DonationItemWrap key={id}>
      <TagIconWrap backgroundColor={backgroundColor}>
        {icon &&
          React.cloneElement(icon, {
            width: 24,
            height: 24,
            color: '#ffffff'
          })}
      </TagIconWrap>
      <DonationDataWrap>
        <DonationNameWrap>
          <Badge size={BADGE_SIZES.SMALL} variant={isTaken ? BADGE_VARIANTS.PRIMARY : BADGE_VARIANTS.PINK} label={isTaken ? 'Taken' : 'Not taken'} />
          <TagLabel>{tagName}</TagLabel>
        </DonationNameWrap>
        <DonationData>{maxSizeStr}</DonationData>
        { priority !== "" &&
        <DonationData>Priority: {priority}</DonationData>
        }
      </DonationDataWrap>
    </DonationItemWrap>
  )
}

export default DonationItem

DonationItem.defaultProps = {
  height: '',
  width: '',
  length: '',
  priority: '',
}

DonationItem.propTypes = {
  id: PropTypes.any.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  tagName: PropTypes.string.isRequired,
  height: PropTypes.string,
  width: PropTypes.string,
  length: PropTypes.string,
  priority: PropTypes.string,
  isTaken: PropTypes.bool.isRequired
}
