import React from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import { PortalScreenContainer } from './styles'

const PortalWrapper = ({ children }) =>
  ReactDOM.createPortal(
    <PortalScreenContainer>{children}</PortalScreenContainer>,
    document.getElementById('root')
  )

export default PortalWrapper

PortalWrapper.propTypes = {
  children: PropTypes.node.isRequired
}
