import styled from 'styled-components'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { HStack, VStack } from '../../../global/styles'
import Typography from '../../../components/UI/Typography'
import { pxToVh } from '../../../utils/styles-utils'

export const HomeScreenHeader = styled(VStack)`
  padding: 0 16px 16px 16px;
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);
  /* TODO:
  margin-bottom: 0;
  */
`
export const HomeLocationWrap = styled(HStack)`
  width: auto;
  justify-content: space-between;
  align-items: center;
  > :first-child {
    margin-right: 8px;
  }
`
export const HomeLocationLabel = styled(Typography).attrs({
  variant: 'headingM'
})`
  color: ${({ theme }) => theme.colors.skyBase};
  text-align: right;
`

export const HomeScreenContent = styled(VStack)`
  margin-top: 32px;
  width: 100%;
  padding-bottom: 70px;
`
export const Headline = styled(Typography).attrs({
  variant: 'headingXL',
  bold: true
})`
  margin-bottom: ${pxToVh(32)};
  color: ${({ theme }) => theme.colors.skyNeutral_1};
  margin-left: 16px;
`
export const Section = styled(VStack)`
  padding: ${({ withScroll }) => (withScroll ? '0' : '0 16px')};
  margin-bottom: 32px;
  /* TODO:
  margin-bottom: 0;
  */  
`
export const SectionCaption = styled(Typography).attrs({
  variant: 'textM',
  bold: true
})`
  padding: ${({ withPadding }) =>
    withPadding ? '0 0 18px 16px' : '0 0 18px 0'};
  color: ${({ theme }) => theme.colors.skyNeutral1};
`
export const HorScroll = styled(OverlayScrollbarsComponent).attrs({
  options: {
    scrollbars: { visibility: 'hidden' }
  }
})`
  width: 100%;
  display: flex;
`
export const TagWrap = styled(HStack)`
  /* 
  height: 72px;
  TODO: 
  flex-wrap: wrap;
  padding-left: 16px;
  padding-right: 16px
  */
`
export const ShopsWrap = styled(HStack)`
  height: 279px;
  > :first-child {
    margin-left: 16px;
  }
`
export const PostsWrap = styled(HStack)`
  padding-bottom: 12px;
  > :first-child {
    margin-left: 16px;
  }  
`
export const LogoLocationWrap = styled(HStack)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: ${pxToVh(60)};
  margin-bottom: ${pxToVh(36)};
`
export const HomeScreenContainer = styled(OverlayScrollbarsComponent).attrs({
  options: {
    scrollbars: { visibility: 'hidden' }
  }
})`
  width: 100%;
  height: calc(100% - 238px - 72px);
  /* TODO:
  padding-top: 20px;
  */
`
export const IsEmptyText = styled(Typography)`
  color: ${({ theme }) => theme.colors.skyNeutral_1};
`
