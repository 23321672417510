import styled from 'styled-components'
import { Form } from 'formik'
import { HStack } from '../../../global/styles'

// eslint-disable-next-line import/prefer-default-export
export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 32px;
`

export const ButtonsWrapper = styled(HStack)`
  margin-top: 8px;
  > *:first-child {
    margin-right: 8px;
  }
`