import styled from 'styled-components'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { pxToVh } from '../../../utils/styles-utils'
import Typography from '../../../components/UI/Typography'
import { VStack } from '../../../global/styles'

// eslint-disable-next-line import/prefer-default-export
export const EventsContentWrapper = styled(OverlayScrollbarsComponent).attrs({
  options: {
    scrollbars: { visibility: 'hidden' }
  }
})`
  height: calc(100% - 72px - 20.25vh);
  margin-top: ${pxToVh(164)};
  width: 100%;
  padding: 18px 0 20px 0;
  /* TODO:
  padding: 0;
  */
`

export const PostsWrap = styled(VStack)`
  /* mobile */
  padding: 24px 16px 70px 16px;
  > * {
    margin-bottom: 24px;
  }
`

export const IsEmptyText = styled(Typography)`
  color: ${({ theme }) => theme.colors.skyNeutral_1};
  text-align: center;
  align-self: center;
`
