import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Search } from '@styled-icons/material'
import { HeaderWrap, Headline, HeadlineWrap, TabsWrap } from './styles'
import Tab from '../../../components/UI/Tab'
import { FRIENDS_TABS } from '../../../const/tabs'
import ROUTES from '../../../const/ROUTES'
import IconButton from '../../../components/UI/Buttons/IconButton'
import { useGetUserCountersQuery } from '../../../api/api.generated'

const FriendsHeader = () => {
  const { data: counters } = useGetUserCountersQuery(undefined, {
    refetchOnMountOrArgChange: true
  })
  const history = useHistory()
  const location = useLocation()

  const handleOpenContactsSearch = () => {
    history.push(location.pathname + ROUTES.FIND_FRIENDS)
  }

  return (
    <HeaderWrap>
      <HeadlineWrap>
        <Headline>Friends List</Headline>
        <IconButton size={32} onClick={handleOpenContactsSearch}>
          <Search size={32} color="#A0A7B5" />
        </IconButton>
      </HeadlineWrap>
      <TabsWrap>
        {FRIENDS_TABS.map((tabItem) => (
          <Tab
            autoWidth
            key={tabItem.destination}
            destination={tabItem.destination}
            isActive={location.pathname.includes(tabItem.destination)}
            label={tabItem.label}
            count={
              tabItem.label === 'Messages'
                ? counters?.messages
                : tabItem.label === 'Requests'
                ? counters?.requests
                : null
            }
          />
        ))}
      </TabsWrap>
    </HeaderWrap>
  )
}

export default FriendsHeader

FriendsHeader.propTypes = {}
