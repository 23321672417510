import styled from 'styled-components'
import { pxToVh } from '../../utils/styles-utils'
import Typography from '../UI/Typography'
import { HStack, VStack } from '../../global/styles'

export const ButtonsWrapper = styled(VStack)`
  width: 100%;
  margin-top: ${pxToVh(32)};
  > *:first-child {
    margin-bottom: 12px;
    justify-content: space-between;
    > *:first-child {
      margin-right: 12px;
    }
  }
`

export const DistanceToShop = styled(Typography).attrs(({ theme }) => ({
  color: theme.colors.skyNeutral1,
  variant: 'textXS'
}))`
  margin-left: 2px;
`

export const Rating = styled(Typography).attrs(({ theme }) => ({
  color: theme.colors.skyNeutral_1,
  variant: 'textXS'
}))`
  margin-left: 2px;
`
export const ShopAddress = styled(Typography).attrs(({ theme }) => ({
  color: theme.colors.skyNeutral2,
  variant: 'textXS'
}))`
  margin: 2px 0 4px 0;
`

export const ShopDataWrap = styled(VStack)`
  margin-left: 16px;
  cursor: pointer;
`

export const ShopDistance = styled(HStack)`
  align-items: center;
  width: auto;
`
export const ShopInfo = styled(HStack)`
  align-items: flex-start;
  justify-content: center;
`

export const ShopMetaData = styled(HStack)`
  align-items: center;
`
export const ShopName = styled(Typography).attrs(({ theme }) => ({
  color: theme.colors.skyNeutral_2,
  variant: 'headingS',
  bold: true
}))`
  line-height: 28px;
`
export const ShopRating = styled(HStack)`
  width: auto;
  margin-right: 8px;
  align-items: center;
`
export const ShopItemWrap = styled(VStack)`
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.skyNeutral3};
  padding: 12px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.04);
  margin: 8px 0 8px 0;
`
export const LastVisited = styled(Typography).attrs(({ theme }) => ({
  color: theme.colors.greenBase,
  variant: 'textXS'
}))`
  margin-left: 8px;
`
export const IconButtonWrap = styled.div`
  margin-top: 3px;
`
