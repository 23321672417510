/*eslint-disable*/
import React from 'react'
import PropTypes from 'prop-types'
import { Favorite } from '@styled-icons/material'
import useAddToFavorite from '../../../hooks/shop/useAddToFavorite'
import Button from '../../../components/UI/Buttons/Button/Button'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES,
  BUTTONS_SIZES as BUTTON_SIZES
} from '../../../const/UIvariants'
import { values } from 'lodash/object'

const AddToFavButton = ({ id, isSmall, size }) => {
  const { toggleAddToFavorite, isFavorite, favoriteText } = useAddToFavorite({
    shopId: id
  })
  return (
    <Button
      variant={isFavorite ? BUTTON_VARIANTS.PINK : BUTTON_VARIANTS.GREY}
      leftIcon={!isSmall ? <Favorite /> : null}
      size={size}
      onClick={toggleAddToFavorite}
      isFullWidth={!isSmall}
    >
      {isSmall && <Favorite size={24} />}
      {!isSmall && (isFavorite ? favoriteText || 'Remove' : 'Add to Fav')}
    </Button>
  )
}

export default AddToFavButton

AddToFavButton.defaultProps = {
  isSmall: false,
  size: BUTTON_SIZES.MED
}
AddToFavButton.propTypes = {
  id: PropTypes.string.isRequired,
  isSmall: PropTypes.bool,
  size: PropTypes.oneOf(values(BUTTONS_SIZES))
}
