import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  selectFilterCategories,
  selectFilterRatings
} from '../../store/selectors'
import {
  filterCityShops,
  selectTag,
  toggleRateFilter,
  toggleTagFilter
} from '../../features/hop/hopSlice'

const useFilter = () => {
  const dispatch = useDispatch()
  const selectedRates = useSelector(selectFilterRatings, shallowEqual)
  const selectedTags = useSelector(selectFilterCategories, shallowEqual)
  const handleToggleRate = (rateToSet) => {
    dispatch(toggleRateFilter(rateToSet))
    dispatch(filterCityShops())
  }
  const handleToggleTag = (tag) => {
    dispatch(toggleTagFilter(tag))
    dispatch(filterCityShops())
  }
  const handleSelectOneTag = (tagId) => {
    dispatch(selectTag(tagId))
    dispatch(filterCityShops())
  }
  return {
    selectedTags,
    selectedRates,
    handleToggleTag,
    handleToggleRate,
    handleSelectOneTag
  }
}

export default useFilter
