import React from 'react'
import Modal from 'react-modal'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { usePostUserToggleFavoriteByShopIdMutation } from '../../../api/api.generated'
import TextInput from '../../UI/Inputs/TextInput'
import { ButtonWrapper, ModalHeadline, StyledForm } from './styles'
import Button from '../../UI/Buttons/Button'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES as BUTTON_SIZES,
  INPUT_SIZES
} from '../../../const/UIvariants'
import { closeAddToFavoritesModal } from '../../../features/app/appSlice'

const AddToFavoritesModal = () => {
  const isOpen = useSelector((state) => state.app.showAddToFavoriteModal)
  const [addToFavorites] = usePostUserToggleFavoriteByShopIdMutation()
  const shopId = useSelector((state) => state.app.addToFavoritesShopId)
  const dispatch = useDispatch()
  const handleCloseModal = () => {
    dispatch(closeAddToFavoritesModal())
  }

  const handleSubmit = (values) => {
    handleCloseModal()
    addToFavorites({
      shopId,
      body: { reasonOfAdding: values.reasonOfAdding, isFavorite: true }
    })
  }
  return (
    <Modal {...{ isOpen }}>
      <Formik
        initialValues={{
          reasonOfAdding: ''
        }}
        onSubmit={handleSubmit}
      >
        <StyledForm>
          <ModalHeadline> What do you love about this store?</ModalHeadline>
          <TextInput size={INPUT_SIZES.SMALL} inputName="reasonOfAdding" />
          <ButtonWrapper>
            <Button
              variant={BUTTON_VARIANTS.PRIMARY}
              size={BUTTON_SIZES.MED}
              type="submit"
            >
              Add To Fav
            </Button>
            <Button
              variant={BUTTON_VARIANTS.OUTLINED}
              size={BUTTON_SIZES.MED}
              onClick={handleCloseModal}
              type="button"
            >
              Cancel
            </Button>
          </ButtonWrapper>
        </StyledForm>
      </Formik>
    </Modal>
  )
}

export default AddToFavoritesModal

AddToFavoritesModal.propTypes = {}
