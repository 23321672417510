import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  ChatBubble,
  CheckCircle,
  HourglassFull,
  PersonAdd
} from '@styled-icons/material'
import { ContactName, ImageNameWrap, Wrapper, ContactNameWrap, Pronouns } from './styles'
import UserAvatar from '../UserAvatar'
import IconButton from '../UI/Buttons/IconButton'
import useHandlePendingRequests from '../../hooks/friends/useHandlePendingRequests'
import useChatRoom from '../../hooks/friends/useChatRoom'

const ContactSearchResultItem = ({
  id,
  roomId,
  contactName,
  preferredPronouns,
  contactAvatar,
  requestStatus,
  bgColor
}) => {
  const { sendAddFriendRequest } = useHandlePendingRequests()

  const { handleOpenChatRoom } = useChatRoom(id, roomId)
  const [requestSent, setRequestSent] = useState(false)
  return (
    <Wrapper>
      <ImageNameWrap>
        <UserAvatar
          image={contactAvatar}
          size={44}
          userName={contactName}
          {...{ bgColor }}
        />
        <ContactNameWrap>
          <ContactName>{contactName}</ContactName>
          {preferredPronouns !== '' &&
          <Pronouns>({preferredPronouns})</Pronouns>
          }
        </ContactNameWrap>        
      </ImageNameWrap>
      {requestStatus === 'accepted' && (
        <IconButton
          size={24}
          onClick={() => {
            handleOpenChatRoom()
          }}
        >
          <ChatBubble size={24} color="#2D6CE4" />
        </IconButton>
      )}
      {requestStatus === 'pendingApproval' && (
        <HourglassFull size={24} color="#454E60" />
      )}
      {requestStatus === 'available' &&
        (requestSent ? (
          <CheckCircle size={24} color="#4EA64C" />
        ) : (
          <IconButton
            size={24}
            onClick={() => {
              sendAddFriendRequest(id)
              setRequestSent(true)
            }}
          >
            <PersonAdd size={24} color="#2D6CE4" />
          </IconButton>
        ))}
    </Wrapper>
  )
}

export default ContactSearchResultItem

ContactSearchResultItem.defaultProps = {
  preferredPronouns: '',
  roomId: null,
  contactAvatar: null
}

ContactSearchResultItem.propTypes = {
  id: PropTypes.string.isRequired,
  roomId: PropTypes.string,
  bgColor: PropTypes.string.isRequired,
  contactName: PropTypes.string.isRequired,
  preferredPronouns: PropTypes.string,
  contactAvatar: PropTypes.string,
  requestStatus: PropTypes.string.isRequired
}
