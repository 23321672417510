/*eslint-disable*/

import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash/lang'
import { shallowEqual, useSelector } from 'react-redux'
import { H } from '../../config/hereWego'
import ShopMarker from '../../assets/MapMarkers/ShopMarker'
import { getImageFromServer } from '../../utils/helpers'
import { selectHopRoute } from '../../store/selectors'
import useGetCityShops from './useGetCityShops'
import useShopDetails from '../../hooks/shop/useShopDetails'

const useAddMarkers = (localMapInstance) => {
  const { filteredSortedShops, isLoading } = useGetCityShops()
  const route = useSelector(selectHopRoute, shallowEqual)
  const [tempId, setTempId] = useState(null)
  const { handleOpenShop } = useShopDetails()

  const handleClick = (shopId) => {
    handleOpenShop(shopId)
  }

  useEffect(() => {
    const routeMap = route.reduce((acc, curr) => {
      const initial = { ...acc }
      initial[curr.shopId] = curr.shopId
      return initial
    }, {})
    if (localMapInstance && !isEmpty(filteredSortedShops) && !isLoading) {
      const markers = new H.map.Group()
      setTempId(markers.getId())

      filteredSortedShops.forEach((shop) => {
        markers.addObject(
          ShopMarker({
            handleClick: handleClick,
            shopId: shop.id,
            organizationIcon: shop.organizationIcon,
            isInCurrentRoute: !!routeMap[shop.id],
            routingIsActive: route.length !== 0,
            lat: shop.location.lat,
            lng: shop.location.lng,
            ...(shop.logotype && {
              img: shop.logotype.includes('lorem')
                ? shop.logotype
                : getImageFromServer(shop.logotype)
            })
          })
        )
      })
      localMapInstance.getObjects().forEach((object) => {
        object.getId() === tempId && localMapInstance.removeObject(object)
      })
      localMapInstance.addObject(markers)
    }
  }, [localMapInstance, filteredSortedShops, route])
}
export default useAddMarkers
