import styled from 'styled-components'
import { HStack } from '../../global/styles'
import Typography from '../UI/Typography'

export const Wrapper = styled(HStack)`
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  box-shadow: inset 0px -1px 0px rgba(208, 201, 214, 0.4);
`

export const ImageNameWrap = styled(HStack)`
  align-items: center;
  justify-content: flex-start;
`

export const ContactName = styled(Typography).attrs({
  variant: 'textM'
})`
  color: ${({ theme }) => theme.colors.skyNeutral};
  margin-left: 16px;
`
export const ContactNameWrap = styled(HStack)`
`
export const Pronouns = styled(Typography).attrs({
  variant: 'textXS',
  bold: true
})`
  margin-left: 5px;
  color: ${({ theme }) => theme.colors.skyNeutral};
`
