import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { ContentWrap, OverlayScreen, SuccessScreenWrap } from './styles'
import { AuthDescription, AuthHeadline } from '../../../global/styles'
import OphopLogo from '../../../assets/vector/OphopLogo'
import SuccessScreenCollage from '../../../assets/vector/SuccessScreenCollage'
import { BUTTON_VARIANTS, BUTTONS_SIZES } from '../../../const/UIvariants'
import Button from '../../../components/UI/Buttons/Button'
import ROUTES from '../../../const/ROUTES'

const SuccessScreenOverlay = ({ title, description, destination }) => {
  const history = useHistory()
  return (
    <OverlayScreen>
      <SuccessScreenWrap>
        <OphopLogo />
        <ContentWrap>
          <SuccessScreenCollage />
          <AuthHeadline align="center">
            {title ?? 'Your Account has been created, Welcome to our Community'}
          </AuthHeadline>
          <AuthDescription align="center">
            {description ?? 'We are very happy you want to hop with us'}
          </AuthDescription>
        </ContentWrap>
        <Button
          variant={BUTTON_VARIANTS.PRIMARY}
          size={BUTTONS_SIZES.MED}
          onClick={() => {
            history.push(destination)
          }}
        >
          Continue
        </Button>
      </SuccessScreenWrap>
    </OverlayScreen>
  )
}

export default SuccessScreenOverlay

SuccessScreenOverlay.defaultProps = {
  title: null,
  description: null,
  destination: ROUTES.LOGIN
}

SuccessScreenOverlay.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  destination: PropTypes.string
}
