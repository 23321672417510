/*eslint-disable*/
import React from 'react'
import PropTypes from 'prop-types'
import Badge from '../UI/Badge'
import { BADGE_VARIANTS } from '../../const/UIvariants'
import { formatPostDeadline } from '../../utils/helpers'
import {
  QuestionItemWrap,
  QuestionDetailsWrap,
  QuestionTitle
} from './styles'

const HolidayItem = ({ name, dateFrom, dateTo }) => (
  <QuestionItemWrap>
    <QuestionDetailsWrap>
      <QuestionTitle>{name}</QuestionTitle>
      <Badge
        label={formatPostDeadline(dateFrom, dateTo)}
        variant={BADGE_VARIANTS.PINK}
      />
    </QuestionDetailsWrap>
  </QuestionItemWrap>
)

export default HolidayItem

HolidayItem.propTypes = {
  name: PropTypes.string.isRequired,
  dateFrom: PropTypes.string.isRequired,
  dateTo: PropTypes.string.isRequired
}
