import React from 'react'
import PropTypes from 'prop-types'
import { useField } from 'formik'
import {
  InputError,
  InputOuterLabel,
  InputWrapper,
  StyledInput
} from './styles'

const TextAreaInput = ({ disabled, label, rows, inputName, placeholder }) => {
  const [field, meta] = useField({ name: inputName })
  const { name, onBlur, onChange, value, multiple } = field
  return (
    <InputWrapper error={meta.touched && meta.error}>
      <InputOuterLabel
        {...{ disabled }}
        htmlFor={name}
        variant="textS"
        component="label"
      >
        {label}
      </InputOuterLabel>
      <StyledInput
        {...{
          name,
          onBlur,
          onChange,
          value,
          multiple,
          disabled,
          placeholder,
          rows
        }}
        error={meta.touched && meta.error}
      />
      <InputError>{meta.touched && meta.error ? meta.error : null}</InputError>
    </InputWrapper>
  )
}

export default TextAreaInput

TextAreaInput.defaultProps = {
  disabled: false,
  placeholder: 'Enter data',
  rows: null
}
TextAreaInput.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string.isRequired,
  inputName: PropTypes.string.isRequired,
  rows: PropTypes.number,
  disabled: PropTypes.bool
}
