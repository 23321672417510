import { useParams } from 'react-router-dom'
import { find } from 'lodash/collection'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  api,
  useGetRoomsQuery,
  useLazyGetRoomsQuery
} from '../../api/api.generated'

const useGetRoomMetaData = () => {
  const { roomId } = useParams()
  const dispatch = useDispatch()
  const { currentRoomData, isLoading } = useGetRoomsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      currentRoomData: find(data, (room) => room.id === roomId)
    })
  })

  const [getRooms] = useLazyGetRoomsQuery(undefined)

  useEffect(() => {
    if (!currentRoomData) {
      getRooms()
    }
  }, [currentRoomData])

  useEffect(() => {
    dispatch(api.util.invalidateTags(['Counters']))
  }, [])
  return { currentRoomData, isLoading }
}
export default useGetRoomMetaData
