import React from 'react'
import { NoDataMessage, VStack } from '../../../global/styles'
import LoadingSpinner from '../../../components/LoadingSpinner'
import ContactRequestItem from '../../../components/ContactRequestItem'
import useHandlePendingRequests from '../../../hooks/friends/useHandlePendingRequests'
import usePersistOpenFriendsTab from '../../../hooks/friends/usePersistOpenFriendsTab'

const FriendshipRequests = () => {
  usePersistOpenFriendsTab('/requests')
  const {
    requestsList: { listOfPendingRequests, isLoading },
    confirmAddFriendRequest,
    declineAddFriendRequest
  } = useHandlePendingRequests()
  return (
    <VStack>
      {isLoading && <LoadingSpinner />}
      {listOfPendingRequests.length !== 0 ? (
        listOfPendingRequests.map((contact) => (
          <ContactRequestItem
            key={contact.id}
            contactAvatar={contact.contactAvatar}
            id={contact.id}
            contactName={contact.contactName}
            preferredPronouns={contact.preferredPronouns}
            confirmAddFriendRequest={() => {
              confirmAddFriendRequest(contact.id)
            }}
            declineAddFriendRequest={() => {
              declineAddFriendRequest(contact.id)
            }}
            bgColor={contact.avatarBackground}
          />
        ))
      ) : (
        <NoDataMessage> You don&apos;t have any requests yet</NoDataMessage>
      )}
    </VStack>
  )
}

export default FriendshipRequests

FriendshipRequests.propTypes = {}
