import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  Explore,
  Home,
  Event,
  SupervisedUserCircle,
  Person
} from '@styled-icons/material'
import {
  useGetUserShoppingCartQuery
} from '../../api/api.generated'
import ROUTES from '../../const/ROUTES'
import MainTab from '../MainTab'
import { TabBarWrap } from './styles'

const MAIN_NAV_TABS = [
  { label: 'Home', Icon: <Home />, destination: ROUTES.HOME },
  { label: 'Hop', Icon: <Explore />, destination: ROUTES.HOP },
  { label: 'Events', Icon: <Event />, destination: ROUTES.EVENTS },
  {
    label: 'Friends',
    Icon: <SupervisedUserCircle />,
    destination: ROUTES.FRIENDS
  },
  /*
  {
    label: 'Cart',
    Icon: <ShoppingCart />,
    destination: ROUTES.SHOPPING_CART
  },
  {
    label: 'Order',
    Icon: <History />,
    destination: ROUTES.ORDERS
  },
  */
  {
    label: 'Me',
    Icon: <Person />,
    destination: ROUTES.SETTINGS
  }
]
const MainSectionTabBar = () => {
  const history = useHistory()
  const { pathname } = useLocation()
  const { data: { shopProducts } = {} } = useGetUserShoppingCartQuery()

  const handleClick = (destination) => {
    history.push(destination)
  }

  return (
    <TabBarWrap>
      {MAIN_NAV_TABS.map((navItem) => (
        <MainTab
          key={navItem.label}
          handleClick={() => {
            handleClick(navItem.destination)
          }}
          icon={navItem.Icon}
          label={navItem.label}
          isActive={pathname.includes(navItem.destination)}
          count={
            navItem.label === 'Me'
              ? shopProducts?.length ?? 0
              : null
          }

        />
      ))}
    </TabBarWrap>
  )
}

export default MainSectionTabBar

MainSectionTabBar.propTypes = {}
