import React from 'react'
import Modal from 'react-modal'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES,
} from '../../../const/UIvariants'
import Button from '../../UI/Buttons/Button'
import Typography from '../../UI/Typography'
import { StyledForm, ModalHeadline, ErrorWrap, ButtonWrapper } from './styles'

const ShowMessageModal = ({ isOpen, isLoading, title, message, onClose }) => (
  <Modal {...{ isOpen }}>
    <Formik>
      <StyledForm>
        <ModalHeadline>{title}</ModalHeadline>
        <ErrorWrap>
          <Typography variant="textS">
            {message}
          </Typography>
        </ErrorWrap>     
        <ButtonWrapper>
          <Button
            variant={BUTTON_VARIANTS.OUTLINED}
            size={BUTTONS_SIZES.MED}
            onClick={onClose}
            isLoading={isLoading}
            type="button"
          >
            Close
          </Button>
        </ButtonWrapper>
      </StyledForm>
    </Formik>
  </Modal>
)

export default ShowMessageModal

ShowMessageModal.defaultProps = {
  isLoading: false
}

ShowMessageModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
}
