import React, { useEffect, useState, useRef, useCallback } from 'react'
import { isEmpty } from 'lodash/lang'
import PropTypes from 'prop-types'
import { useLazyGetUserShopByShopIdGalleryQuery } from '../../../api/api.generated'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import { getImageFromServer, mergeArrays } from '../../../utils/helpers'
import { IsEmptyText } from '../ShopPosts/styles'
import { GalleryWrap, ImageWrap } from './styles'

const ShopGallery = ({ shopId }) => {
  const [hasMore, setHasMore] = useState(true)
  const [page, setPage] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [images, setImages] = useState([])
  const observer = useRef();
  const [getUserShopByShopIdGallery, { data: { shopImages } = {}, isLoading: loadingImages }] = useLazyGetUserShopByShopIdGalleryQuery()
  const ITEMS_PER_PAGE = 20

  const lastElementRef = useCallback(  
    (node) => {
      if (isLoading) return;
      if (!hasMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setPage((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore]
  );

  const handleShowMore = () => {
    setIsLoading(true)
    getUserShopByShopIdGallery({ shopId, page, limit: ITEMS_PER_PAGE })
  }

  useEffect(() => {
    handleShowMore()
  }, [])

  useEffect(() => {
    handleShowMore()
  }, [page])

  useEffect(() => {
    if (shopImages) {
      setIsLoading(false)
      setHasMore(shopImages.length === ITEMS_PER_PAGE)
      setImages(mergeArrays(images, shopImages))
    }
  }, [shopImages])

  return (
    <>
      {loadingImages && <LoadingSpinner />}
      {images && (
        <GalleryWrap>
          {isEmpty(images) && (
            <IsEmptyText>This store doesn&apos;t have any images</IsEmptyText>
          )}
          {images.map((image) => (
            <ImageWrap key={image} background={getImageFromServer(image)} />
          ))}
        </GalleryWrap>
      )}
      {isLoading &&
      <div>Loading...</div>
      }
      {!isLoading && hasMore &&
      <div ref={lastElementRef}>&nbsp;</div>
      }      
    </>
  )
}

export default ShopGallery

ShopGallery.propTypes = {
  shopId: PropTypes.string.isRequired
}
