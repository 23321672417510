import { useContext, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash/lang'
import { at } from 'lodash/object'
import { remove } from 'lodash/array'
import MapContext from '../../features/hop/MapContext'
import useCreateMapRoute from './useCreateMapRoute'
import {
  selectClosestInRoute,
  selectCurrentCityShops,
  selectCurrentDestinationIndex,
  selectDoesRouteRequiresUpdate,
  selectHopRoute,
  selectIsSearchOpen
} from '../../store/selectors'
import {
  removeShopFromRouteByShopId,
  setCurrentDestinationIndex,
  setRouteRequiredUpdate
} from '../../features/hop/hopSlice'

const useOnRoute = () => {
  const mapInstance = useContext(MapContext)
  const dispatch = useDispatch()
  const { buildRoute, stopRoute } = useCreateMapRoute()

  const closestInRoute = useSelector(selectClosestInRoute)
  const [isOpen, setIsOpen] = useState(false)
  const [shopsInRoutesData, setShopInRoutesData] = useState()
  const shops = useSelector(selectCurrentCityShops, shallowEqual)
  const currentTargetIndex = useSelector(selectCurrentDestinationIndex)
  const isRouteRequiresUpdate = useSelector(selectDoesRouteRequiresUpdate)
  const isSearchOpen = useSelector(selectIsSearchOpen)
  const route = useSelector(selectHopRoute, shallowEqual)

  useEffect(() => {
    if (!isEmpty(route) && !isEmpty(shops)) {
      const ids = route.map((shop) => shop.shopId)
      setShopInRoutesData(at(shops, ids))
    } else setShopInRoutesData([])
  }, [route, shops])
  useEffect(() => {
    if (route.length === 0) {
      stopRoute()
    }
    if (isRouteRequiresUpdate && !isSearchOpen) {
      buildRoute(route)
      dispatch(setRouteRequiredUpdate(false))
    }
  }, [isRouteRequiresUpdate, isSearchOpen])
  const tempToggleDrawerSize = () => {
    setIsOpen((p) => !p)
  }
  const handleClickOnShopLogo = ({ lat, lng }) => {
    mapInstance.setCenter({ lat, lng })
    mapInstance.setZoom(18)
    setIsOpen(false)
  }

  const handleRemoveShopFromRoute = (shopId) => {
    dispatch(removeShopFromRouteByShopId(shopId))
    const routeCopy = [...route]
    remove(routeCopy, (shop) => shop.shopId === shopId)
    if (routeCopy.length === 0) {
      stopRoute()
    }
    if (currentTargetIndex !== 0) {
      dispatch(setCurrentDestinationIndex(currentTargetIndex - 1))
    }
  }
  return {
    closestInRoute,
    isOpen,
    shopsInRoutesData,
    tempToggleDrawerSize,
    handleClickOnShopLogo,
    handleRemoveShopFromRoute,
    currentTargetIndex,
    setIsOpen
  }
}

export default useOnRoute
