import styled from 'styled-components'
import { VStack } from '../../../global/styles'

export const NoShopsWrap = styled(VStack)`
  align-items: center;
  margin-top: 40px;
`
export const IconWrap = styled.div`
  width: 200px;
`
