import React from 'react'
import PropTypes from 'prop-types'
import { CheckCircle, NotInterested } from '@styled-icons/material'
import { ButtonsWrapper, ContactName, ImageNameWrap, Wrapper, ContactNameWrap, Pronouns } from './styles'
import UserAvatar from '../UserAvatar/UserAvatar'
import IconButton from '../UI/Buttons/IconButton'

const ContactRequestItem = ({
  contactName,
  preferredPronouns,
  contactAvatar,
  confirmAddFriendRequest,
  declineAddFriendRequest,
  bgColor
}) => (
  <Wrapper>
    <ImageNameWrap>
      <UserAvatar
        image={contactAvatar}
        size={44}
        userName={contactName}
        {...{ bgColor }}
      />
      <ContactNameWrap>
        <ContactName>{contactName}</ContactName>
        {preferredPronouns !== '' &&
        <Pronouns>({preferredPronouns})</Pronouns>
        }
      </ContactNameWrap>
    </ImageNameWrap>
    <ButtonsWrapper>
      <IconButton size={32} onClick={confirmAddFriendRequest}>
        <CheckCircle size={32} color="#4EA64C" />
      </IconButton>
      <IconButton size={32} onClick={declineAddFriendRequest}>
        <NotInterested size={32} color="#EC72A5" />
      </IconButton>
    </ButtonsWrapper>
  </Wrapper>
)

export default React.memo(ContactRequestItem)

ContactRequestItem.defaultProps = {
  contactAvatar: null,
  preferredPronouns: ''
}
ContactRequestItem.propTypes = {
  contactName: PropTypes.string.isRequired,
  preferredPronouns: PropTypes.string,
  bgColor: PropTypes.string.isRequired,
  contactAvatar: PropTypes.string,
  confirmAddFriendRequest: PropTypes.func.isRequired,
  declineAddFriendRequest: PropTypes.func.isRequired
}
