import styled from 'styled-components'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { HStack, VStack } from '../../../global/styles'
import { fadeId } from '../../../global/animations'
// height: ${({ isOpen }) => (isOpen ? '100%' : '20%')};
// export const DrawerWrapper = styled(VStack)`
//   display: flex;
//   margin-top: auto;
//   position: fixed;
//   top: ${({ isOpen }) => (isOpen ? '15vh' : '80vh')};
//   //margin-bottom: 90px;
//   bottom: 72px;
//   //height: 100%;
//   z-index: 20;
//   min-height: 100px;
//   border-radius: 16px 16px 0 0;
//   transition: top 0.2s ease-in-out;
//   background-color: ${({ theme }) => theme.colors.white};
// `
export const DrawerWrapper = styled(VStack)`
  height: 75vh;
  align-items: stretch;
  justify-content: flex-start;
`
export const DragNotchWrap = styled(HStack)`
  width: 100%;
  min-height: 32px;
  align-items: center;
  justify-content: center;
`
export const DragNotch = styled.div`
  width: 52px;
  height: 4px;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.skyNeutral2};
`

export const ContainerWrap = styled(OverlayScrollbarsComponent).attrs({
  options: {
    scrollbars: { visibility: 'hidden' }
  }
})`
  height: 100%;
  width: 100%;
  padding: 18px 16px 20px 16px;
`
export const OpenDrawerHeader = styled(HStack)`
  padding: 12px 12px;
  justify-content: space-between;

  animation: ${fadeId} 0.3s ease-in-out forwards;
`
export const ClosedDrawerHeader = styled(VStack)`
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;

  animation: ${fadeId} 0.3s ease-in-out forwards;
`

export const TabsWrap = styled(HStack).attrs({ as: 'ul' })`
  height: 52px;
  z-index: 10;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.03);
`
export const SwipeableStyles = {
  style: { borderRadius: '16px 16px 0 0', zIndex: '20', marginBottom: '64px' },
  bodyStyle: {
    borderRadius: '16px 16px 0 0',
    overflow: 'hidden'
  },
  overlayStyle: { borderRadius: '16px 16px 0 0', background: 'transparent' }
}
