import React, { useState } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as Yup from 'yup'
import ROUTES from '../../../const/ROUTES'
import {
  INPUT_SIZES,
  BUTTON_VARIANTS,
  BUTTONS_SIZES
} from '../../../const/UIvariants'
import TextInput from '../../UI/Inputs/TextInput'
import Button from '../../UI/Buttons/Button'
import Typography from '../../UI/Typography'
import { getErrorMessage } from '../../../utils/helpers'
import { 
  usePostChatMessageToUserMutation,
  usePostChatMessageToShopMutation
} from '../../../api/api.generated'
import { ButtonWrapper, ModalHeadline, StyledForm, ErrorWrap } from './styles'

const SendMessageModal = ({ isOpen, onClose, userId, shopId }) => {
  const history = useHistory()
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [postUserMessage] = usePostChatMessageToUserMutation()
  const [postShopMessage] = usePostChatMessageToShopMutation()

  const handleSubmit = (values) => {
    setError('')
    setIsLoading(true)
    if (userId) {
      postUserMessage({
        userId,
        body: {
          message: values.message
        }
      })
      .unwrap()
      .then((res) => {
        history.push(generatePath(ROUTES.FRIENDS_CHAT, { roomId: res.roomId }))
        onClose()
      })
      .catch((err) => {
        setIsLoading(false)
        setError(getErrorMessage(err))
      })
    }
    if (shopId) {
      postShopMessage({
        shopId,
        body: {
          message: values.message
        }
      })
      .unwrap()
      .then((res) => {
        history.push(generatePath(ROUTES.FRIENDS_CHAT, { roomId: res.roomId }))
        onClose()
      })
      .catch((err) => {
        setIsLoading(false)
        setError(getErrorMessage(err))
      })
    }  
  }  

  return (
    <Modal {...{ isOpen }}>
      <Formik
        initialValues={{
          message: ''
        }}
        validationSchema={Yup.object({
          message: Yup.string().max(250, 'Message is too long').required('Message is required')
        })}        
        onSubmit={handleSubmit}
      >
        <StyledForm>
          <ModalHeadline>Send message</ModalHeadline>
          <TextInput size={INPUT_SIZES.SMALL} inputName="message" />
          {error && (
            <ErrorWrap>
              <Typography variant="textS" color="red">
                {error}
              </Typography>
            </ErrorWrap>          
           )}
          <ButtonWrapper>
            <Button
              variant={BUTTON_VARIANTS.PRIMARY}
              size={BUTTONS_SIZES.MED}
              type="submit"
              isLoading={isLoading}
            >
              Send
            </Button>
            <Button
              variant={BUTTON_VARIANTS.OUTLINED}
              size={BUTTONS_SIZES.MED}
              onClick={onClose}
              type="button"
            >
              Cancel
            </Button>
          </ButtonWrapper>
        </StyledForm>
      </Formik>
    </Modal>
  )
}

export default SendMessageModal

SendMessageModal.defaultProps = {
  userId: null,
  shopId: null
}

SendMessageModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  userId: PropTypes.string,
  shopId: PropTypes.string
}
