import React from 'react'
import { Form, Formik } from 'formik'
import { RemoveRedEye, VisibilityOff } from '@styled-icons/material'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'
import { InputsWrap, LoginWrap, MessageWrap } from './styles'
import {
  AuthButtonsWrap,
  AuthDescription,
  AuthHeadline,
  ScreenContainer
} from '../../../global/styles'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES,
  INPUT_SIZES
} from '../../../const/UIvariants'
import TextInput from '../../../components/UI/Inputs/TextInput/TextInput'
import OphopLogo from '../../../assets/vector/OphopLogo'
import Button from '../../../components/UI/Buttons/Button/Button'
import ROUTES from '../../../const/ROUTES'
import LinkButton from '../../../components/UI/Buttons/LinkButton'
import PhoneNumberInput from '../../../components/UI/Inputs/PhoneNumberInput/PhoneNumberInput'
import useLogin from '../../../hooks/auth/useLogin'

const Login = () => {
  const {
    isLoggingIn,
    handleLogin,
    handleShowPassword,
    handleGoToRestorePassword,
    passwordInputType
  } = useLogin()
  const history = useHistory()
  return (
    <ScreenContainer>
      <Formik
        initialValues={{
          phoneNumber: '',
          password: ''
        }}
        validationSchema={Yup.object({
          phoneNumber: Yup.string()
            .matches(/^[0-9]+$/, 'Please enter valid phone number')
            .test(
              'len',
              'Must be exactly 11 digits',
              (val) => val?.length === 11
            ),
          password: Yup.string()
            .min(6, 'Password must contain at least 6 characters')
            .required('Required')
        })}
        onSubmit={handleLogin}
      >
        {({ isValid }) => (
          <Form>
            <LoginWrap>
              <OphopLogo />
              <MessageWrap>
                <AuthHeadline>Welcome Back</AuthHeadline>
                <AuthDescription>Please log in to your account</AuthDescription>
              </MessageWrap>
              <InputsWrap>
                <PhoneNumberInput
                  inputName="phoneNumber"
                  label="Phone Number"
                  type="tel"
                  placeholder="Phone Number"
                  size={INPUT_SIZES.LARGE}
                />
                <TextInput
                  type={passwordInputType}
                  placeholder="Enter your password"
                  rightIcon={
                    passwordInputType === 'password' ? (
                      <RemoveRedEye />
                    ) : (
                      <VisibilityOff />
                    )
                  }
                  rightIconClick={handleShowPassword}
                  size={INPUT_SIZES.LARGE}
                  inputName="password"
                  label="Password"
                />
                <LinkButton onClick={handleGoToRestorePassword}>
                  Forgot Password?
                </LinkButton>
              </InputsWrap>
              <AuthButtonsWrap>
                <Button
                  variant={BUTTON_VARIANTS.PRIMARY}
                  size={BUTTONS_SIZES.MED}
                  type="submit"
                  disabled={!isValid}
                  isLoading={isLoggingIn}
                >
                  Login
                </Button>
                {false &&
                <Button
                  variant={BUTTON_VARIANTS.SECONDARY}
                  size={BUTTONS_SIZES.MED}
                  type="button"
                  onClick={() => {
                    history.push(ROUTES.SIGNUP)
                  }}
                >
                  Create Account
                </Button>
                }
              </AuthButtonsWrap>
            </LoginWrap>
          </Form>
        )}
      </Formik>
    </ScreenContainer>
  )
}

export default Login

Login.propTypes = {}
