import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { PersistGate } from 'redux-persist/integration/react'
import { ThemeProvider } from 'styled-components'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import './config/interceptors'
import './config/modal'
import App from './features/app/App'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'overlayscrollbars/css/OverlayScrollbars.css'
import store, { persistor } from './store/store'
import { GlobalStyle, globalTheme } from './global/theme'

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <GlobalStyle />
      <ThemeProvider theme={globalTheme}>
        <Router>
          <App />
        </Router>
      </ThemeProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)
