import React from 'react'
import { PersonPin } from '@styled-icons/material'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { BadgeBody } from './styles'
import Typography from '../UI/Typography'

const ClosestBadge = ({ shopId, isClosest }) => {
  const closestShopId = useSelector((state) => state.hop.closestFilteredShopId)
  return (
    <BadgeBody visible={shopId === closestShopId || isClosest || false}>
      <PersonPin size={12} color="#ffffff" />
      <Typography variant="textXS" color="#ffffff">
        Closest
      </Typography>
    </BadgeBody>
  )
}

export default ClosestBadge

ClosestBadge.defaultProps = {
  shopId: null,
  isClosest: false
}

ClosestBadge.propTypes = {
  shopId: PropTypes.string,
  isClosest: PropTypes.bool
}
