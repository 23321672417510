import styled from 'styled-components'
import { HStack } from '../../global/styles'

export const SearchInputBody = styled.input`
  width: 100%;
  height: 56px;
  font-size: 17px;
  background-color: ${({ theme, error }) =>
    error ? theme.colors.pinkBase4 : theme.colors.skyNeutral4};
  color: ${({ theme, error }) =>
    error ? theme.colors.pinkBase : theme.colors.skyNeutral_1};
  outline: none;
  border: none;
  display: flex;
  border-radius: 8px;
  padding-left: 52px;

  & :placeholder-shown {
    padding-top: 0;
  }
  &:focus {
    box-shadow: 0 0 0 2px hsla(219, 12%, 51%, 1);
  }
  &::-webkit-search-cancel-button {
    display: none;
  }
`
export const InputWrap = styled(HStack)`
  width: 100%;
  position: relative;
  height: 56px;
  align-items: center;
  margin-right: 25px;
`
export const SearchIconWrap = styled.div`
  position: absolute;
  left: 20px;
`
