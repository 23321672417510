import { apiV1 } from './axios'
import store, { persistor } from '../store/store'
import { logout } from '../features/auth/authSlice'

apiV1.interceptors.response.use(
  (response) => response,
  (e) => {
    const { status } = e.response
    if (status === 401) {
      store.dispatch(logout())
      persistor.purge()
    }
    return Promise.reject(e)
  }
)

apiV1.interceptors.request.use(
  (req) => {
    const { token } = store.getState().auth
    if (token) {
      // eslint-disable-next-line no-param-reassign
      req.headers = {
        Authorization: `Bearer ${token}`
      }
    }
    return req
  },
  (e) => {
    if (e.toJSON().message === 'Network Error') {
      // eslint-disable-next-line no-console
      console.log('No internet connection')
    }
    return Promise.reject(e)
  }
)
