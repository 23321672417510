import React, { useState } from 'react'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { FilterList } from '@styled-icons/material'
import { useSelector } from 'react-redux'
import SwipeableBottomSheet from 'react-swipeable-bottom-sheet'
import {
  ClosedDrawerHeader,
  DragNotch,
  DragNotchWrap,
  DrawerWrapper,
  OpenDrawerHeader,
  SwipeableStyles
} from './styles'
import ROUTES from '../../../const/ROUTES'
import HopDrawerLocation from '../../../components/HopDrawerLocation'
import Typography from '../../../components/UI/Typography'
import IconButton from '../../../components/UI/Buttons/IconButton'
import { selectCurrentCityName } from '../../../store/selectors'
import useStartGoNow from '../../../hooks/route/useStartGoNow'
import HopDrawerTabs from '../../../components/HopDrawerTabs'
import { HOP_TABS } from '../../../const/tabs'

const HopDrawerContainer = () => {
  // need this hook here to listen to "go now" events across the app
  useStartGoNow()
  const [open, setIsOpen] = useState(false)
  const cityName = useSelector(selectCurrentCityName)
  const history = useHistory()
  // const [isOpen, setIsOpen] = useState(true)
  const location = useLocation()
  return (
    <SwipeableBottomSheet
      overflowHeight={140}
      marginTop={190}
      open={open}
      onChange={(_open) => {
        setIsOpen(_open)
      }}
      topShadow={false}
      style={SwipeableStyles.style}
      bodyStyle={SwipeableStyles.bodyStyle}
      overlayStyle={SwipeableStyles.overlayStyle}
    >
      <DrawerWrapper>
        <DragNotchWrap>
          <DragNotch />
        </DragNotchWrap>
        {open ? (
          <OpenDrawerHeader>
            <HopDrawerLocation city={cityName} />
            <IconButton
              onClick={() => {
                history.push(location.pathname + ROUTES.HOP_FILTER)
              }}
            >
              <FilterList size={24} color="#A0A7B5" />
            </IconButton>
          </OpenDrawerHeader>
        ) : (
          <ClosedDrawerHeader>
            <HopDrawerLocation city={cityName} />
            <Typography
              align="center"
              variant="headingS"
              color="hsla(220, 12%, 67%, 1)"
            >
              Slide up to see stores in {cityName}
            </Typography>
          </ClosedDrawerHeader>
        )}

        <HopDrawerTabs />
        <div style={{ height: 'auto', flex: 1 }}>
          <Switch>
            {HOP_TABS.map((routeItem) => (
              <Route
                key={routeItem.destination}
                path={routeItem.destination}
                render={routeItem.Component}
              />
            ))}
          </Switch>
        </div>
      </DrawerWrapper>
    </SwipeableBottomSheet>
  )
}

export default HopDrawerContainer

HopDrawerContainer.propTypes = {}
