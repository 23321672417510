import styled from 'styled-components'
import { HStack, VStack } from '../../global/styles'
import Typography from '../UI/Typography'

export const ShopItemWrap = styled(VStack)`
  width: 193px;
  box-shadow: 0px 8px 16px -5px rgba(7, 16, 34, 0.1);
  border-radius: 8px;
  align-items: center;
  padding: 12px;
  margin-right: 12px;
`
export const ClosestBadgeWrap = styled(HStack)`
  justify-content: center;
  position: absolute;
  top: 60px;
  margin: 0 auto;
`
export const ShopName = styled(Typography).attrs(({ theme }) => ({
  color: theme.colors.skyNeutral_2,
  variant: 'textS',
  bold: true
}))`
  text-align: center;
`
export const ShopAddress = styled(Typography).attrs(({ theme }) => ({
  color: theme.colors.skyNeutral2,
  variant: 'textXS'
}))`
  margin: 2px 0 4px 0;
`
export const ShopMetaData = styled(HStack)`
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
`

export const ShopRating = styled(HStack)`
  width: auto;
  margin-right: 8px;
  align-items: center;
`
export const Rating = styled(Typography).attrs(({ theme }) => ({
  color: theme.colors.skyNeutral_1,
  variant: 'textXS'
}))`
  margin-left: 2px;
`
export const NameAddressWrap = styled(VStack)`
  align-items: center;
  margin-top: 16px;
  cursor: pointer;
`
export const ButtonsWrapper = styled(HStack)`
  margin-top: 8px;
  > *:first-child {
    margin-right: 8px;
  }
`
