import { useState } from 'react'
import { omit } from 'lodash/object'
import { usePostUserProfileChangePasswordMutation } from '../../api/api.generated'

const useChangePassword = () => {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [sendUpdatePasswordRequest, { isLoading }] =
    usePostUserProfileChangePasswordMutation()
  const handleUpdatePassword = (values, formik) => {
    sendUpdatePasswordRequest({
      body: { ...omit(values, 'repeatNewPassword') }
    })
      .unwrap()
      .then(() => {
        setIsSubmitted(true)
      })
      .catch((err) => {
        if (err?.data?.message) {
          formik.setErrors({ ...err.data.message })
        }
      })
  }
  return { isSubmitted, handleUpdatePassword, isLoading }
}
export default useChangePassword
