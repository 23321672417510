import React from 'react'
import PropTypes from 'prop-types'
import { HStack } from '../../global/styles'
import {
  ProductDetailsWrap,
  ProductImage,
  ProductItemWrap,
  ProductName,
  ProductPrice,
  ShippingCost
} from './styles'
import { getImageFromServer } from '../../utils/helpers'

const OrderProductItem = ({
  productImage,
  productPrice,
  productName,
  shippingCost
}) => (
  <ProductItemWrap>
    <ProductImage background={getImageFromServer(productImage)} />
    <ProductDetailsWrap>
      <ProductName>{productName}</ProductName>
      <HStack>
        <ProductPrice>Price: ${productPrice}</ProductPrice>
        <ShippingCost>Shipping: ${shippingCost}</ShippingCost>
      </HStack>
    </ProductDetailsWrap>
  </ProductItemWrap>
)

export default OrderProductItem

OrderProductItem.defaultProps = {
  productImage: null,
}

OrderProductItem.propTypes = {
  productImage: PropTypes.string,
  productPrice: PropTypes.number.isRequired,
  shippingCost: PropTypes.number.isRequired,
  productName: PropTypes.string.isRequired,
}
