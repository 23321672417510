import axios from 'axios'
import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from '../config/axios'
import { API_KEY } from '../const/hereWeGo'

export const api = createApi({
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    'ShopVolunteers',
    'ShopPosts',
    'ShopSubscriptions',
    'ShopFavourites',
    'ShopVisited',
    'Events',
    'ShopProducts',
    'ShoppingCart',
    'Orders',
    'Notes',
    'States',
    'ChatMessages',
    'ShopsPreview',
    'Distances',
    'Contacts',
    'User',
    'Counters'
  ],

  endpoints: (build) => ({
    getStates: build.query({
      query: () => ({ url: `/user/states` }),
      providesTags: ['States']      
    }),
    postUserLogin: build.mutation({
      query: (queryArg) => ({
        url: `/user/login`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    postSignUp: build.mutation({
      query: (queryArg) => ({
        url: `/user/sign-up`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    postCheckCredentials: build.mutation({
      query: (queryArg) => ({
        url: `/checkCredentials`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    postUserRestorePassword: build.mutation({
      query: (queryArg) => ({
        url: `/user/restorePassword`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    postUserVerificationCode: build.mutation({
      query: (queryArg) => ({
        url: `/user/verificationCode`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    postUserCreateNewPassword: build.mutation({
      query: (queryArg) => ({
        url: `/user/createNewPassword`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    getTags: build.query({
      query: () => ({ url: `/tags` })
    }),
    getUserHomeScreen: build.query({
      query: (queryArg) => ({
        url: `/user/homeScreen/${queryArg.cityId}/city`
      }),
      providesTags: ['ShopSubscriptions', 'ShopFavourites', 'ShopVisited']
    }),
    postUserAction: build.mutation({
      query: (queryArg) => ({
        url: `/user/actions`,
        method: 'POST',
        body: queryArg.body
      }),
      invalidatesTags: ['ShopVisited']
    }),    
    postUserSearchResult: build.mutation({
      query: (queryArg) => ({
        url: `/user/searchResult`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    getUserCities: build.query({
      query: () => ({ url: `/user/cities` })
    }),
    postUserRateShopByShopId: build.mutation({
      query: (queryArg) => ({
        url: `/user/rateShop/${queryArg.shopId}`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    getUserContacts: build.query({
      query: () => ({ url: `/user/contacts` }),
      providesTags: ['Contacts']
    }),
    getUserNotes: build.query({
      query: () => ({ url: `/user/notes` }),
      providesTags: ['Notes']
    }),
    postUserNote: build.mutation({
      query: (queryArg) => ({
        url: `/user/notes`,
        method: 'post',
        body: queryArg.body
      }),
      invalidatesTags: ['Notes']
    }),
    deleteUserNote: build.mutation({
      query: (noteId) => ({
        url: `/user/notes/${noteId}`,
        method: 'delete'
      }),
      invalidatesTags: ['Notes']
    }),
    getEvents: build.query({
      query: () => ({ url: `/user/events` }),
      providesTags: ['Events']
    }),
    getRooms: build.query({
      query: () => ({ url: `/user/chat/rooms` })
    }),
    getUserContactRequests: build.query({
      query: () => ({ url: `/user/contactRequests` })
    }),
    getUserAcceptRequestByUserId: build.query({
      query: (queryArg) => ({ url: `/user/acceptRequest/${queryArg.userId}` }),
      invalidatesTags: ['Contacts']
    }),
    getUserDeclineRequestByUserId: build.query({
      query: (queryArg) => ({ url: `/user/declineRequest/${queryArg.userId}` })
    }),
    postUserFindUser: build.mutation({
      query: (queryArg) => ({
        url: `/user/findUser`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    postBroadcastingAuth: build.mutation({
      query: (queryArg) => ({
        url: `/broadcasting/auth`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    postChatRoomByRoomIdMessage: build.mutation({
      query: (queryArg) => ({
        url: `/user/chat/room/${queryArg.roomId}/message`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    postChatMessageToUser: build.mutation({
      query: (queryArg) => ({
        url: `/user/chat/user/${queryArg.userId}/message`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    postChatMessageToShop: build.mutation({
      query: (queryArg) => ({
        url: `/user/chat/shop/${queryArg.shopId}/message`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    deleteChatMessage: build.mutation({
      query: (messageId) => ({
        url: `/user/chat/message/${messageId}`,
        method: 'delete'
      }),
      invalidatesTags: ['ChatMessages']
    }),
    deleteChatRoom: build.mutation({
      query: (roomId) => ({
        url: `/user/chat/room/${roomId}`,
        method: 'delete'
      }),
      invalidatesTags: ['ChatMessages']
    }),
    postUserToggleFavoriteByShopId: build.mutation({
      query: (queryArg) => ({
        url: `/user/toggleFavorite/${queryArg.shopId}`,
        method: 'POST',
        body: queryArg.body
      }),
      // todo - refactor to work properly
      async onQueryStarted({ shopId, body }, { dispatch }) {
        dispatch({
          type: 'hop/updateShopData',
          payload: {
            shopId,
            data: {
              isFavorite: body.isFavorite,
              favoriteText: body.reasonOfAdding
            }
          }
        })
      },      
      invalidatesTags: ['ShopSubscriptions', 'ShopFavourites']
    }),
    getChatRoomByRoomIdMessages: build.query({
      query: (queryArg) => ({ url: `/user/chat/room/${queryArg.roomId}/messages?id=${queryArg.messageId}` }),
      providesTags: ['ChatMessages']
    }),
    getUserShopByShopIdAbout: build.query({
      query: (queryArg) => ({ url: `/user/shop/${queryArg.shopId}/about` }),
      providesTags: ['ShopVolunteers']
    }),
    getUserShopByShopIdProducts: build.query({
      query: (queryArg) => ({ url: `/user/shop/${queryArg.shopId}/products?page=${queryArg.page}&limit=${queryArg.limit}` }),
      providesTags: ['ShopProducts']
    }),
    getUserShopByShopIdPosts: build.query({
      query: (queryArg) => ({ url: `/user/shop/${queryArg.shopId}/posts?page=${queryArg.page}&limit=${queryArg.limit}` }),
      providesTags: ['ShopPosts']
    }),
    getUserShopByShopIdGallery: build.query({
      query: (queryArg) => ({ url: `/user/shop/${queryArg.shopId}/gallery?page=${queryArg.page}&limit=${queryArg.limit}` })
    }),
    getUserShopByShopIdVideos: build.query({
      query: (queryArg) => ({ url: `/user/shop/${queryArg.shopId}/videos?page=${queryArg.page}&limit=${queryArg.limit}` })
    }),
    getUserShopByShopIdQuestions: build.query({
      query: (queryArg) => ({ url: `/user/shop/${queryArg.shopId}/questions?page=${queryArg.page}&limit=${queryArg.limit}` })
    }),
    getUserShopByShopIdHolidays: build.query({
      query: (queryArg) => ({ url: `/user/shop/${queryArg.shopId}/holidays?page=${queryArg.page}&limit=${queryArg.limit}` })
    }),
    getUserProfileDetails: build.query({
      query: () => ({ url: `/user/profile/details` }),
      providesTags: ['User']
    }),
    patchUserProfileDetails: build.mutation({
      query: (queryArg) => ({
        url: `/user/profile/details`,
        method: 'PATCH',
        body: queryArg.body
      }),
      invalidatesTags: ['User']
    }),
    postUserProfileChangePassword: build.mutation({
      query: (queryArg) => ({
        url: `/user/profile/changePassword`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    putUserProfileUserAvatar: build.mutation({
      query: (queryArg) => ({
        url: `/user/profile/userAvatar`,
        method: 'PUT',
        body: queryArg.body
      }),
      invalidatesTags: ['User']
    }),
    putUserProfileNotifications: build.mutation({
      query: (queryArg) => ({
        url: `/user/profile/notifications`,
        method: 'PUT',
        body: queryArg.body
      })
    }),
    getUserProfileNotifications: build.query({
      query: () => ({ url: `/user/profile/notifications` })
    }),
    postUsersShopsSearch: build.mutation({
      query: (queryArg) => ({
        url: `/users/shops/search`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    getUserShopByCityId: build.query({
      query: (queryArg) => ({ url: `/user/shops/${queryArg.cityId}` })
    }),
    getUserShopByShopIdVisited: build.query({
      query: (queryArg) => ({ url: `/user/shop/${queryArg.shopId}/visited` })
    }),
    getUserSendRequestByUserId: build.query({
      query: (queryArg) => ({ url: `/user/sendRequest/${queryArg.userId}` })
    }),
    postShopVolunteer: build.mutation({
      query: (queryArg) => ({
        url: `/user/shop/${queryArg.shopId}/volunteers`,
        method: 'post',
        body: queryArg.body
      }),
      invalidatesTags: ['ShopVolunteers']
    }),
    postShopSubscribe: build.mutation({
      query: (queryArg) => ({
        url: `/user/shop/${queryArg.shopId}/subscribe`,
        method: 'post',
        body: queryArg.body
      }),
      invalidatesTags: ['ShopSubscriptions']
    }),
    postShopPostLike: build.mutation({
      query: (queryArg) => ({
        url: `/user/post/${queryArg.postId}/like`,
        method: 'post',
        body: queryArg.body
      }),
      invalidatesTags: ['ShopPosts']
    }),
    postShopPostInterested: build.mutation({
      query: (queryArg) => ({
        url: `/user/post/${queryArg.postId}/interested`,
        method: 'post',
        body: queryArg.body
      }),
      invalidatesTags: ['ShopPosts', 'Events']
    }),
    postShopPostAttended: build.mutation({
      query: (queryArg) => ({
        url: `/user/post/${queryArg.postId}/attended`,
        method: 'post',
        body: queryArg.body
      }),
      invalidatesTags: ['ShopPosts', 'Events', 'User']
    }),
    getUserShoppingCart: build.query({
      query: () => ({ url: `/user/shoppingCart` }),
      providesTags: ['ShoppingCart']
    }),
    postShoppingCartAdd: build.mutation({
      query: (queryArg) => ({
        url: `/user/shoppingCart/${queryArg.productId}/add`,
        method: 'post'
      }),
      invalidatesTags: ['ShopProducts', 'ShoppingCart']
    }),
    postShoppingCartRemove: build.mutation({
      query: (queryArg) => ({
        url: `/user/shoppingCart/${queryArg.productId}/remove`,
        method: 'post'
      }),
      invalidatesTags: ['ShopProducts', 'ShoppingCart']
    }),
    postShoppingCartCheckout: build.mutation({
      query: (queryArg) => ({
        url: `/user/shoppingCart/checkout`,
        method: 'post',
        body: queryArg.body
      }),
      invalidatesTags: ['ShopProducts', 'ShoppingCart']
    }),
    postDonate: build.mutation({
      query: (queryArg) => ({
        url: `/user/donations`,
        method: 'post',
        body: queryArg.body
      }),
      invalidatesTags: ['ShopProducts', 'ShoppingCart']
    }),
    getOrders: build.query({
      query: () => ({ url: `/user/orders` }),
      providesTags: ['Orders']
    }),
    getOrderProducts: build.query({
      query: (queryArg) => ({
        url: `/user/orders/${queryArg.orderId}/products`
      })
    }),
    // enchanced
    /*
    getChatRoomByUserId: build.query({
      query: (userId) => ({
        url: `/user/chat/room/${userId}/user`
      })
    }),
    */
    setShopAsVisited: build.query({
      query: (shopId) => ({
        url: `/user/shop/${shopId}/visited`
      })
    }),
    getHereWeGoToken: build.query({
      query: () => ({
        url: `/hereToken`,
        method: 'GET'
      })
    }),
    postUserSupportRequest: build.mutation({
      query: (body) => ({
        url: '/user/supportRequest',
        method: 'POST',
        body
      })
    }),
    patchUserCheckProfileChangePhone: build.mutation({
      query: (body) => ({
        url: '/user/profile/changePhone',
        method: 'PATCH',
        body
      }),
      invalidatesTags: ['User']
    }),
    getUserCounters: build.query({
      query: () => ({
        url: '/user/counters',
        method: 'GET'
      }),
      providesTags: ['Counters']
    }),
    postCalculateDistanceMatrix: build.mutation({
      async queryFn(args, { getState }) {
        const { currentLat } = getState().app
        const { currentLng } = getState().app
        const shops = args?.shopsArray ?? Object.values(getState().hop.route)
        const response = await axios.post(
          `https://matrix.router.hereapi.com/v8/matrix?async=false&apiKey=${API_KEY}`,
          {
            origins: [
              {
                lat: currentLat,
                lng: currentLng
              }
            ],
            destinations: shops.map((shop) => ({
              lat: parseFloat(shop.shopLat),
              lng: parseFloat(shop.shopLng)
            })),
            regionDefinition: {
              type: 'world'
            },
            matrixAttributes: ['travelTimes', 'distances']
          }
        )
        // console.log(response)
        return response
      },
      providesTags: ['Distances']
    }),

    postReport: build.mutation({
      query: (queryArg) => ({
        url: `/user/shop/${queryArg.shopId}/report`,
        method: 'post',
        body: queryArg.body
      })
    })
  })
})
export const {
  useGetStatesQuery,  

  usePostUserLoginMutation,
  usePostSignUpMutation,
  usePostCheckCredentialsMutation,
  usePostUserRestorePasswordMutation,
  usePostUserVerificationCodeMutation,
  usePostUserCreateNewPasswordMutation,
  useGetTagsQuery,
  useGetUserHomeScreenQuery,
  usePostUserActionMutation,
  usePostUserSearchResultMutation,
  useGetUserCitiesQuery,
  usePostUserRateShopByShopIdMutation,
  useGetUserContactsQuery,
  useGetUserNotesQuery,
  usePostUserNoteMutation,
  useDeleteUserNoteMutation,
  useGetEventsQuery,
  useGetRoomsQuery,
  useLazyGetRoomsQuery,
  useGetUserContactRequestsQuery,
  useGetUserAcceptRequestByUserIdQuery,
  useLazyGetUserAcceptRequestByUserIdQuery,
  useGetUserDeclineRequestByUserIdQuery,
  useLazyGetUserDeclineRequestByUserIdQuery,
  usePostUserFindUserMutation,
  usePostBroadcastingAuthMutation,
  usePostChatRoomByRoomIdMessageMutation,
  usePostChatMessageToUserMutation,
  usePostChatMessageToShopMutation,
  useDeleteChatMessageMutation,
  useDeleteChatRoomMutation,

  usePostUserToggleFavoriteByShopIdMutation,
  useGetChatRoomByRoomIdMessagesQuery,
  useLazyGetChatRoomByRoomIdMessagesQuery,
  useGetUserShopByShopIdAboutQuery,
  useGetUserShopByShopIdProductsQuery,
  useLazyGetUserShopByShopIdProductsQuery,
  useGetUserShopByShopIdPostsQuery,
  useLazyGetUserShopByShopIdPostsQuery,
  useGetUserShopByShopIdGalleryQuery,
  useLazyGetUserShopByShopIdGalleryQuery,
  useGetUserShopByShopIdVideosQuery,
  useLazyGetUserShopByShopIdVideosQuery,
  useGetUserShopByShopIdQuestionsQuery,
  useLazyGetUserShopByShopIdQuestionsQuery,
  useGetUserShopByShopIdHolidaysQuery,
  useLazyGetUserShopByShopIdHolidaysQuery,
  useGetUserProfileDetailsQuery,
  usePatchUserProfileDetailsMutation,
  usePostUserProfileChangePasswordMutation,
  usePutUserProfileUserAvatarMutation,
  usePutUserProfileNotificationsMutation,
  useGetUserProfileNotificationsQuery,
  usePostUsersShopsSearchMutation,
  useGetUserShopByCityIdQuery,
  useGetUserShopByShopIdVisitedQuery,
  useGetUserSendRequestByUserIdQuery,

  usePostShopVolunteerMutation,
  usePostShopSubscribeMutation,
  usePostShopPostLikeMutation,
  usePostShopPostInterestedMutation,
  usePostShopPostAttendedMutation,

  useGetUserShoppingCartQuery,
  usePostShoppingCartAddMutation,
  usePostShoppingCartRemoveMutation,
  usePostShoppingCartCheckoutMutation,

  usePostDonateMutation,

  useGetOrdersQuery,
  useGetOrderProductsQuery,

  // enchanced
  // useGetChatRoomByUserIdQuery,
  // useLazyGetChatRoomByUserIdQuery,
  useSetShopAsVisitedQuery,
  useLazySetShopAsVisitedQuery,
  usePostCalculateDistanceMatrixMutation,
  useLazyGetTagsQuery,
  useLazyGetUserHomeScreenQuery,
  useLazyGetUserCitiesQuery,
  useGetUserShopsQuery,
  usePostUserStartNavigationMutation,
  useLazyGetUserContactsQuery,

  useLazyGetUserShopByCityIdQuery,
  useLazyGetUserSendRequestByUserIdQuery,
  usePostUserSupportRequestMutation,
  usePatchUserCheckProfileChangePhoneMutation,
  useGetUserCountersQuery,

  usePostReportMutation

} = api
