import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import {
  selectCurrentCityShops,
  selectCurrentDestinationIndex,
  selectHopRoute,
  selectVisitedShops
} from '../../store/selectors'
import { DISTANCE_OF_TRIGGER_IS_SHOP_VISITED } from '../../config/navigation'
import {
  setCurrentDestinationIndex,
  setVisitedStatus,
  updateShopLastVisitDate
} from '../../features/hop/hopSlice'
import { useLazySetShopAsVisitedQuery } from '../../api/api.generated'

const useRouteShopReached = () => {
  const dispatch = useDispatch()
  const evaluatedRouteDistances = useSelector(
    (state) => state.hop.evaluatedRouteDistances
  )
  const evaluatedRouteTravelTimes = useSelector(
    (state) => state.hop.evaluatedRouteTravelTimes
  )
  const currentDestinationIndex = useSelector(selectCurrentDestinationIndex)
  const visitedShops = useSelector(selectVisitedShops)
  const route = useSelector(selectHopRoute, shallowEqual)
  const shops = useSelector(selectCurrentCityShops, shallowEqual)
  const [currentTargetShopId, setCurrentTargetShopId] = useState()

  const [setShopAsVisited] = useLazySetShopAsVisitedQuery()

  const goToNextDestination = () => {
    if (route.length > currentDestinationIndex + 1) {
      dispatch(setCurrentDestinationIndex(currentDestinationIndex + 1))
    }
  }
  useEffect(() => {
    const newTargetShopId = route?.[currentDestinationIndex]?.shopId ?? ''
    setCurrentTargetShopId(newTargetShopId)
  }, [currentDestinationIndex])

  useEffect(() => {
    if (
      evaluatedRouteDistances[currentDestinationIndex] <=
        DISTANCE_OF_TRIGGER_IS_SHOP_VISITED &&
      !visitedShops[currentTargetShopId] &&
      evaluatedRouteDistances.length
    ) {
      // eslint-disable-next-line no-console
      /*
      console.log(
        `Shop #${currentDestinationIndex} ${shops[currentTargetShopId]?.storeName} reached`
      )
      */

      if (
        shops[currentTargetShopId]?.lastVisited !== dayjs().format('YYYY-MM-DD')
      ) {
        // eslint-disable-next-line no-console
        /*
        console.log(
          `set last visited  of shop #${currentDestinationIndex}to ${dayjs().format(
            'YYYY-MM-DD'
          )} `
        )
        */
      }
      setShopAsVisited(currentTargetShopId)
      dispatch(updateShopLastVisitDate(currentTargetShopId))
      dispatch(setVisitedStatus({ shopId: currentTargetShopId, status: true }))
      goToNextDestination()
    }
  }, [evaluatedRouteTravelTimes, evaluatedRouteDistances])
}

export default useRouteShopReached
