import { useEffect, useState } from 'react'
import { usePostUserFindUserMutation } from '../../api/api.generated'

const useFindFriends = () => {
  const [searchQuery, setSearchQuery] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const [searchUsers, { isLoading, data, status }] = usePostUserFindUserMutation()
  const handleSubmit = (e) => {
    e.preventDefault()
    if (searchQuery.length >= 2) {
      searchUsers({ body: { searchQuery } })
    }
  }
  useEffect(() => {
    if (data?.searchResults) {
      setSearchResults(data.searchResults)
    }
  }, [data])

  return {
    handleSubmit,
    searchQuery,
    setSearchQuery,
    searchResults,
    isLoading,
    status
  }
}
export default useFindFriends
