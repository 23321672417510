import React, { useState } from 'react'
import { Formik } from 'formik'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import { withTheme } from 'styled-components'
import { VStack } from '../../../global/styles'
import ContactListItemShare from '../../ContactListItemShare'
import LoadingSpinner from '../../LoadingSpinner'
import Typography from '../../UI/Typography'
import Button from '../../UI/Buttons/Button'
import { BUTTON_VARIANTS, BUTTONS_SIZES } from '../../../const/UIvariants'
import { getErrorMessage } from '../../../utils/helpers'
import { ACTION_SHARE } from '../../../const/app'
import { usePostChatMessageToUserMutation, usePostUserActionMutation } from '../../../api/api.generated'
import { useGetUserContactsQuery } from '../../../api/api.generated'
import { ButtonWrapper, ModalHeadline, StyledForm, ErrorWrap } from './styles'

const ShareShopModal = ({ isOpen, onClose, shopId }) => {
  const [error, setError] = useState('')
  const [isPosting, setIsPosting] = useState(false)
  const [postChatMessage] = usePostChatMessageToUserMutation()
  const [postAction] = usePostUserActionMutation()

  const handleClick = (userId) => {
    postAction({ body: {actionId: ACTION_SHARE, shopId } })

    setError('')
    setIsPosting(true)
    postChatMessage({
      userId,
      body: {
        message: '',
        shopId
      }
    })
    .unwrap()
    .then(() => {
      onClose()
    })
    .catch((err) => {
      setIsPosting(false)
      setError(getErrorMessage(err))
    })
  } 

  const { data: { contacts } = {}, isLoading } = useGetUserContactsQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true
    }
  )

  return (
    <Modal {...{ isOpen }}>
      <Formik>
        <StyledForm>
          <ModalHeadline>Choose friend</ModalHeadline>
          <VStack>
            {(isLoading || isPosting) && <LoadingSpinner />}
            {contacts &&
              contacts.map((contact) => (
                <ContactListItemShare
                  key={contact.id}
                  userId={contact.id}
                  contactName={contact.contactName}
                  contactAvatar={contact.contactAvatar}
                  contactLocation={contact.contactLocation}
                  bgColor={contact.avatarBackground}
                  onClick={handleClick}
                />
              ))}
          </VStack>
          {error && (
            <ErrorWrap>
              <Typography variant="textS" color="red">
                {error}
              </Typography>
            </ErrorWrap>          
           )}
          <ButtonWrapper>
            <Button
              type="button"
              variant={BUTTON_VARIANTS.SECONDARY}
              size={BUTTONS_SIZES.MED}
              onClick={onClose}
            >
              Cancel
            </Button>
          </ButtonWrapper>
        </StyledForm>
      </Formik>
    </Modal>
  )
}

export default withTheme(ShareShopModal)

ShareShopModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  shopId: PropTypes.string.isRequired
}
