import React, { useEffect, useState, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash/lang'
import { keyBy } from 'lodash'
import { mergeArrays } from '../../../utils/helpers'
import {
  useGetTagsQuery,
  useLazyGetUserShopByShopIdProductsQuery,
  useGetUserShopByShopIdAboutQuery
} from '../../../api/api.generated'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import ProductItem from '../../../components/ProductItem'
import { 
  IsEmptyText, 
  ProductsWrap, 
  OnlineShop
} from './styles'

const ShopProducts = ({ shopId }) => {
  const [tagsById, setTagsById] = useState()
  const [hasMore, setHasMore] = useState(true)
  const [page, setPage] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [products, setProducts] = useState([])
  const observer = useRef();
  const { data: { shopData } = {}, loadingShop } = useGetUserShopByShopIdAboutQuery({ shopId })
  const { data: { tags } = [], isLoading: loadingTags } = useGetTagsQuery(undefined, {})
  const [getUserShopByShopIdProducts, { data: { shopProducts } = {}, isLoading: loadingProducts }] = useLazyGetUserShopByShopIdProductsQuery()
  const ITEMS_PER_PAGE = 20

  const lastElementRef = useCallback(  
    (node) => {
      if (isLoading) return;
      if (!hasMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setPage((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore]
  );

  const handleShowMore = () => {
    setIsLoading(true)
    getUserShopByShopIdProducts({ shopId, page, limit: ITEMS_PER_PAGE })
  }

  useEffect(() => {
    handleShowMore()
  }, [])

  useEffect(() => {
    handleShowMore()
  }, [page])

  useEffect(() => {
    if (shopProducts) {
      setIsLoading(false)
      setHasMore(shopProducts.length === ITEMS_PER_PAGE)
      setProducts(mergeArrays(products, shopProducts))
    }
  }, [shopProducts])

  useEffect(() => {
    if (tags?.length && !tagsById) {
      setTagsById(keyBy(tags, 'id'))
    }
  }, [tags])

  return (
    <>
      {(loadingShop || loadingTags || loadingProducts) && <LoadingSpinner />}
      <ProductsWrap>
        {isEmpty(products) ? (
          <IsEmptyText variant="textM">No products added yet</IsEmptyText>
        ) : (
          <>
            { shopData?.onlineShopUrl &&
              <OnlineShop variant="textM">Online Shop: <a href={shopData?.onlineShopUrl} target="_blank" rel="noreferrer"><b>{shopData?.onlineShopUrl}</b></a></OnlineShop>
            }
            {tagsById && products.map((product) => (
              <ProductItem
                key={product.id}
                productId={product.id}
                productImage={product.productImage}
                productName={product.productName}
                productPrice={product.productPrice}
                shippingCost={product.shippingCost}
                tags={product.tags.map((tag) => tagsById[tag].tagName)}
                showBuyButton={shopData.isStoreEnabled}
                isAdded={product.isAdded}
                isShippingEnabled={product.isShippingEnabled}
                isCollectEnabled={product.isCollectEnabled}
              />
            ))}
            {isLoading &&
            <div>Loading...</div>
            }
            {!isLoading && hasMore &&
            <div ref={lastElementRef} /> 
            }
          </>
        )}
      </ProductsWrap>
    </>
  )
}

export default ShopProducts

ShopProducts.propTypes = {
  shopId: PropTypes.string.isRequired
}
