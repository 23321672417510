import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezonePlugin from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'
import { USER_ROLE } from '../const/app'
import { getBase64 } from './helpers'

dayjs.extend(utc)
dayjs.extend(timezonePlugin)
dayjs.extend(relativeTime)
dayjs.extend(updateLocale)

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a moment',
    m: 'a minute',
    mm: '%d minutes',
    h: 'an hour',
    hh: '%d hours',
    d: 'a day',
    dd: '%d days',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years'
  }
})
export const adaptLoginData = (formData) => {
  const { phoneNumber, password } = formData
  const timezone = dayjs.tz.guess()
  const device = window.innerWidth > 1000 ? 'desktop' : 'mobile'

  return {
    phone: phoneNumber,
    password,
    timezone,
    device_name: device,
    role: USER_ROLE
  }
}

export const adaptSignupData = (formData) => {
  const { phoneNumber, password, email, username, verificationCode } = formData
  const timezone = dayjs.tz.guess()
  const device = window.innerWidth > 1000 ? 'desktop' : 'mobile'

  return {
    phone: phoneNumber,
    password,
    username,
    email,
    timezone,
    device_name: device,
    role: USER_ROLE,
    verificationCode
  }
}
export const adataptSupportFormData = async (initialData) => {
  const base64Images = await Promise.all(
    initialData.images.map(
      async (image) =>
        // eslint-disable-next-line no-return-await
        await getBase64(image.file)
    )
  )
  return { ...initialData, images: base64Images }
}
