import styled from 'styled-components'

export const ImageWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  margin-bottom: 16px;
`
export const ImagePreview = styled.img`
  border-radius: 4px;
  width: 84px;
  height: 84px;
`
export const SelectImage = styled.div`
  width: 84px;
  border: 1px solid ${({ theme }) => theme.colors.skyNeutral4};
  height: 84px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
`
export const SelectImageWrap = styled.label`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  //&:nth-child(n+4){
  //  padding-top: 10px;
  //}
`

export const Label = styled.p`
  font-size: 11px;
  text-align: center;
  margin-top: 9px;
`
export const ImagePreviewWrap = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-right: 22px;
  margin-bottom: 22px;
  justify-content: center;
  align-items: center;
`
export const CloseIconWrap = styled.div`
  background: ${({ theme }) => theme.colors.white};
  position: absolute;
  width: 24px;
  height: 24px;
  display: flex;
  top: -8px;
  right: -8px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
`
