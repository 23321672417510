import styled from 'styled-components'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { HStack } from '../../global/styles'

// eslint-disable-next-line import/prefer-default-export
export const ScrollWrapper = styled(OverlayScrollbarsComponent).attrs({
  options: {
    scrollbars: { visibility: 'hidden' }
  }
})`
  height: 100%;
  width: 100%;
  padding: 20px 16px 192px 16px;
`
export const ShowMoreWrap = styled(HStack)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`