/*eslint-disable*/
import React from 'react'
import PropTypes from 'prop-types'
import {
  VolunteerItemWrap,
  VolunteerDetailsWrap,
  VolunteerName,
  VolunteerData
} from './styles'

const VolunteerItem = ({ name, email, phone, message }) => (
  <VolunteerItemWrap>
    <VolunteerDetailsWrap>
      <VolunteerName>{name}</VolunteerName>
      <VolunteerData>{email}</VolunteerData>
      <VolunteerData>{phone}</VolunteerData>
      <VolunteerData>{message}</VolunteerData>
    </VolunteerDetailsWrap>
  </VolunteerItemWrap>
)

export default VolunteerItem

VolunteerItem.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
}
