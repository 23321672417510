import styled from 'styled-components'
import Typography from '../../Typography'

export const VerificationWrap = styled.label`
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 6px 16px -4px rgba(15, 18, 20, 0.1);
  height: 76px;
  padding: 22px 25px;
  border-radius: 6px;
  align-items: center;
`
export const CodeNumber = styled(Typography).attrs({
  variant: 'headingXL',
  bold: true
})`
  color: ${({ isFilled, theme }) =>
    isFilled ? theme.colors.skyBase : theme.colors.skyNeutral3};
`
