import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { withTheme } from 'styled-components'
import {
  TitleWrap,
  ActionButtonsSection,
  ButtonWrap,
  FormInputsWrap,
  FormWrap,
  StyledForm,
  ErrorWrap,
  CreditCardExpire
} from './styles'
import Typography from '../../UI/Typography'
import Button from '../../UI/Buttons/Button'
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES,
  INPUT_SIZES
} from '../../../const/UIvariants'
import TextInput from '../../UI/Inputs/TextInput'
import FormSelectOneInput from '../../UI/Inputs/SelectOneInput/FormSelectOneInput'
import { usePostDonateMutation } from '../../../api/api.generated'
import { getMonthList, getYearList } from '../../../utils/helpers'

const emptyData = {
  amount: '',
  note: '',
  cardName: '',
  cardNumber: '',
  cardMonth: '',
  cardYear: '',
  cardCode: ''
}
const DonateModal = ({ isOpen, onClose, shopId, theme }) => {
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [initialData, setInitialData] = useState(emptyData)
  const [postDonate] = usePostDonateMutation()

  useEffect(() => {
    setInitialData(emptyData)
  }, [])

  const handleSubmitForm = (values, handleClose = onClose) => {
    setError('')
    setIsLoading(true)
    postDonate({
      body: {
        shopId,
        amount: values.amount,
        note: values.note,
        cardName: values.cardName,
        cardNumber: values.cardNumber,
        cardMonth: values.cardMonth,
        cardYear: values.cardYear,
        cardCode: values.cardCode
      }
    })
      .unwrap()
      .then(() => {
        handleClose()
      })
      .catch((err) => {
        setIsLoading(false)
        if (err && err.data && err.data.message) {
          if (err.data.message.error) {
            setError(err.data.message.error)
          } else {
            let message = ''
            Object.keys(err.data.message).forEach((key) => {
              message += `${err.data.message[key]} `
            })
            setError(message)
          }
        } else {
          setError('Network error')
        }
      })
  }

  const validationSchema = Yup.object({
    amount: Yup.number().required('Amount is required'),
    cardName: Yup.string().required('Name on card is required'),
    cardNumber: Yup.string().required('Card number is required'),
    cardMonth: Yup.string().required('Card month is required'),
    cardYear: Yup.string().required('Card year is required'),
    cardCode: Yup.string().required('Card code is required')
  })

  const monthList = getMonthList();
  const yearList = getYearList();

  return (
    <Modal {...{ isOpen }}>
      <FormWrap>
        <Formik
          initialValues={initialData}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleSubmitForm(values)
          }}
        >
          <StyledForm>
            {isLoading && <LoadingSpinner />}
            <TitleWrap>
              <Typography variant="headingM" color={theme.colors.skyNeutral_2}>
                Donate
              </Typography>
            </TitleWrap>
            <FormInputsWrap>
              <TextInput
                size={INPUT_SIZES.SMALL}
                inputName="amount"
                label="Amount"
                placeholder="Enter amount"
              />
              <TextInput
                size={INPUT_SIZES.SMALL}
                inputName="note"
                label="Note"
                placeholder="Enter note"
              />
              <TextInput
                size={INPUT_SIZES.SMALL}
                inputName="cardName"
                label="Name on Card "
                placeholder="Enter name on card"
              />
              <TextInput
                size={INPUT_SIZES.SMALL}
                inputName="cardNumber"
                label="Card Number "
                placeholder="Enter card number"
              />
              <CreditCardExpire>
                <FormSelectOneInput
                  inputName="cardMonth"
                  label="Expiry Month"
                  options={monthList}
                  size=""
                />
                <FormSelectOneInput
                  inputName="cardYear"
                  label="Expiry Year"
                  options={yearList}
                  size=""
                />
              </CreditCardExpire>
              <TextInput
                size={INPUT_SIZES.SMALL}
                inputName="cardCode"
                label="Card Code (CVC) "
                placeholder="Enter CVC"
              />
            </FormInputsWrap>
            <ErrorWrap>
              {error && (
                <Typography variant="textS" color="red">
                  {error}
                </Typography>
              )}
            </ErrorWrap>
            <ActionButtonsSection>
              <ButtonWrap>
                <Button
                  type="submit"
                  variant={BUTTON_VARIANTS.PRIMARY}
                  size={BUTTONS_SIZES.MED}
                >
                  Donate
                </Button>
                <Button
                  type="button"
                  variant={BUTTON_VARIANTS.SECONDARY}
                  size={BUTTONS_SIZES.MED}
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </ButtonWrap>
            </ActionButtonsSection>
          </StyledForm>
        </Formik>
      </FormWrap>
    </Modal>
  )
}

export default withTheme(DonateModal)

DonateModal.propTypes = {
  theme: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  shopId: PropTypes.string.isRequired
}
