import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { withTheme } from 'styled-components'
import {
  ActionButtonsSection,
  ButtonWrap,
  FormInputsWrap,
  FormWrap,
  LeftHalf,
  RightHalf,
  StyledForm
} from './styles'
import Typography from '../../../components/UI/Typography'
import Button from '../../../components/UI/Buttons/Button'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES,
  INPUT_SIZES
} from '../../../const/UIvariants'
import TextInput from '../../../components/UI/Inputs/TextInput'
import TextAreaInput from '../../../components/UI/Inputs/TextAreaInput'
import { usePostShopVolunteerMutation } from '../../../api/api.generated'

const emptyVolunteer = {
  name: '',
  email: '',
  phone: '',
  message: ''
}
const AddNewVolunteerModal = ({ isOpen, onClose, theme, shopId }) => {
  const [initialData, setInitialData] = useState(emptyVolunteer)
  const [postShopVolunteer] = usePostShopVolunteerMutation()

  useEffect(() => {
    setInitialData(emptyVolunteer)
  }, [])

  const handleSubmitForm = (values, handleClose = onClose) => {
    postShopVolunteer({
      shopId,
      body: values
    })
    handleClose()
  }

  return (
    <Modal {...{ isOpen }}>
      <FormWrap>
        <Formik
          initialValues={initialData}
          validationSchema={Yup.object({
            name: Yup.string().required('Name is required'),
            email: Yup.string().required('Email is required'),
            phone: Yup.string().required('Phone is required')
          })}
          onSubmit={(values) => {
            handleSubmitForm(values)
          }}
        >
          <StyledForm>
            <FormInputsWrap>
              <LeftHalf>
                <Typography
                  variant="headingM"
                  color={theme.colors.skyNeutral_2}
                >
                  Volunteer Details
                </Typography>
                <TextInput
                  size={INPUT_SIZES.SMALL}
                  inputName="name"
                  label="Name"
                  placeholder="Enter name"
                />
                <TextInput
                  size={INPUT_SIZES.SMALL}
                  inputName="email"
                  label="Email"
                  placeholder="Enter email"
                />
                <TextInput
                  size={INPUT_SIZES.SMALL}
                  inputName="phone"
                  label="Phone"
                  placeholder="Enter phone"
                />
              </LeftHalf>
              <RightHalf>
                <TextAreaInput
                  inputName="message"
                  label="Message"
                  rows={16}
                  placeholder="Enter message"
                />
              </RightHalf>
            </FormInputsWrap>
            <ActionButtonsSection>
              <ButtonWrap>
                <Button
                  type="submit"
                  variant={BUTTON_VARIANTS.PRIMARY}
                  size={BUTTONS_SIZES.MED}
                >
                  Save
                </Button>
                <Button
                  type="button"
                  variant={BUTTON_VARIANTS.SECONDARY}
                  size={BUTTONS_SIZES.MED}
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </ButtonWrap>
            </ActionButtonsSection>
          </StyledForm>
        </Formik>
      </FormWrap>
    </Modal>
  )
}

export default withTheme(AddNewVolunteerModal)

AddNewVolunteerModal.propTypes = {
  theme: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  shopId: PropTypes.string.isRequired
}
