import React from 'react'
import { NearMe, Store } from '@styled-icons/material'
import PropTypes from 'prop-types'
import {
  CityItemWrap,
  CityName,
  Country,
  Distance,
  DistanceWrap,
  Labels,
  MetaWrap,
  ShopsWrap,
  StoresCount
} from './styles'

const CityItem = ({
  isActive,
  city,
  country,
  shopsInCity,
  distance,
  handleChangeCity
}) => (
  <CityItemWrap {...{ isActive }} onClick={handleChangeCity}>
    <Labels>
      <CityName {...{ isActive }}>{city}</CityName>
      <Country>{country}</Country>
    </Labels>
    <MetaWrap>
      <ShopsWrap>
        <Store size={15} />
        <StoresCount>
          {`${shopsInCity} Store${shopsInCity !== 1 ? `s` : ''}`}
        </StoresCount>
      </ShopsWrap>
      {distance && (
        <DistanceWrap>
          <NearMe size={14} />
          <Distance>{distance}</Distance>
        </DistanceWrap>
      )}
    </MetaWrap>
  </CityItemWrap>
)

export default CityItem

CityItem.propTypes = {
  city: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  distance: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  shopsInCity: PropTypes.number.isRequired,
  handleChangeCity: PropTypes.func.isRequired
}
