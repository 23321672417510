import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper } from './styles'
import ContactListItemDetail from '../ContactListItemDetail'

const ContactListItemShare = ({
  contactName,
  userId,
  contactAvatar,
  contactLocation,
  bgColor,
  onClick
}) => (
  <Wrapper
    onClick={() => {
      onClick(userId)
    }}
  >
    <ContactListItemDetail
      contactName={contactName}
      contactAvatar={contactAvatar}
      contactLocation={contactLocation}
      bgColor={bgColor}
    />
  </Wrapper>
)

export default ContactListItemShare

ContactListItemShare.defaultProps = {
  contactAvatar: null,
  contactLocation: null
}

ContactListItemShare.propTypes = {
  userId: PropTypes.string.isRequired,
  contactName: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
  contactLocation: PropTypes.string,
  contactAvatar: PropTypes.string,
  onClick: PropTypes.func.isRequired
}
