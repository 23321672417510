import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { keys } from 'lodash/object'
import { capitalize } from 'lodash/string'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { Language } from '@styled-icons/material'
import { isEmpty } from 'lodash/lang'
import { useDispatch, useSelector } from 'react-redux'
import HomeScreenTag from '../../../components/HomeScreenTag'
import {
  Heading,
  InfoBlock,
  SocialIconsWrap,
  InlineText,
  PhoneNumber,
  ScheduleWrap,
  DayItem,
  DayLabel,
  DonationsWrap,
  SmallHeading,
  LatestPostsWrap,
  GalleryWrapper,
  ImageContainer,
  ImageCount,
  CustomerReviewWrap,
  ReviewDetails,
  CustomerName,
  ReviewWrap,
  SocialIconWrap,
  ImageOverlay,
  HorScroll,
  TagWrap
} from './styles'
import { usePostUserActionMutation, useGetUserShopByShopIdAboutQuery } from '../../../api/api.generated'
import LoadingSpinner from '../../../components/LoadingSpinner'
import Badge from '../../../components/UI/Badge'
import {
  BADGE_VARIANTS,
  BUTTON_VARIANTS,
  BUTTONS_SIZES as BUTTON_SIZES,
  USER_AVATAR_SIZES
} from '../../../const/UIvariants'
import { getHourAMPM } from '../../../utils/helpers'
import ShopPostItem from '../../../components/ShopPostItem'
import ROUTES from '../../../const/ROUTES'
import UserAvatar from '../../../components/UserAvatar'
import Rating from '../../../components/Rating'
import InstagramIcon from '../../../assets/vector/InstagramIcon'
import FacebookIcon from '../../../assets/vector/FacebookIcon'
import TwitterIcon from '../../../assets/vector/TwitterIcon'
import Button from '../../../components/UI/Buttons/Button'
import { toggleRateShopModal } from '../../app/appSlice'
import { selectTagsById } from '../../../store/selectors'
import { ACTION_PROFILE } from '../../../const/app'

const ShopDetails = ({ shopId, match }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const tagsById = useSelector(selectTagsById)
  const { data: { shopData } = {}, isLoading } = useGetUserShopByShopIdAboutQuery({ shopId })
  const [postAction] = usePostUserActionMutation()

  const handleOpenLinkInNewTab = (url) => {
    const validUrl =
      url.startsWith('http://') || url.startsWith('https://')
        ? url
        : `http://${url}`
    window.open(validUrl, '_blank')
  }

  useEffect(() => {
    postAction({ body: {actionId: ACTION_PROFILE, shopId } })
  }, [])

  return (
    <>
      {isLoading && <LoadingSpinner />}
      {shopData && !isEmpty(tagsById) && (
        <>
          <InfoBlock withPadding>
            <Heading>Bio</Heading>
            <SocialIconsWrap>
              {shopData.instagram && (
                <SocialIconWrap
                  onClick={() => {
                    handleOpenLinkInNewTab(shopData.instagram)
                  }}
                >
                  <InstagramIcon />
                </SocialIconWrap>
              )}
              {shopData.facebook && (
                <>
                  <SocialIconWrap
                    onClick={() => {
                      handleOpenLinkInNewTab(shopData.facebook)
                    }}
                  >
                    <FacebookIcon />
                  </SocialIconWrap>
                </>
              )}
              {shopData.twitter && (
                <>
                  <SocialIconWrap
                    onClick={() => {
                      handleOpenLinkInNewTab(shopData.twitter)
                    }}
                  >
                    <TwitterIcon />
                  </SocialIconWrap>
                </>
              )}
              {shopData.website && (
                <SocialIconWrap
                  onClick={() => {
                    handleOpenLinkInNewTab(shopData.website)
                  }}
                >
                  <Language size={24} color="#2D6CE4" />
                </SocialIconWrap>
              )}
            </SocialIconsWrap>
            <InlineText>
              {shopData.description || `Store owner haven't add description yet`}
            </InlineText>
          </InfoBlock>
          {shopData.phoneNumber && (
            <InfoBlock withPadding>
              <Heading>Phone</Heading>
              <PhoneNumber href={`tel:${shopData.phoneNumber}`}>
                {shopData.phoneNumber}
              </PhoneNumber>
            </InfoBlock>
          )}
          {!isEmpty(shopData.tags) && (
            <InfoBlock>
              <Heading withPadding>Store Categories</Heading>
              <HorScroll>
                <TagWrap>
                  {shopData.tags.map((tagId) => (
                    <HomeScreenTag key={tagId} {...{ tagId }} allowClick={false}/>
                  ))}
                </TagWrap>
              </HorScroll>
            </InfoBlock>
          )}

          <InfoBlock withPadding>
            <Heading>Opening Hours</Heading>

            <ScheduleWrap>
              {keys(shopData.schedule).map((day) => (
                <DayItem key={day}>
                  <DayLabel>{capitalize(day)}</DayLabel>
                  <Badge
                    variant={BADGE_VARIANTS.GREEN}
                    label={
                      shopData.schedule[day].isOpen
                        ? `${getHourAMPM(
                            shopData.schedule[day].open
                          ).toUpperCase()} - ${getHourAMPM(
                            shopData.schedule[day].close
                          ).toUpperCase()}`
                        : 'Closed'
                    }
                  />
                </DayItem>
              ))}
            </ScheduleWrap>
          </InfoBlock>

          <InfoBlock>
            <Heading withPadding>Donations</Heading>
            <DonationsWrap>
              {shopData.donations.map((donation) => (
                <Badge
                  variant={BADGE_VARIANTS.LIGHT}
                  label={donation.tag_name}
                  key={donation.id}
                />
              ))}
            </DonationsWrap>
          </InfoBlock>
          {!!shopData.latestPosts.length && (
            <InfoBlock>
              <SmallHeading withPadding>Latest Updates</SmallHeading>
              <OverlayScrollbarsComponent
                options={{ scrollbars: { visibility: 'hidden' } }}
                style={{ width: '100%' }}
              >
                <LatestPostsWrap>
                  {shopData.latestPosts.map((post) => (
                    <ShopPostItem
                      key={post.id}
                      postDeadline={
                        post.startTime !== '' && post.endTime !== ''
                      }
                      deadlineText={post.deadlineText}
                      shopName={post.shopName}
                      showShopName={false}
                      postTitle={post.postTitle}
                      postDescription={post.postDescription}
                      requireCode={post.requireCode}
                      postImage={post.postImage}
                      postId={post.id}
                      dateStart={post.startTime}
                      dateEnd={post.endTime}
                      handleShowShopPostDetails={() => {}}
                      showInterestedButton={false}
                      isInterested={post.isInterested}
                      showLikeButton={false}
                      isLiked={post.isLiked}
                      showAttendedButton={false}
                      isAttended={post.isAttended}
                    />
                  ))}
                </LatestPostsWrap>
              </OverlayScrollbarsComponent>
            </InfoBlock>
          )}
          {!!shopData.gallery.length && (
            <InfoBlock withPadding>
              <SmallHeading>Gallery</SmallHeading>
              <GalleryWrapper>
                {shopData.gallery.length > 4 ? (
                  <>
                    <ImageContainer bg={shopData.gallery[0]} />
                    <ImageContainer>
                      <ImageContainer bg={shopData.gallery[1]} />
                      <ImageContainer bg={shopData.gallery[2]} />
                      <ImageContainer bg={shopData.gallery[3]} />
                      <ImageContainer
                        onClick={() => {
                          history.replace(match.params[0] + ROUTES.SHOP_GALLERY)
                        }}
                        bg={shopData.gallery[shopData.gallery.length - 1]}
                      >
                        <ImageCount>
                          +{shopData.imagesInGalleryCount}
                        </ImageCount>
                        <ImageOverlay />
                      </ImageContainer>
                    </ImageContainer>
                  </>
                ) : (
                  shopData.gallery[0] && (
                    <ImageContainer
                      onClick={() => {
                        history.replace(match.params[0] + ROUTES.SHOP_GALLERY)
                      }}
                      bg={shopData.gallery[0]}
                    >
                      <ImageCount>+{shopData.imagesInGalleryCount}</ImageCount>
                      <ImageOverlay />
                    </ImageContainer>
                  )
                )}
              </GalleryWrapper>
            </InfoBlock>
          )}
          {/* todo: add filter for reviews */}
          {/* <InfoBlock withPadding> */}
          {/*  <SmallHeading>Reviews</SmallHeading> */}
          {/*  <BadgeTabsWrap> */}
          {/*    {RATES.map((rate) => ( */}
          {/*      <Badge */}
          {/*        key={rate} */}
          {/*        variant={BADGE_VARIANTS.GOLD} */}
          {/*        onClick={() => {}} */}
          {/*        icon={<Star size={16} color="#F0C93F" />} */}
          {/*        label={`${rate}.0`} */}
          {/*      /> */}
          {/*    ))} */}
          {/*  </BadgeTabsWrap> */}
          {/* </InfoBlock> */}
          <InfoBlock withPadding>
            <SmallHeading>Reviews</SmallHeading>
            <Button
              variant={BUTTON_VARIANTS.OUTLINED}
              size={BUTTON_SIZES.MED}
              onClick={() => {
                dispatch(toggleRateShopModal())
              }}
            >
              Add Review
            </Button>
            {shopData.reviews.map((review) => (
              <CustomerReviewWrap key={review.id}>
                <UserAvatar
                  size={USER_AVATAR_SIZES.SMALL}
                  userName={review.userName}
                  bgColor={review.avatarBackground}
                />
                <ReviewDetails>
                  <CustomerName>{review.userName}</CustomerName>
                  <ReviewWrap>
                    <Rating rating={review.rating} />
                  </ReviewWrap>
                </ReviewDetails>
              </CustomerReviewWrap>
            ))}
          </InfoBlock>
        </>
      )}
    </>
  )
}

export default ShopDetails

ShopDetails.propTypes = {
  shopId: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired
}
