import styled from 'styled-components'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { HStack, ScreenContainer, VStack } from '../../../global/styles'

// eslint-disable-next-line import/prefer-default-export
export const SearchScreenContainer = styled(ScreenContainer)`
  z-index: 100;
  background-color: white;
  position: fixed;
  top: 0;
  justify-content: flex-start;
`
export const SearchHeader = styled(VStack)`
  min-height: 132px;
  justify-content: flex-end;
`

export const SearchResultsWrap = styled(VStack)`
  position: relative;
  height: 100%;
`

export const StyledForm = styled.form`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  padding: 16px;
  > *:first-child {
    margin-right: 10px;
  }  
`
export const InputWrap = styled(HStack)`
  width: 100%;
  position: relative;
  height: 56px;
  align-items: center;
  margin-right: 25px;
`
export const SearchInput = styled.input`
  width: 100%;
  height: 56px;
  font-size: 17px;
  background-color: ${({ theme, error }) =>
    error ? theme.colors.pinkBase4 : theme.colors.skyNeutral4};
  color: ${({ theme, error }) =>
    error ? theme.colors.pinkBase : theme.colors.skyNeutral_1};
  outline: none;
  border: none;
  display: flex;
  border-radius: 8px;
  padding-left: 52px;

  & :placeholder-shown {
    padding-top: 0;
  }
  &:focus {
    box-shadow: 0 0 0 2px hsla(219, 12%, 51%, 1);
  }
  &::-webkit-search-cancel-button {
    display: none;
  }
`

export const SearchIconWrap = styled.div`
  position: absolute;
  left: 20px;
`
export const StyledScrollbars = styled(OverlayScrollbarsComponent).attrs({
  options: {
    scrollbars: { visibility: 'hidden' }
  }
})`
  width: 100%;
  padding: 0 16px;
  height: 100%;
`
