import * as React from 'react'

function EmptyHop(props) {
  return (
    <svg
      fill="none"
      viewBox="0 0 448 297"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0_4411_130918)">
        <path
          opacity={0.1}
          d="M172.8 19.3L0 119.6l272.7 157.7 174.4-102-274.3-156z"
          fill="#D1D3D4"
        />
        <path
          opacity={0.121}
          d="M172.9 19.5L.5 119.6l272.1 157.3 173.9-101.7L172.9 19.5z"
          fill="#D0D2D3"
        />
        <path
          opacity={0.143}
          d="M173 19.8L1 119.6l271.4 156.9L446 175.1 173 19.8z"
          fill="#CFD1D2"
        />
        <path
          opacity={0.164}
          d="M173.1 20L1.6 119.6l270.7 156.5L445.4 175 173.1 20z"
          fill="#CED0D1"
        />
        <path
          opacity={0.186}
          d="M173.2 20.3L2.1 119.6l270 156.2 172.7-101L173.2 20.3z"
          fill="#CDCFD0"
        />
        <path
          opacity={0.207}
          d="M173.3 20.5L2.6 119.6 272 275.4l172.2-100.7L173.3 20.5z"
          fill="#CCCECF"
        />
        <path
          opacity={0.229}
          d="M173.4 20.8L3.1 119.7 271.8 275l171.8-100.4L173.4 20.8z"
          fill="#CBCDCE"
        />
        <path
          opacity={0.25}
          d="M173.5 21.1L3.6 119.7l268.1 154.9L443 174.4 173.5 21.1z"
          fill="#CACCCD"
        />
        <path
          opacity={0.271}
          d="M173.5 21.3L4.1 119.7l267.4 154.5 171-99.9-269-153z"
          fill="#C9CBCC"
        />
        <path
          opacity={0.293}
          d="M173.6 21.6L4.7 119.7l266.7 154.2 170.5-99.7L173.6 21.6z"
          fill="#C8CACB"
        />
        <path
          opacity={0.314}
          d="M173.7 21.8L5.2 119.7l266 153.8 170.1-99.4L173.7 21.8z"
          fill="#C7C9CA"
        />
        <path
          opacity={0.336}
          d="M173.8 22.1L5.7 119.7 271 273.1l169.7-99.2L173.8 22.1z"
          fill="#C6C8C9"
        />
        <path
          opacity={0.357}
          d="M173.9 22.4L6.2 119.7l264.7 153 169.2-98.9L173.9 22.4z"
          fill="#C5C7C8"
        />
        <path
          opacity={0.379}
          d="M174 22.6L6.7 119.7l264 152.7 168.9-98.7L174 22.6z"
          fill="#C4C6C7"
        />
        <path
          opacity={0.4}
          d="M174.1 22.9L7.3 119.8 270.6 272 439 173.6 174.1 22.9z"
          fill="#C3C5C6"
        />
        <path
          opacity={0.421}
          d="M174.2 23.1L7.8 119.8l262.6 151.8 168-98.2L174.2 23.1z"
          fill="#C2C4C5"
        />
        <path
          opacity={0.443}
          d="M174.3 23.4l-166 96.4 262 151.4 167.5-97.9L174.3 23.4z"
          fill="#C1C3C4"
        />
        <path
          opacity={0.464}
          d="M174.4 23.6L8.8 119.8l261.3 151.1 167.1-97.7L174.4 23.6z"
          fill="#C0C2C3"
        />
        <path
          opacity={0.486}
          d="M174.5 23.9L9.3 119.8 270 270.5l166.7-97.4L174.5 23.9z"
          fill="#BFC1C2"
        />
        <path
          opacity={0.507}
          d="M174.6 24.2L9.8 119.8l260 150.3 166.3-97.2L174.6 24.2z"
          fill="#BEC0C1"
        />
        <path
          opacity={0.529}
          d="M174.7 24.4L10.4 119.8l259.3 149.9 165.8-96.9L174.7 24.4z"
          fill="#BDBFC0"
        />
        <path
          opacity={0.55}
          d="M174.8 24.7L10.9 119.8l258.6 149.6 165.4-96.7-260.1-148z"
          fill="#BDBFC0"
        />
        <path
          opacity={0.571}
          d="M174.9 24.9L11.4 119.8l258 149.2 164.9-96.4L174.9 24.9z"
          fill="#BCBEBF"
        />
        <path
          opacity={0.593}
          d="M174.9 25.2l-163 94.7 257.3 148.7 164.5-96.2L174.9 25.2z"
          fill="#BBBDBE"
        />
        <path
          opacity={0.614}
          d="M175 25.5L12.4 119.9l256.7 148.3 164.1-95.9L175 25.5z"
          fill="#BABCBD"
        />
        <path
          opacity={0.636}
          d="M175.1 25.7L13 119.9l255.9 147.9 163.7-95.6L175.1 25.7z"
          fill="#B9BBBC"
        />
        <path
          opacity={0.657}
          d="M175.2 26L13.5 119.9l255.3 147.6L432 172.1 175.2 26z"
          fill="#B8BABB"
        />
        <path
          opacity={0.679}
          d="M175.3 26.2L14 119.9l254.6 147.2 162.8-95.2L175.3 26.2z"
          fill="#B7B9BA"
        />
        <path
          opacity={0.7}
          d="M175.4 26.5L14.5 119.9l254 146.8 162.3-94.9L175.4 26.5z"
          fill="#B6B8B9"
        />
        <path
          opacity={0.721}
          d="M175.5 26.7L15 119.9l253.3 146.4 162-94.6-254.8-145z"
          fill="#B5B7B8"
        />
        <path
          opacity={0.743}
          d="M175.6 27L15.5 119.9 268.1 266l161.6-94.4L175.6 27z"
          fill="#B4B6B7"
        />
        <path
          opacity={0.764}
          d="M175.7 27.3L16.1 119.9 268 265.6l161.1-94.2L175.7 27.3z"
          fill="#B3B5B6"
        />
        <path
          opacity={0.786}
          d="M175.8 27.5L16.6 120l251.2 145.2 160.7-93.9L175.8 27.5z"
          fill="#B2B4B5"
        />
        <path
          opacity={0.807}
          d="M175.9 27.8L17.1 120l250.6 144.8 160.2-93.6-252-143.4z"
          fill="#B1B3B4"
        />
        <path
          opacity={0.829}
          d="M176 28L17.6 120l249.9 144.5 159.8-93.4L176 28z"
          fill="#B0B2B3"
        />
        <path
          opacity={0.85}
          d="M176.1 28.3L18.1 120l249.3 144.1 159.4-93.2L176.1 28.3z"
          fill="#AFB1B2"
        />
        <path
          opacity={0.871}
          d="M176.2 28.6L18.7 120l248.5 143.7 159-92.9-250-142.2z"
          fill="#AEB0B1"
        />
        <path
          opacity={0.893}
          d="M176.2 28.8L19.2 120l247.9 143.3 158.5-92.6L176.2 28.8z"
          fill="#ADAFB0"
        />
        <path
          opacity={0.914}
          d="M176.3 29.1L19.7 120l247.2 143L425 170.6 176.3 29.1z"
          fill="#ACAEAF"
        />
        <path
          opacity={0.936}
          d="M176.4 29.3L20.2 120l246.6 142.6 157.6-92.2-248-141.1z"
          fill="#ABADAE"
        />
        <path
          opacity={0.957}
          d="M176.5 29.6L20.7 120l245.9 142.2 157.3-91.9L176.5 29.6z"
          fill="#AAACAD"
        />
        <path
          opacity={0.979}
          d="M176.6 29.8L21.2 120.1l245.3 141.7 156.8-91.6L176.6 29.8z"
          fill="#A9ABAC"
        />
        <path
          d="M176.7 30.1l-154.9 90 244.5 141.3 156.4-91.3-246-140z"
          fill="#A8AAAB"
        />
        <path
          d="M272.7 275.1l174.4-102-63.8-52.1-65.7-21.9-64.4-50-80.4-32L0 117.4l79 32 65.5 51.2 64.4 21.8 63.8 52.7z"
          fill="#fff"
        />
        <path
          d="M211.4 217.9l-64.5-21.8-65.3-51-70.3-28.5 161.9-94 77.5 30.9 64.5 50 65.7 22 57.5 46.9L273.2 269l-61.8-51.1z"
          fill="url(#prefix__paint0_linear_4411_130918)"
        />
        <path
          d="M178.4 74.6l-6 3.7 7.4 8.7 10.6-5.4-12-7zM137.9 189.1l36.8-23.1 12.5 9.3c.1.1.3.2.4.2l7.7 2.6-18.8 11 1.5 2.6 21.7-12.7c.5-.3.8-.9.7-1.4-.1-.6-.5-1.1-1-1.3l-10.6-3.6-11.4-8.4 10.8-6.8-1.6-2.5-51.2 32.1 2.5 2zM56.9 101.8l-33.3 19.7L27 123l33.4-19.7-.2-.3 15.2 5.7 1.1-2.8L48 95.3l-3.3 1.9 12.2 4.6z"
          fill="#D1D3D4"
        />
        <path
          d="M143.7 107.8c.2.1.4.1.6.1.3 0 .6-.1.8-.2l12.5-7.8 4.7 2 26.8 24.8 2-2.2-6.3-5.8 32.9-20.1c.4-.2.7-.7.7-1.1 0-.5-.1-.9-.5-1.3L199.6 80c-.1-.1-.1-.1-.2-.1l-19.7-12.5-1.6 2.5 19.6 12.4 9.2 8.1-31.7 19.4-11.1-10.3c-.1-.1-.3-.2-.4-.3l-42-17.9-1.2 2.8 33.7 14.4-10 6.3-51.8-23.2 20.2-12.1-1.5-2.6L89 80.2l-8.4-3.8-3.2 1.9 66.3 29.5zm65.6-15.3l5.1 4.5-31.9 19.5-5-4.6 31.8-19.4zM327.1 214.2l-2 2.2 14.4 13.8 2.7-1.5-15.1-14.5zM215.9 207.2l-1.6-2.5-14.3 9.4 3.6 1.2 12.3-8.1zM346.3 158.2c.1.6.5 1 1.1 1.2l23.3 6.7.8-2.9-22.4-6.4-1.3-7.5 36.5-21-2.5-2-34.6 19.8-.7-4c-.1-.5-.4-.9-.8-1.1l-7.1-3.6 28.5-16.4-3.8-1.3-27.9 16.1-21.7-11.1-1.4 2.7 31.3 16 2.7 14.8zM266.1 65.4L254.7 72l1.5 2.6 12.4-7.2-2.5-2z"
          fill="#D1D3D4"
        />
        <path
          d="M216.8 120l40.6-1.7 37-7.7 18 9.4-31.3 19.3-11.3-3-51.4 18.3-15.6-29 14-5.6zM172.1 21l-64.5 38.5 24 12 27-11 19 1.5 32-17.5-3-9.5-34.5-14zM232.6 163.5l-31.5 11 .5 6.5 4.5 12.5 25 11 4.5 3 2.5 2 46 10 11 7.5 23-14-11-18.5 11-21.5-10.5-8.5-36.5-16.5-38.5 15.5z"
          fill="#CFE4BD"
        />
        <path
          d="M240.5 241.9l27.1 22.4c2.7-14.2 7.6-43.8 9.2-64.7 1.5-19.6-15.2-29.2-24-38.7-5.4-5.8-7.4-11.3-9.7-18.1-2.8-8.7-1.2-16.7.7-25.9 2-9.5 6.9-21.4 3.6-33.3-3.7-13.3-22.2-20.1-40.5-29.4-14.4-7.3-27-18.9-35.1-30.9l-24.7 14.3c12.5 15.1 35.6 22.1 53.6 31.2 11.1 5.6 23.4 16.4 24.7 21.2 2 7.3.5 14.5-1.2 22.8-2.2 10.5-7.7 23.1-3.3 36.9 3.6 11 10.8 18.7 17.1 25.5 8.8 9.4 16.8 14.5 16 24.9-1 14.2-8.1 30.9-13.5 41.8z"
          fill="#72A2FF"
        />
        <path
          d="M300.3 235.5l74.3-46c.9-.6 1.4-1.6 1.4-2.6l-1-35.9 20.2-13.7-4.8-4-20 13.7c-.8.6-1.3 1.5-1.3 2.6l1 35.8-74.5 46.1.1.1-10.6-8.8c-.4-.3-.9-.5-1.4-.6l-48.1-9.3-4.9-4.9c-.3-.3-.7-.5-1.1-.7l-61.5-22.8-50-43.4 59-35.4c1.2-.7 1.8-2.3 1.3-3.6l-9.8-25.7 42.7-23.9 22.3 8.8 63.2 49.7c.1.1.3.2.5.3l17.2 9-35.5 22.1 3.2 5.1 57.1-35.9-7.4-2.5-12.2 7.7.3-.5-19.8-10.4-63.3-49.8c-.2-.2-.5-.3-.8-.4l-24-9.5c-.8-.3-1.8-.3-2.6.2l-44.9 25.2-34 2.4-24.3-12.5-6.2 3.6L124 77.2l-83.9 51L47 131l84-51.1 31.8-2.2 9.1 24-60.3 36.2c-.8.5-1.4 1.4-1.4 2.3-.1 1 .3 1.9 1 2.5l53.5 46.5c.3.2.6.4.9.5l18.4 6.8-12.1 8 7.2 2.4 11.8-7.8 36 13.4 5 5c.4.4 1 .7 1.5.8l48.2 9.4 25.5 21.3 5.5-3.2-12.3-10.3z"
          fill="#FEE2A6"
        />
        <path
          d="M315.8 157c-.3-.3-.7-.5-1.1-.7l-42-18.5c-.9-.4-2-.5-2.9-.1L200.9 162l-21.8-17.7c1.5-1.7 2.2-3.7 2.2-5.7 0-1-.2-1.9-.5-2.8l29.5-16.3 45.1-3.4c.6 0 1.2-.2 1.7-.5l41.5-24.7-6.8-5.3-38 22.6-45.1 3.4c-.6 0-1.1.2-1.6.5l-32 17.7c-2.8-1.5-6.4-2.3-10.1-2.3-2 0-4 .2-5.8.7l-18.1-14.7c-.3-.2-.6-.4-1-.6L69.2 83l-8.7 5 76 31.9 15 12.2c-1.8 1.8-2.8 4-2.8 6.3 0 1.5.4 2.9 1.2 4.3l-40.2 24.4 6.7 5.3 40.1-24.4c2.5 1 5.5 1.6 8.5 1.6 2.3 0 4.6-.3 6.7-.9l25.8 21c1.1.9 2.5 1.1 3.9.7l69.5-24.5 40 17.6 56.4 50.6 7.3-4.2-58.8-52.9zm-144-17c-1.7 1-4.1 1.5-6.6 1.5-2.6 0-5-.6-6.7-1.6-1.2-.7-1.6-1.4-1.6-1.6 0-.2.4-.8 1.6-1.5 1.7-1 4.1-1.5 6.6-1.5 2.6 0 5 .6 6.7 1.6 1.2.7 1.6 1.4 1.6 1.6-.1.2-.5.8-1.6 1.5z"
          fill="url(#prefix__paint1_linear_4411_130918)"
        />
        <path
          d="M375.6 208.5L365.1 198l14-9 16.5-43 6-2.5 35.5 29-61.5 36zM109.1 164.5l-25.5-19-2.5-19-11-7 24-15 37 14.5-2.5 7-19.5 11.5-8.5 5.5 13 3.5 4 14.5-8.5 3.5z"
          fill="#CFE4BD"
        />
        <path
          d="M322.9 135.7c-.2 0-.3 0-.5-.1l-1.8-.8c-.6-.3-.9-1-.6-1.7.3-.6 1-.9 1.7-.6l1.8.8c.6.3.9 1 .6 1.7-.2.4-.7.7-1.2.7z"
          fill="url(#prefix__paint2_linear_4411_130918)"
        />
        <path
          d="M317.5 133.3c-.2 0-.3 0-.5-.1l-3.6-1.6c-.6-.3-.9-1-.6-1.7.3-.6 1-.9 1.7-.6l3.6 1.6c.6.3.9 1 .6 1.7-.2.4-.7.7-1.2.7z"
          fill="url(#prefix__paint3_linear_4411_130918)"
        />
        <path
          d="M306.8 130.3c-.4 0-.8-.2-1.1-.6-.4-.6-.2-1.4.4-1.7l1.7-1c.4-.2.8-.2 1.2-.1l1.8.8c.6.3.9 1 .6 1.7-.3.6-1 .9-1.7.6l-1.2-.5-1.2.7c0 .1-.3.1-.5.1z"
          fill="url(#prefix__paint4_linear_4411_130918)"
        />
        <path
          d="M285.8 143.1c-.4 0-.8-.2-1.1-.6-.4-.6-.2-1.4.4-1.7l3.5-2.1c.6-.4 1.4-.2 1.7.4.4.6.2 1.4-.4 1.7l-3.5 2.1c-.1.1-.4.2-.6.2zm7-4.3c-.4 0-.8-.2-1.1-.6-.4-.6-.2-1.4.4-1.7l3.5-2.1c.6-.4 1.4-.2 1.7.4.4.6.2 1.4-.4 1.7l-3.5 2.1c-.1.2-.4.2-.6.2zm7-4.2c-.4 0-.8-.2-1.1-.6-.4-.6-.2-1.4.4-1.7l3.5-2.1c.6-.4 1.4-.2 1.7.4.4.6.2 1.4-.4 1.7l-3.5 2.1c-.1.1-.4.2-.6.2z"
          fill="url(#prefix__paint5_linear_4411_130918)"
        />
        <path
          d="M280.6 146.2c-.1 0-.3 0-.4-.1l-1.9-.7c-.6-.2-1-1-.7-1.6.2-.6 1-1 1.6-.7l1.3.5 1.2-.7c.6-.4 1.4-.2 1.7.4.4.6.2 1.4-.4 1.7l-1.7 1c-.3.1-.5.2-.7.2z"
          fill="url(#prefix__paint6_linear_4411_130918)"
        />
        <path
          d="M205.6 165.8c-.5 0-1-.3-1.2-.8-.2-.7.1-1.4.8-1.6l3.7-1.3c.7-.2 1.4.1 1.6.8.2.7-.1 1.4-.8 1.6l-3.7 1.3c-.1-.1-.2 0-.4 0zm7.3-2.6c-.5 0-1-.3-1.2-.8-.2-.7.1-1.4.8-1.6l3.7-1.3c.7-.2 1.4.1 1.6.8.2.7-.1 1.4-.8 1.6l-3.7 1.3h-.4zm7.4-2.6c-.5 0-1-.3-1.2-.8-.2-.7.1-1.4.8-1.6l3.7-1.3c.7-.2 1.4.1 1.6.8.2.7-.1 1.4-.8 1.6l-3.7 1.3h-.4zm7.3-2.6c-.5 0-1-.3-1.2-.8-.2-.7.1-1.4.8-1.6l3.7-1.3c.7-.2 1.4.1 1.6.8.2.7-.1 1.4-.8 1.6L228 158h-.4zm7.3-2.5c-.5 0-1-.3-1.2-.8-.2-.7.1-1.4.8-1.6l3.7-1.3c.7-.2 1.4.1 1.6.8.2.7-.1 1.4-.8 1.6l-3.7 1.3c-.1-.1-.3 0-.4 0zm7.3-2.6c-.5 0-1-.3-1.2-.8-.2-.7.1-1.4.8-1.6l3.7-1.3c.7-.2 1.4.1 1.6.8.2.7-.1 1.4-.8 1.6l-3.7 1.3h-.4zm7.3-2.6c-.5 0-1-.3-1.2-.8-.2-.7.1-1.4.8-1.6l3.7-1.3c.7-.2 1.4.1 1.6.8.2.7-.1 1.4-.8 1.6l-3.7 1.3h-.4zm7.3-2.6c-.5 0-1-.3-1.2-.8-.2-.7.1-1.4.8-1.6l3.7-1.3c.7-.2 1.4.1 1.6.8.2.7-.1 1.4-.8 1.6l-3.7 1.3h-.4zm7.3-2.5c-.5 0-1-.3-1.2-.8-.2-.7.1-1.4.8-1.6l3.7-1.3c.7-.2 1.4.1 1.6.8.2.7-.1 1.4-.8 1.6l-3.7 1.3c-.1-.1-.2 0-.4 0zm11-1c-.1 0-.3 0-.4-.1l-3.6-1.3c-.6-.2-1-1-.7-1.6.2-.6 1-1 1.6-.7l3.6 1.3c.6.2 1 1 .7 1.6-.2.5-.7.8-1.2.8z"
          fill="url(#prefix__paint7_linear_4411_130918)"
        />
        <path
          d="M200.1 167.7c-.3 0-.6-.1-.8-.3l-1.6-1.3c-.5-.4-.6-1.2-.2-1.8.4-.5 1.2-.6 1.8-.2l1 .8 1.2-.4c.7-.2 1.4.1 1.6.8.2.7-.1 1.4-.8 1.6l-1.9.7c0 .1-.2.1-.3.1z"
          fill="url(#prefix__paint8_linear_4411_130918)"
        />
        <path
          d="M195.8 164.2c-.3 0-.6-.1-.8-.3l-2.8-2.2c-.5-.4-.6-1.2-.2-1.8.4-.5 1.2-.6 1.8-.2l2.8 2.2c.5.4.6 1.2.2 1.8-.3.3-.7.5-1 .5zm-5.5-4.5c-.3 0-.6-.1-.8-.3l-2.8-2.2c-.5-.4-.6-1.2-.2-1.8.4-.5 1.2-.6 1.8-.2l2.8 2.2c.5.4.6 1.2.2 1.8-.3.4-.7.5-1 .5zm-5.6-4.5c-.3 0-.6-.1-.8-.3l-2.8-2.2c-.5-.4-.6-1.2-.2-1.8.4-.5 1.2-.6 1.8-.2l2.8 2.2c.5.4.6 1.2.2 1.8-.2.4-.6.5-1 .5zm-5.5-4.4c-.3 0-.6-.1-.8-.3l-2.8-2.2c-.5-.4-.6-1.2-.2-1.8.4-.5 1.2-.6 1.8-.2l2.8 2.2c.5.4.6 1.2.2 1.8-.2.3-.6.5-1 .5z"
          fill="url(#prefix__paint9_linear_4411_130918)"
        />
        <path
          d="M173.7 146.3c-.3 0-.6-.1-.8-.3l-1.6-1.3c-.3-.2-.5-.6-.5-1s.2-.7.5-1c0 0 .6-.5 1.3-1.2.5-.5 1.2-.6 1.8-.1.5.5.6 1.2.1 1.8l-.5.5.4.3c.5.4.6 1.2.2 1.8-.2.3-.5.5-.9.5z"
          fill="url(#prefix__paint10_linear_4411_130918)"
        />
        <path
          d="M175.5 140.5c-.1 0-.3 0-.4-.1-.7-.2-1-.9-.8-1.6.1-.4.2-.9.2-1.3 0-.5-.1-.9-.3-1.4-.3-.6 0-1.4.7-1.6.6-.3 1.4 0 1.6.7.3.8.5 1.5.5 2.4 0 .7-.1 1.4-.3 2.1-.2.5-.7.8-1.2.8zm-2.4-6.3c-.3 0-.5-.1-.8-.3-.8-.6-1.7-1.1-2.8-1.4-.7-.2-1-.9-.8-1.6.2-.7.9-1 1.6-.8 1.4.4 2.6 1.1 3.6 1.8.5.4.6 1.2.2 1.8-.2.3-.6.5-1 .5zm-6.8-2.3h-.1c-1.1-.1-2.3-.1-3.5 0-.7.1-1.3-.5-1.3-1.2s.5-1.3 1.2-1.3c1.2-.1 2.6-.1 3.8 0 .7.1 1.2.7 1.1 1.3 0 .7-.5 1.2-1.2 1.2z"
          fill="url(#prefix__paint11_linear_4411_130918)"
        />
        <path
          d="M157.1 132.7c-.3 0-.6-.1-.8-.3l-1.5-1.3c-.5-.4-.6-1.2-.2-1.8.4-.5 1.2-.6 1.8-.2l1 .9c.4-.1.8-.2 1.4-.3.7-.1 1.3.3 1.4 1 .1.7-.3 1.3-1 1.4-1.1.2-1.8.4-1.8.4-.1.2-.2.2-.3.2z"
          fill="url(#prefix__paint12_linear_4411_130918)"
        />
        <path
          d="M152.4 128.8c-.3 0-.6-.1-.8-.3l-3.1-2.6c-.5-.4-.6-1.2-.2-1.8.4-.5 1.2-.6 1.8-.2l3.1 2.6c.5.4.6 1.2.2 1.8-.3.3-.6.5-1 .5z"
          fill="url(#prefix__paint13_linear_4411_130918)"
        />
        <path
          d="M146.1 123.5c-.3 0-.6-.1-.8-.3l-1.5-1.3c-.3-.3-.5-.7-.4-1.1 0-.4.3-.8.6-1l1.7-1c.6-.3 1.4-.1 1.7.5.3.6.1 1.4-.5 1.7l-.2.1.2.1c.5.4.6 1.2.2 1.8-.3.3-.6.5-1 .5z"
          fill="url(#prefix__paint14_linear_4411_130918)"
        />
        <path
          d="M149.4 119.4c-.4 0-.9-.2-1.1-.6-.3-.6-.1-1.4.5-1.7l3.1-1.8c.6-.3 1.4-.1 1.7.5.3.6.1 1.4-.5 1.7l-3.1 1.8c-.2.1-.4.1-.6.1zm6.1-3.5c-.4 0-.9-.2-1.1-.6-.3-.6-.1-1.4.5-1.7l3.1-1.8c.6-.3 1.4-.1 1.7.5.3.6.1 1.4-.5 1.7l-3.1 1.8c-.2 0-.4.1-.6.1zm6.1-3.6c-.4 0-.9-.2-1.1-.6-.3-.6-.1-1.4.5-1.7l3.1-1.8c.6-.3 1.4-.1 1.7.5.3.6.1 1.4-.5 1.7l-3.1 1.8c-.1.1-.4.1-.6.1zm6.1-3.5c-.4 0-.9-.2-1.1-.6-.3-.6-.1-1.4.5-1.7l3.1-1.8c.6-.3 1.4-.1 1.7.5.3.6.1 1.4-.5 1.7l-3.1 1.8c-.1 0-.3.1-.6.1z"
          fill="url(#prefix__paint15_linear_4411_130918)"
        />
        <path
          d="M173.9 105.2c-.4 0-.8-.2-1.1-.6-.3-.6-.1-1.4.5-1.7l.9-.5-.3-1c-.2-.7.2-1.4.9-1.5.7-.2 1.4.2 1.5.9l.5 1.9c.2.5-.1 1.1-.6 1.4l-1.7 1c-.2.1-.4.1-.6.1z"
          fill="url(#prefix__paint16_linear_4411_130918)"
        />
        <path
          d="M174.1 98.7c-.5 0-1-.4-1.2-.9l-1-3.6c-.2-.7.2-1.4.9-1.5.7-.2 1.4.2 1.5.9l1 3.6c.2.7-.2 1.4-.9 1.5h-.3z"
          fill="url(#prefix__paint17_linear_4411_130918)"
        />
        <path
          d="M172.1 91.6c-.5 0-1-.4-1.2-.9l-.5-1.9c-.2-.6.1-1.2.6-1.4l1.8-.9c.6-.3 1.4-.1 1.7.5.3.6.1 1.4-.5 1.7l-.9.5.3 1c.2.7-.2 1.4-.9 1.5-.2-.1-.3-.1-.4-.1z"
          fill="url(#prefix__paint18_linear_4411_130918)"
        />
        <path
          d="M177.3 86.6c-.4 0-.9-.2-1.1-.7-.3-.6-.1-1.4.5-1.7l2.5-1.2c.6-.3 1.4-.1 1.7.5.3.6.1 1.4-.5 1.7l-2.5 1.2c-.2.2-.4.2-.6.2z"
          fill="url(#prefix__paint19_linear_4411_130918)"
        />
        <path
          d="M183.7 84.8c-1.2.7-3.2.7-4.4 0-1.2-.7-1.2-1.8 0-2.5s3.2-.7 4.4 0c1.2.6 1.2 1.8 0 2.5z"
          fill="url(#prefix__paint20_linear_4411_130918)"
        />
        <path
          d="M328.4 208.3l18-10.7-21.6-20-6.4 4.4 12 13.3-8 5 6 8zM284.4 259l18-10.7-16.5-14.5-6.3 4.3-1.2 12.9 6 8zM311.4 133l-14.3 9 9.5 5.3 9-2.7 10.2 5.7 11-6.3-25.4-11zM143.1 82l-4 2.6 19 8.4 4-4-3.7-7h-15.3zM157.1 169.3l14.3-9-5.6-5.7-6 3.4-5.7-5.4-10.7 6.4 13.7 10.3zM255.1 103l13.7-9.4-13.7-12-4.7 4.4 7.4 5.6-6.4 5.4 3.7 6zM356.1 188.6l10-6.3-.3-16-7.7-1.3-2 10.3-3.7 2.7-16.3-14.4-5.3 3 25.3 22zM201.1 159l11-5.7-12.7-25-10 5.3-.3 7.4-5.3 4 17.3 14z"
          fill="#D1D3D4"
        />
        <path
          d="M317.6 99.1l-64.4-50L79 149.4l65.5 51.2L317.6 99.1zM208.9 222.4l63.8 52.7 174.4-102-63.8-52.1-174.4 101.4z"
          fill="#000"
          fillOpacity={0.2}
        />
        <path
          d="M328.6 147c14.359 0 26-5.954 26-13.3 0-7.345-11.641-13.3-26-13.3s-26 5.955-26 13.3c0 7.346 11.641 13.3 26 13.3z"
          fill="url(#prefix__paint21_radial_4411_130918)"
        />
        <path
          d="M329 34.5c-8.5-4.9-16.1-5-21.3-1.4l-6.1 2.8.7 3.9c-1.3 3-2.1 6.7-2.1 11 0 12.5 6.7 32.2 13.6 49.2l7.2 37.6 8-4s28.7-23.8 28.7-49.7c.1-18.1-12.8-40.3-28.7-49.4z"
          fill="url(#prefix__paint22_linear_4411_130918)"
        />
        <path
          d="M349.8 88c0 25.9-28.7 49.7-28.7 49.7s-28.7-57-28.7-82.9c0-18.2 12.9-25.6 28.7-16.4 15.9 9.2 28.7 31.4 28.7 49.6z"
          fill="url(#prefix__paint23_linear_4411_130918)"
        />
        <path
          d="M322.9 37.4c-8.3-4.8-15.7-5-21-1.6 5.1-2.3 11.8-1.6 19.2 2.6 15.9 9.2 28.7 31.4 28.7 49.6 0 22.2-21.1 42.8-27.1 48.3.1.3.2.4.2.4s28.7-23.8 28.7-49.7c.1-18.2-12.8-40.4-28.7-49.6z"
          fill="url(#prefix__paint24_linear_4411_130918)"
        />
        <path
          d="M333.6 78.6c0 7.9-5.6 11.1-12.5 7.1s-12.5-13.6-12.5-21.6c0-7.9 5.6-11.1 12.5-7.1 6.9 4.1 12.5 13.7 12.5 21.6z"
          fill="#fff"
        />
        <path
          d="M324.9 84c-6.9-4-12.5-13.6-12.5-21.6 0-3.1.9-5.5 2.4-7-3.7.3-6.2 3.5-6.2 8.8 0 7.9 5.6 17.6 12.5 21.6 4.2 2.4 7.9 2.2 10.1-.1-1.8.1-4-.4-6.3-1.7z"
          fill="url(#prefix__paint25_linear_4411_130918)"
        />
      </g>
      <defs>
        <linearGradient
          id="prefix__paint0_linear_4411_130918"
          x1={329.7}
          y1={346.079}
          x2={149.033}
          y2={-1.254}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.076} stopColor="#F0EFF2" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear_4411_130918"
          x1={242.376}
          y1={145.021}
          x2={-23.957}
          y2={32.687}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.035} stopColor="#FFA98C" />
          <stop offset={0.317} stopColor="#FFB892" />
          <stop offset={0.86} stopColor="#FEE1A3" />
          <stop offset={1} stopColor="#FEECA8" />
        </linearGradient>
        <linearGradient
          id="prefix__paint2_linear_4411_130918"
          x1={324.096}
          y1={135.435}
          x2={149.347}
          y2={19.436}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.035} stopColor="#9F19AB" />
          <stop offset={0.244} stopColor="#AE25B1" />
          <stop offset={0.645} stopColor="#D744BF" />
          <stop offset={1} stopColor="#FF63CE" />
        </linearGradient>
        <linearGradient
          id="prefix__paint3_linear_4411_130918"
          x1={323.467}
          y1={136.383}
          x2={148.716}
          y2={20.382}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.035} stopColor="#9F19AB" />
          <stop offset={0.244} stopColor="#AE25B1" />
          <stop offset={0.645} stopColor="#D744BF" />
          <stop offset={1} stopColor="#FF63CE" />
        </linearGradient>
        <linearGradient
          id="prefix__paint4_linear_4411_130918"
          x1={322.338}
          y1={138.084}
          x2={147.589}
          y2={22.085}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.035} stopColor="#9F19AB" />
          <stop offset={0.244} stopColor="#AE25B1" />
          <stop offset={0.645} stopColor="#D744BF" />
          <stop offset={1} stopColor="#FF63CE" />
        </linearGradient>
        <linearGradient
          id="prefix__paint5_linear_4411_130918"
          x1={314.574}
          y1={149.78}
          x2={139.823}
          y2={33.779}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.035} stopColor="#9F19AB" />
          <stop offset={0.244} stopColor="#AE25B1" />
          <stop offset={0.645} stopColor="#D744BF" />
          <stop offset={1} stopColor="#FF63CE" />
        </linearGradient>
        <linearGradient
          id="prefix__paint6_linear_4411_130918"
          x1={306.774}
          y1={161.531}
          x2={132.023}
          y2={45.53}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.035} stopColor="#9F19AB" />
          <stop offset={0.244} stopColor="#AE25B1" />
          <stop offset={0.645} stopColor="#D744BF" />
          <stop offset={1} stopColor="#FF63CE" />
        </linearGradient>
        <linearGradient
          id="prefix__paint7_linear_4411_130918"
          x1={290.059}
          y1={186.711}
          x2={115.309}
          y2={70.711}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.035} stopColor="#9F19AB" />
          <stop offset={0.244} stopColor="#AE25B1" />
          <stop offset={0.645} stopColor="#D744BF" />
          <stop offset={1} stopColor="#FF63CE" />
        </linearGradient>
        <linearGradient
          id="prefix__paint8_linear_4411_130918"
          x1={272.314}
          y1={213.442}
          x2={97.565}
          y2={97.442}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.035} stopColor="#9F19AB" />
          <stop offset={0.244} stopColor="#AE25B1" />
          <stop offset={0.645} stopColor="#D744BF" />
          <stop offset={1} stopColor="#FF63CE" />
        </linearGradient>
        <linearGradient
          id="prefix__paint9_linear_4411_130918"
          x1={272.833}
          y1={212.662}
          x2={98.083}
          y2={96.662}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.035} stopColor="#9F19AB" />
          <stop offset={0.244} stopColor="#AE25B1" />
          <stop offset={0.645} stopColor="#D744BF" />
          <stop offset={1} stopColor="#FF63CE" />
        </linearGradient>
        <linearGradient
          id="prefix__paint10_linear_4411_130918"
          x1={274.278}
          y1={210.484}
          x2={99.528}
          y2={94.485}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.035} stopColor="#9F19AB" />
          <stop offset={0.244} stopColor="#AE25B1" />
          <stop offset={0.645} stopColor="#D744BF" />
          <stop offset={1} stopColor="#FF63CE" />
        </linearGradient>
        <linearGradient
          id="prefix__paint11_linear_4411_130918"
          x1={277.975}
          y1={204.915}
          x2={103.225}
          y2={88.915}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.035} stopColor="#9F19AB" />
          <stop offset={0.244} stopColor="#AE25B1" />
          <stop offset={0.645} stopColor="#D744BF" />
          <stop offset={1} stopColor="#FF63CE" />
        </linearGradient>
        <linearGradient
          id="prefix__paint12_linear_4411_130918"
          x1={275.243}
          y1={209.03}
          x2={100.494}
          y2={93.031}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.035} stopColor="#9F19AB" />
          <stop offset={0.244} stopColor="#AE25B1" />
          <stop offset={0.645} stopColor="#D744BF" />
          <stop offset={1} stopColor="#FF63CE" />
        </linearGradient>
        <linearGradient
          id="prefix__paint13_linear_4411_130918"
          x1={275.354}
          y1={208.861}
          x2={100.605}
          y2={92.862}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.035} stopColor="#9F19AB" />
          <stop offset={0.244} stopColor="#AE25B1" />
          <stop offset={0.645} stopColor="#D744BF" />
          <stop offset={1} stopColor="#FF63CE" />
        </linearGradient>
        <linearGradient
          id="prefix__paint14_linear_4411_130918"
          x1={276.295}
          y1={207.443}
          x2={101.546}
          y2={91.445}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.035} stopColor="#9F19AB" />
          <stop offset={0.244} stopColor="#AE25B1" />
          <stop offset={0.645} stopColor="#D744BF" />
          <stop offset={1} stopColor="#FF63CE" />
        </linearGradient>
        <linearGradient
          id="prefix__paint15_linear_4411_130918"
          x1={284.75}
          y1={194.708}
          x2={110}
          y2={78.708}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.035} stopColor="#9F19AB" />
          <stop offset={0.244} stopColor="#AE25B1" />
          <stop offset={0.645} stopColor="#D744BF" />
          <stop offset={1} stopColor="#FF63CE" />
        </linearGradient>
        <linearGradient
          id="prefix__paint16_linear_4411_130918"
          x1={293.504}
          y1={181.521}
          x2={118.754}
          y2={65.521}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.035} stopColor="#9F19AB" />
          <stop offset={0.244} stopColor="#AE25B1" />
          <stop offset={0.645} stopColor="#D744BF" />
          <stop offset={1} stopColor="#FF63CE" />
        </linearGradient>
        <linearGradient
          id="prefix__paint17_linear_4411_130918"
          x1={296.364}
          y1={177.213}
          x2={121.614}
          y2={61.212}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.035} stopColor="#9F19AB" />
          <stop offset={0.244} stopColor="#AE25B1" />
          <stop offset={0.645} stopColor="#D744BF" />
          <stop offset={1} stopColor="#FF63CE" />
        </linearGradient>
        <linearGradient
          id="prefix__paint18_linear_4411_130918"
          x1={299.214}
          y1={172.92}
          x2={124.464}
          y2={56.919}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.035} stopColor="#9F19AB" />
          <stop offset={0.244} stopColor="#AE25B1" />
          <stop offset={0.645} stopColor="#D744BF" />
          <stop offset={1} stopColor="#FF63CE" />
        </linearGradient>
        <linearGradient
          id="prefix__paint19_linear_4411_130918"
          x1={302.925}
          y1={167.327}
          x2={128.176}
          y2={51.328}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.035} stopColor="#9F19AB" />
          <stop offset={0.244} stopColor="#AE25B1" />
          <stop offset={0.645} stopColor="#D744BF" />
          <stop offset={1} stopColor="#FF63CE" />
        </linearGradient>
        <linearGradient
          id="prefix__paint20_linear_4411_130918"
          x1={304.423}
          y1={165.073}
          x2={129.673}
          y2={49.073}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.035} stopColor="#9F19AB" />
          <stop offset={0.244} stopColor="#AE25B1" />
          <stop offset={0.645} stopColor="#D744BF" />
          <stop offset={1} stopColor="#FF63CE" />
        </linearGradient>
        <linearGradient
          id="prefix__paint22_linear_4411_130918"
          x1={314}
          y1={29}
          x2={284.34}
          y2={148.01}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6979F8" />
          <stop offset={0.22} stopColor="#6E7DF8" />
          <stop offset={0.46} stopColor="#7B89F8" />
          <stop offset={0.72} stopColor="#929EF9" />
          <stop offset={0.99} stopColor="#B2BAF9" />
          <stop offset={1} stopColor="#B2BAF9" />
        </linearGradient>
        <linearGradient
          id="prefix__paint23_linear_4411_130918"
          x1={337.184}
          y1={154.218}
          x2={395.851}
          y2={90.953}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6979F8" />
          <stop offset={0.22} stopColor="#6E7DF8" />
          <stop offset={0.46} stopColor="#7B89F8" />
          <stop offset={0.72} stopColor="#929EF9" />
          <stop offset={0.99} stopColor="#B2BAF9" />
          <stop offset={1} stopColor="#B2BAF9" />
        </linearGradient>
        <linearGradient
          id="prefix__paint24_linear_4411_130918"
          x1={342.5}
          y1={151.5}
          x2={405.339}
          y2={76.323}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BDC4FF" />
          <stop offset={0.22} stopColor="#6E7DF8" />
          <stop offset={0.46} stopColor="#BFC6FF" />
          <stop offset={0.72} stopColor="#929EF9" />
          <stop offset={0.854} stopColor="#E0E4FF" />
          <stop offset={0.99} stopColor="#B2BAF9" />
        </linearGradient>
        <linearGradient
          id="prefix__paint25_linear_4411_130918"
          x1={322.5}
          y1={78.5}
          x2={339.576}
          y2={68.727}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4D5CD6" />
          <stop offset={0.22} stopColor="#6E7DF8" />
          <stop offset={0.46} stopColor="#7B89F8" />
          <stop offset={0.72} stopColor="#929EF9" />
          <stop offset={0.99} stopColor="#B2BAF9" />
          <stop offset={1} stopColor="#B2BAF9" />
        </linearGradient>
        <radialGradient
          id="prefix__paint21_radial_4411_130918"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(26.0346 0 0 13.2752 329.149 133.73)"
        >
          <stop stopColor="#C7C8C9" />
          <stop offset={1} stopColor="#fff" />
        </radialGradient>
        <clipPath id="prefix__clip0_4411_130918">
          <path fill="#fff" d="M0 0h447.1v296.2H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default EmptyHop
