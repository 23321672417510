import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { usePostUserLoginMutation } from '../../api/api.generated'
import { adaptLoginData } from '../../utils/adapters'
import ROUTES from '../../const/ROUTES'

const useLogin = () => {
  const [passwordInputType, setPasswordInputType] = useState('password')
  const [login, { isLoading: isLoggingIn }] = usePostUserLoginMutation()
  const history = useHistory()

  const handleLogin = (values, formik) => {
    login({ body: adaptLoginData(values) })
      .unwrap()
      .then((res) => {
        if (res.token) {
          history.push(ROUTES.HOME)
        }
      })
      .catch((err) => {
        if (err?.data?.message) {
          formik.setErrors({ ...err.data.message })
        }
      })
  }

  const handleShowPassword = () => {
    if (passwordInputType === 'password') {
      setPasswordInputType('text')
      return
    }
    setPasswordInputType('password')
  }
  const handleGoToRestorePassword = () => {
    history.push(ROUTES.RESTORE_PASSWORD)
  }
  return {
    isLoggingIn,
    handleLogin,
    handleShowPassword,
    handleGoToRestorePassword,
    passwordInputType
  }
}
export default useLogin
