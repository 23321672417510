import React from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { TabsWrap } from './styles'
import Tab from '../UI/Tab'
import { HOP_TABS } from '../../const/tabs'
import { selectHopRoute } from '../../store/selectors'

const HopDrawerTabs = () => {
  const location = useLocation()
  const route = useSelector(selectHopRoute)
  return (
    <TabsWrap>
      {HOP_TABS.map((tabItem) => (
        <Tab
          autoWidth
          key={tabItem.destination}
          destination={tabItem.destination}
          isActive={location.pathname.includes(tabItem.destination)}
          label={tabItem.label}
          count={tabItem.label === 'My Hop' ? route.length : null}
        />
      ))}
    </TabsWrap>
  )
}

export default HopDrawerTabs

HopDrawerTabs.propTypes = {}
