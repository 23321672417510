import axios from 'axios'
import { BASE_URL } from '../const/api'

export const apiV1 = axios.create({
  baseURL: BASE_URL,
  timeout: 10000
})

const axiosBaseQuery =
  () =>
  async ({ url, method, body }) => {
    try {
      const result = await apiV1({ url, method, data: body })
      return { data: result.data?.data || result.data }
    } catch (axiosError) {
      const err = axiosError
      return {
        error: { status: err.response?.status, data: err.response?.data }
      }
    }
  }

apiV1.interceptors.request.use(
  (response) => response,
  (e) => {
    if (e.toJSON().message === 'Network Error') {
      // eslint-disable-next-line no-console
      console.log('No internet connection')
    }
    return Promise.reject(e)
  }
)
export default axiosBaseQuery
