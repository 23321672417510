import styled from 'styled-components'
import { Form } from 'formik'
import { HStack, VStack } from '../../../global/styles'
import { pxToVh } from '../../../utils/styles-utils'

export const SignUpWrap = styled(VStack)`
  margin-top: ${pxToVh(84)};
  padding: 0 24px 24px 24px;
  justify-content: space-between;
`
export const LoginLinkWrap = styled(HStack)`
  margin-top: auto;
  align-self: center;
  width: auto;
`
export const StyledForm = styled(Form)`
  display: flex;
  height: 100%;
`
export const MessageWrap = styled(VStack)`
  margin: ${pxToVh(43)} auto ${pxToVh(24)} auto;
`
