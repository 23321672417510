import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { FormBody, HeadlineWrap, StyledForm, StyledScrollbars } from './styles'
import useSupportRequest from '../../../hooks/settings/useSupportRequest'
import ImagePicker from '../../../components/ImagePicker'
import Button from '../../../components/UI/Buttons/Button'
import { BUTTON_VARIANTS, BUTTONS_SIZES } from '../../../const/UIvariants'
import { Description, Headline } from '../SettingsScreen/styles'
import TextAreaInput from '../../../components/UI/Inputs/TextAreaInput'
import ScreenHeader from '../../../components/ScreenHeader'
import TextInput from '../../../components/UI/Inputs/TextInput'

const SupportRequest = () => {
  const { sendRequest } = useSupportRequest()
  return (
    <Formik
      initialValues={{
        topic: '',
        description: '',
        images: []
      }}
      validationSchema={Yup.object({
        topic: Yup.string().required('Required'),
        description: Yup.string().required('Required')
      })}
      onSubmit={sendRequest}
    >
      <>
        <ScreenHeader title="Account" backButton />
        <StyledForm>
          <StyledScrollbars>
            <FormBody>
              <HeadlineWrap>
                <Headline>Suggestions</Headline>
                <Description>Send us a feedback and screenshots </Description>
              </HeadlineWrap>
              <TextInput
                placeholder="Enter a topic"
                label="Topic"
                inputName="topic"
              />
              <TextAreaInput
                rows={10}
                inputName="description"
                label="How can we help you?"
              />
              <ImagePicker name="images" />
              <Button
                type="submit"
                size={BUTTONS_SIZES.LARGE}
                variant={BUTTON_VARIANTS.PRIMARY}
              >
                Send
              </Button>
            </FormBody>
          </StyledScrollbars>
        </StyledForm>
      </>
    </Formik>
  )
}

export default SupportRequest

SupportRequest.propTypes = {}
