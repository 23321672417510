import styled from 'styled-components'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { Form } from 'formik'
import { VStack, HStack } from '../../../global/styles'

export const StyledForm = styled(Form)`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.skyNeutral5};
`

export const ContentWrapper = styled(OverlayScrollbarsComponent).attrs({
  options: {
    scrollbars: { visibility: 'hidden' }
  }
})`
  height: 100%;
  width: 100%;
  padding: 0;
`

export const FormBody = styled(VStack)`
  padding: 16px;
  margin-bottom: 50px;
`
export const HeadlineWrap = styled(VStack)`
  margin-bottom: 24px;
`

export const LineWrap = styled(HStack)`
  & > *:nth-child(2) {
    margin-left: 15px;
  }
`
