import styled from 'styled-components'
import { HStack, VStack } from '../../global/styles'
import Typography from '../UI/Typography'

export const CityItemWrap = styled(VStack)`
  width: 100%;
  padding: 12px;
  height: 96px;
  border-radius: 12px;
  background: ${({ isActive, theme }) =>
    isActive ? theme.colors.skyBase4 : theme.colors.white};
  border: ${({ isActive, theme }) =>
    isActive ? 'none' : `1px solid ${theme.colors.skyNeutral3}`};
  justify-content: space-between;
  margin-bottom: 16px;
  &:last-of-type {
    //border: 1px solid red;
  }
`
export const Labels = styled(VStack)`
  align-items: flex-start;
`
export const CityName = styled(Typography).attrs(() => ({
  variant: 'textM',
  bold: true
}))`
  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.skyBase_1 : theme.colors.skyNeutral_1};
`
export const Country = styled(Typography).attrs(() => ({
  variant: 'textXS'
}))`
  color: ${({ theme }) => theme.colors.skyNeutral2};
`
export const MetaWrap = styled(HStack)`
  justify-content: flex-start;
  align-items: center;
`
export const ShopsWrap = styled(HStack)`
  width: auto;
  align-items: center;
  color: ${({ theme }) => theme.colors.skyBase};
  margin-right: 10px;
  > :first-child {
    margin-right: 6px;
  }
`
export const StoresCount = styled(Typography).attrs(() => ({
  variant: 'textXS'
}))`
  color: ${({ theme }) => theme.colors.skyNeutral_1};
`
export const DistanceWrap = styled(HStack)`
  width: auto;
  align-items: center;
  color: ${({ theme }) => theme.colors.skyNeutral2};
  margin-right: 10px;
  > :first-child {
    margin-right: 6px;
  }
`
export const Distance = styled(Typography).attrs(() => ({
  variant: 'textXS'
}))`
  color: ${({ theme }) => theme.colors.skyNeutral1};
`
