function AustralianRedCross() {
  return (
    `<svg version="1.1" id="Layer_1" x="0px" y="0px" width="30px" height="30px" viewBox="0 0 30 30" enableBackground="new 0 0 30 30">  
    <image id="image0" width="30" height="30" x="0" y="0" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAMAAAAM7l6QAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
    AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAC31BMVEUAAADkIxPCHhDhIxP/
    LBj/pVqqGg7kIxPkIxPkIxPkIxPkIxPkIxPkIxPkIxPkIxPkIxPkIxMAAAAAAAAAAAAAAAAAAAAA
    AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
    AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
    AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADkIxPkIxMAAAAAAAAAAAAAAAAA
    AAAAAAAAAAAAAAAAAAAAAAAAAAAAAADkIxPkIxPkIxPkIxPkIxPkIxPkIxPoJBMAAAAAAAAAAAAA
    AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADkIxPkIxPkIxPkIxPkIxPkIxPkIxPmIxMA
    AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADkIxPkIxPnIxMAAAAAAAAAAAAAAAAAAAAAAAAA
    AAAAAAAAAADkIxPsJBQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADpJBMAAAAAAAAAAAAA
    AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADlIxMAAAAAAAAAAAAAAADkIxMAAAAAAADkIxPk
    IxPkIxPkIxPkIxPkIxPkIxPlIxMAAAAAAAAAAAAAAAAAAAAAAAAAAADkIxPkIxPkIxPkIxPkIxPk
    IxPkIxPpJBMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADkIxMCAAAAAAAAAAAA
    AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
    AAAAAAAAAAAAAAAAAAAAAAAAAAAAAADkIxPkIxPkIxPkIxP///+AdnyqAAAA83RSTlMAAAAAAAAA
    FLjdxiIX2eoo1+gTRQIOCiJCMaUITDRGhUC3D1I4SAwBS6owOTtTgoyKZ1uRWmY2km9/V5VOmrWm
    n82nun6BvI/O3RYnYn1dY3MzWJBEdYeWDQwk2ukzCwJ2bm2oolxeTYlgwqtresHJzvf60bcf+Yhk
    Sr+AlIRpefjrKAmboJNqYXRwmPUmFifSyHy04YvfeycNP2hUTywqdzxRMgUXKO5VLfT2Vpzk7e78
    /fDYJQNyUI41K0ktLkLf7E8rB8x4l+gRFbaZ47md1SUEsXFB5YPEuzobruY+nmUjJY1Z1i+hbNGk
    BB8gCwYVw9IBn7weAAAAAWJLR0T0MtUrTQAAAAd0SU1FB+YGAQsFDq2v9lkAAAJQSURBVCjPY2Bg
    ZOfg5OJmZMABGHl4P/Px45EW+CxIjLSQsAhIQFQMSIhLiKNLS0pJgwRkZIGEnLwEurSCohJIQFkF
    SKgqqwGNUkeW1tDU0tbR1dM3MDQyNjE1M5exsESWtrK2MbWwtbN3cHRydnG1dHP38GRgZPQCSnsz
    MjIy+Pj6+QeYBwb5B4eEhrmamgTrhzNEREZFf46JjYuIV08IS/RPSk5J9U9L98xwzczKzsllyMsv
    KPxcVJxfUqquWVZeYVFZVZ2ZWl1Taxlsq19Xz9DwGQIam5pbWtsMagKT9Ns7OrvCLLvbQdI9UGnB
    3r7cfv0JE5OzJk1O058ytdp9WnbddIT0jJnaSrNmz5mbOC9n/qwFEgu16xct1kZI8zNhC3KENDN+
    aZZ5ZkuAAkvVpGX6MKWXsUotXwgUWLEyZJUFXHo1VDpmmbp+dg5QYI1W/Vp3EZj0uvUbNn7etHnD
    lq3bts/q2iE+b+cu1/mzFXav6dsTuEuZYe++/Qc+Hzy09/CRJdtnuR89dvyEjuXJU6fPnLU4d/78
    BWCUsAOj5CIjI9ulnZftr+jtPHPVcuZMqZ21Z3JVXWWQIlTE/tr1G26hfrOqRZWO5czaeXOuVCiS
    9JJJwZbZrhZBt67fruys0+lceyf0LrL09q5gk3u+nbtqde8fe2AS+nDqo8fI0p1PahxTNJ8+m33i
    +Qvdl8EOr85UIUm/XiSZdj+ozunNw9Ppe95mX72fZJmCnNZE1EXEti2RVmdQExFheKfevOR9MyQT
    xeDJJR8+8n2KwiENAAhlE5q5TqLDAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIyLTA2LTAxVDExOjA1
    OjE0KzAwOjAwjy3iygAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMi0wNi0wMVQxMTowNToxNCswMDow
    MP5wWnYAAAAASUVORK5CYII=" />
    </svg>`
  )
}

function HobartCityMission() {
  return (
    `<svg version="1.1" id="Layer_1" x="0px" y="0px" width="30px" height="30px" viewBox="0 0 30 30" enableBackground="new 0 0 30 30">  
    <image id="image0" width="30" height="30" x="0" y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAIAAAC0Ujn1AAAABGdBTUEAALGPC/xhBQAAACBjSFJN
    AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAA
    CXBIWXMAAA7DAAAOwwHHb6hkAAAAB3RJTUUH5gYBCjgTpYO4CQAAAAFvck5UAc+id5oAAAXXSURB
    VEjHrVZdjBtXFT7nztz5s8fetddZ25td7266/Y2WTbukoUiAqjQ/SomEKoSKKKBWqiq1L7zxhOCl
    8EL6QkEIJFSKKtTw0zb9IVRQRWqVohI1pBWUbZOGXf+s12t7bY89Hs+de3gY29lf6EOP5mE059zv
    fufc75y5uPTRNd/3EREIdjcEAJCBnJzMxmx7s6frebVaPZtJExEibl936oFvFEtllatAe2IzhTUa
    zV8+/eObZqfffueSHY1GItbdi3duNJp/ePGVh7/54BB68x6MgCQQEUmAPR4KLVzT7XpBEARBAAC6
    pmm6BgCIKKUMXy5dvnL5yvsAoAIgAiLi3qwBsR+gKIphGJZlSaKXXj0/kUmX1yq/fu7s+L5UtVa/
    c+HguddeZ4zN5qYW5g+qAAB7lnlgRAQgiTSN//ODpcToiGkY6fHUB0tXk4kEAZmmYVnm9eV8amxs
    IjM+NpYEAAaf2BCx1/MPzOQW5g9Wa3XTNDquG4/ZyUTCdbuFYokxdviuhfF9qV6vdwNaEtiW+CTo
    nHPO1UjEEiIwTUMIEQTBu1feSyRGfd/3fREEge+LPjQBIMCJI6tRUwTBDhENisIYttudRqNZKJYm
    Mun6RiObSa9V1hlj01OTyyuFYmm1WqvtS43lC0UAUBmSL9hs1n38gaWVNeuNSymuCiLcydfzelOT
    E185fVJRFEPXPa+nafzuuxYUVVUVxe12GWNAYFnml08eCxUCQKBp0tCkoQUUprDLufblFbNtRBRC
    RCIWAHDOQ7cdjQ5DQ5cqCTmX1wqRZ8/PXF4a0bjcSTksSKjrsy+ci5hWpVr93OHFW+YObBVRv13C
    JmAAwBACiW9cSjmuqjDaVd8kyTSN68srpmHcduvc4qHPrJbXLrx1EQA6riulDKU/rN7gGAkQyPWU
    fjX6FLYrW1XVYmlV07TZ6VypXF766FqxtHr2j+fee/9frZazcxUb7AO1piap33KmaWyLC7Ptdr3+
    MRDomrZ4aME0Ta7xeDw2JLsLdLPNiYCINM5jth1IuTkUQzhdD7cMpCQgABiJxwrF0q6J3ujG0EUE
    TGGaxreFIqIvRCY97gtRWV+vrFcNXXfa7Wg04jhtAAj1szv05ppGLIu2hiJD13Vnp6c8z3vr4jsk
    KZlMVNarkxPZm/s62a4rdSs1ACCGyFWVqK9vRITBLAaA06eO40DOQoiVfHEmNwkAirKd5e7jKawj
    ICqK4gtBUjLG+pJC5JwLIeobjZbTfvPi38Jfz3q1JoJgmDrtDR2OcNhoNJKjIypXW07bsqzlfOH1
    v15oOe1nnnv+tT//xXHaDz34VVVVAeCFl//UbLaGB4M7oDH0qIoiSTqdzmMPP/Ty7579zS9+cset
    NzuOE4/ZS1c/rlTWNxrNU8ePjsRj+WLJaXeW84UTR78UiVir5TUhRKm85rquup0vAVdVAPQ878wP
    v3//ifsAYGZ66vlnft71PCmpVt+o1euj8fjf3/2H0+4Yht7tetGIVa3V0+OplXwxNZa88Obb9xz5
    7BbWiMgU5vt+zI4+9aMf3H/iPiECIgqktO1oaiyp65plGoVSORazdUP/8OrHycToRqMxkc3YdvTf
    H17LTU1evb58ePFQr9e7Aa0oiuf1Wq3WRDbzve9+59Txo0EgVVVBRIUxKQkANM5H4vF8oWjb0Xa7
    84XPH3nplfMHZnK//f2LQCSEQETh+5yriKgOcFmj2ZrJTT3x6LdPHrs3HKGb9RQ2phDBRCa9VlkX
    QnQ9L5Menz94OwLO33FbvlBKJEYdx4nHY52OS0TK3O3zbrfbbDnH7v3ir3721C1zNwEgETG2XTyI
    WCitAsB0brK8VtF1reU4nHNEVBSm6dqB6Vyr3c7t31+r13Vdh9Nf+9bcwj1ff+Tx8LLh+z59Sqa2
    Wu392exPzzwJAEEQhCL93zYcL4MbEyD2PyLi0MuSydGnzzwZj8WCIFAU5f/iwuDGM5yLiDc+bvH+
    ZyVPRKHIPl37LwkwfvevMJbNAAAAGmVYSWZNTQAqAAAACAABARIAAwAAAAEAAQAAAAAAABPAdecA
    AAAldEVYdGRhdGU6Y3JlYXRlADIwMjItMDYtMDFUMTA6NTY6MTkrMDA6MDCpDE3OAAAAJXRFWHRk
    YXRlOm1vZGlmeQAyMDIyLTA2LTAxVDEwOjU2OjE5KzAwOjAw2FH1cgAAAABJRU5ErkJggg==" />
    </svg>`
  )
}

function Lifeline() {
  return (
    `<svg version="1.1" id="Layer_1" x="0px" y="0px" width="30px" height="30px" viewBox="0 0 30 30" enableBackground="new 0 0 30 30">  
    <image id="image0" width="30" height="30" x="0" y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
    AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAA
    CXBIWXMAAA7EAAAOxAGVKw4bAAAAB3RJTUUH5gYBCwEkEnj6iwAAB8lJREFUSMe9l1lsXNUZx3/3
    zr6PZ/FMvE7seIkTZ08IEGjYWh5oIRWiopUoUtWiqi+VKip1UWlRX/rYJRKCVoiHoopABAVEgbS0
    STBOSOJgx5nYseOMPeNkPEt8Z587c+/pw5ihiW2lTxzpPtz7fff7n/93zvc/35GEEAKgXK2xkFTo
    7wrwZQwZoFBWeftElMNHPmZ85lrTmC9WSWYLzfe0UmQ5X6au6bw/Os1sPNO0LaZzTM+nSCwprHC5
    PfD0fIo/HfmYE2NzvHB0lLqmA3D2Upy/H59sOn946jKfTMQ4dynBk7/4K2/8a6JpOxuN89qxcV5+
    5wxqTbstsBFAyVcollUsZiOpG0XUmobRIKMUKmSUUtM5o5QQQjA2vciNdI5Cudq0las1MkqJaq1O
    Xdex3I5xRinx1vFJ9m3p4sdP3kOlWuOdkxfX/aGq1jk/vUhL0M1Ad+tqB7Hy3A44kVKYW8zyw2/u
    5/EHhnlwXx8nzl9d09kgSxTLKvlilVee+xZPPLjtJrskgSxLSNL/ASyEwGEz098dxCDLDEZaEfra
    U+7t8ONx2fA4rdy9PYLJaPiCqACnzcyO/rabvq+7xk6bhf1buzkTjbOULeBxWtnWv2GFws3OD+zt
    48LsNY6fu4LDZr45w0Lgddl5+pE9GA3y7Rm/czLKPTsivHsyym9e+hCjQUaWJKbn08jSzQHyxQrp
    5RJOuwWLyXhLKAlN10kvFwGIJxVefvtTlEJlbeCTn82RUUqUKipppUhd0xm/fI14chn5lokfOz3D
    ibE5Ls+nGJ2IEZ1b4meH3yOZLWAwSNzIlfjzm6epqnUOvz7C9357pFlymqZz+MgIr/5jrJFqo8FA
    fEmhWKmxe7CDyStJ6pqOJEurdmcmV0QpVhgZj/HH10ZwOy288LeT+D12Im0+qqqGUshzLZPnvZFL
    iEqNt45P8t1H9jAxe51fvfgBAY+d/cNdyI/eO0R6uYjXaeX5Z77K6IUYOwfa6G33o6+jQJIkcSWR
    4djpGdAFx07PUCyrsLKrZ+MZFtM5IpFWonNLLGUL/PvsLNlMgSuJLCPjMeTNG0O0uO3U6hr5UpWq
    WmdHfxtWi3FNUCEEAa8Dt9NKvlRl364ebuRLFEpVJBolNRVLsWugnTd+9xRel42ZeJoz0Tj9Pa10
    hVuYTWQx5Dy7f72p089CUmFsKkFbwE02VyaZLWCzmlnKFjiwYyPQkNBkJs9QT4i9Q53U6xq//8mj
    jF6YpzvsJVesommCUrXGQHeQJx7czploHJPRwKnJeZ77/kO0B92UKiry/PUb9HUG2Lelk3ypyqH7
    tlJVa2SU4tqMAYvZSFYpce+uHuqahlrTsJgbGdJ1wXK+zGCkoWoD3UGm51M4rGZ2D3aQSCm47Bbk
    zpCXK4ksZ6JxHFYzb5+IYjGb8Lnt69agpgmWC2UGu4OMz1zH7bTgdlgRAjRdp6rWCftdALQHPcSX
    FPxeB0aDxFQsxUAkiPzTpw5Sq2tIksS3H97JVCzFge0RHrqjH13XV28sGsHVmobf6+DcpQRbe8JY
    VxhrukAXAqetcUx4nFYySgmf20YyW6BYqTHcG0aenk+RUUrYLEbCfhdmk4HxmWuotfr6jHUdsVJr
    U7Eldg60Ia0ItC4ECDCZGrJpMRkpllXcDiuXrqbwuW10tHqR3/zPJD6PnaxS4rkXP2DP5g7ORuPM
    xjPI66i9ECBLEqkbBZbzFYY2hlaV3ud/ShLUNR2jQeb8dILB7lbsVhNyrabRFfLisJkZnYg1RV7X
    xSqtvrWWL84t4bJbaG/1NPzX9YVSRWXySpKdA20AyHdtjxDw2rGaTXhcNgyyxPCm8EqwtQN9nolT
    E/P0dQWa67veMJuMxK4vk8wW2NobbsT4xj1DfPxZjK/dOcDPn76fWl1HQmKgO4hAoK2BbjBI1DWd
    kYmr7NncwWwiw8xCGllenSIBOGxmzk0lMBpkwn4X749OIxfKKiMTV7lzuIvvPLwTXQjOX15c2RgG
    CiV1VSCDLGMxGckVq2zv28A/T89wbiqxJjAC3HYLqWyB3g4/ui546c1TjawVyyozCxk0XTAdSzU7
    CJfdyrV0rtn8NVMtS4R8TjZu8NEZ8nJ+enHdNRYIPE4rPo+dvUOdLKZzLCSXkdsCbrrDLbxw9BOO
    fjTBB6emuXtbBICA187lhQxjUwmKZRVdFw09BiJtLZjNBs5E45y+uEB70L1mryVEo5bbgm7yxQpH
    P5rAbjVjDHgdPHZwC8/+4V3GphZpb/Xw9XuHAAi2OKmqdR579hX6OgPsHGhHliV00ZDChevLPPnL
    Vwn5nETafFzP5NdkbbeaGegK8vxfjgHwzKH9jb7a67Rht5opV2sEvY5md+F1WtnSG2IxkeXTiwsk
    lhQkSUIXgp52PxvbfSyncwxv2kDI5/yilm9ZaqNB5q5t3ZQqNYSAr+zqaQD3dwX40eN3cvf2CD84
    dEezZ5IkiUcObMbssGA0GAj5XYT9LgIeOy0uG4cObsXqsvH4/cO0+pyEWpyEfU7CPlfz0HBYzXhd
    Nu7bs4nBSCt7hzo5sGMj0v/enRbTOXrb/TfNtlRpXG+qtTr7t3ZhtZhAQFfYS0YpcerCPAd391JR
    axTLKrW6jhCCjlYPFrORXLGCUqjQGfLyyUQMh9XMtr4NXwB/2eO/PDCDrK/nEDcAAAAldEVYdGRh
    dGU6Y3JlYXRlADIwMjItMDYtMDFUMTE6MDE6MzUrMDA6MDBilE55AAAAJXRFWHRkYXRlOm1vZGlm
    eQAyMDIyLTA2LTAxVDExOjAxOjM2KzAwOjAwIiHsWAAAAABJRU5ErkJggg==" />
    </svg>`
  )
}

function RSPCA() {
  return (
    `<svg version="1.1" id="Layer_1" x="0px" y="0px" width="30px" height="30px" viewBox="0 0 30 30" enableBackground="new 0 0 30 30">  
    <image id="image0" width="30" height="30" x="0" y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAIAAAC0Ujn1AAAABGdBTUEAALGPC/xhBQAAACBjSFJN
    AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAA
    CXBIWXMAAA7DAAAOwwHHb6hkAAAAB3RJTUUH5gYBCwc5JyQx1AAAAAFvck5UAc+id5oAAAbPSURB
    VEjHrVZJjF1HFT33Vr33/vvv/56/+7e72912u21HtmNiBwhIJlFEBgIxDiigeMW0YYWQ4gUCIXZs
    ABEhRUgQiCKUFYNAUSALA4oYbBITPMTxEE9td7d/j39+U1VdFr+NLIudU4sq1T2lU/dW6d5zSURw
    z8O4fLF9c7Jv651GvkdSgQCoJ+tvXPnDXdA9U4sAWE2WV7KVD5iaQABW4iUi/l8QHwC1E0dE51fO
    vrt8OmDvr9feIBBus7N1zjgxzvUuFMCJOCcC3AVhA3K9R7BOjHMA5jtzt5KF3Jr36mc3IgEAaMV8
    ey+9hYhAIpC7IIEwAcQAnEAxKWgA7bxtrEkl9V1wZ0z6J+/MrSV2ohx8eU+VQQC+fuxSoPjHj8y8
    dGZ+rpVtiryv7an6ShPoRydv/PlG61dPzQ743i/OLlxrxkcfnHGSelBEpEj1POh9gP7O8dVmO4d1
    itSXdo8em1v96Tt1CA5vG3zxbP3f19qAM5a+sX/8aqP77eO1pOHe3Ns8NDP83RNL8wvtw9u3AOn9
    lQ8vdm8EugDAOcukQOD+gq+KAYLglfN1AL+73AZrKHVmLRsuBij6KIavXmwCeO1qI0kYkQYrQIg1
    wqCZERNND84WvWgkrABgUkREIO1ErBUoXGxkuTVnVlMwwclY5BnrYAUe9aDjt7ogwAlDEuOamYWT
    pTjbWZ4moYIK7xvaA+DS2vlqNObgdO/viHgtdRfryUI3BwmAkVBbAQhaUSNz59e6F+o5FMEKgZqZ
    7RoB841W6wuzD//y1Et7N+1baC0EKnyr9k8WqRRHNYFgrRDlxNebaSNzgYZSbiBQDoBzJgeYz9ez
    pdiACTmYsJ5aYwRAPeN2unCufrbaVz23eir0w+V4sZnV+8IB7hq3Y7jw2JaiyVw9dctdc2TX0MuP
    T2pCJ3dTI4UvzpaQueutrJk7hgC5A+qpgXUg1BM5vfyfTJKL6++FutjN2+2s2cm7Ra/EmXVjkff4
    VB+MS6yAUOtmz+6o7BoqtDI7GurPzw5BXK2bNxK7v1J49elpBVmOLSDQqMX5crykiJvpeiWsAkht
    RoSp8lYGIAICgeDEDQTq9QuNH769oFkbJ7KRSNTIBLn76Fj03M5NByqFq60MmiuRWuqaVBwRRoPR
    +4c/RGDNejio9Pn9rBnrqfnHYguKS57SBGj1vX8tryVp0eNO7v620AZTZhxEpsoa4EoULrayQPNI
    Qa+mrqxHMpM8MfPZnSN7hgojHxv9xGRp+pWzP+OSz2dWkt++3/ECtTnSBU1Q1E7k7wvtSqjP1eIX
    T6/5gQIJBIO+7iVxLc5DzdWitxxn0333eaRKXj+Aohd9cubTzDzXucIQAjGYSgp7R4rVogchiJxf
    SzQBIEuqoGjA1xABbZSe5diUNI9G/lK3W4lmPrXl6cgvrSdrL5z8/rGrr99KFkIv4o2alruHqoXB
    QnCwGiDJwLTYtRuQuD6Px0sKTpqZ6dlWYlv2eTzSkrl25h6beaagC2vxSizxzfZcN+sMBxWmjdIm
    h2eGABx9sPrAeIBcusZJz03Lk5GeHQjhMNfKAKzGcT2zzFAEWFlNN+4jJgYKulDgcF9lvxaAIQ5y
    qR4DLrPy8pMz+35+zghABBEk8ee2D0+UAyh6qxafWFyfb2W+4lO17s1mCo+W46xHHemIWWvWT04f
    mh7YxtZhIGB4fG4tTYz7wdsLqTEg8hiplaGy/tOR7c8f2KwIKKiTS8mjv36/lkhsBOCOYzisxq6n
    IZFX7vcHlru1P179vXFG5yIDgVJElxtZN7e/udJ57VobTH2eck76ffXE1AiAoUCHHscOMDoxtpVb
    MDTBCFbiHIidhEUvGvKHmfX17oVbnUW2DiWPN0dqvpNfbiZtQ9faBGA01ERkN0QNm4p6U8hwAk3N
    XBIjEEotQKjFWbNTcyJMPF3eOhFNinOatXYioeJNoTpVy95dSayIUmIJW/t9J0IAQE4Qaj3bH1yv
    d9ijRuq6Rsq+6/dwM8VqIqWg7AAAj049lbm0lTbHSuPMRL7iLWUfTk7U2sZBQOzznuGCEXiqp7YA
    8PHNJVjxFXWMpIl9/sDoX57dAUgjE9bDRCTAcLEyVpp4bvdXAlXgRpw3U1MNFeppkruJCK6ZPTDs
    zQ4W51tZPTHoqS1waFsZ7JLUzTUStLN+nydLBWT24lq8odeAiEhvAtQ3v3X04Hjx4YlSU5kju4Y+
    s63vzVuNFx7ZMjNQbKfdj4wGj0wMEsEJxkvBapKE2n1192AxomdmhiZLhVWbPFQND44P9PK0J11E
    BIBut5Nyu38QA9HgO7L6ziEWTkFtbER6LP/38H8BsiOyI2whOy0AAAAaZVhJZk1NACoAAAAIAAEB
    EgADAAAAAQABAAAAAAAAE8B15wAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMi0wNi0wMVQxMTowNzo1
    NiswMDowMJgNLSQAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjItMDYtMDFUMTE6MDc6NTYrMDA6MDDp
    UJWYAAAAAElFTkSuQmCC" />
    </svg>`
  )
}

function SacredHeart() {
  return (
    `<svg version="1.1" id="Layer_1" x="0px" y="0px" width="30px" height="30px" viewBox="0 0 30 30" enableBackground="new 0 0 30 30">  
    <image id="image0" width="30" height="30" x="0" y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
    AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAA
    CXBIWXMAAA7EAAAOxAGVKw4bAAAAB3RJTUUH5gYBCjIfVtocqAAABmRJREFUSMe1l29IVHsaxz/n
    jzM7M2pjZLWaZX+UZjUjWWQ3abNNetNlUQqsQEMIlvZlgS8WFjbIoCIIL2VvzFY3utWtrvYioqzu
    JQTLJtvNkEZm1GxcB508M850Zuac89sX3aypu7faW9935/Cc53Oe73l+z/n9JCGE4DNLCIF2pYvo
    pcs4q/5Azu4GZJvtvaDPrlh/vxj5bYUI5C8VI6tLxExX93sx8peoNnrxW8TEf5BUFRGNEvv2MlYi
    kRb32cHGzAuSAwNIkgSAJMuYoyMYL8JfFiy0CIRnQH4rtWHAO630+cEJHZFKpt+024n98APR77/H
    Sia/DBik9EtZxhx7hvbXvzH9578w0/nPTwenUikMw/j5IFUFRU1/FcNAMi2kWJzYPzpJ+P2o7z7n
    9Xrp7u6mvr6egYEBNm/eTDAYJJVK0dnZiSzL7NmzB7/fj8vlQlVVKioqUBTlVYKMjFdwIUB6p3pF
    xpoIkvjXv9PBpmnS2dnJ8+fP8Xq9tLa2YlkWHR0dLFiwgJycHABOnz6NYRgkEglu3brFuXPnKCsr
    +2mr35EwLUwtkm61YRhMTk5SXl6O0+nE4/Fw+/ZtGhsbqaurIxAIsGzZMmw2GyUlJaxYsYLKykqG
    h4ffTv3zHaAoqO7sdLDdbmfv3r3cv3+fu3fvMjw8zNTUFEVFRRiGgc/nY8mSJSiKgsPhwDAMcnJy
    cLvdbxKbJpjm+zYDmBZy/q+xla1JtzocDtPX18fChQvRNI0tW7bQ29tLW1sbyWSS0tJSrl+/TjQa
    xTRNxsfHsdlslJeXEwwGufzdd/yp5Deg6wjDQJKkN/ULwOnAtbsB2/IV6WDLsujr68Pj8bBp0yZy
    c3MpLy8nFovhdrvp7+8nmUwiyzLz5s1jw4YNlJaW4na7efDgAUeOHGHD11+z+Pe/Qzh+hexyYcXj
    iGgUyeHE8ccqsr7a+qoL3hvwsZgwDEMIIYSmaULXdSGEEIFAQDx58kSEw2Fx/PhxsXv3btHQ0CD6
    +vqEEEL09PSImpoa0X//nghPTgrLMoUphDAtU5iplDBNI42jbN269e9nz55l1apVtLe3Y1kWwWCQ
    5uZmjh07xtKlS9F1nW3btuH1eqmsrGTfvn2EQiG8Xi+jo6OMjY1x4sQJRkdH+eab8yRNk6qqKiRA
    kiQkWUaS0keGeuPGDTo6OqipqeHMmTM0NjYyODjIpUuXKCsrw+FwMDg4yOjoKIqioGkaDoeDlpYW
    ent7uXLlCufPn8fr9c4lnZ6e5kOSLctCkiRevHjx4+BRCYVCHDp0iJs3b1JVVYXP56Ouro758+ej
    aRqmaeJwOEgkEng8njfD4xMkK4qC3++nvr6ep0+fkkql0DQNm83G48eP0XWdYDDI2rVrEUIwMzND
    MpnkwoUL3Llzh127dlFSUoLH4yEvL4+mpiZqa2s/CFZTqRTJZBKfz4csy+i6zuTkJAcOHKCgoID2
    9nampqbIzs7GNE2mp6eJxWK0tbVx8uRJqqurWb9+PadOnaKnp4fm5mZUVf0wOBaLYbfbWbNmDT6f
    j0gkgsvl4uDBgxQXF+N0OgkEAhw+fJhQKEQoFEIIgWEYZGZmIssymZmZzMzMzC3Jj7I6FAqxfPly
    WlpaWLx4MZqmkZGRwbp161i5ciWRSARFUdi/fz+rV68mGAySlZVFRUUFra2tzM7Ozg2fRCLx8eBn
    z54hyzI2m41EIoGu6/j9fnbu3EldXR337t3D5XKxfft2CgsLmZiYQFVVamtrefjwId3d3QBomkYs
    Fvvwb/M1uLi4mOrqalwuFxUVFeTl5WGaJkNDQ0xNTTExMUF2djaqqpKdnY2u6xiGMedIW1sb0WiU
    eDxOMpnENM2PAksvX74UqqoiyzKJRILZ2VkePXpEfn4+WVlZGIaBEILCwkJ8Ph8DAwMcPXqUixcv
    0tXVRTwep6mpiWvXrqHrOjU1NR/VXNKnbuij0SiBQID8/HwikQh2ux1Jkua+r9vtJhwOs2jRIiRJ
    wuVyze04fxH4tbq6urh69Sq5ubnIsozT6SQej+N0OjEMA1VVKSgoYMeOHdjePUXwCzZ7qVSKjIwM
    NE3Dbrdjt9sxTZPBwUFycnIYGxtjaGgIWf5pxP9d8cjICLOzswQCAcbHx19V8eOajkajFBUVoSgK
    Gzdu/LxWv63XDSjLMoqiYFnW/6z0tf4Lb3CnYnuPhpMAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjIt
    MDYtMDFUMTA6NTA6MjgrMDA6MDCM6jHeAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIyLTA2LTAxVDEw
    OjUwOjI4KzAwOjAw/beJYgAAAABJRU5ErkJggg==" />
    </svg>`
  )
}

function SaveTheChildren() {
  return (
    `<svg version="1.1" id="Layer_1" x="0px" y="0px" width="30px" height="30px" viewBox="0 0 30 30" enableBackground="new 0 0 30 30"> 
    <image id="image0" width="30" height="30" x="0" y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
    AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAA
    CXBIWXMAAA7EAAAOxAGVKw4bAAAAB3RJTUUH5gYBCjohX2KLCwAAB0NJREFUSMellW1sU+cZhq/z
    YccfsY1TIMH5YEDY6MwoSUMMVQiwMZUvTW3p6H6wDn5swP6uoBFW1q4MJsEmJiGtGxVd2xVpgkBh
    TGWQQoGyCgiQTzoyQ0kItRPsOE58bOf4nPPuR1BYp0ECvaX3xznPOc/9vO99P+8jCSEEY4QwTQY/
    /Dv977yN7PEw7odrcM6uQPH7x5piBNJYiIVpkmluQjvdQOrIB1h9fQDIPh+2qVNxzqvBt/JF1MIi
    hK4j2e2jEqujfWAODpD6qAFkGVtJGdbgIMgyAFY6jTM0j9yNMD2bN5I3cxbG3V4mbKob9RTkhwW1
    8+eIbPgJVn8/vu89h3N2BbI7HywLhECyqbhqapm4bQfkOej/4x9QxvmRPZ5Rd/xQYiMeJ3fnDsn3
    /kzywF9RA8V4X/oBktsNqorrO99FLSwk8c4+9NYW1OLi4YIU5fE1trQUif1/wVlZRd/u3zHU2oyz
    Zj7uRd/GSCax0mkUdz6Z8+fIXvgUR/Vcxv14PYk39zDxV9uxl01+PI31W7eQVBXX01Wo23bQ+8st
    pD9qIPPJOWSfD5Cwkv0IXUfx+/Gv+ymu6hCpD4+h37w5KvEDjzoX+QLJ6QTAPnkyE7a+Tl4wCIaB
    1deH1Rcf1lqSyKuoxDm7AgDb5K9hpQYeX2Oh61jJ5Mhz3tRpuGoXIYQASRpeAEJgnzptpIVkpxM5
    /yuYSy14Av3fHQhdH3ln9ifg/1lCuZ/GSqexlZY9PrG9vBwzFiPT3DScUB8i1337/k7/C0Y0OixP
    NAKyjP2rEKvjJ+CqqSWx902Me3oKTeN/aSVZRr/+L4xEH9mWZlxzQmO6uR7ax75VLyG73Nx9fSv6
    zZsPyCBjdHWRvXIZ19xncHwzOCrpqMSK18eELa8ie330bq0j13lrOCDE8LIsME2stEam8RKK1zty
    nY6GsQ0JXSf96T9JnTzO0GfXsBIJhGkiOZzYSkpwzp2HZ8lybKWlYyIdM/H9CgRGfz/WQBIMA8np
    QvH7ke/1+6NAEkIIXdcZGBjE6/VgH4MxHlyXQNM0HA4HqjrK4Ovs7BTr128Qy5YtFxs3bRKpVEo8
    KmKxmGhqahKapon16zeIixcvjvqPEgwGXztw4AB1dZupqppDQYGf06dPc+XKVXw+L1euXsWTn09v
    by8dHR0YpsmJEycYGBiguLgY0zTZs2cP+/a9zfTp0zlw8CCmYaBpGsXFxcTjcY4f/wexWIxAIIBy
    b3KpoVCIssmT2fbr7ax84QW8Xg+XGhu5dPESly83cvdujOpQNbe7ugCJcDiMy+Wi63YX2954g+rq
    aj7//BYDgwNkMhmEEHTdvs2Jkw1EIlE+OX8efWiISDTKpo2vsGTJEgDk0tJSfrtrJ2vXruFPe/fS
    3NxMJp1BlmUikSjLly/n8OHDNDe3UFVVRWtbG+PHj2f2U7ORZQVVVZkx4xs8OeNJQqEQiqyw6vur
    +Pr06bS1t9HU1ITf76eyouJL/pEbGhr42SsbOfa3Yzwzbx537nzBhYsX8Ho9uFwuFiyoJZfLUVZW
    xuLFi1m4oJaeniiKolBYOBGAoqIiWltbOXXqFO58N7IskZeXR0lJCUuefZZ4PIYQFoWFRfddnU6n
    RUdHB6lUipkzZ6IoCu3t7Xg8HhwOB1OmTCEcDuN2uwkEAiSTSVpaWvB4PASDQWw2G5qm0dLSQlFR
    EZlMhkAgQCwWw+124/V6aW5uxm7PY+bMIA6H48vtFIlEUFWVwsJCUqkULpdr5GgGBwdRVRVd15Ek
    Ca/XC0AqlULXdQoKCh659dT29na27/gN0UgEWZH50csvc+bMWVavXs2CBbUA7N79e8rKSolGezAM
    gy1b6gD44MgRmq42sWvXzkcn/vnmOvLsdt56ay+ZbBZFlnn//f3U19dz7bNrvLhyJePHP4Hf76et
    rR3TNIhEItTXH+LcubM4nE4aGxsJh8OYpkkoFOLUqdPk5dlZsWIFjY2N9PT0kkgkeP755ygrGx6Z
    aktLC7/YUseUKVMAyGazWJaFJEkcqj+EBFy5cpVUKoWiyORygp07d9HfnyA/34MQgjNnz/Luu++x
    ft06tu/YQX+in0w2Qzwep/HyZZwOJ/G+PpLJJFu3vjrs6vLyaXz88Rmi0SgdHR10d3ejqApLly4h
    GAzS29uLJSwsIRAChvQhrl+/Tk1NDbNmfWs4Zlk8XVnJ2rVr6L7djdfrZdHChZSXlyMswfza+YSq
    5xCLx9E0jWw2i7J///7XGhoaOFhfz8mTJyktLaG7+w6VlRX09PQwbtw4DMOkqKgIELjdbmbNeoqj
    R4/S09NLIBBgUtEkstksy5YtxTRNWlvbsCyTiooKOrs6KZ82jZxhICwLu92Olk4PuzqRSNDZ2YnP
    56OkpIRoNIrf70fTNBRFIZfLYbfbMU0TAJ/Px40bN/B6vaiqis1mQ9d1Jk2ahGVZhMNhcrkcU6dO
    o68vjtvtxjAMTNPE6XSiqir/ARSZn2mf7hydAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIyLTA2LTAx
    VDEwOjU4OjMyKzAwOjAw9+cu+gAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMi0wNi0wMVQxMDo1ODoz
    MiswMDowMIa6lkYAAAAASUVORK5CYII=" />
    </svg>`
  )
}

function StVincentDePaul() {
  return (
    `<svg version="1.1" id="Layer_1" x="0px" y="0px" width="30px" height="30px" viewBox="0 0 30 30" enableBackground="new 0 0 30 30">  
    <image id="image0" width="30" height="30" x="0" y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
    AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAA
    CXBIWXMAAA7EAAAOxAGVKw4bAAAAB3RJTUUH5gYBCi4TuRsN3gAAAHN0RVh0UmF3IHByb2ZpbGUg
    dHlwZSBpcHRjAAppcHRjCiAgICAgIDM4CjM4NDI0OTRkMDQwNDAwMDAwMDAwMDAxOTFjMDI2NzAw
    MTQ2OTZmNmY1MzRkNDc1ODdhNzU3YTczNTY0NDU4NmEzODc1Nzc2NQo0NDAwCvthisYAAAnCSURB
    VEjHjZd7bFtnGYefc3yOz7FjO3F8SR07ca5rkjZOm2ZpCzT0Asuo1m1slFYI2nIpDKKJMnEZbKBJ
    SEzT0FCBTqOVKrGhlW5oQCuGBuvW0DbdsrVZsl7StFka5+bEuTn28d3n8Ee3wQRb9/776Xuf9/s+
    vb/f+wmGYRjcJAq6QXg6xsDwDENjc0zMxoknMwiCQKndgs9to6HSzcpqDwGPA0G4WUYQPgqcL+i8
    dXWaV/quc3EkilWVcdpV5pdSrK4vY2hsnrpAKeeHIlwJz7GqvoxgWQmd7TWsqHIjfEQF0octjEeX
    eOalt+nuD9O23EdDpQuTKOC0q2ipHPUBF8l0jkJBZ2w6Rq3fyYZQBf94Y4Qzb4/x2bZqtm9qxF1s
    /fgn7r08ycHj5ylzFmGzmLGqMtFFDUWWqCwr5tL1WfR3t0kmkZN9o2zf1IDLYWFqLkHA46B3cJJk
    OscDX1xHU5X75uB/DYQ5dLyPUrvKrQ3l+Fw2zl2ZwkBgei6Gyy7hKbZiUSQwYDGRZmh8HrfLSaEA
    PpcNUYTnX71MXjdoDLr51p2trKor+3Bw//AMj/7hDH63japlxWRyBabmE5hlGWt+lu5jzzA/PU6R
    VUVRFMxmMyaTiWQ6Q1X9Slo330uyIHNtfI7w9BK7bg+RTGc5NTDGz3ZvoKbc+b/guaUUP3rqFZw2
    hbYGH8OTC8zGUiwrsSAtXOL3h36Ly+VixYqVeDwePB4PZsVMua8ch8POLx//JYIAe+//Ea+N5tFS
    GSrLHBgGnL04Tp2/lB9/+ZMUqfIHwU/99TxPHTuHzWKmbbkPn9uOFptnZuDv/POlF7nttk42b96M
    aBKRJRnRJGIYBsHKIKFQiGg0ysMPP8wbva/zwA9/wmjOx5XxBT7XXoNiNvG7Y318447VbN/YCIDp
    kUceeWRkapEX/jWIbBKJaWliWpbk4jTnjx/g2tAgX9yxg7Xta1FVFWeJE9WiopgVZLOMqqh4vV5s
    NhvBYJClRJwDv95PldeK4qyg2GHjemSRItXMlfAc61YEKFJlRIBX+67jKbFy94bldLRUEqr1UpIe
    5s3es9yx7Q4aGxqRZRlFVZAkCY/bQ3VNNQ3LG6iqqkIURQBef/11Mpks3+nq4k9HnqYQeYuRqRgV
    XgeeEitaOsdrlyZudEM8mWVgeIbZWJKJ6BJW1cyKCgcvvXyBtevWMfLOCM0rmylxlmBRLcTjcU6f
    Pk02mwXAZrPR2dmJz+dj+/btpNNpioqK2L3nq/T3n6NzXSfRxSS6bmASBV67OM7ta2uRRiKLFHSD
    ezsaAHhzaBqrvoCu6xx59ggvv/wyPT09CIJAKpVCFEWsViuSJJHL5Ri5PsLRo0fp6upi69at3H//
    /SQ0jb7z5zl+/BglcoZ3IlmWB5wIAgyG55me15BGIzHefmcGLZXFUWRmmcvB0Nsnqaurx+/3s3v3
    bgKBAGd6zqAlNObm5ojFYmiahiAIBCuDiKLI4cOH6e3tZe/evVRUVNDU1IQsm1mcGsbnauLMhXEG
    hmcoUmUmoktIk7NxYlqGc0MRBKBzTYA3TnWzY8cOMpkMhUKBLVu2kEqlWFxcxG63YxgGkiQhyRKT
    E5OMhkdpbm7m4sWL7Nmzh/u+fR933XkXTU1N9L35Go5QgP5rMwgCZHIFIvMJxJiWAePG/QuiiBab
    Y3j4GqWlpSRTSU52nyQajbJp0yYWFhZIp9OIokg2myUWixGLxchmsrg9bvx+P9PT0/z04Z+yb98+
    XG4Xb50/Rzq59L7EFnSdpWQGyST+x0EMw8DpcuH1eDlw4ABlZWW039rOwMAAtXW11NXVEY1Gueee
    e8jn8+TyOZ47+hw2m40ybxlP//5pDh8+jK7rPPjgg/T39+P2eNC05H85lYAoiEilDguiKGAYYOg6
    SBZWt7Zy6NAhdu3axc6dO9m9ZzeTE5NcH73OxPgER/54BK/HS76Qp7q6mvr6evbv309XVxctLS0o
    isLBgwfZsmULK1eGMFuLMYwUCAKS6YbDiQGPA1kyvVuMwMSsRmj1rdjtNtrXtjM0NMQPvv8DLg9e
    JhgMYhgGtTW1rF+/njWta3C7XTz55JNs3LiRjo4OTpw4ga7rxONxstkM6zs2MxvPIQhgGGBRZHwu
    O1JteQlOu4XIXBxREHhncoHPNNbhcbvJZXPU1tUiSzLd3d04S5ysXbuWK0NXeP7554lGoyDAndvu
    ZNu2bUQiU++3XE9PD3abDWdgOVNDY4iCgK4blDmLqPDakfweB8srSpmcXcIkCiwmUgzPC4RaVpHP
    51EUBS2hEawMYrVaCY+FScQTxGIx9u3bR3NzM6qq3lCu3l78fj8A3d3dhFav4XIkTzKdwyQKGBg0
    13hxOayIimxi46ogksn07tPDqQtThD7RyfzcLMWOYmpra9GSGrFYjFw2x1J8ierqatasWfM+9MSJ
    E+gFndbWVo4ePcrZnjM4gy2cuxpFFAQMwKrIfHpVEEHghlZ/KlTBLRWl6LqBIAjMLCQI533s3LWX
    P7/wAuFwmI4NHQQCASwWCwF/gK1btyKKNxzq5MmTDA4O0t7ezmOPPcYD39tHY+unCOeWsaSlEQTQ
    dYNV9ctovWXZB23xL6ev8ItnzpAr6BiGQYXXwfoVFbR4c/zmiV8gSTIPPfQQoVAIWZYxm80ALCws
    8MSvnkAxK7z44otoWoJ7vvR1Uo5Gzg5Os5RIAwJFFplHv7mJDaHKD4LT2Tw/f/o0x88MUVPu5Cu3
    reTCSBRVkWmpsHLs2YOcPtXNunXrkcwyhn5DEDKZDJcuXUKSTFRUVrH57t0MTEHb8mX0XBjj3FCE
    QsHgK53NfPcL7bynG+9PmapZouvzbUTmEhTbFAxg46og0wsavcNzVH1yJ1JJBeGhfuxWM2bZBAjo
    CASqb8Hpr6duVQcZuQinXWN0OsZn22rI5XWKbSpf29rCf4vV/wx7w5MLPH7kLBZFYtPqIJdH53AU
    KfRdjdAY9JBIZXBYFURBQFUk0tk86Uwek2TC7VCpLHOQy+v0XY2QSGYRBIH77lqDz2W7+Xg7EY3z
    1LHzZHN5VLOEp8RKNldg9S3LUGWJV/quk8rkWNfkp8SuMjK5SK6g4y2xkszkOHLiEsGyYsrddr62
    teX/ztYf+pNIZnL87ew1ut8axW4147SrXBtfoK3BRy6vs5hIU1Negs1iZiIax6rKzCxoDAzPYDKJ
    bN/YyGfaqjG/p4ofF/xeROY1Tg2E6bkwxpWxeRqDLuaX0hQKOjEtg6NIodxlYzaWotxtp63BR0eo
    Eqdd/ai0Nwe/F6lMnrGZJa5OzDMRjb/bnzcE3+92UOd3EvA6UGTTx0nHvwGCQzS5EnjeqwAAACV0
    RVh0ZGF0ZTpjcmVhdGUAMjAyMi0wNi0wMVQxMDo0NjoxOSswMDowMGiCkg4AAAAldEVYdGRhdGU6
    bW9kaWZ5ADIwMjItMDYtMDFUMTA6NDY6MTkrMDA6MDAZ3yqyAAAAAElFTkSuQmCC" />
    </svg>`
  )
}

function TheSalvationArmy() {
  return (
    `<svg version="1.1" id="Layer_1" x="0px" y="0px" width="30px" height="30px" viewBox="0 0 30 30" enableBackground="new 0 0 30 30">  
    <image id="image0" width="30" height="30" x="0" y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
    AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAA
    CXBIWXMAAA7EAAAOxAGVKw4bAAAAB3RJTUUH5gYBCiU6GF1MeQAAAjx6VFh0UmF3IHByb2ZpbGUg
    dHlwZSB4bXAAADiNlVVd0tswCHznFD2CDAik4zix9daZPvb4XdDn/DjJ1zaekWWE2GVBCv3++Yt+
    xM+bkVxlePNii4ldrLpyMbZqbt122Zj3cblcBjPs3TQs1aXqJkU3LyrwbdZJm6+OjVV81b2q4Y2A
    ItjELEN2LnL1JitgsdG2ALOFS3zb1XaXWKNAABu1ETxknQs392RyDwPbJXbobQeX2nSrhTjIDU+T
    VN7FeAOfRUQaLC4dtkWqdGnConyFlaXAZjzw7hgXUeItjWuYcgTp08Nf6TFYmKyVVdVOqTHlYqTX
    XPEUWZHO8Pzx7vDiPRl7Ivd4kgljZIzbBAAjF0d9QhFvSAsIsf7MAhRQKhSCradSHQrB41i3hSDY
    cAgbrKawj7UIgV/5Jth+LxHmQphuSKWBTwnykLYctTqH1A3d8yEsPcd9HxYBtzfBxbVG66bG9HfS
    74NHczv0gs+IcOSSGjE21Bk/pJ7YEV5D4IogGrWrATDs+tKUBYzgEE6SsxINqnJrOkcfoXPzJM7a
    pif3O1gqt5LGskTxLdKo2jUrOJ2QWs+juqLTMzAOI44lvhQd5RgXWRQWUlbBEqvhE1N0bYWbY3mB
    tZ6RNdIRr3Eag+EBTDdkfYusZ+RPwHRHPi6MJ/SavWxAxwfmUcHyUPjVvqSh/9Rm+cSQvtXmEfmD
    NgcwfdCG/1WbA5g4roNUh8f3Z/3sNe+lw0qvt/FcevOXgJTm5e88b3P6A6pkbsRwHLobAAAH3UlE
    QVRIx5WX2XNUxxXGf913mdFIMxppBBohDEGyQRLGBgSywCJgsJ04juMslbekKqnKU/6jPDiuilOJ
    t4R4JbEdXBQGm8IGDEYYA0LWMlpHmhlptntvd+ehJSHFdpXpl3vnVnV/p8/5zne+EcYYw30uEwTo
    0TFMGOJs24pIJu/3CFy0Rs/OgdaIVBIRj4PrfguawYQhplAk+mKY4L3ToCL8Hz+F27sL0dKCiMdA
    iG/fGwSYQhGURrZvwkUpdH6B8NzHoDTOju3IbDuiOYXwfbupXseUltG5KcJLV4gufoaemYVYDDUy
    ijfQj7O7F6cji0inEYkGkBKiCFOuoBcW0ePj6MUi3uBBRGsLLp6HSCUxlSr1V/+JaGxEtKSRmRZE
    LI5REVRr6NISZmERs7wM8Tjuo3twun5AeO4T6iffQrx3GpFuRrZlEOlmhOtiKhX0YhGTz6MLRfxj
    RxBPPYGI+bgAIpHA6doBvo8plzHLy+ix8W+ki4YGZLYdmW0n9utfUP3TnyGRsPuqVQs0mdu4Twjc
    3b0YY5APbEU0NdlvAMLzEI0JhO9jgsCmyRgwBpFsss90GtmWwRs6RHDqPWRzM/6Rw6iJSXRuytZ2
    fX0N4AhA4PbvQ05NI9PNtnxggXGctehWN4lkE97jg3gH+gnOnMU72E944SLC8wDQ83mCDz5EpFI2
    yPWZcSTC85CdW/CfPI6pVCxGPGYvBcg1pHodVLQCbvCfPkHs2WdQo1+jxyeQmzfhDQ6AXAku7uM+
    dhDZ2QFar2UI38d/8jjx3/8WubUT79gQ3tAhCEMIo3XtBBAp9NIyJgjtVynRYxNEX95ET+bQhSK6
    UCQ4fQbvyGFMPUBdG0Zdu44aG7elaE7hDR5EdnaCEETXruPufcSyvn+v7Y5i0Qa5CmyCADOfh2jl
    xsYQfnYZ5+E++1tKEAJTLqPvjqJn56j9/TXQBtGaxj9+FKe7CxGPI9s3EXx4Fj03j0gliT67DLW6
    Lc9iARMEiDXgShU9NW2jWa33ahCeC0qhbnyJnshRv3XHptTzcHt3ITuyeEOHqL/xDu5DDyLbN4MA
    0dhoCWag/o9/2UQKganW7imXWV5GT88A61gppa27kJhiifob79jAhLC1jvn4Q4eovXqS6PoNzGIB
    U62ip6eRnVsQiQbUrdvo2TmiGzfBGNxYDKoV+26CAFMsoefz94hjw6P+9ilMpboWiDc4AEbjdO/A
    lCs4fb2I5g8gCG2groMaHUNPTSOaUwSn3gfPQ2Za0bPzmIUFTLkCUYRLrY5eWMCUlr7Rh6ZWA6Vw
    9+zG6euBeh2nu8uWxRh0Po/bswscB+F7mEIJU1oi+OD0mha4+/fi7nuU2osvoQsldLGECQJcU6th
    5vKYen2NcRibdW9wAD0+iWjL4GzfRu3l1/CfOmH7Mh7HLJdx+npQX93CaE3w7/cxYQiehzc4gFla
    QiSTVgWNwVQqmLl5TH0FWM/O2VQeOYxMp9HFItGFi3gD/ajWVvTsLCiFzk1jKmWQEhHzqb34Em5v
    D+H5T9D5BYgUzs5unJ5dmHIZ/7ln0JNTlh9CQBCiclN4tRqSWh09N4+76yH8J36I+noMr38fIplE
    ZjI4vTsRsRgohcy0El2+is5NoSdy6PFJ6iffRM/ncffuQW7J4h09gvtwH+HZ8wjXhSDALCystKm2
    N67VV8hVLmPKZWRbBnzfTptUClOpINs3I9s3I1pb8E8cIzx/wfZjqYSIx3B292IKRfzjR+04bWpC
    3RmBIECNjFrSrp81lSoEAa7VWYO6+zX1N98l8cc/UPvba5hSyRoCpZEdWfT0DOGFi+jFRWRzM7pW
    I/6734AxyLY2TKWM07MLPT5hmYsVHhGPfasxcHFdO4NTKdTEJLW/voL/kx+hbo8Qnj2PnpvHLJdR
    I3chCHEf68c7sJ/qC39BNDUS/Oe/xH7+U/Bj1F95HVMoIbdvA0cSDd/AzOXXBgPY4YPv4Yp4HGf7
    NmT7ZrzHB6m/ehKztITO51Ejo6jbd+4JihQ43V24B/YjXn4dpMR/7hmiS58jMi3o3Ax6ZsZOISGI
    Pr28ceo5DnLbVkRDAlc0NeLue9SSJDdlwd86RXTpCmizIVo8D5lOY5aWcbY/YM/a2kn40cfIhjgI
    iG7eIvrq9kZAAK2RWztxe3sQqSZcmWnFVKq4ex+h+sJL1tpEyqrYeiUzBpFMIjKtmEoFt68XlO1d
    /8QxwjMfEV66ck/j1y+lES1pYr963rrSpiZbY6dzCwwdQjQmqL/5LurWHSsm629rDDK7GdEQRw1/
    idOzk+jKVcKLl1C5adwHuxBSssErr5zh7Owm9suf4e59BNmRtclY89Vao/ML6LEJwnMfE5z7xFqa
    cEWHtSb2/LOQSBC8fQqna4cV/yiy02rNvdjjRDxujcBAP+7gAM72B5CZ1rXpJ/7f0JsgwCwWUGMT
    qGvXib4YRo3cRS8W8J8+jp6cIrp81erqakaMsXyI+TgdWZy+Htw9fThdXcgtWWS6GVYs0xq7v/Of
    hNb3PHEuh7rxFeGVq6gvhjHV6kZDmE7j7u6xA6FnJzKbRTQnEQ0NG8v1vYDXr0hZ9zE9TTR8k+DD
    M0SXP0fEYniHB/GGDuE82I3c1IZoTHwn2P0DryOYKRZREznCC58imhrxDuxHdrQjEonvfcz9A6/i
    12romTmE51qrs2qX7mP9D0MF/kmVK3g4AAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIyLTA2LTAxVDEw
    OjM3OjU4KzAwOjAwjEbp/QAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMi0wNi0wMVQxMDozNzo1OCsw
    MDowMP0bUUEAAAAZdEVYdFNvZnR3YXJlAEFkb2JlIEltYWdlUmVhZHlxyWU8AAAAAElFTkSuQmCC" />
    </svg>`
  )
}

function Kangaroo() {
  return (
    `<svg version="1.1" id="Layer_1" x="0px" y="0px" width="28px" height="32px" viewBox="0 0 28 32" enableBackground="new 0 0 28 32">  
    <image id="image0" width="28" height="32" x="0" y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAgCAMAAAA2a+hwAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
    AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAB9VBMVEX////+/v7x8fL29vbj
    5OTq6+tkcX64vcO4vsRndIL19fXo6eo+UmZRZXl4hZFLYHXv8PCeqbMsSWYxU3VDW3Tb3d/d3t8/
    YYMtXY0vXYxlgZ7g5Of///7+/f37+vmzuL0wUXIvXo0yYY8wYZNve4j39vX5+fnL0dagrLeLmaeA
    kaFvhp5FbZU0aJw1XIOTnqqcrsDEx8r9/f35+fiUpbdAbZoyZJUxY5UxZJYyZpozZpkyZptefZ3v
    7+/IzNE+apUzZ5s0Z5o1aJsvXIowYJCXpraJnK8yZZg0Zpk1aZ0vWIEwWYORpLfd4ONOdZ00Z5sy
    Y5QuWoY0Zph0h5tLaIRWep3r7Oz8/PuOpLsrVX80ZJN6jZ/GzdRAXnxNaYXl5uj+/fyptcE/b586
    X4RIbJEtV4M2X4eQnarz8vLq7fC6wcfP0dL9/fzm5eWXoaw8aJQ6Z5OpsLi/w8kwTWowY5VRd5zk
    5OTBxcmwtrx6hI9JZH8yYpFEbpimsr3V2Ns5WnwuX5BXepzW2+Hx8/TMzc1ndIFAYYMwWIA8Yodn
    fZTEyc3//v7X2t46XH4tXIs0V3pYdJCbprHx8vP09PTR1Ni9wcXR0tP19PPu7/B8jqA4ZZI2X4lD
    bJRUe6FwjqvW2d3z8/O7xM2ywM2ot8axvMfHzdTz9PT6iuysAAAAAWJLR0QAiAUdSAAAAAlwSFlz
    AAASdAAAEnQB3mYfeAAAAAd0SU1FB+YJCwsNFHX58p4AAADxSURBVCjPY2AY0oCRiZGZBZckKxs7
    BycXDkluHl4+fgFcWgWFhEVEcUmKiUtISkljk5GRlZNXUFRSVlFFl1FT19DU0tbR1dM3MDRCkzM2
    MTUzt7C0sraxxTTSzt7B0dHRydnFFVNO1s3d0cPR0dPL20cGQ9LXz8E/IFA3KDgkNAxdLjxC1zLI
    MTIqOiY2Lh5NLiExKTkl1SotPSMzK5sRRSonNy+/oLCouKSUFU1TWXlFZVV1TW1OXX1DY1MzqmRL
    a1t7R2dXN0NPb1//hImTUP0/ecrUadOB9IyZs2bPmTtvPvYwX7Bw0eIlS5cxjDQAAKmBOek7B6eM
    AAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIyLTA5LTExVDExOjEzOjIwKzAwOjAw/UQzMAAAACV0RVh0
    ZGF0ZTptb2RpZnkAMjAyMi0wOS0xMVQxMToxMzoyMCswMDowMIwZi4wAAAAodEVYdGRhdGU6dGlt
    ZXN0YW1wADIwMjItMDktMTFUMTE6MTM6MjArMDA6MDDbDKpTAAAAAElFTkSuQmCC" />
    </svg>`
  )
}

export const ORGANIZATION_ICONS = {
  australianRedCross: AustralianRedCross(),
  hobartCityMission: HobartCityMission(),
  lifeline: Lifeline(),
  RSPCA: RSPCA(),
  sacredHeart: SacredHeart(),
  saveTheChildren: SaveTheChildren(),
  stVincentDePaul: StVincentDePaul(),
  theSalvationArmy: TheSalvationArmy(),
  kangaroo: Kangaroo()  
}

export default ORGANIZATION_ICONS
