import styled from 'styled-components'
import { ScreenContainer } from '../../../global/styles'

// eslint-disable-next-line import/prefer-default-export
export const PortalScreenContainer = styled(ScreenContainer)`
  z-index: 10000;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
`
