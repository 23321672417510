import styled from 'styled-components'
import { HStack, VStack } from '../../../global/styles'
import Typography from '../../../components/UI/Typography'

export const PreviewRouteWrap = styled(VStack)`
  z-index: 20;
  position: relative;
  margin-top: auto;
  padding: 24px 16px;
  margin-bottom: 96px;
  background-color: ${({ theme }) => theme.colors.white};
`
export const PreviewRouteHeader = styled(HStack)`
  padding-bottom: 12px;
  width: 100%;
  justify-content: flex-start;
  box-shadow: inset 0px -1px 0px rgba(208, 201, 214, 0.4);
  align-items: center;
`
export const PreviewShopContainer = styled(HStack)`
  padding-top: 16px;
`
export const Headline = styled(Typography).attrs(() => ({
  variant: 'textM'
}))`
  color: ${({ theme }) => theme.colors.skyNeutral_1};
  margin-left: 12px;
`
