import React from 'react'
import PropTypes from 'prop-types'
import useShopDetails from '../../../hooks/shop/useShopDetails'
import SearchResultItem from '../SearchResulItem'

const SearchResults = ({ shops, searchQuery, searchOption }) => {
  const { handleOpenShop } = useShopDetails()

  return (
    <div>
      {shops.map((shop, index) => (
        <SearchResultItem
          key={shop.id}
          id={shop.id}
          handleOpenShop={() => {
            handleOpenShop(shop.id)
          }}
          address={shop.location.suburbName}
          shopName={shop.storeName}
          lastVisited={shop.lastVisited}
          distance={shop.location.displayDistance}
          lat={shop.location.lat}
          lng={shop.location.lng}
          tags={shop.tags}
          donations={shop.donations}
          logotype={shop.logotype}
          rating={shop.rating}
          sequence={shop.sequence}
          isClosest={index === 0}
          searchQuery={searchQuery}
          searchOption={searchOption}
        />
      ))}
    </div>
  )
}

export default SearchResults

SearchResults.defaultProps = {
  shops: []
}

SearchResults.propTypes = {
  searchQuery: PropTypes.string.isRequired,
  searchOption: PropTypes.number.isRequired,
  shops: PropTypes.array
}

// <SearchResultItem
//   key={shop.id}
//   id={shop.id}
//   handleOpenShop={() => {
//     handleOpenShop(shop.id)
//   }}
//   address={shop.location.address}
//   shopName={shop.storeName}
//   isFavorite={shop.isFavorite}
//   lastVisited={shop.lastVisited}
//   favoriteText={shop.favoriteText}
//   distance={shop.location.distance}
//   lat={shop.location.lat}
//   lng={shop.location.lng}
//   logotype={shop.logotype}
//   rating={shop.rating}
//   sequence={shop.sequence}
// />
