/* eslint-disable */

// import {} from './styles'

import { useSelector } from 'react-redux'
import {
  selectCurrentCityId,
  selectCurrentCityShops,
  selectSelectedCityId,
  selectToken
} from '../../../store/selectors'
import {
  api,
  useLazyGetTagsQuery,
  useLazyGetUserCitiesQuery,
  useLazyGetUserShopByCityIdQuery
} from '../../../api/api.generated'
import { useEffect } from 'react'
import useHopCity from '../../../hooks/hopCities/useHopCity'
import { isNull } from 'lodash'
import { isEmpty } from 'lodash/lang'

const OnAppLaunch = () => {
  const currentCityId = useSelector(selectCurrentCityId)
  const selectedCityId = useSelector(selectSelectedCityId)
  const cityShops = useSelector(selectCurrentCityShops)
  const token = useSelector(selectToken)
  const [getShops] = useLazyGetUserShopByCityIdQuery()
  const [getCities] = useLazyGetUserCitiesQuery()
  const [getTags] = useLazyGetTagsQuery()

  useHopCity()

  useEffect(() => {
    if (token) {
      getCities()
      getShops({ cityId: selectedCityId })
      getTags()
    }
  }, [token])

  useEffect(() => {
    if (
      token &&
      (isEmpty(cityShops) ||
        isNull(currentCityId) ||
        currentCityId !== selectedCityId)
    ) {
      getShops({ cityId: selectedCityId })
    }
  }, [currentCityId, selectedCityId])

  //1. Check if user is logged in
  //2. check if we have cities and shops for current city. If yes, check if they are up to date
  //3. check current location, set current city
  //4. add on app launch hook
  //
  // get cities >  check if currCityID exists > get currcityId > getCurrCityShops

  return null
}

export default OnAppLaunch

OnAppLaunch.propTypes = {}
