import styled from 'styled-components'
import { VStack } from '../../../global/styles'

export const AppWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  align-items: stretch;
`
export const Content = styled(VStack)`
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
`

export const ContentWrap = styled.div`
  position: absolute;
  right: 0;
`
