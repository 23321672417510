import styled from 'styled-components'
import { HStack, ScreenContainer, VStack } from '../../../global/styles'
import { pxToVh } from '../../../utils/styles-utils'
import Typography from '../../../components/UI/Typography'

export const FilterHeader = styled(VStack)`
  height: ${pxToVh(116)};
  justify-content: flex-end;
  box-shadow: inset 0px -1px 0px rgba(95, 110, 130, 0.15);
  width: 100%;
  padding: 16px 24px;
`
export const FilterHeadlineWrap = styled(HStack)`
  width: 100%;
  justify-content: space-between;
`

export const FilterHeadline = styled(Typography).attrs(({ theme }) => ({
  color: theme.colors.skyNeutral_1,
  variant: 'headingM',
  bold: true
}))``
export const FilterContent = styled(VStack)`
  padding: 37px 20px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.skyNeutral5};
  > :last-child {
    margin-top: auto;
  }
`
export const FilterSection = styled(VStack)`
  margin-bottom: 40px;
`
export const FilterTitle = styled(Typography).attrs(({ theme }) => ({
  color: theme.colors.skyNeutral1,
  variant: 'headingS'
}))`
  margin-bottom: 20px;
`
export const BadgesWrap = styled(HStack)`
  flex-wrap: wrap;
  width: 100%;
  > * {
    margin-right: 10px;
    margin-bottom: 12px;
  }
`
export const FilterScreenContainer = styled(ScreenContainer)`
  z-index: 10000;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
`
