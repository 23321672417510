import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { keyBy } from 'lodash'
import ProductItem from '../../../components/ProductItem'
import Button from '../../../components/UI/Buttons/Button'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES as BUTTON_SIZES
} from '../../../const/UIvariants'
import CheckoutModal from '../../../components/Modals/CheckoutModal'
import { HStack } from '../../../global/styles'
import { ProductsWrap, ButtonWrap, OrderContentWrapper, ShopsWrap, ShopWrap, StoreButtonsWrap, TotalWrap, WarningWrap } from './styles'

const ShoppingCartItems = ({ tags, shops }) => {
  const [deliveryShops, setDeliveryShops] = useState([])
  const [total, setTotal] = useState(0)
  const [allowCheckout, setAllowCheckout] = useState(false)
  const [isCheckout, setIsCheckout] = useState(false)
  const [checkoutModal, setCheckoutModal] = useState(false)

  const tagsById = keyBy(tags, 'id')

  const handleSetIsCheckout = () => {
    setIsCheckout(true)
  }

  const handleOpenCheckout = () => {
    setCheckoutModal(true)
  }

  const handleCloseCheckout = () => {
    setCheckoutModal(false)
  }

  const handleSetDelivery = (id, value) => {
    setDeliveryShops (
      deliveryShops.map((shop) => shop.id === id ? { 
        ...shop, 
        value
      } : shop )
    );
  }

  const getDelivery = (id, value) => {
    const item = deliveryShops.find((shop) => shop.id === id );
    return item.value === value 
  }

  const isShopCheckoutDisabled = (id) => {
    const deliveryShop = deliveryShops.find((item) => item.id === id)
    return (deliveryShop && !deliveryShop.allowCheckout)
  }

  const getAllowCollect = (id) => {
    const deliveryShop = deliveryShops.find((item) => item.id === id)
    return (deliveryShop && deliveryShop.allowCollect)
  }

  const getAllowShipping = (id) => {
    const deliveryShop = deliveryShops.find((item) => item.id === id)
    return (deliveryShop && deliveryShop.allowShipping)
  }

  useEffect(() => {
    const tmp = shops.map(shop => ({ 
      id: shop.id, 
      totalCollect: shop.totalCollect, 
      totalDelivery: shop.totalDelivery,
      allowShipping: shop.products.filter((productItem) => productItem.isShippingEnabled).length === shop.products.length,
      allowCollect: shop.products.filter((productItem) => productItem.isCollectEnabled).length === shop.products.length
    }))
    const res = tmp.map(shop => ({ 
      id: shop.id, 
      value: shop.allowShipping, 
      totalCollect: shop.totalCollect, 
      totalDelivery: shop.totalDelivery,
      allowShipping: shop.allowShipping,
      allowCollect: shop.allowCollect,
      allowCheckout: shop.allowShipping || shop.allowCollect
    }))
    setDeliveryShops(res)
  }, [shops])

  useEffect(() => {
    const disabledShops = deliveryShops.filter((item) => !item.allowCheckout)
    setAllowCheckout(disabledShops.length === 0)

    setTotal(deliveryShops.reduce((accumulator, obj) => obj.value ? accumulator + obj.totalDelivery : accumulator + obj.totalCollect, 0))
  }, [deliveryShops])

  return (
    <>
      {!isCheckout &&
      <ButtonWrap>
        <Button
          variant={BUTTON_VARIANTS.OUTLINED}
          size={BUTTON_SIZES.MED}
          disabled={!allowCheckout}
          onClick={() => handleSetIsCheckout()}
        >
          Checkout
        </Button>
      </ButtonWrap>
      }
      {isCheckout &&
      <ButtonWrap>
        <ShopWrap>Total: ${total}</ShopWrap>
        <Button
          variant={BUTTON_VARIANTS.OUTLINED}
          size={BUTTON_SIZES.MED}
          onClick={() => handleOpenCheckout()}
        >
          Pay
        </Button>
      </ButtonWrap>
      }
      <OrderContentWrapper>
        <ProductsWrap>
          {shops.map((shop) => (
            <ShopsWrap key={shop.id}>
              <ShopWrap>{shop.name}</ShopWrap>
              <HStack>
                <TotalWrap>Collect: ${shop.totalCollect}</TotalWrap>
                <TotalWrap>Delivery: ${shop.totalDelivery}</TotalWrap>
              </HStack>
              {isShopCheckoutDisabled(shop.id) &&
                <WarningWrap>Some products in your cart only support 1 type of delivery/shipping</WarningWrap>
              }
              {isCheckout &&
                <StoreButtonsWrap>
                  <HStack>
                    {getAllowCollect(shop.id) &&
                    <Button
                      variant={ getDelivery(shop.id, false) ? BUTTON_VARIANTS.PRIMARY : BUTTON_VARIANTS.OUTLINED }
                      size={BUTTON_SIZES.MED}
                      onClick={() => handleSetDelivery(shop.id, false)}
                    >
                      Click and Collect
                    </Button>
                    }
                    {getAllowShipping(shop.id) &&
                    <Button
                      variant={ getDelivery(shop.id, true) ? BUTTON_VARIANTS.PRIMARY : BUTTON_VARIANTS.OUTLINED }
                      size={BUTTON_SIZES.MED}
                      onClick={() => handleSetDelivery(shop.id, true)}
                    >
                      Delivery
                    </Button>
                  }
                  </HStack>
                </StoreButtonsWrap>
              }
              { shop.products.map((product)  => (
                <ProductItem
                  key={product.id}
                  productId={product.id}
                  productImage={product.productImage}
                  productName={product.productName}
                  productPrice={product.productPrice}
                  shippingCost={product.shippingCost}
                  tags={product.tags.map((tag) => tagsById[tag].tagName)}
                  showBuyButton={!isCheckout}
                  isAdded
                  hideOnRemove
                  isShippingEnabled={product.isShippingEnabled}
                  isCollectEnabled={product.isCollectEnabled}
                />
                ))}
              </ShopsWrap>
          ))}
        </ProductsWrap>
      </OrderContentWrapper>
      { checkoutModal &&
        <CheckoutModal
          isOpen={checkoutModal}
          onClose={handleCloseCheckout}
          total={total}
          delivery={ deliveryShops.map((shop) => ({ id: shop.id, value: shop.value })) }
        />
      }
    </>
  )
}

export default ShoppingCartItems

ShoppingCartItems.propTypes = {
  tags: PropTypes.array.isRequired,
  shops: PropTypes.array.isRequired
}