import React from 'react'

import { Route, Redirect, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import ROUTES from '../../const/ROUTES'

const PrivateRoute = ({ children, path }) => {
  // to render routes that requires token
  const { token } = useSelector((state) => state.auth)

  return (
    <Switch>
      <Route
        {...{ path }}
        render={() =>
          token ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: ROUTES.LOGIN
              }}
            />
          )
        }
      />
    </Switch>
  )
}
PrivateRoute.defaultProps = {
  children: <p>Redirect</p>,
  fallbackRoute: ROUTES.LOGIN
}
PrivateRoute.propTypes = {
  children: PropTypes.node,
  path: PropTypes.string.isRequired,
  fallbackRoute: PropTypes.string
}

export default PrivateRoute
