import { createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { USER_ROLE } from '../../const/app'
import { api } from '../../api/api.generated'

const authInitState = {
  token: null,
  userId: null,
  role: USER_ROLE,
  timezone: null,
  email: '',
  phoneNumber: '',
  lastRestoreRequestTime: null,
  restoreRequestPhoneNumber: ''
}

const authSlice = createSlice({
  name: 'auth',
  initialState: authInitState,
  reducers: {
    logout: () => authInitState,
    setToken: (state, { payload }) => {
      state.token = payload
    },
    setLastRestoreRequestTime: (state) => {
      state.lastRestoreRequestTime = dayjs().format()
    },
    setRestorePhoneNumber: (state, { payload }) => {
      state.restoreRequestPhoneNumber = payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        api?.endpoints?.postUserLogin?.matchFulfilled,
        (state, { payload, meta }) => {
          state.token = payload.token
          state.userId = payload.userId
          state.phoneNumber = meta.arg.originalArgs.body.phoneNumber
          state.timezone = meta.arg.originalArgs.body.timezone
        }
      )
      .addMatcher(
        api?.endpoints?.postSignUp?.matchFulfilled,
        (state, { payload, meta }) => {
          state.token = payload.token
          state.userId = payload.userId
          state.phoneNumber = meta.arg.originalArgs.body.phoneNumber
          state.timezone = meta.arg.originalArgs.body.timezone
          state.email = meta.arg.originalArgs.body.email
        }
      )
  }
})
export const {
  logout,
  setToken,
  setLastRestoreRequestTime,
  setRestorePhoneNumber
} = authSlice.actions
export default authSlice.reducer
