import styled from 'styled-components'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { VStack, HStack } from '../../../global/styles'
import Typography from '../../../components/UI/Typography'
import { pxToVh } from '../../../utils/styles-utils'

export const UserInfo = styled(VStack)`
  position: relative;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  margin: 20px 0 36px 0;
`
export const ButtonWrapper = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.colors.skyBase};
  border-radius: 50px;
  width: 32px;
  height: 32px;
  top: 90px;
  right: 0;
`

export const Headline = styled(Typography).attrs({
  variant: 'headingL',
  bold: true
})`
  color: ${({ theme }) => theme.colors.skyNeutral_1};
  margin-top: 16px;
`
export const DescriptionWrapper = styled(HStack)`
`
export const Description = styled(Typography).attrs({
  variant: 'textS',
  bold: true
})`
  color: ${({ theme }) => theme.colors.skyNeutral1};
  margin-top: 2px;
`
export const Value = styled(Typography).attrs({
  variant: 'textS',
})`
  color: ${({ theme }) => theme.colors.skyNeutral1};
  margin-top: 2px;
`
export const MenuItemsWrap = styled(VStack)`
  margin-bottom: 26px;
`
export const Container = styled(VStack)`
  align-items: center;
  background: ${({ theme }) => theme.colors.skyNeutral5};
  height: 100%;
`

export const ScrollWrapper = styled(OverlayScrollbarsComponent).attrs({
  options: {
    scrollbars: { visibility: 'hidden' }
  }
})`
  height: 100%;
  width: 100%;
  padding: 18px 0 20px 0;
`
export const ButtonWrap = styled.div`
  padding: 0 16px;
  width: 100%;
`
export const ScrollablePart = styled(VStack)`
  align-items: center;
  padding-bottom: ${pxToVh(140)};
`
