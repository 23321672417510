/*eslint-disable*/
import styled from 'styled-components'
import { BUTTONS_SIZES, BUTTON_VARIANTS } from '../../../../const/UIvariants'
import { iconButtonSize, setLightness } from '../../../../utils/styles-utils'

export const StyledButton = styled.button`
  cursor: pointer;
  height: ${({ size }) => size}px;
  min-height: ${({ size }) => size}px;
  max-height: ${({ size }) => size}px;
  //transition: background-color 0.2s ease-in-out;
  background-color: ${({ theme, variant, fillColor }) =>
    variant === BUTTON_VARIANTS.PRIMARY
      ? fillColor || theme.colors.skyBase
      : variant === BUTTON_VARIANTS.SECONDARY
      ? theme.colors.skyBase4
      : variant === BUTTON_VARIANTS.PINK
      ? theme.colors.pinkBase
      : variant === BUTTON_VARIANTS.GREY
      ? theme.colors.skyNeutral4
      : 'transparent'};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 1;
  border-radius: 8px;
  overflow: hidden;
  flex-basis: ${({ isFullWidth }) => (isFullWidth ? '100%' : 'auto')};
  width: ${({ isFullWidth }) => (isFullWidth ? '100%' : 'auto')};
  padding: ${({ size }) =>
    size === BUTTONS_SIZES.LARGE
      ? '0 20px '
      : size === BUTTONS_SIZES.MED
      ? '0 11px'
      : '0 6px'};
  color: ${({ theme, variant, fillColor }) =>
    variant === BUTTON_VARIANTS.PRIMARY
      ? theme.colors.white
      : variant === BUTTON_VARIANTS.SECONDARY
      ? theme.colors.skyBase_1
      : variant === BUTTON_VARIANTS.OUTLINED
      ? theme.colors.skyBase
      : variant === BUTTON_VARIANTS.PINK
      ? theme.colors.white
      : variant === BUTTON_VARIANTS.GREY
      ? theme.colors.skyNeutral2
      : fillColor || 'transparent'};
  border: ${({ variant, theme }) =>
    variant === BUTTON_VARIANTS.OUTLINED
      ? `2px solid ${theme.colors.skyCreator(78, 89)}`
      : 'none'};
  word-wrap: normal;

  p,
  h6,
  h5 {
    white-space: nowrap;
    margin: ${({ size }) =>
      size === BUTTONS_SIZES.LARGE
        ? ' 0 8px '
        : size === BUTTONS_SIZES.MED
        ? ' 0 13px'
        : ' 0 12px'};
    color: ${({ theme, variant, fillColor }) =>
      variant === BUTTON_VARIANTS.PRIMARY
        ? theme.colors.white
        : variant === BUTTON_VARIANTS.SECONDARY
        ? theme.colors.skyBase_1
        : variant === BUTTON_VARIANTS.OUTLINED
        ? theme.colors.skyBase
        : variant === BUTTON_VARIANTS.PINK
        ? theme.colors.white
        : variant === BUTTON_VARIANTS.GREY
        ? theme.colors.skyNeutral2
        : fillColor || 'transparent'};
  }

  &:only-of-type {
    flex-basis: 100%;
    width: 100%;
  }

  &:disabled {
    p {
      color: ${({ theme }) => theme.colors.skyNeutral2};
    }
    background-color: ${({ theme }) => theme.colors.skyNeutral4};
  }
`
export const LeftIconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ size }) => iconButtonSize(size).width}px;
  height: ${({ size }) => iconButtonSize(size).height}px;
  margin-left: ${({ size }) =>
    size === BUTTONS_SIZES.LARGE
      ? '12px '
      : size === BUTTONS_SIZES.MED
      ? '15px'
      : '6px'};
`
export const RightIconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ size }) => iconButtonSize(size).width}px;
  height: ${({ size }) => iconButtonSize(size).height}px;
  margin-right: ${({ size }) =>
    size === BUTTONS_SIZES.LARGE
      ? '12px '
      : size === BUTTONS_SIZES.MED
      ? '15px'
      : '6px'};
`
//   &:active {
//   background-color: ${({ theme, variant, fillColor }) =>
//   variant === BUTTON_VARIANTS.PRIMARY
//     ? (fillColor && setLightness(fillColor, 30)) ||
//     theme.colors.skyCreator(66, 29)
//     : variant === BUTTON_VARIANTS.OUTLINED
//       ? theme.colors.skyBase3
//       : variant === BUTTON_VARIANTS.PINK
//         ? theme.colors.pinkBase3
//         : variant === BUTTON_VARIANTS.GREY
//           ? theme.colors.skyNeutral1
//           : theme.colors.skyCreator(20, 75)};
// color: ${({ theme, variant }) =>
// variant === BUTTON_VARIANTS.OUTLINED ? theme.colors.skyBase_2 : 'white'};
// }
