import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash/lang'
import { VolunteersWrap, ButtonWrap, TitleWrap, TitleText, MessageWrap } from './styles'
import { useGetUserShopByShopIdAboutQuery } from '../../../api/api.generated'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { IsEmptyText } from '../ShopPosts/styles'
import VolunteerItem from '../../../components/VolunteerItem'
import Button from '../../../components/UI/Buttons/Button'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES as BUTTON_SIZES
} from '../../../const/UIvariants'
import AddNewVolunteerModal from '../AddNewVolunteerModal'

const ShopVolunteers = ({ shopId }) => {
  const [addNewModalIsOpen, setAddNewModalIsOpen] = useState(false)
  const { shopVolunteers, volunteerTitle, volunteerMessage, isLoading } = useGetUserShopByShopIdAboutQuery(
    { shopId },
    {
      selectFromResult: ({ data }) => ({
        shopVolunteers: data?.shopData?.volunteers,
        volunteerTitle: data?.shopData?.volunteerTitle,
        volunteerMessage: data?.shopData?.volunteerMessage,
      })
    }
  )

  const handleOpenAddVolunteer = () => {
    setAddNewModalIsOpen(true)
  }

  const handleCloseAddVolunteer = () => {
    setAddNewModalIsOpen(false)
  }

  /*
  const createMarkup = () => ({__html: volunteerMessage})
  <div dangerouslySetInnerHTML={createMarkup()} />
  */

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <AddNewVolunteerModal
        isOpen={addNewModalIsOpen}
        onClose={handleCloseAddVolunteer}
        shopId={shopId}
      />
      {volunteerTitle &&
        <TitleWrap>
          <TitleText>{volunteerTitle}</TitleText>
        </TitleWrap>
      }
      {volunteerMessage &&
        <MessageWrap>
          <IsEmptyText>{volunteerMessage}</IsEmptyText>
        </MessageWrap>
      }
      <ButtonWrap>
        <Button
          variant={BUTTON_VARIANTS.OUTLINED}
          size={BUTTON_SIZES.MED}
          onClick={handleOpenAddVolunteer}
        >
          Add Volunteer
        </Button>
      </ButtonWrap>
      {isEmpty(shopVolunteers) && (
        <IsEmptyText>This store doesn&apos;t have any volunteers</IsEmptyText>
      )}
      {shopVolunteers && (
        <VolunteersWrap>
          {shopVolunteers.map((volunteer) => (
            <VolunteerItem
              key={volunteer.id}
              name={volunteer.name}
              email={volunteer.email}
              phone={volunteer.phone}
              message={volunteer.message}
            />
          ))}
        </VolunteersWrap>
      )}
    </>
  )
}

export default ShopVolunteers

ShopVolunteers.propTypes = {
  shopId: PropTypes.string.isRequired
}
