import React from 'react'
import PropTypes from 'prop-types'
import { ArrowBackIos } from '@styled-icons/material'
import { useHistory } from 'react-router-dom'
import IconButton from '../UI/Buttons/IconButton'
import {
  ActionButton,
  HeaderTitle,
  HeaderBody,
  HeadlineWrapper
} from './styles'

const ScreenHeader = ({
  title,
  actionIsDisabled,
  backButton,
  actionTitle,
  actionHandler
}) => {
  const { goBack } = useHistory()
  return (
    <HeaderBody>
      <HeadlineWrapper>
        {backButton && (
          <IconButton
            size={24}
            onClick={() => {
              goBack()
            }}
          >
            <ArrowBackIos size={24} color="#454E60" />
          </IconButton>
        )}
        <HeaderTitle>{title}</HeaderTitle>
        {actionTitle && actionHandler ? (
          <ActionButton
            disabled={actionIsDisabled}
            type="submit"
            onClick={actionHandler}
          >
            {actionTitle}
          </ActionButton>
        ) : null}
      </HeadlineWrapper>
    </HeaderBody>
  )
}

export default ScreenHeader
ScreenHeader.defaultProps = {
  backButton: false,
  actionTitle: null,
  actionHandler: null,
  actionIsDisabled: false
}
ScreenHeader.propTypes = {
  title: PropTypes.string.isRequired,
  backButton: PropTypes.bool,
  actionTitle: PropTypes.string,
  actionHandler: PropTypes.func,
  actionIsDisabled: PropTypes.bool
}
