import React, { useEffect } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ScreenContainer } from '../../../global/styles'
import { FRIENDS_TABS } from '../../../const/tabs'
import { FriendsContentWrapper } from './styles'
import FallbackRoute from '../../../components/FallbackRoute'
import ROUTES from '../../../const/ROUTES'
import { selectLastOpenFriendsTab } from '../../../store/selectors'
import FriendsHeader from '../FriendsHeader'

const ContactsScreenContainer = () => {
  const lastVisitedTab = useSelector(selectLastOpenFriendsTab)
  const history = useHistory()
  useEffect(() => {
    if (!history.location.pathname.includes(lastVisitedTab)) {
      history.replace(ROUTES.FRIENDS + lastVisitedTab)
    }
  }, [])

  return (
    <ScreenContainer>
      <FriendsHeader />
      <FriendsContentWrapper>
        <Switch>
          {FRIENDS_TABS.map((routeItem) => (
            <Route
              key={routeItem.destination}
              path={routeItem.destination}
              render={routeItem.Component}
            />
          ))}
          <FallbackRoute fallbackPath={ROUTES.FRIENDS_CONTACTS} />
        </Switch>
      </FriendsContentWrapper>
    </ScreenContainer>
  )
}

export default ContactsScreenContainer

ContactsScreenContainer.propTypes = {}
