import styled from 'styled-components'
import { HStack, VStack } from '../../global/styles'
import Typography from '../UI/Typography'
import { pxToVh } from '../../utils/styles-utils'

export const TagWrapper = styled(VStack)`
  min-width: 80px;
  /*
  max-width: ${pxToVh(60)};
  margin-right: 16px;
  */
  width: auto;
  margin-left: 1px; 
  margin-right: 1px;
  align-items: center;
  &:first-of-type {
    padding-left: 16px;
  }
  &:last-of-type {
    padding-right: 16px;
  }
`
export const TagIconBackground = styled(HStack)`
  overflow: hidden;
  background-color: ${({ bgColor }) => bgColor};
  width: 46px;
  border-radius: 46px;
  align-items: center;
  justify-content: center;
  height: 46px;
  position: relative;
  border: 2px solid ${({ theme }) => theme.colors.skyNeutral3};
`
export const TagLabel = styled(Typography).attrs({
  variant: 'textXS'
})`
  color: ${({ theme }) => theme.colors.skyNeutral};
  text-align: center;
`
export const TagIconShiftedBg = styled(HStack)`
  position: absolute;
  right: -16px;
  top: -16px;
  background-color: ${({ bgColor }) => bgColor};
  width: 46px;
  filter: brightness(2);
  opacity: 0.5;
  border-radius: 46px;
  align-items: center;
  justify-content: center;
  height: 46px;
`
