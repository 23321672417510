import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from 'styled-components'
import { Place } from '@styled-icons/material'
import { HStack } from '../../global/styles'
import Typography from '../UI/Typography'

const HopDrawerLocation = ({ city, theme }) => (
  <HStack alignItems="center" width="auto">
    <Place size={24} color={theme.colors.skyNeutral_1} />
    <Typography variant="headingM" bold color={theme.colors.skyNeutral_1}>
      {city}
    </Typography>
  </HStack>
)

export default withTheme(HopDrawerLocation)

HopDrawerLocation.propTypes = {
  city: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired
}
