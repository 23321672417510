import styled from 'styled-components'
import { HStack, VStack } from '../../global/styles'
import Typography from '../UI/Typography'

export const DonationItemWrap = styled(HStack)`
  box-shadow: inset 0px -1px 0px rgba(208, 201, 214, 0.4);
  align-items: center;
  height: 80px;
  padding: 0 16px;
`
export const TagIconWrap = styled(VStack)`
  justify-content: center;
  margin-right: 20px;
  align-items: center;
  border-radius: 50px;
  width: 42px;
  height: 42px;
  background-color: ${({ backgroundColor }) => backgroundColor};
`
export const TagLabel = styled(Typography).attrs(({ theme }) => ({
  color: theme.colors.skyNeutral,
  variant: 'textM'
}))`
  margin-left: 10px;
  text-align: left;
`

export const DonationDataWrap = styled(VStack)``

export const DonationData = styled(Typography).attrs(({ theme }) => ({
  variant: 'textXS',
  color: theme.colors.skyNeutral_1
}))`
  margin-bottom: auto;
`

export const DonationNameWrap = styled(HStack)`
`