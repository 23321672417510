import { useState } from 'react'
import {
  usePostCheckCredentialsMutation,
  usePostSignUpMutation
} from '../../api/api.generated'
import { adaptSignupData } from '../../utils/adapters'
import { setToken } from '../../utils/axios-utils'

export const SIGN_UP_STEPS = {
  CONTACTS: 'CONTACTS',
  PASSWORD: 'PASSWORD'
}
const useSignUp = () => {
  const [passwordInputType, setPasswordInputType] = useState('password')
  const [showSuccess, setShowSuccess] = useState(false)
  const [step, setStep] = useState(SIGN_UP_STEPS.CONTACTS)
  const [checkCredentials, { isLoading: checkingCredentials }] =
    usePostCheckCredentialsMutation()
  const [createNewAccount, { isLoading: creatingAccount }] =
    usePostSignUpMutation()
  const handleCreateAccount = (values, formik) => {
    createNewAccount({ body: adaptSignupData(values) })
      .unwrap()
      .then((res) => {
        if (res.token) {
          setToken(res.token)
          setShowSuccess(true)
        }
      })
      .catch((err) => {
        if (err?.data?.message?.verificationCode) {
          formik?.setErrors(err?.data?.message)
        }
      })
  }

  const handleCheckContacts = (values, setFieldError) => {
    checkCredentials({
      body: { email: values.email, phoneNumber: values.phoneNumber }
    })
      .unwrap()
      .then((res) => {
        if (res?.errors?.phoneNumber || res?.errors?.email) {
          setFieldError(res.errors)
        }
        if (!res.emailIsAvailable) {
          setFieldError('email', 'Email is not available')
        }
        if (!res.phoneIsAvailable) {
          setFieldError('phoneNumber', 'Phone number is not available')
        }
        if (res.emailIsAvailable && res.phoneIsAvailable) {
          setStep(SIGN_UP_STEPS.PASSWORD)
        }
      })
      .catch((err) => {
        if (err?.data?.errors?.phoneNumber || err?.data?.errors?.email) {
          setFieldError('phoneNumber', err?.data?.errors?.phoneNumber?.[0])
          setFieldError('email', err?.data?.errors?.email?.[0])
        }
      })
  }

  const handleShowPassword = () => {
    if (passwordInputType === 'password') {
      setPasswordInputType('text')
      return
    }
    setPasswordInputType('password')
  }
  return {
    step,
    showSuccess,
    checkingCredentials,
    creatingAccount,
    passwordInputType,
    handleCreateAccount,
    handleCheckContacts,
    handleShowPassword
  }
}

export default useSignUp
