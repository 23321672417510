import styled from 'styled-components/macro'
import { Form } from 'formik'
import { HStack, VStack } from '../../../global/styles'

export const FormWrap = styled(VStack)`
  justify-content: flex-start;
  min-height: 500px;
`
export const StyledForm = styled(Form)`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: column;
`
export const TitleWrap = styled(HStack)`
  padding: 32px 32px 0 32px;
  width: 100%;
  align-items: stretch;
`
export const FormInputsWrap = styled.div`
  @media only screen and (min-width: 861px) {
    flex-direction: row;
  } 
  @media only screen and (max-width: 860px) {
    flex-direction: column;
  }
  align-items: ${({ alignItems }) => alignItems ?? 'flex-start'};
  justify-content: ${({ justify }) => justify ?? 'flex-start'};
  display: flex;
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  padding: 32px 32px 0 32px;
  width: 100%;
  align-items: stretch;
`
export const LeftHalf = styled(VStack)`
  @media only screen and (min-width: 861px) {
    width: 50%;
    min-width: 365px;
    margin-right: 28px;
  }
  align-items: stretch;
`
export const RightHalf = styled(VStack)`
  @media only screen and (min-width: 861px) {
    width: 50%;
    min-width: 365px;
    margin-left: 28px;
  }
  align-items: stretch;
`
export const CreditCardExpire = styled(HStack)`
  padding: 0;
  margin: 0;
`
export const ErrorWrap = styled(HStack)`
  padding: 0 32px 32px 32px;
  width: 100%;
  align-items: stretch;
`
export const ActionButtonsSection = styled.div`
  display: flex;
  border-top: 1px solid hsla(220, 66%, 8%, 0.1);
  padding: 16px 32px;
`
export const ButtonWrap = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  width: 100%;
  > :first-child {
    margin-right: 16px;
  }  
`
