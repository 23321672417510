import styled from 'styled-components'
import { HStack, VStack } from '../../../global/styles'
import Typography from '../../../components/UI/Typography'
import { pxToVh } from '../../../utils/styles-utils'

export const TabsWrap = styled(HStack).attrs({ as: 'ul' })`
  height: 52px;
  z-index: 10;
`
export const HeaderWrap = styled(VStack)`
  min-height: ${pxToVh(164)};
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.03);
  justify-content: flex-end;
  position: fixed;
`
export const HeadlineWrap = styled(HStack)`
  justify-content: space-between;
  padding: 0 16px;
  align-items: center;
  margin-bottom: ${pxToVh(16)};
`

export const Headline = styled(Typography).attrs({
  variant: 'textL',
  bold: true
})`
  color: ${({ theme }) => theme.colors.skyNeutral_1};
`
