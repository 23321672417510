import styled from 'styled-components'
import { HStack, VStack } from '../../global/styles'
import Typography from '../UI/Typography'

export const ProductItemWrap = styled(HStack)`
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  position: relative;
  padding: 10px;
  box-shadow: inset 0px -1px 0px rgba(208, 201, 214, 0.4);
`
export const ProductImage = styled.div`
  width: 88px;
  min-width: 88px;
  height: 88px;
  background: url(${({ background }) => background}) center;
  background-size: cover;
  border-radius: 6px;
`
export const ProductDetailsWrap = styled(VStack)`
  margin-left: 20px;
  justify-content: flex-end;
  //height: 88px;
  align-self: stretch;
`
export const ProductName = styled(Typography).attrs(({ theme }) => ({
  variant: 'textM',
  color: theme.colors.skyNeutral1
}))``

export const ProductPrice = styled(Typography).attrs(({ theme }) => ({
  variant: 'textM',
  color: theme.colors.skyNeutral_1
}))`
  margin-bottom: auto;
`
export const ShippingCost = styled(Typography).attrs(({ theme }) => ({
  variant: 'textM',
  color: theme.colors.skyNeutral_1
}))`
  margin-bottom: auto;
  margin-left: 20px;
`
