import React, { useState } from 'react'
import { VStack } from '../../../global/styles'
import ContactListItem from '../../../components/ContactListItem'
import { useGetUserContactsQuery } from '../../../api/api.generated'
import LoadingSpinner from '../../../components/LoadingSpinner'
import usePersistOpenFriendsTab from '../../../hooks/friends/usePersistOpenFriendsTab'
import SendMessageModal from '../../../components/Modals/SendMessageModal'

const Contacts = () => {
  usePersistOpenFriendsTab('/contacts')
  const [sendMessageIsOpen, setSendMessageIsOpen] = useState(false)
  const [sendMessageUserId, setSendMessageUserId] = useState(null)
  const { data: { contacts } = {}, isLoading } = useGetUserContactsQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true
    }
  )

  const handleOpenSendMessage = (userId) => {
    setSendMessageUserId(userId);
    setSendMessageIsOpen(true)
  }

  const handleCloseSendMessage = () => {
    setSendMessageIsOpen(false)
  }

  return (
    <VStack>
      {isLoading && <LoadingSpinner />}
      {contacts &&
        contacts.map((contact) => (
          <ContactListItem
            key={contact.id}
            id={contact.id}
            contactName={contact.contactName}
            preferredPronouns={contact.preferredPronouns}
            contactAvatar={contact.contactAvatar}
            contactLocation={contact.contactLocation}
            bgColor={contact.avatarBackground}
            onSendMessage={handleOpenSendMessage}
          />
        ))}
      { sendMessageIsOpen && sendMessageUserId &&
      <SendMessageModal
        isOpen={sendMessageIsOpen}
        onClose={handleCloseSendMessage}
        userId={sendMessageUserId}
      />
      }
    </VStack>
  )
}

export default Contacts

Contacts.propTypes = {}
