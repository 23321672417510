import styled from 'styled-components'

export const ShopLogoImage = styled.img`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  min-width: ${({ size }) => size}px;
  border-radius: 100px;
  border: ${({ theme, error, isCurrentTarget }) =>
    error
      ? `1px solid ${theme.colors.earthBase}`
      : isCurrentTarget
      ? `2px solid ${theme.colors.skyBase}`
      : `1px solid ${theme.colors.skyNeutral3}`};
`
export const EmptyShopLogo = styled(ShopLogoImage).attrs({ as: 'div' })`
  display: flex;
  justify-content: center;
  align-items: center;
`
